import { Divider, Typography } from '@strise/ui-components'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { Timespan } from '~/components/Timespan/Timespan'
import { type BaseEntityLikeFragment, type RoleMetaFragment } from '~/graphqlTypes'
import { Trans } from '@lingui/macro'
import React from 'react'

export const RoleWithDetailedEntity = ({
  entity,
  otherIndustries,
  otherRoles
}: {
  entity: BaseEntityLikeFragment
  otherIndustries: string[]
  otherRoles: { company: BaseEntityLikeFragment; roles: RoleMetaFragment[] }[]
}): React.ReactNode => {
  return (
    <div className='w-full'>
      <EntityLink entity={entity} />

      <Typography className='mt-2 block' variant='aLabelBold'>
        <Trans>Other industries</Trans>
      </Typography>
      {otherIndustries.map((industryName, index) => {
        return <Typography key={index}>{industryName}</Typography>
      })}

      <Typography className='mt-2 block' variant='aLabelBold'>
        <Trans>Other roles</Trans>
      </Typography>
      {otherRoles.map((otherRole, index) => {
        const isLast = index === otherRoles.length - 1

        return (
          <React.Fragment key={index}>
            <div className='grid grid-cols-[2fr,3fr] gap-4'>
              <div className='min-h-flags-chip'>
                <EntityLink entity={otherRole.company} />
              </div>
              <div>
                {otherRole.roles.map((role, roleIndex) => {
                  return (
                    <div className='flex min-h-flags-chip items-center justify-between' key={roleIndex}>
                      <Typography className='w-1/2'>{role.roleTitle}</Typography>
                      <Timespan
                        className='w-1/2'
                        isActive={role.isActive}
                        timespan={role.prettyTimespan}
                        duration={role.prettyDuration}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            {!isLast && <Divider />}
          </React.Fragment>
        )
      })}
    </div>
  )
}
