import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSlideArrows = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M22 13H2v-2h20v2Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m7.293 5.293 1.414 1.414L3.414 12l5.293 5.293-1.414 1.414L.586 12l6.707-6.707ZM16.707 18.707l-1.414-1.414L20.586 12l-5.293-5.293 1.414-1.414L23.414 12l-6.707 6.707Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconSlideArrows.displayName = 'IconSlideArrows'
