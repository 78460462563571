import { cn, Scrollbar, type ScrollbarProps } from '@strise/ui-components'
import * as React from 'react'
import { getBrowserGlobals } from '@strise/react-utils'
import { type DivPropsWithChildren } from '@strise/react-utils'

export const StickyScrollbar: React.FC<
  {
    scrollbarProps?: ScrollbarProps
  } & DivPropsWithChildren
> = ({ children, className, scrollbarProps, ...props }) => {
  const [scrollEl, setScrollEl] = React.useState<HTMLElement | null>(null)
  const scrollThumbRef = React.useRef<HTMLDivElement | null>(null)
  const resizeObserver = React.useRef<ResizeObserver | null>(null)

  const [thumbWidth, setThumbWidth] = React.useState(0)
  const [viewportWidth, setViewportWidth] = React.useState(0)
  const [scrollWidth, setScrollWidth] = React.useState(0)

  const scrollState = React.useRef({
    left: 0,
    right: 0,
    width: 0,
    raf: true
  })

  const handleMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (scrollEl && scrollState.current.raf) {
        scrollState.current.raf = false
        getBrowserGlobals()?.window.requestAnimationFrame(() => {
          scrollState.current.raf = true

          const localOffset = event.clientX - scrollState.current.left
          const localOffsetPercentage = localOffset / scrollState.current.width

          scrollEl.scrollLeft = localOffsetPercentage * scrollWidth
        })
      }
    },
    [scrollEl, scrollWidth]
  )

  const handleMouseUp = React.useCallback(() => {
    if (scrollThumbRef.current) {
      scrollThumbRef.current.style.opacity = ''
    }

    getBrowserGlobals()?.window.removeEventListener('mousemove', handleMouseMove)
    getBrowserGlobals()?.window.removeEventListener('mouseup', handleMouseUp)
  }, [handleMouseMove])

  const setScrollWidths = (el: HTMLElement): void => {
    const elWidth = el.clientWidth
    const elScrollWidth = el.scrollWidth
    const ratio = elWidth / elScrollWidth

    setThumbWidth(elWidth * ratio - 4)
    setViewportWidth(elWidth)
    setScrollWidth(elScrollWidth - elWidth)
  }

  React.useEffect(() => {
    if (!scrollEl) return

    setScrollWidths(scrollEl)
    resizeObserver.current = new ResizeObserver(() => {
      setScrollWidths(scrollEl)
    })
    resizeObserver.current.observe(scrollEl)

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
      }

      getBrowserGlobals()?.window.removeEventListener('mousemove', handleMouseMove)
      getBrowserGlobals()?.window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [scrollEl, handleMouseMove, handleMouseUp])

  const handleScroll = (): void => {
    if (scrollThumbRef.current && scrollEl) {
      scrollThumbRef.current.style.transform = `translateX(${scrollEl.scrollLeft * (thumbWidth / viewportWidth)}px)`
    }
  }

  const handleMouseDown = (event: React.MouseEvent): void => {
    if (scrollEl && scrollThumbRef.current) {
      event.preventDefault()

      const scrollViewportRect = scrollEl.getBoundingClientRect()
      const scrollThumbRect = scrollThumbRef.current.getBoundingClientRect()

      const thumbX = event.clientX - scrollThumbRect.left

      scrollState.current.left = scrollViewportRect.left + thumbX
      scrollState.current.right = scrollViewportRect.right - scrollThumbRect.width + thumbX
      scrollState.current.width = scrollState.current.right - scrollState.current.left

      scrollThumbRef.current.style.opacity = '1'

      getBrowserGlobals()?.window.addEventListener('mousemove', handleMouseMove)
      getBrowserGlobals()?.window.addEventListener('mouseup', handleMouseUp)
    }
  }

  const { className: scrollbarClassName, ...restScrollbarProps } = scrollbarProps || {}

  return (
    <div className='relative'>
      <div
        ref={setScrollEl}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={cn('system-hide-native-scrollbar overflow-auto', className)}
        onScroll={handleScroll}
        {...props}
      >
        {children}
      </div>
      <Scrollbar
        className={cn('sticky bottom-0', scrollbarClassName)}
        ref={scrollThumbRef}
        thumbProps={{
          style: { width: thumbWidth },
          onMouseDown: handleMouseDown
        }}
        {...restScrollbarProps}
      />
    </div>
  )
}
