import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconHeart = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M6.953 3C4.987 3 3 4.648 3 7.378c0 2.735 1.165 4.282 2.71 6.332l.254.337c.721.951 2.39 2.743 3.922 4.34.758.789 1.469 1.516 1.99 2.046l.146.148.149-.15c.524-.53 1.238-1.256 1.998-2.045 1.54-1.599 3.202-3.385 3.895-4.325l.006-.008.16-.213C19.82 11.734 21 10.17 21 7.378 21 4.648 19.024 3 17.062 3c-.923 0-1.964.537-2.868 1.208a11.351 11.351 0 0 0-1.413 1.25l-.016.018-.002.002v.001l-.742.824-.743-.823v-.001l-.003-.002-.016-.017a9.803 9.803 0 0 0-.364-.364c-.255-.244-.62-.566-1.053-.887C8.935 3.539 7.888 3 6.953 3Zm5.066.41c-.27-.243-.604-.526-.988-.81C10.037 1.867 8.55 1 6.953 1 3.853 1 1 3.573 1 7.378c0 3.42 1.543 5.46 3.062 7.469l.304.403.003.004c.805 1.062 2.563 2.944 4.074 4.518a237.668 237.668 0 0 0 2.635 2.699l.173.174.06.061.709-.706-.708.706.705.707.708-.704L12.02 22l.705.71.062-.063.174-.174a205.129 205.129 0 0 0 2.648-2.7c1.512-1.57 3.273-3.453 4.062-4.52l.221-.294C21.45 12.899 23 10.849 23 7.378 23 3.582 20.166 1 17.062 1c-1.591 0-3.071.868-4.06 1.602-.382.283-.715.565-.983.808Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconHeart.displayName = 'IconHeart'
