import * as React from 'react'
import { type DisplayLanguage } from '@strise/types'
import { type LinkProps } from '@strise/ui-components'
import { displayLanguage } from './state'
import { type BaseEntityLikeFragment } from './graphqlTypes'
import { createContext } from '@strise/react-utils'

interface AppSharedContextProps {
  EntityIdLink: React.FC<{ id: string } & Omit<LinkProps, 'to'>>

  EntityLink: React.FC<
    {
      entity: BaseEntityLikeFragment
      noFlags?: boolean
      noLifeStatus?: boolean
      noTooltip?: boolean
    } & Omit<LinkProps, 'to'>
  >
  EventLink: React.FC<{ entityId: string; eventId: string } & Omit<LinkProps, 'to'>>
  language: DisplayLanguage | undefined
}

export const AppSharedContext = createContext<AppSharedContextProps>()

export const AppSharedContextProvider = ({
  children,
  value
}: {
  children: React.ReactNode
  value: AppSharedContextProps
}): React.ReactNode => {
  React.useEffect(() => {
    displayLanguage(value.language)
  }, [value.language])

  return <AppSharedContext.Provider value={value}>{children}</AppSharedContext.Provider>
}
