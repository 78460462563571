import { Button, IconReview, Typography } from '@strise/ui-components'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { STORAGE_KEYS } from '~/constants'
import { type BaseReviewCompanyFragment, type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { Trans } from '@lingui/macro'
import { useContext, usePersistentObjectState } from '@strise/react-utils'
import { type SetStateFn } from '@strise/react-utils'
import { type ReviewState } from '~/utils/reviewUtils2'
import { isFunction } from 'lodash-es'
import * as React from 'react'
import { TestIDs } from '~/utils/testIDs'
import { ReviewCompanyCard2 } from '~/components/Review/ReviewCompanyCard2'

const createReviewStateId = (companyId: string, teamId: string): string => `${companyId}-${teamId}`

export const ReviewContent2 = ({
  companies,
  filterHeight,
  infiniteScrollItemRef,
  loading,
  teamId,
  teamReviewSettings,
  toggleFilterOpen
}: {
  companies: BaseReviewCompanyFragment[]
  filterHeight: number | undefined
  infiniteScrollItemRef: React.Ref<HTMLDivElement>
  loading: boolean
  teamId: string
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
  toggleFilterOpen: () => void
}): React.ReactNode => {
  const { isMediumScreen } = useContext(ContentViewContext)
  const { removeItem, setItem, state } = usePersistentObjectState<ReviewState>(STORAGE_KEYS.reviewState2, {
    maxSize: 5,
    daysToLive: 7
  })

  const resetState = (companyId: string): void => {
    removeItem(createReviewStateId(companyId, teamId))
  }

  if (!companies.length && !loading) {
    return (
      <div className='mt-16 flex flex-col items-center justify-center text-center' data-id={TestIDs.Review.emptyState}>
        <IconReview className='mb-4 size-[84px] text-secondary-shade-30' />
        <Typography className='mb-4' variant='h2'>
          <Trans>No companies to show</Trans>
        </Typography>
        <Typography className='mb-4 w-3/4' variant='body1'>
          <Trans>Explore more companies by adjusting one of the many filters</Trans>
        </Typography>
        <Button
          variant='contained'
          palette='secondary'
          onClick={toggleFilterOpen}
          data-track='Review / Empty / Open filters'
        >
          <Trans>Open filters</Trans>
        </Button>
      </div>
    )
  }

  return (
    // Adding padding for inline comments
    <div className={isMediumScreen ? 'pr-10' : undefined}>
      {companies.map((company) => {
        const companyStateId = createReviewStateId(company.id, teamId)
        const reviewState = state[companyStateId]
        const setReviewState: SetStateFn<ReviewState> = (valueOrFn) => {
          // @ts-expect-error
          const value = isFunction(valueOrFn) ? valueOrFn(reviewState) : valueOrFn

          setItem(companyStateId, value)
        }

        return (
          <ReviewCompanyCard2
            ref={infiniteScrollItemRef}
            filterHeight={filterHeight}
            key={company.id}
            baseCompany={company}
            teamReviewSettings={teamReviewSettings}
            reviewState={reviewState}
            setReviewState={setReviewState}
            resetReviewState={resetState}
          />
        )
      })}
    </div>
  )
}
