import { SetStateAction } from 'react'
import { SetStateFn } from '../types/types'

type PathToStringProps<T> = T extends string | number | boolean | null | undefined
  ? []
  : {
      [K in keyof T & (string | number)]: [K, ...PathToStringProps<T[K]>]
    }[keyof T & (string | number)]

type Join<T extends string[], D extends string> = T extends []
  ? ''
  : T extends [infer F]
    ? F
    : T extends [infer F, ...infer R]
      ? (F & string) | `${F & string}${D}${Join<Extract<R, string[]>, D>}`
      : string

// @ts-expect-error
export type DotPathToType<T> = Join<PathToStringProps<T>, '.'>

type ValueAtPath<T, P extends string> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? ValueAtPath<T[K], Rest>
    : never
  : P extends keyof T
    ? T[P]
    : never

export function getByPath<T, P extends DotPathToType<T> & string>(obj: T, path: P): ValueAtPath<T, P> {
  const keys = path.split('.') as Array<keyof any>
  let current: any = obj

  for (const key of keys) {
    if (current == null) return undefined as ValueAtPath<T, P>
    current = current[key]
  }

  return current as ValueAtPath<T, P>
}

export function setChildState<T, P extends DotPathToType<T> & string>(
  setState: SetStateFn<T>,
  path: P
): (value: SetStateAction<ValueAtPath<T, P>>) => void {
  return (value: SetStateAction<ValueAtPath<T, P>>) => {
    setState((prevState) => {
      const keys = path.split('.') as Array<keyof any>
      const lastKey = keys.pop()

      const deepClone = (obj: any) => {
        if (Array.isArray(obj)) return [...obj]
        if (obj && typeof obj === 'object') return { ...obj }
        return obj
      }

      const newState = deepClone(prevState)

      let current = newState as any
      for (const key of keys) {
        current[key] = deepClone(current[key])
        current = current[key]
      }

      const existingValue = getByPath(prevState, path)

      if (typeof value === 'function') {
        current[lastKey as string] = (value as Function)(existingValue)
      } else {
        current[lastKey as string] = value
      }

      return newState
    })
  }
}
