export const TestIDs = {
  // TODO: This string pattern currently follows our Mixpanel standard which is formatted like that for a reason(?).
  //       However, the pattern we have used for data-ids is standard kebab-case, which is easier to handle programmatically.
  //       We should consider changing this setup to using kebab-case instead.

  Navigation: {
    review: 'nav-bar-menu-button-review',
    portfolio: 'nav-bar-menu-button-portfolio',
    DropdownMenu: {
      trigger: 'nav-bar-context-menu-trigger',
      settings: 'nav-bar-context-menu-settings'
    }
  },
  Settings: {
    Navigation: {
      userAccount: 'Settings / Tab User Account',
      userLanguage: 'Settings / Tab User Language',
      userFeatures: 'Settings / Tab User Features',
      userNotifications: 'Settings / Tab User Notifications',
      teamPreferences: 'Settings / Tab Team Preferences',
      teamAndMembers: 'Settings / Tab Team and Members',
      teamReviewSettings: 'Settings / Tab Team Review Settings',
      teamTriggers: 'Settings / Tab Team Triggers',
      teamSanctions: 'Settings / Tab Sanctions',
      teamPdf: 'Settings / Tab Team PDF Settings',
      teamConnectedApps: 'Settings / Tab Team Connected Apps'
    },
    Language: {
      trigger: 'select-display-language'
    },
    Members: {
      inviteButton: 'invite-users-button',
      emailInput: 'invite-users-email-input-field',
      nameInput: 'invite-users-name-input-field',
      addToListButton: 'invite-users-add-to-list-button',
      sendInviteButton: 'invite-users-confirm-dialog-accept-button',
      removeUserButton: (email: string) => `remove-user-button-${email}`,
      removeUserConfirmButton: 'remove-user-confirm-button'
    },
    Review: {
      checkbox: (kind: string) => `settings-review-checkbox-${kind}`
    },
    saveButton: 'settings-save-button',
    noChangesMadeMessage: 'settings-review-unsaved-changes-message'
  },
  Search: {
    root: 'Search Entity / Input',
    result: 'Search Entity / Result',
    kindFilterTrigger: 'search-entity-kind-filter-trigger',
    kindFilterOptions: 'search-entity-kind-filter-options'
  },
  SidePanel: {
    root: 'Sidepanel',
    Header: {
      CloseButton: 'Sidepanel / Close button'
    },
    Tags: {
      trigger: 'sidepanel-tags-trigger',
      options: 'sidepanel-tags-options'
    },
    Assignee: {
      root: 'Sidepanel / Assignee',
      select: 'Sidepanel / Assignee / Select',
      trigger: 'Sidepanel / Assignee / Trigger'
    },
    CompanyStatus: {
      root: 'Sidepanel / Company Status',
      select: 'Sidepanel / Company Status / Select',
      trigger: 'Sidepanel / Company Status / Trigger',
      confirmRemoveButton: 'sidepanel-company-status-confirm-remove-button'
    },
    Counterparty: {
      root: 'sidepanel-counterparties',
      Add: 'Sidepanel / Counterparty / Add',
      searchEntityTrigger: 'sidepanel-counterparty-search-entity-trigger',
      searchEntityResults: 'sidepanel-counterparty-search-entity-results',
      searchEntityInput: 'sidepanel-counterparty-search-entity-input',
      addCounterpartyCardButton: 'sidepanel-counterparty-add-button',
      addCounterpartyConfirmButton: 'sidepanel-counterparty-confirm-button',
      addCounterpartyCancelButton: 'sidepanel-counterparty-cancel-button',
      toggleEditButton: 'sidepanel-counterparty-toggle-edit-button',
      deleteCounterpartyButton: 'sidepanel-counterparty-delete-button',
      deleteCounterpartyConfirmButton: 'sidepanel-counterparty-delete-confirm-button'
    },
    Review: {
      Add: 'Sidepanel / Add to review'
    },
    RiskSummary: {
      root: 'sidepanel-risk-summary',
      showNetworkSwitch: 'sidepanel-risk-summary-show-network-switch'
    },
    PepAndSanctions: {
      root: 'sidepanel-pep-and-sanctions',
      sanctions: 'sidepanel-pep-and-sanctions-sanctions',
      peps: 'sidepanel-pep-and-sanctions-peps',
      sanctionCard: (name: string) => `sidepanel-pep-and-sanctions-sanction-card-${name}`,
      pepCard: (name: string) => `sidepanel-pep-and-sanctions-pep-card-${name}`
    },
    Roles: {
      root: 'sidepanel-roles',
      editButton: 'sidepanel-roles-edit-button',
      resetRolesButton: 'sidepanel-roles-reset-button',
      resetRolesConfirmButton: 'sidepanel-roles-reset-confirm-button',
      addRoleButton: 'sidepanel-roles-add-role-button',
      searchEntityTrigger: 'sidepanel-roles-search-entity-trigger',
      searchEntityInput: 'sidepanel-roles-search-entity-input',
      searchEntityResults: 'sidepanel-roles-search-entity-results',
      searchRoleTitleTrigger: 'sidepanel-roles-search-role-trigger',
      searchRoleTitleResults: 'sidepanel-roles-search-role-results',
      fromDateInput: 'sidepanel-roles-from-date-input',
      editRoleConfirmButton: 'sidepanel-roles-edit-role-confirm-button',
      removeRoleButton: 'sidepanel-roles-remove-role-button',
      removeRoleConfirmButton: 'sidepanel-roles-remove-role-confirm-button'
    },
    Ownerships: {
      root: 'sidepanel-ownerships',
      loader: 'sidepanel-ownerships-loader',
      resetOwnershipsButton: 'sidepanel-ownerships-reset-button',
      resetOwnershipsConfirmButton: 'sidepanel-ownerships-reset-confirm-button',
      node: (name: string) => `sidepanel-ownerships-node-${name}`,
      targetEntity: 'sidepanel-ownerships-target-entity',
      addPersonOption: 'sidepanel-ownerships-add-person-option',
      addCompanyOption: 'sidepanel-ownerships-add-company-option',
      editOwnershipOption: 'sidepanel-ownerships-edit-ownership-option',
      mergeEntityOption: 'sidepanel-ownerships-merge-entity-option',
      searchEntityTrigger: 'sidepanel-ownerships-search-entity-trigger',
      searchEntityInput: 'sidepanel-ownerships-search-entity-input',
      searchEntityResults: 'sidepanel-ownerships-search-entity-results',
      ownershipPercentageButton: (percentage: number) =>
        `sidepanel-ownerships-ownership-percentage-${percentage}-button`,
      ownershipPercentageInput: 'sidepanel-ownerships-ownership-percentage-input',
      addOwnerConfirmButton: 'sidepanel-ownerships-add-owner-confirm-button',
      removeOwnerButton: 'sidepanel-ownerships-remove-ownership-button',
      removeOwnerConfirmButton: 'sidepanel-ownerships-remove-ownership-confirm-button',
      editOwnerConfirmButton: 'sidepanel-ownerships-edit-ownership-confirm-button',
      mergeEntityConfirmButton: 'sidepanel-ownerships-merge-entity-confirm-button',
      undoMergeOption: 'sidepanel-ownerships-undo-merge-option',
      undoMergeConfirmButton: 'sidepanel-ownerships-undo-merge-confirm-button'
    },
    CreditScore: {
      root: 'sidepanel-creditscore'
    },
    Financials: {
      root: 'sidepanel-financials',
      editButton: 'sidepanel-edit-financials'
    },
    EditableCard: {
      toggleEditButton: 'sidepanel-editable-card-toggle-edit-button'
    }
  },
  Grow: {
    Location: {
      input: 'Grow / Location / Input'
    },
    button: 'nav-bar-menu-button-grow',
    opportunities: {
      company: 'opportunities-company',
      financials: 'opportunities-company-financials',
      figures: 'spark-line-figure',
      name: 'entity-meta-name',
      ignoreButton: 'opportunities-company-ignore'
    }
  },
  Review: {
    root: 'review-page',
    AddToReview: {
      button: 'Review / Add to Review',
      dialog: 'add-to-review-confirm-dialog',
      searchResults: 'add-to-review-select-company-options-container',
      confirm: 'add-to-review-confirm-dialog-confirm-button'
    },
    Card: {
      root: (companyName?: string | null) => `review-card-${companyName}`,
      Header: 'review-card-header',
      startReviewButton: 'Review / Start Review',
      submitReviewButton: 'submit-review-button',
      errorMessage: 'review-error-message',
      CompanyStatus: {
        trigger: 'review-company-status-trigger',
        options: 'review-company-status-options'
      },
      resolveConflictButton: 'review-resolve-conflict-button',
      downloadPdfButton: 'download-review-pdf-button',
      row: (kind: string) => `review-row-${kind}`,
      RemoveFromReview: {
        trigger: 'review-remove-trigger',
        options: 'review-remove-options'
      },
      dangerIcon: 'review-row-tooltip-danger-icon',
      warningIcon: 'review-row-tooltip-warning-icon',
      successIcon: 'review-row-tooltip-success-icon',
      companyInformationHeading: 'Review / Company information'
    },
    paginationFooter: 'review-pagination-footer',
    emptyState: 'review-empty-state'
  },
  Activities: {
    activityButton: 'nav-bar-menu-button-activity',
    newActivityButton: 'activity-view-new-activity-button',
    activityCard: 'team-activity-details-card',
    searchEntityTrigger: 'activity-form-select-company-input-button',
    searchEntityInput: 'activity-form-select-company-input-field',
    searchEntityResults: 'activity-form-select-company-options-container',
    searchEntityResultOption: 'entity-meta-name',
    activityInput: 'activity-form-rich-text-editor-text',
    createButton: 'activity-view-create-team-activity-button'
  },
  Common: {
    Sanctions: {
      sources: 'sanction-sources',
      references: 'sanction-references'
    },
    CreditScore: {
      initialFetchButton: 'sidepanel-creditscore-initial-fetch-button',
      fetchButton: 'sidepanel-creditscore-fetch-button',
      score: 'sidepanel-creditscore-score'
    },
    Tags: {
      actions: 'tag-actions',
      deleteTagButton: 'tag-delete-button',
      confirmDeleteTagButton: 'tag-confirm-delete-button'
    }
  },
  ConflictsModal: {
    checkbox: (kind: string) => `role-conflict-checkbox-${kind}`
  },
  Messages: {
    successMessage: 'app-toast'
  }
}
