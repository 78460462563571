import * as React from 'react'
import { type ApolloClient, type ApolloLink, ApolloProvider, type NormalizedCacheObject } from '@apollo/client/index.js'

export const ApolloClientProvider: React.FC<{
  children: React.ReactNode
  client: ApolloClient<NormalizedCacheObject>
  link: ApolloLink
}> = ({ children, client, link }) => {
  const [hasLink, setHasLink] = React.useState(false)

  React.useEffect(() => {
    client.setLink(link)
    setHasLink(true)
  }, [link, client])

  if (!hasLink) return null

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
