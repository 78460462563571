import * as React from 'react'
import { createColumnHelper, DataTable, IconButton, IconGarbage, IconPenBox } from '@strise/ui-components'
import { t } from '@lingui/macro'
import { usePrivatePersonSearchQuery } from '~/graphqlOperations'
import { type PrivatePersonBaseFragment } from '~/graphqlTypes'
import { EntityLink } from '~/components/EntityLink/EntityLink'

const columnHelper = createColumnHelper<PrivatePersonBaseFragment>()

const extractColumns = (privatePersons: PrivatePersonBaseFragment[]) => {
  // We should consider having the keyMetaV2 fields as regular fields instead of an array
  const metaLabels = [...new Set(privatePersons.flatMap((row) => row.keyMetaV2.map((meta) => meta.label)))]

  return [
    columnHelper.accessor('name', {
      header: t`Name`,
      cell: ({ row }) => <EntityLink entity={row.original} noFlags />
    }),
    ...metaLabels.map((label, index) =>
      columnHelper.display({
        id: `keyMetaV2.${index}`,
        header: label,
        cell: ({ row }) => {
          const metaItems = row.original.keyMetaV2
          const metaItem = metaItems.find((item) => item.label === label)
          return metaItem?.content ?? '-'
        }
      })
    ),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: () => (
        <div className='flex gap-2'>
          <IconButton title={t`Edit private person`} data-track='Private person portfolio / Edit private person'>
            <IconPenBox size='md' />
          </IconButton>
          <IconButton title={t`Remove private person`} data-track='Private person portfolio / Remove private person'>
            <IconGarbage size='md' />
          </IconButton>
        </div>
      )
    })
  ]
}

export const PortfolioPrivatePersonView: React.FC = () => {
  const [rowSelection, setRowSelection] = React.useState({})
  const [pageIndex, setPageIndex] = React.useState(0)
  const [pageSize, setPageSize] = React.useState(10)

  const { data, loading } = usePrivatePersonSearchQuery({
    variables: {
      searchInput: {
        query: ''
      },
      pageInfo: {
        limit: pageSize,
        offset: pageIndex * pageSize
      }
    }
  })

  const privatePersons = data?.privatePersonSearch.edges.map((edge) => edge.node) ?? []

  const columns = React.useMemo(() => extractColumns(privatePersons), [privatePersons])

  return (
    <div>
      <DataTable
        bodyCellClassName='text-md'
        columns={columns}
        data={privatePersons}
        enablePagination
        pageIndex={pageIndex}
        pageSize={pageSize}
        onPageChange={setPageIndex}
        onPageSizeChange={setPageSize}
        variant='backplate'
        emptyStateText={t`No private persons available`}
        loading={loading}
        loadingRows={pageSize}
        options={{
          state: {
            rowSelection
          },
          enableRowSelection: true,
          onRowSelectionChange: setRowSelection,
          getRowId: (row) => row.id
        }}
        rowsPerPageText={t`Rows per page:`}
        aria-label={t`Private persons`}
      />
    </div>
  )
}
