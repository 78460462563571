import * as React from 'react'
import { FlagSeverity } from '@strise/types'
import {
  cn,
  IconInfoBlue,
  IconPositiveGreen,
  type IconProps,
  type IconPropsWithRef,
  IconWarningRed,
  IconWarningYellow
} from '@strise/ui-components'

const severityToComponent: {
  [key in FlagSeverity]?: React.ForwardRefExoticComponent<Omit<IconProps, 'ref'> & React.RefAttributes<SVGSVGElement>>
} = {
  [FlagSeverity.Positive]: IconPositiveGreen,
  [FlagSeverity.Low]: IconInfoBlue,
  [FlagSeverity.Medium]: IconWarningYellow,
  [FlagSeverity.High]: IconWarningRed
}

export const AdverseFlag = ({
  className,
  severity,
  ...props
}: { severity: FlagSeverity } & IconPropsWithRef): React.ReactNode => {
  const VariantComponent = severityToComponent[severity]

  if (!VariantComponent) return null

  // Using `text-text-primary` here since all our icons with primary text color is overridden to use `currentColor`,
  // whilst the ones with white text color has kept the hardcoded white color.
  return <VariantComponent className={cn('text-text-primary', className)} size='md' {...props} />
}
