import * as React from 'react'
import { ReviewCheckboxColumn } from '~/components/Review/ReviewCheckboxColumn'
import { type BaseCheckboxSectionProps, extractTextColorClass } from '~/utils/reviewUtils2'
import { Checkbox, cn } from '@strise/ui-components'
import { ReviewSettingKind, ReviewRowKind } from '@strise/types'
import { TestIDs } from '~/utils/testIDs'
import { setChildState } from '@strise/react-utils'
import { ReviewCardInlineComments2 } from '~/components/Review/ReviewCardInlineComments2'
import { type ReviewRowType } from '~/components/Review/reviewCompanyCardUtils'

export const ReviewRow2 = ({
  checkedState,
  disabled,
  errors,
  inlineComments,
  loading,
  row,
  setInlineComments,
  teamReviewSettings,
  updateCheckedState
}: BaseCheckboxSectionProps & {
  row: ReviewRowType
}): React.ReactNode => {
  const rowKey = row.customCheckboxName ?? row.rowKind
  const rowDisabled = disabled
  const checked = row.customCheckboxName
    ? Boolean(checkedState.customCheckedMap[row.customCheckboxName])
    : Boolean(checkedState.checkedMap[row.rowKind])
  const [isHovered, setIsHovered] = React.useState(false)
  const inlineCommentsEnabled = teamReviewSettings.settings.find(
    (setting) => setting.kind === ReviewSettingKind.EnableInlineComments
  )?.enabled
  const colorClass = extractTextColorClass(errors && !checked, checked)

  if (!row.children) {
    return null
  }

  const filteredInlineComments =
    row.rowKind === ReviewRowKind.CustomCheckbox
      ? inlineComments[row.rowKind]?.filter((comment) => comment.customCheckboxName === row.customCheckboxName)
      : inlineComments[row.rowKind]

  return (
    <div
      className={cn('relative flex border-b border-divider', isHovered ? 'bg-tertiary-main' : 'bg-transparent')}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-id={TestIDs.Review.Card.row(row.rowKind)}
    >
      <ReviewCheckboxColumn
        fillSpace={row.rowKind === ReviewRowKind.CustomCheckbox}
        className={cn('py-2', checked ? 'text-text-secondary' : 'text-text-primary')}
      >
        <Checkbox
          labelProps={{ className: colorClass }}
          label={row.label}
          disabled={rowDisabled || loading}
          id={rowKey}
          data-track='Review / Toggle checkbox'
          checked={checked}
          onCheckedChange={() => updateCheckedState(row.rowKind, row.customCheckboxName)}
        />
      </ReviewCheckboxColumn>

      <div
        className={cn('grow', checked ? 'text-text-secondary' : 'text-text-primary', {
          '[&>a]:text-text-secondary': checked
        })}
      >
        {row.children}
      </div>
      {inlineCommentsEnabled && (
        <ReviewCardInlineComments2
          customCheckboxName={row.customCheckboxName}
          rowKey={rowKey}
          title={row.label}
          inlineComments={filteredInlineComments}
          setInlineComments={setChildState(setInlineComments, row.rowKind)}
          isHovered={isHovered}
        />
      )}
    </div>
  )
}
