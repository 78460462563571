import { defineMessage } from '@lingui/macro'
import { isNil } from 'lodash-es'
import { type MessageDescriptor } from '@lingui/core'

export interface FromToLike<Typename extends string> {
  __typename: Typename
  from: number | null
  to: number | null
}

export const activeText = (from: number | null, to: number | null): MessageDescriptor[] => {
  const text = [defineMessage({ message: `From ${from ?? 0}` })]
  if (to !== null) return [...text, defineMessage({ message: `to ${to}` })]
  return text
}

export const valueToString = (value: number | null | undefined): string => {
  if (isNil(value)) return ''
  return String(value)
}
