import { getBrowserGlobals } from '@strise/react-utils'

interface BrowserInfo {
  name: string
  version: number | null
}

const extractBrowserName = (window: Window): string | null => {
  const userAgent = window.navigator.userAgent
  const vendor = window.navigator.vendor || '' // vendor is undefined for at least IE9
  const opera: unknown = 'opera' in window ? window.opera : false

  if (opera || userAgent.includes(' OPR/')) {
    if (userAgent.includes('Mini')) {
      return 'Opera Mini'
    }
    return 'Opera'
  }
  if (/(blackberry|playbook|bb10)/i.test(userAgent || '')) {
    return 'BlackBerry'
  }
  if (userAgent.includes('IEMobile') || userAgent.includes('WPDesktop')) {
    return 'Internet Explorer Mobile'
  }
  if (userAgent.includes('Edge')) {
    return 'Microsoft Edge'
  }
  if (userAgent.includes('FBIOS')) {
    return 'Facebook Mobile'
  }
  if (userAgent.includes('Chrome')) {
    return 'Chrome'
  }
  if (userAgent.includes('CriOS')) {
    return 'Chrome iOS'
  }
  if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
    return 'UC Browser'
  }
  if (userAgent.includes('FxiOS')) {
    return 'Firefox iOS'
  }
  if (vendor.includes('Apple')) {
    if (userAgent.includes('Mobile')) {
      return 'Mobile Safari'
    }
    return 'Safari'
  }
  if (userAgent.includes('Android')) {
    return 'Android Mobile'
  }
  if (userAgent.includes('Konqueror')) {
    return 'Konqueror'
  }
  if (userAgent.includes('Firefox')) {
    return 'Firefox'
  }
  if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer'
  }
  if (userAgent.includes('Gecko')) {
    return 'Mozilla'
  }
  return null
}

const extractBrowserVersion = (window: Window, browserName: string): number | null => {
  const versionRegexs: Record<string, RegExp | undefined> = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/
  }

  const regex = versionRegexs[browserName]
  if (!regex) return null

  const matches = window.navigator.userAgent.match(regex)
  if (!matches) return null

  const match = matches[matches.length - 2]

  if (!match) return null

  return Number.parseFloat(match)
}

export const extractBrowserInfo = (): BrowserInfo | null => {
  const browserGlobals = getBrowserGlobals()

  if (!browserGlobals) return null

  const name = extractBrowserName(browserGlobals.window)

  if (!name) return null

  const version = extractBrowserVersion(browserGlobals.window, name)
  return { name, version }
}
