import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconArchive = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M4 1h16v4h1l1.944 7H23v11H1V12h.056L3 5h1V1Zm2 4h12V3H6v2Zm-3 9v7h18v-7H3Zm2-7v5h14V7H5Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconArchive.displayName = 'IconArchive'
