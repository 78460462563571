import * as React from 'react'
import { Combobox, type ComboboxItem, type ComboboxProps } from '@strise/ui-components'
import { CountryKind } from '@strise/types'
import { NationFlag } from '@strise/app-shared'

// TODO: Need to add full country name with locale
const getCountryItems = (): Array<ComboboxItem<string>> =>
  Object.values(CountryKind)
    .map((code) => ({
      id: code,
      value: code,
      label: code,
      renderNode: (
        <div className='flex items-center gap-2'>
          <NationFlag countryIsoCode={code} />
          {code}
        </div>
      )
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

export type CountryComboboxProps = Omit<ComboboxProps<string>, 'items' | 'value'> & {
  value?: string | null
}

export const CountryCombobox = ({ value, ...props }: CountryComboboxProps): React.ReactNode => {
  const items = React.useMemo(() => getCountryItems(), [])

  const selectedValue = items.filter((item) => item.value === value)

  return (
    <Combobox
      items={items}
      singleSelect
      closeOnSelect
      {...props}
      value={selectedValue}
      className='border'
      data-track={props['data-track']}
      customSelectedItemsRenderer={
        <div className='flex items-center gap-2'>
          <NationFlag countryIsoCode={value} />
          {value}
        </div>
      }
    />
  )
}
