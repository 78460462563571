import { useContext } from '@strise/react-utils'
import { ApolloClientContext } from '~/apolloClient/ApolloClientContext'

export const useClearApolloClientCache = (): (() => Promise<void>) => {
  const { client, persistor } = useContext(ApolloClientContext)
  return async () => {
    try {
      await client?.clearStore()
      await persistor?.purge()
    } catch (error) {
      console.error('Error clearing cache:', error)
    }
  }
}
