import { type EntityLikeMetaFragment } from '~/graphqlTypes'
import React from 'react'
import { useOwners } from '~/components/Ownerships/ownershipChartUtils'
import { OwnershipsContext } from '~/components/Ownerships/OwnershipsContext/OwnershipsContext'

export const OwnershipsContextProvider = ({
  above25,
  children,
  danish,
  editMode,
  isExport,
  noFlags,
  noLink,
  noStatus,
  noSubsidiaryCount,
  rootEntity,
  showOriginal
}: {
  above25: boolean
  children: React.ReactNode
  danish: boolean
  editMode: boolean
  isExport: boolean
  noFlags: boolean
  noLink: boolean
  noStatus: boolean
  noSubsidiaryCount: boolean
  rootEntity: EntityLikeMetaFragment
  showOriginal: boolean
}): React.ReactNode => {
  const { ...owners } = useOwners(rootEntity, above25 ? 25 : 5, showOriginal)

  const values = React.useMemo(
    () => ({
      rootEntity,
      owners,
      above25,
      editMode,
      danish,
      noFlags,
      noLink,
      noStatus,
      noSubsidiaryCount,
      isExport,
      showOriginal
    }),
    [
      rootEntity,
      owners,
      above25,
      editMode,
      danish,
      noFlags,
      noLink,
      noStatus,
      noSubsidiaryCount,
      isExport,
      showOriginal
    ]
  )

  return <OwnershipsContext.Provider value={values}>{children}</OwnershipsContext.Provider>
}
