import { Button, type ButtonProps, cn, IconSpark, Typography } from '@strise/ui-components'
import { ActivityFilter } from '~/components/TeamActivity/teamActivityUtils'
import { t, Trans } from '@lingui/macro'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import * as React from 'react'

const ActivityDetailsEmptyButton: React.FC<{ dataTrack: string } & ButtonProps> = ({
  children,
  className,
  dataTrack,
  ...props
}) => (
  <Button className={className} variant='ghost' palette='primary' data-track={dataTrack} {...props}>
    {children}
  </Button>
)

export const ActivityDetailsEmpty: React.FC<
  {
    activityFilter: ActivityFilter
    hasFilteredTeamActivities: boolean
    hasTeamActivities: boolean
    onClick: () => void
    setActivityFilter: SetStateFn<ActivityFilter>
  } & DivProps
> = ({
  activityFilter,
  className,
  hasFilteredTeamActivities,
  hasTeamActivities,
  onClick,
  setActivityFilter,
  ...props
}) => {
  const isFilteredByPlannedActivities = activityFilter === ActivityFilter.PLANNED_ACTIVITIES

  const filterButtonText = isFilteredByPlannedActivities ? t`See completed activities` : t`See planned activities`

  const handleActivityFilter = (): void => {
    const filter = isFilteredByPlannedActivities
      ? ActivityFilter.COMPLETED_ACTIVITIES
      : ActivityFilter.PLANNED_ACTIVITIES

    setActivityFilter(filter)
  }

  return (
    <div className={cn('h-[200px] text-center', className)} {...props}>
      <div className='mx-auto mb-1 px-10 text-center'>
        <IconSpark className='size-[45px] text-secondary-shade-20' />
        <Typography className='my-4' variant='h4'>
          <Trans>You're up to date!</Trans>
        </Typography>
        <Typography variant='aLabel'>
          <Trans>All your scheduled and overdue activities will appear here.</Trans>
        </Typography>
      </div>
      {!hasTeamActivities && !hasFilteredTeamActivities && (
        <ActivityDetailsEmptyButton dataTrack='Activity View / Create a new activity' onClick={onClick}>
          <Trans>Create a new activity</Trans>
        </ActivityDetailsEmptyButton>
      )}

      {hasTeamActivities && !hasFilteredTeamActivities && (
        <ActivityDetailsEmptyButton
          dataTrack={
            isFilteredByPlannedActivities
              ? 'Activity View / See completed activities'
              : 'Activity View / See planned activities'
          }
          onClick={handleActivityFilter}
        >
          {filterButtonText}
        </ActivityDetailsEmptyButton>
      )}
    </div>
  )
}
