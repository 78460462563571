import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useHelpCenterLink } from '~/utils/userHooks'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { t } from '@lingui/macro'
import { DataSources } from '~/components/Sidepanel/DataSources'
import * as React from 'react'
import { type SidepanelPersonFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'
import { useEntitySanctionInfo } from '~/utils/sanctions'
import { usePersonPepInfoQuery } from '~/graphqlOperations'
import { Skeleton } from '@strise/ui-components'
import { SanctionCard } from '~/components/PepAndSanctions/SanctionCard'
import { Detail2 } from '~/components/Sidepanel/Detail2'
import { PersonPepCard } from '~/components/PepAndSanctions/PersonPepCard'
import { TestIDs } from '~/utils/testIDs'

interface SidepanelPersonPepCardProps {
  person: SidepanelPersonFragment
}

export const SidepanelPersonPepsAndSanctionsCard = React.forwardRef<HTMLDivElement, SidepanelPersonPepCardProps>(
  ({ person }, ref) => {
    const features = useCurrentUserFeatures()
    const helpCenterLink = useHelpCenterLink()

    const { data, loading: pepLoading } = usePersonPepInfoQuery({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING
      },
      skip: !features.PEP_AND_SANCTIONS_V2
    })

    const { loading: sanctionsLoading, sanctionInfo } = useEntitySanctionInfo(person.id)

    if (!features.PEP_AND_SANCTIONS_V2) return null

    const pepInfos = data?.person.pepInfo ? [data.person.pepInfo] : data?.person.pepInfos

    return (
      <SidepanelCard title={t`PEPs and Sanctions`} ref={ref} tab={SidepanelTab.PepsAndSanctions}>
        <div className='flex flex-col p-4'>
          <Detail2 title={t`Sanctions`} description={null} dataId={TestIDs.SidePanel.PepAndSanctions.sanctions}>
            {sanctionsLoading && <Skeleton className='h-[48px] w-[312px]' />}
            {!sanctionsLoading && sanctionInfo && (
              <SanctionCard
                entity={person}
                sanctions={sanctionInfo}
                associatedCompanies={null}
                currentCompanyId={null}
                noLink
              />
            )}

            <DataSources
              className='px-0'
              dataSources={person.dataSources.sanctions}
              hideLinks
              learnMoreLink={`${helpCenterLink}/articles/37050-how-strise-sanctions-work`}
            />
          </Detail2>

          <Detail2 title={t`PEP status`} description={null} dataId={TestIDs.SidePanel.PepAndSanctions.peps}>
            {pepLoading && <Skeleton className='h-[48px] w-[312px]' />}
            {!pepLoading && pepInfos && (
              <PersonPepCard
                pepInfos={pepInfos}
                person={person}
                associatedCompanies={null}
                currentCompanyId={null}
                noLink
              />
            )}

            <DataSources
              className='px-0'
              dataSources={person.dataSources.peps}
              hideLinks
              learnMoreLink={`${helpCenterLink}/articles/20734-what-is-a-pep`}
            />
          </Detail2>
        </div>
      </SidepanelCard>
    )
  }
)
