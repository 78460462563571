import { type ReviewSanctionValueFragment } from '~/graphqlTypes'
import { ReviewSanction } from '~/components/Review/items/ReviewSanction'
import * as React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { ReviewValueStatusKind } from '@strise/types'
import { t } from '@lingui/macro'

export const ReviewSanctions = ({ items }: { items: ReviewSanctionValueFragment[] }): React.ReactNode => {
  const filtered = items.filter((item) => item.value?.isSanctioned)

  if (!filtered.length) {
    return (
      <ReviewItem
        reviewMeta={{
          status: ReviewValueStatusKind.Ok,
          reason: t`No sanctions`,
          __typename: 'ReviewValueMeta' as const
        }}
      />
    )
  }

  return items.map((item, index) => <ReviewSanction key={index} item={item} />)
}
