import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCorrespondence = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 16 1 10v13h22V10l-11 6Zm9-2.63-9 4.908-9-4.909V21h18v-7.63Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        d='M4 8.364 1 10v2.5l3 1.636v-2.278l-1-.545v-.126l1-.545V8.364ZM20 10.642V8.364L23 10v2.5l-3 1.636v-2.278l1-.545v-.126l-1-.545Z'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M18 3H6v9.966l6 3.258 6-3.258V3Zm2 11.156L12 18.5l-8-4.344V1h16v13.156Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M17 6H7V4h10v2ZM17 9H7V7h10v2ZM17 12H7v-2h10v2Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCorrespondence.displayName = 'IconCorrespondence'
