import { cva } from 'class-variance-authority'

export const progressVariants = cva('flex h-full items-center transition-[width] duration-1000', {
  variants: {
    variant: {
      success: 'bg-accent-green-shade-10',
      error: 'bg-semantic-danger-shade-10',
      loading: 'bg-accent-blue-shade-10'
    }
  }
})
