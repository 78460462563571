import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSync = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M20.683 12a8.636 8.636 0 0 1-15.822 4.794l4.782 1.115.49-2.102-8.165-1.905-1.245 8.741 2.137.305.623-4.374a10.777 10.777 0 0 0 8.564 4.222c5.83 0 10.58-4.622 10.788-10.401 0 .132.005-.131 0 0L20.683 12ZM13.866 8.193l8.166 1.905 1.245-8.741-2.138-.305-.622 4.374a10.777 10.777 0 0 0-8.564-4.222c-5.82 0-10.565 4.607-10.787 10.373-.006.14 0-.142 0 0l2.15.423a8.636 8.636 0 0 1 15.822-4.794l-4.781-1.115-.49 2.102Z'
      />
    </svg>
  )
)
IconSync.displayName = 'IconSync'
