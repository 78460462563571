import { FlagSeverity } from '@strise/types'
import { defineMessage } from '@lingui/macro'

export const flagSeverityToTitle = {
  [FlagSeverity.Positive]: defineMessage({ message: 'Positive' }),
  [FlagSeverity.Low]: defineMessage({ message: 'Information' }),
  [FlagSeverity.Medium]: defineMessage({ message: 'Negative' }),
  [FlagSeverity.High]: defineMessage({ message: 'Severely negative' })
}

export const flagSeverityToChipTitle = {
  [FlagSeverity.Positive]: defineMessage({ message: 'Positive flag' }),
  [FlagSeverity.Low]: defineMessage({ message: 'Information flag' }),
  [FlagSeverity.Medium]: defineMessage({ message: 'Negative flag' }),
  [FlagSeverity.High]: defineMessage({ message: 'Severely negative flag' })
}

export const flagSeverityToClasses = {
  [FlagSeverity.Positive]: 'bg-semantic-success-shade-5',
  [FlagSeverity.Low]: 'bg-semantic-info-shade-5',
  [FlagSeverity.Medium]: 'bg-semantic-warning-shade-5',
  [FlagSeverity.High]: 'bg-semantic-danger-shade-5'
}
