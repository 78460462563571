import * as React from 'react'
import { PreLoaderContext } from '~/contexts/PreLoaderContext/PreLoaderContext'
import { useContext } from '@strise/react-utils'

export const PreLoaderContextStop = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const { loading, setLoading } = useContext(PreLoaderContext)
  React.useEffect(() => {
    setLoading(false)
  }, [])

  if (loading) return null

  return <>{children}</>
}
