import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFeatures = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M2 2h9v9H2V2Zm2 2h5v5H4V4ZM13 2h9v9h-9V2Zm2 2h5v5h-5V4ZM2 13v9h9v-9H2Zm7 2H4v5h5v-5ZM13 13h9v9h-9v-9Zm2 2h5v5h-5v-5Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconFeatures.displayName = 'IconFeatures'
