import { Button, IconPlusSmall, Typography } from '@strise/ui-components'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { Event } from '~/components/Events/Event'
import { OwnerDetail } from '~/components/OwnerDetail'
import { SanctionsEntityContent } from '~/components/PepAndSanctions/SanctionsEntityContent'
import { Register } from '~/components/Register'
import { CreditScore } from '~/utils/apiTable/CreditScore'
import { Flag } from '~/utils/apiTable/Flag'
import { PepsWithEntity } from '~/utils/apiTable/PepsWithEntity'
import { Role } from '~/utils/apiTable/Role'
import { RoleWithDetailedEntity } from '~/utils/apiTable/RoleWithDetailedEntity'
import { RoleWithEntity } from '~/utils/apiTable/RoleWithEntity'
import React from 'react'
import { type TableLabelContentProps, type TableValueContentProps } from '~/utils/apiTable/apiTableUtils'
import { CCJs } from '~/utils/apiTable/CCJs'
import { Trans, t } from '@lingui/macro'
import { RoleWithEntityForPerson } from '~/utils/apiTable/RoleWithEntityForPerson'
import { PepsWithEntity2 } from '~/utils/apiTable/PepsWithEntity2'
import { filterNullishValues } from '@strise/ts-utils'
import { groupBy } from 'lodash-es'

export const TableLabelContent = ({ editMode, handleEdit, label }: TableLabelContentProps): React.ReactNode => {
  if (label.__typename === 'TableLabelEntity') {
    return (
      <div className='flex items-center'>
        <EntityLink entity={label.entity} transparent={!label.isActive} />
        {label.description && <Typography className='ml-1'>({label.description})</Typography>}
      </div>
    )
  }

  if (label.__typename === 'TableLabelEntityRole') {
    return (
      <div className='grid items-center'>
        <EntityLink entity={label.entity} transparent={!label.isActive} />
        {editMode && handleEdit && (
          <Button
            data-track='Sidepanel / Roles / Open Add Role'
            palette='primary'
            className='w-fit pl-0'
            startIcon={<IconPlusSmall />}
            onClick={() => {
              handleEdit(label.entity, {
                existingRole: true,
                type: 'EditRole'
              })
            }}
          >
            <Typography>
              <Trans>Add</Trans>
            </Typography>
          </Button>
        )}
      </div>
    )
  }

  if (label.__typename === 'TableLabelRole') {
    return (
      <div className='flex items-center gap-2'>
        <Typography className='mr-1'>{label.name}</Typography>
        {editMode && handleEdit && (
          <Button
            data-track='Sidepanel / Roles / Open Add Role'
            palette='primary'
            className='h-[24px] w-fit p-0'
            onClick={() => {
              handleEdit(null, {
                existingRole: false,
                type: 'EditRole',
                roleTitleId: label.roleTitleId
              })
            }}
          >
            <IconPlusSmall />
          </Button>
        )}
      </div>
    )
  }

  // Keeping this in case we introduce more types l8r
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (label.__typename === 'TableLabelString') {
    return <span className={label.isActive ? 'text-inherit' : 'text-text-secondary'}>{label.content}</span>
  }

  return null
}

export const TableValueContent = ({
  editMode,
  entity,
  handleEdit,
  reviewState,
  sectionEntity,
  setReviewState,
  value
}: TableValueContentProps): React.ReactNode => {
  switch (value.__typename) {
    case 'TableRowValueString': {
      return value.content
    }
    case 'TableRowValueLabeledString': {
      return value.content
    }
    case 'TableRowValueRelatedEntity': {
      return <EntityLink entity={value.entity} />
    }
    case 'TableRowValueOwnership': {
      return (
        <OwnerDetail
          rootEntityId={entity.id}
          entity={value.maybeEntity}
          entityName={value.entityName}
          ownership={value.ownership}
          uboMeta={value.uboMeta}
          customMeta={value.customMeta}
          shareClasses={value.shareClasses.map(
            (shareClass) => `${shareClass.shareClass ?? t`Unknown`}: ${shareClass.share}`
          )}
        />
      )
    }
    case 'TableRowValueRoleWithEntity': {
      return (
        <RoleWithEntity
          entity={value.entity}
          roleMetas={value.roleMetas}
          detailedRoles={value.detailedRoles}
          handleEdit={handleEdit}
          editMode={editMode}
        />
      )
    }
    case 'TableRowValueRole': {
      return <Role roleMeta={value.roleMeta} handleEdit={handleEdit} editMode={editMode} />
    }
    case 'TableRowValueFlag': {
      return <Flag entityId={entity.id} flag={value.flag} />
    }
    case 'TableRowValueRegisterV2': {
      return <Register entityId={entity.id} register={value.register} />
    }
    case 'TableRowValueRoleForPerson': {
      return <RoleWithEntityForPerson value={value} handleEdit={handleEdit} editMode={editMode} />
    }
    case 'TableRowValueEvent': {
      return (
        <Event
          event={value.event}
          entityId={sectionEntity?.id || entity.id}
          reviewState={reviewState}
          setReviewState={setReviewState}
          isReview
          hideImage
          hidePublisherImage
          topics={filterNullishValues(value.topics.map((topic) => topic.name))}
          hideSummary
        />
      )
    }
    case 'TableRowValueCreditReport': {
      return <CreditScore entity={entity} creditReport={value.creditReport} />
    }
    case 'TableRowValueCCJs': {
      return <CCJs value={value} />
    }
    case 'TableRowValuePepsWithEntity': {
      return <PepsWithEntity entity={value.maybeEntity} entityName={value.entityName} pepInfo={value.pepInfo} />
    }
    case 'TableRowValuePepsWithEntity2': {
      return <PepsWithEntity2 value={value} />
    }
    case 'TableRowValueSanctionsWithEntity': {
      return (
        <SanctionsEntityContent
          entity={value.maybeEntity}
          entityName={value.entityName}
          sanctionInfo={value.sanctions}
          associatedCompanies={null}
          currentCompanyId={null}
        />
      )
    }
    case 'TableRowValueRoleWithDetailedEntity': {
      const groupedRoles = groupBy(value.otherRoles.edges, (edge) => edge.node.id)

      const otherRoles = filterNullishValues(
        Object.entries(groupedRoles).map(([, edges]) => {
          const company = edges[0]?.node

          if (!company) return null

          return {
            company,
            roles: edges.map((edge) => edge.role)
          }
        })
      )

      return (
        <RoleWithDetailedEntity
          entity={value.roleEntity}
          otherRoles={otherRoles}
          otherIndustries={value.otherIndustries}
        />
      )
    }
    case 'TableRowValueSanctions': {
      return (
        <SanctionsEntityContent
          entity={entity}
          entityName={entity.name}
          sanctionInfo={value.sanctions}
          associatedCompanies={null}
          currentCompanyId={null}
          noLink
        />
      )
    }
    default: {
      return null
    }
  }
}
