import { PepsWithEntity } from '~/utils/apiTable/PepsWithEntity'
import { type PepInfoFragment, type ReviewPepValueFragment } from '~/graphqlTypes'
import React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { reviewEntityToEntity, reviewPepToPep } from '~/components/Review/reviewValueTransformers'

export const ReviewPep = ({ item }: { item: ReviewPepValueFragment }): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  if (!item.value.id) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const entity = item.value.entity ? reviewEntityToEntity(item.value.entity) : null

  const pepInfo: PepInfoFragment = reviewPepToPep(item.value)

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <PepsWithEntity entity={entity} entityName={item.value.entityName} pepInfo={pepInfo} />
    </ReviewItem>
  )
}
