'use client'

import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from '../../utils/className'
import { basePopperClasses } from '../variants/common'

interface TooltipProviderProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Provider> {}

/** Radix UI requires us to use their TooltipProvider to be able to use their Tooltip */
const TooltipProvider: React.FC<TooltipProviderProps> = ({
  children,
  delayDuration = 100,
  disableHoverableContent,

  skipDelayDuration = 100
}) => (
  <TooltipPrimitive.Provider
    delayDuration={delayDuration}
    skipDelayDuration={skipDelayDuration}
    disableHoverableContent={disableHoverableContent}
  >
    {children}
  </TooltipPrimitive.Provider>
)

interface TooltipProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>,
    Omit<React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>, 'content'> {
  /** Displays an arrow if `true` */
  arrow?: boolean
  /** Props applied to the arrow */
  arrowProps?: TooltipArrowProps
  /** The trigger element of the tooltip */
  children: React.ReactNode
  /** Classes applied to the content */
  className?: string
  /** The content of the tooltip */
  content?: React.ReactNode
  /** Disables rendering content in a portal if `true` */
  disablePortal?: boolean
}

interface TooltipArrowProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Arrow> {}

const Tooltip = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, TooltipProps>(
  (
    {
      arrow,
      arrowProps,
      children,
      className,
      content,
      defaultOpen,
      delayDuration,
      disableHoverableContent,
      disablePortal,
      onOpenChange,
      open,
      side = 'bottom',
      sideOffset = 6,
      ...props
    },
    ref
  ) => {
    const contentComponent = (
      <TooltipPrimitive.Content
        className={cn(
          basePopperClasses.content,
          'px-2 py-1 text-sm font-book leading-4',
          { 'max-w-[10rem]': side === 'left' || side === 'right' },
          className
        )}
        sideOffset={sideOffset}
        side={side}
        ref={ref}
        {...props}
      >
        {content}
        {arrow && <TooltipPrimitive.Arrow width={20} height={10} className='fill-secondary-shade-90' {...arrowProps} />}
      </TooltipPrimitive.Content>
    )

    return (
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
        delayDuration={delayDuration}
        disableHoverableContent={disableHoverableContent}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {content &&
          (disablePortal ? contentComponent : <TooltipPrimitive.Portal>{contentComponent}</TooltipPrimitive.Portal>)}
      </TooltipPrimitive.Root>
    )
  }
)
Tooltip.displayName = TooltipPrimitive.Root.displayName

export { Tooltip, TooltipProvider, type TooltipProps }
