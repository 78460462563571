import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFullFrame = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m21.293 22.707-20-20 1.414-1.414 20 20-1.414 1.414Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m22.707 2.707-20 20-1.414-1.414 20-20 1.414 1.414ZM1 1h7v2H3v5H1V1Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M1 23v-7h2v5h5v2H1ZM23 23h-7v-2h5v-5h2v7ZM23 1v7h-2V3h-5V1h7Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconFullFrame.displayName = 'IconFullFrame'
