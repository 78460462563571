import { useEffect } from 'react'
import { mixpanel } from '~/lib/mixpanel/mixpanel'
import { type MixpanelTrackData } from '~/lib/mixpanel/typings'
import { useUserId, useUserTeams } from '@strise/app-shared'
import { TrackedActivityKind } from '@strise/types'
import { useTeam } from '~/contexts/TeamContext/TeamContext'
import { isSystemChangeEvent, type SystemChangeEventDetail } from '@strise/ui-components-legacy'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { sentry } from '~/lib/sentry'
import * as React from 'react'
import { getBrowserGlobals, useContext } from '@strise/react-utils'

const trackEventNames = Object.values(TrackedActivityKind)

export const toMixpanelEvent = (eventName: TrackedActivityKind, eventMeta: object): MixpanelTrackData => ({
  event: eventName,
  properties: eventMeta
})

const extractAction = (event: Event, eventName: TrackedActivityKind, dataTrack: string): string | undefined => {
  if (eventName !== TrackedActivityKind.StriseInteraction) return
  if (
    isSystemChangeEvent(event) &&
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    event.detail.action
  ) {
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return `${dataTrack} / ${event.detail.action}`
  }

  return dataTrack
}

const handleEventTracking = (event: Event): void => {
  const browserGlobals = getBrowserGlobals()
  if (!browserGlobals) return

  try {
    const element = event.target as HTMLElement | null
    if (!element?.dataset.track || element.dataset.track === 'false') return
    if (!isSystemChangeEvent(event) && element.dataset.system) return

    const dataTrack = element.dataset.track as TrackedActivityKind

    const eventName = trackEventNames.includes(dataTrack) ? dataTrack : TrackedActivityKind.StriseInteraction
    const systemEvent = browserGlobals.window.CustomEvent<SystemChangeEventDetail>
    const eventMeta: Record<string, unknown> = {
      action: extractAction(event, eventName, dataTrack),
      value:
        event instanceof systemEvent
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            event.detail.value
          : undefined
    }

    Object.keys(element.dataset).forEach((key) => {
      if (key !== 'track' && key.startsWith('track')) {
        const trackKey = key.replace(/^track/, '')
        if (!trackKey[0]) return
        const metaKey = `${trackKey[0].toLowerCase()}${trackKey.slice(1)}`

        eventMeta[metaKey] = String(element.dataset[key])
      }
    })

    track(eventName, eventMeta)
  } catch (err) {
    console.error('Error tracking event', err)
  }
}

export const initTracking = (): void => {
  const searchParams = new URLSearchParams(getBrowserGlobals()?.window.location.search)
  if (searchParams.has('api')) return

  if (
    getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.MIXPANEL_ID &&
    getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.MIXPANEL_URL
  ) {
    mixpanel.init(
      getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.MIXPANEL_ID || '',
      getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.MIXPANEL_URL || ''
    )
  }

  sentry.init()

  getBrowserGlobals()?.document.body.addEventListener('click', handleEventTracking)
  getBrowserGlobals()?.document.addEventListener('systemOnChange', handleEventTracking)
}

export const resetTracking = (): void => {
  mixpanel.reset()
}

export const track = (eventName: TrackedActivityKind, eventMeta: object): void => {
  mixpanel.track([toMixpanelEvent(eventName, eventMeta)])
}

export const batchTrack = (events: MixpanelTrackData[]): void => {
  mixpanel.batchTrack(events)
}

export const useTrackUser = (): void => {
  const { settings } = useContext(CurrentUserSettingsContext)
  const { customerId, id: teamId, portfolioId } = useTeam()
  const userId = useUserId()
  const userTeams = useUserTeams()

  React.useEffect(() => {
    mixpanel.identify(userId, {
      $set: {
        userId,
        organizationId: userTeams.map(({ node }) => node.id),
        customerId: userTeams.map(({ node }) => node.customer?.id).filter(Boolean),
        useCaseKinds: settings.useCaseKinds,
        positionKind: settings.positionKind
      }
    })
  }, [])

  useEffect(() => {
    // These are Mixpanel "Group keys", needs to be in every event.
    // We use organizationId instead of teamId because of legacy
    mixpanel.register({
      organizationId: teamId,
      portfolioId,
      customerId,
      useCaseKinds: settings.useCaseKinds,
      positionKind: settings.positionKind
    })
  }, [teamId, portfolioId, customerId, settings.useCaseKinds, settings.positionKind])
}

export const useTrackOnLoad = (eventName: TrackedActivityKind, eventMeta: object, loading: boolean): void => {
  useEffect(() => {
    if (loading) return
    track(eventName, eventMeta)
  }, [loading])
}

export const useTrackOnce = (eventName: TrackedActivityKind, eventMeta: object): void => {
  React.useEffect(() => {
    track(eventName, eventMeta)
  }, [])
}
