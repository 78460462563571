import React, { useState, useEffect, useMemo } from 'react'
import { initCache } from '~/apolloClient/cachePolicies'
import { defaultOptions } from '~/apolloClient/apolloClientUtils'
import { getBrowserGlobals } from '@strise/react-utils'
import { useApolloLink } from '~/apolloClient/useApolloLink'
import { ApolloClient, type NormalizedCacheObject } from '@apollo/client/index.js'
import { type CachePersistor } from 'apollo3-cache-persist'
import { ApolloClientContext } from '~/apolloClient/ApolloClientContext'

export const ApolloClientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null)
  const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject> | null>(null)
  const link = useApolloLink()

  useEffect(() => {
    async function init(): Promise<void> {
      const window = getBrowserGlobals()?.window
      if (window) {
        const { cache, persistor: newPersistor } = await initCache(window)
        const newClient = new ApolloClient({
          cache,
          defaultOptions
        })
        setClient(newClient)
        setPersistor(newPersistor)
      }
    }
    init().catch((error: unknown) => {
      console.error('Error initializing cache:', error)
    })
  }, [])

  const contextValue = useMemo(() => {
    return { client, persistor, link }
  }, [client, persistor, link])

  return <ApolloClientContext.Provider value={contextValue}>{children}</ApolloClientContext.Provider>
}
