import { ReviewRowKind, ReviewSectionKind } from '@strise/types'
import { t } from '@lingui/macro'
import * as React from 'react'
import { type BaseReviewCompanyFragment, type ReviewCompanyDataFragment } from '~/graphqlTypes'
import { ReviewRegister } from '~/components/Review/items/ReviewRegister'
import { ReviewFlag } from '~/components/Review/items/ReviewFlag'
import { ReviewCreditScore } from '~/components/Review/items/ReviewCreditScore'
import { ReviewCCJ } from '~/components/Review/items/ReviewCCJ'
import { ReviewRole } from '~/components/Review/items/ReviewRole'
import { ReviewRoleSection } from '~/components/Review/items/ReviewRoleSection'
import { ReviewEvent } from '~/components/Review/items/ReviewEvent'
import { ReviewStringItem } from '~/components/Review/items/ReviewStringItem'
import { ReviewSanctions } from '~/components/Review/items/ReviewSanctions'
import { type SetStateFn } from '@strise/react-utils'
import { type ReviewState } from '~/utils/reviewUtils2'
import { ReviewOwner } from '~/components/Review/items/ReviewOwner'
import { ReviewEventSection } from '~/components/Review/items/ReviewEventSection'
import { ReviewDetailedRole } from '~/components/Review/items/ReviewDetailedRole'
import { ReviewPepsWithDisposition } from '~/components/Review/items/ReviewPepsWithDisposition'

export type ReviewRowType = {
  children: React.ReactNode
  customCheckboxName?: string
  label: string
  rowKind: ReviewRowKind
}

type ReviewSectionType = {
  rows: ReviewRowType[]
  sectionKind: ReviewSectionKind
  title: string
}

export const extractReviewSections = ({
  baseCompany,
  reviewCompanyData,
  reviewState,
  setReviewState
}: {
  baseCompany: BaseReviewCompanyFragment
  reviewCompanyData: ReviewCompanyDataFragment | null
  reviewState: ReviewState | undefined
  setReviewState: SetStateFn<ReviewState>
}): Array<ReviewSectionType> => {
  return [
    {
      sectionKind: ReviewSectionKind.CompanyInformation,
      title: t`Company Information`,
      rows: [
        {
          rowKind: ReviewRowKind.Countries,
          label: t`Countries`,
          children: reviewCompanyData?.countries?.map((item) => <ReviewStringItem key={item.value} item={item} />)
        },
        {
          rowKind: ReviewRowKind.Industries,
          label: t`Industries`,
          children: reviewCompanyData?.industries?.map((item) => <ReviewStringItem key={item.value} item={item} />)
        },
        {
          rowKind: ReviewRowKind.LegalForm,
          label: t`Legal form`,
          children: reviewCompanyData?.legalForm && <ReviewStringItem item={reviewCompanyData.legalForm} />
        },
        {
          rowKind: ReviewRowKind.CorporatePurpose,
          label: t`Corporate purpose`,
          children: reviewCompanyData?.corporatePurpose && (
            <ReviewStringItem item={reviewCompanyData.corporatePurpose} />
          )
        },
        {
          rowKind: ReviewRowKind.FlaggedEvents,
          label: t`Flagged events`,
          children: reviewCompanyData?.companyFlaggedEvents?.map((item) => (
            <ReviewFlag key={item.value?.id} entityId={reviewCompanyData.id} item={item} />
          ))
        },
        {
          rowKind: ReviewRowKind.Registers,
          label: t`Registers`,
          children: reviewCompanyData?.registers?.map((item) => (
            <ReviewRegister key={item.value?.name} entityId={reviewCompanyData.id} item={item} />
          ))
        },
        {
          rowKind: ReviewRowKind.CreditScore,
          label: t`Credit score`,
          children: reviewCompanyData?.creditScore && (
            <ReviewCreditScore entity={baseCompany} item={reviewCompanyData.creditScore} />
          )
        },
        {
          rowKind: ReviewRowKind.Ccjs,
          label: t`CCJs`,
          children: reviewCompanyData?.ccjs?.map((item) => <ReviewCCJ key={item.value?.caseNumber} item={item} />)
        }
      ]
    },
    {
      sectionKind: ReviewSectionKind.PepsAndSanctions,
      title: t`PEPs and Sanctions`,
      rows: [
        {
          rowKind: ReviewRowKind.Peps,
          label: t`PEPs`,
          children: reviewCompanyData?.peps ? <ReviewPepsWithDisposition items={reviewCompanyData.peps} /> : null
        },
        {
          rowKind: ReviewRowKind.Sanctions,
          label: t`Sanctions`,
          children: reviewCompanyData?.sanctions && <ReviewSanctions items={reviewCompanyData.sanctions} />
        }
      ]
    },
    {
      sectionKind: ReviewSectionKind.ManagementAndRightsHolders,
      title: t`Management and Rights Holders`,
      rows: [
        {
          rowKind: ReviewRowKind.Innehavers,
          label: t`Owner (Innehaver)`,
          children: reviewCompanyData?.innehavers?.map((item) => (
            <ReviewDetailedRole key={item.value?.entity.id} item={item} />
          ))
        },
        {
          rowKind: ReviewRowKind.Partners,
          label: t`Partners`,
          children: reviewCompanyData?.partners?.map((item) => (
            <ReviewDetailedRole key={item.value?.entity.id} item={item} />
          ))
        },
        {
          rowKind: ReviewRowKind.Ceos,
          label: t`CEOs`,
          children: reviewCompanyData?.ceos?.map((item) => <ReviewRole key={item.value?.entity.id} item={item} />)
        },
        {
          rowKind: ReviewRowKind.Chairpersons,
          label: t`Chairpersons`,
          children: reviewCompanyData?.chairpersons?.map((item) => (
            <ReviewRole key={item.value?.entity.id} item={item} />
          ))
        },
        {
          rowKind: ReviewRowKind.OtherBoardMembers,
          label: t`Other board members`,
          children: reviewCompanyData?.otherBoardMembers?.map((section) => (
            <ReviewRoleSection key={section.roleTitle} section={section} />
          ))
        },
        {
          rowKind: ReviewRowKind.BeneficialOwners,
          label: t`Beneficial owners`,
          children: reviewCompanyData?.beneficialOwners?.map((item) => (
            <ReviewOwner
              key={item.value?.entity?.id ?? item.value?.entityName}
              rootEntityId={baseCompany.id}
              item={item}
            />
          ))
        },
        {
          rowKind: ReviewRowKind.AlternativeBeneficialOwners,
          label: t`Alternative beneficial owners`,
          children: reviewCompanyData?.alternativeBeneficialOwners?.map((item) => (
            <ReviewOwner
              key={item.value?.entity?.id ?? item.value?.entityName}
              rootEntityId={baseCompany.id}
              item={item}
            />
          ))
        },
        {
          rowKind: ReviewRowKind.PersonsWithSignificantControl,
          label: t`Persons with Significant Control`,
          children: reviewCompanyData?.personsWithSignificantControl?.map((item) => (
            <ReviewRole key={item.value?.entity.id} item={item} />
          ))
        },
        {
          rowKind: ReviewRowKind.OtherRoles,
          label: t`Other roles`,
          children: reviewCompanyData?.otherRoles?.map((section) => (
            <ReviewRoleSection key={section.roleTitle} section={section} />
          ))
        }
      ]
    },
    {
      sectionKind: ReviewSectionKind.Ams,
      title: t`Adverse Media Screening`,
      rows: [
        {
          rowKind: ReviewRowKind.CompanyAdverseMediaScreening,
          label: t`Company AMS`,
          children: reviewCompanyData?.companyAms?.map((item) => (
            <ReviewEvent
              key={item.value?.id}
              entityId={reviewCompanyData.id}
              item={item}
              reviewState={reviewState}
              setReviewState={setReviewState}
            />
          ))
        },
        {
          rowKind: ReviewRowKind.RolesAdverseMediaScreening,
          label: t`Management and Rights holders AMS`,
          children: reviewCompanyData?.personsAms?.map((section) => (
            <ReviewEventSection
              key={section.entity.id}
              section={section}
              reviewState={reviewState}
              setReviewState={setReviewState}
            />
          ))
        }
      ]
    },
    {
      sectionKind: ReviewSectionKind.Esg,
      title: t`ESG`,
      rows: [
        {
          rowKind: ReviewRowKind.BoardGenderDiversity,
          label: t`Board gender diversity`,
          children: reviewCompanyData?.boardGenderDiversity && (
            <ReviewStringItem item={reviewCompanyData.boardGenderDiversity} />
          )
        },
        {
          rowKind: ReviewRowKind.SubjectToTransparencyAct,
          label: t`Subject to Transparency Act`,
          children: reviewCompanyData?.subjectToPublicProcurement && (
            <ReviewStringItem item={reviewCompanyData.subjectToPublicProcurement} />
          )
        }
      ]
    }
  ]
}
