import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInterimPerson = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M17.659 9H6.34c.264.745.67 1.423 1.187 2h8.944a5.997 5.997 0 0 0 1.187-2ZM17.657 13H6.343a9.049 9.049 0 0 0-1.827 2h14.968a9.05 9.05 0 0 0-1.827-2ZM20.488 17H3.512a8.946 8.946 0 0 0-.457 2h17.89a8.948 8.948 0 0 0-.457-2ZM21 21H3v2h18v-2ZM18 7a5.99 5.99 0 0 0-.341-2H6.34A5.99 5.99 0 0 0 6 7h12ZM7.528 3h8.944A5.985 5.985 0 0 0 12 1a5.985 5.985 0 0 0-4.472 2Z'
      />
    </svg>
  )
)
IconInterimPerson.displayName = 'IconInterimPerson'
