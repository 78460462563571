import * as React from 'react'
import { t } from '@lingui/macro'
import { FromToFilter } from '~/components/FromToFilter'
import { setChildState, useContext } from '@strise/react-utils'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const GrowEmployeeFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const employeesSettings = settings.grow.employees
  const saveEmployeeSettings = setChildState(saveSettings, 'grow.employees')

  const handleFromChange = (value: number | null): void => {
    saveEmployeeSettings({ ...employeesSettings, from: value })
  }

  const handleToChange = (value: number | null): void => {
    saveEmployeeSettings({ ...employeesSettings, to: value })
  }

  return (
    <GrowSettingsSections title={t`Employees restraint`}>
      <FromToFilter
        fromValue={employeesSettings.from ?? null}
        toValue={employeesSettings.to ?? null}
        setFromValue={handleFromChange}
        setToValue={handleToChange}
      />
    </GrowSettingsSections>
  )
}
