import { ContentViews } from '~/utils/urls'
import { type MessageDescriptor } from '@lingui/core'
import { defineMessage } from '@lingui/macro'
import { FeatureKind } from '@strise/types'

export const contentViewToTitle: { [key in ContentViews]: MessageDescriptor } = {
  [ContentViews.Portfolio]: defineMessage({ message: 'Portfolio' }),
  [ContentViews.Review]: defineMessage({ message: 'Review' }),
  [ContentViews.Activity]: defineMessage({ message: 'Activity' }),
  [ContentViews.Grow]: defineMessage({ message: 'Grow' }),
  [ContentViews.Notifications]: defineMessage({ message: 'Notifications' }),
  [ContentViews.Home]: defineMessage({ message: 'Home' })
}

export const contentViewToFeatureMap: Record<ContentViews, FeatureKind | null> = {
  [ContentViews.Review]: FeatureKind.Review,
  [ContentViews.Activity]: FeatureKind.TeamActivitiesAndReminders,
  [ContentViews.Portfolio]: null,
  [ContentViews.Grow]: FeatureKind.Opportunities,
  [ContentViews.Notifications]: null,
  [ContentViews.Home]: null
}
