import { i18n, type MessageDescriptor } from '@lingui/core'
import { t } from '@lingui/macro'
import { type ComboboxItem } from '@strise/ui-components'

export const enumOptions = <T extends PropertyKey>(
  values: T[],
  titleMap: { [key in T]?: MessageDescriptor },
  extractProps?: (value: T) => object
): Array<ComboboxItem<T>> => {
  return values.map((value) => {
    const props = extractProps ? extractProps(value) : {}

    return { id: value as string, value, label: getTitle(titleMap[value]), ...props }
  })
}

export const enumComboboxItems = <T extends PropertyKey>(
  values: T[],
  titleMap: { [key in T]?: MessageDescriptor }
): Array<ComboboxItem<T>> => {
  return values.map((value) => {
    return { id: value as string, label: getTitle(titleMap[value]), value }
  })
}

export const getTitle = (title: MessageDescriptor | undefined): string => {
  return title ? i18n._(title) : t`Unknown`
}
