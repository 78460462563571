import { Chip, LoaderRound, Typography, Combobox, type ComboboxItem } from '@strise/ui-components'
import {
  useCurrentUserEnabledContentLanguages,
  CurrentUserSettingsContext
} from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useLegalFormsQuery } from '~/graphqlOperations'
import { t, Trans } from '@lingui/macro'
import { NationFlag } from '@strise/app-shared'
import { setChildState, useContext } from '@strise/react-utils'
import { GrowSettingsSections } from '~/views/Grow/components/GrowSettingsPanel'
import * as React from 'react'
import { type LegalFormFragment } from '~/graphqlTypes'

export const GrowLegalFormFilter = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const legalFormsState = settings.grow.legalForms
  const saveLegalForms = setChildState(saveSettings, 'grow.legalForms')

  const enabledContentLanguages = useCurrentUserEnabledContentLanguages()

  const { data, loading } = useLegalFormsQuery({
    variables: { countries: enabledContentLanguages }
  })
  const allLegalForms = data?.legalForms.edges ?? []

  const options: Array<ComboboxItem<LegalFormFragment>> = allLegalForms.map(({ node: legalForm }) => {
    return {
      id: legalForm.id,
      searchString: `${legalForm.name} ${legalForm.code}`,
      label: legalForm.name,
      value: legalForm,
      renderNode: (
        <div className='flex items-center'>
          <NationFlag countryIsoCode={legalForm.countryV2} className='mr-2 shrink-0' />
          <Typography>{legalForm.name}</Typography>
        </div>
      )
    }
  })

  const handleAdd = (item: ComboboxItem<LegalFormFragment>): void => {
    saveLegalForms((prevState) => {
      return [...prevState, item.value]
    })
  }

  const handleDelete = (item: ComboboxItem<LegalFormFragment>): void => {
    saveLegalForms((prevState) => {
      return prevState.filter((lf) => lf.id !== item.id)
    })
  }

  return (
    <GrowSettingsSections title={t`Legal form`}>
      {loading ? (
        <LoaderRound />
      ) : (
        <>
          {!!legalFormsState.length && (
            <div className='flex flex-wrap gap-1'>
              {legalFormsState.map((legalForm, index) => {
                return (
                  <Chip
                    className='mr-2'
                    key={index}
                    startIcon={<NationFlag countryIsoCode={legalForm.countryV2} className='w-4' />}
                    variant='outlined'
                    palette='secondary'
                    label={legalForm.name}
                    onDelete={() => handleDelete({ id: legalForm.id, label: legalForm.name, value: legalForm })}
                  />
                )
              })}
            </div>
          )}

          <Combobox
            variant='outlined'
            palette='tertiary'
            value={legalFormsState.map((legalForm) => ({ id: legalForm.id, label: legalForm.name, value: legalForm }))}
            items={options}
            onAdd={handleAdd}
            onRemove={handleDelete}
            data-track='Grow Filter / Legal form / Select'
            customSelectedItemsRenderer={<Trans>Select one or more</Trans>}
          >
            <Trans>Select one or more</Trans>
          </Combobox>
        </>
      )}
    </GrowSettingsSections>
  )
}
