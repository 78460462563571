import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconNotCertified = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3 1h18v8.861a13 13 0 0 1-4.93 10.193L12 23.275l-4.07-3.221A13 13 0 0 1 3 9.861V1Zm2 2v6.861a11 11 0 0 0 4.172 8.625L12 20.725l2.828-2.24A11 11 0 0 0 19 9.862V3H5Zm5.586 8L8.293 8.707l1.414-1.414L12 9.586l2.293-2.293 1.414 1.414L13.414 11l2.293 2.293-1.414 1.414L12 12.414l-2.293 2.293-1.414-1.414L10.586 11Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconNotCertified.displayName = 'IconNotCertified'
