import { Duration } from '@strise/types'

export const timeOptions = [
  Duration.Day,
  Duration.Week,
  Duration.Month,
  Duration.HalfYear,
  Duration.Year,
  Duration.ThreeYear
]
