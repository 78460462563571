import * as React from 'react'
import { IconCheckSmall } from '@strise/ui-components'
import { NationFlags } from '@strise/app-shared'

export const FinancialFieldSelectRenderText = (
  fieldName: string,
  countryIsoCodes: string[],
  selected?: boolean
): React.ReactNode => {
  return (
    <div className='flex items-center'>
      {selected && <IconCheckSmall className='mr-4' />}
      <NationFlags className='mr-2' countryIsoCodes={countryIsoCodes} />
      {fieldName}
    </div>
  )
}
