import { type DialogProps } from '@strise/ui-components-legacy'
import { type PepInfoFragment, type PersonBaseFragment } from '~/graphqlTypes'
import { PepDispositionComparisonDialog } from '~/components/PepAndSanctions/PepDispositionComparisonDialog'
import { PepDispositionListDialog } from '~/components/PepAndSanctions/PepDispositionListDialog'
import React from 'react'

interface PepDispositionDialogProps extends Omit<DialogProps, 'isOpen'> {
  onClose: () => void
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment
}

export const PepDispositionDialog = ({
  onClose,
  pepInfos,
  person,
  ...props
}: PepDispositionDialogProps): React.ReactNode => {
  const defaultValue = pepInfos.length === 1 && pepInfos[0] ? pepInfos[0] : null
  const [comparePepInfo, setComparePepInfo] = React.useState<PepInfoFragment | null>(defaultValue)

  // Render both dialogs to keep the state when switching views
  return (
    <>
      <PepDispositionComparisonDialog
        person={person}
        pepInfo={comparePepInfo}
        onClose={onClose}
        setComparePepInfo={defaultValue ? null : setComparePepInfo}
        {...props}
      />
      <PepDispositionListDialog
        person={person}
        pepInfos={pepInfos}
        onClose={onClose}
        comparePepInfo={comparePepInfo}
        setComparePepInfo={setComparePepInfo}
        {...props}
      />
    </>
  )
}
