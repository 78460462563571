import React, { useCallback } from 'react'
import { AuthContext } from './AuthContext'
import { AuthError } from './authError'
import { type Auth0Client, type User } from '@auth0/auth0-spa-js'
import { useCurrentUserQuery } from '../../graphqlOperations'
import { getBrowserGlobals, useContext } from '@strise/react-utils'
import { type SimpleUserFragment, type TeamEdgeFragment } from '../../graphqlTypes'
import { type NonNullableFields } from '@strise/types'

export const useLogin = (): (() => Promise<void>) => {
  const { auth0 } = useContext(AuthContext)
  return useCallback(async () => {
    if (!auth0) return
    await auth0.loginWithRedirect({
      appState: {
        redirectUrl:
          (getBrowserGlobals()?.window.location.pathname || '') + (getBrowserGlobals()?.window.location.search || '')
      }
    })
  }, [auth0])
}

// eslint-disable-next-line functional/no-let
let logoutInProgress = false
export const logoutWithError = async ({
  auth0,
  errorCode,
  errorContext,
  onLogout
}: {
  auth0: Auth0Client | null
  errorCode?: AuthError
  errorContext?: string
  onLogout?: (code?: AuthError, context?: string) => PromiseLike<boolean> | undefined
}): Promise<void> => {
  if (logoutInProgress) return
  logoutInProgress = true

  if (!auth0) return
  if (onLogout) await onLogout(errorCode, errorContext)
  auth0.logout({
    logoutParams: {
      returnTo: (getBrowserGlobals()?.window.location.origin || '') + (errorCode ? `/?error=${errorCode}` : '')
    }
  })
}

export const useLogout = (): ((errorCode?: AuthError, errorContext?: string) => Promise<void>) => {
  const { auth0, onLogout } = useContext(AuthContext)
  return useCallback(
    async (errorCode?: AuthError, errorContext?: string) => {
      await logoutWithError({ auth0, onLogout, errorCode, errorContext })
    },
    [auth0, onLogout]
  )
}

const ignoredErrors = [
  /Timeout/,
  /Login required/, // Auth0 Login required
  /Invalid state/ // Auth0 Invalid state
]

export const isIgnoredError = (err: unknown): boolean => {
  const stringifiedError = String(err)
  return ignoredErrors.some((ignoredError) => ignoredError.test(stringifiedError))
}

export const useAccessToken = (): (() => Promise<string | undefined>) => {
  const { auth0 } = useContext(AuthContext)
  const logout = useLogout()

  return useCallback(async () => {
    if (!auth0) return
    try {
      return await auth0.getTokenSilently()
    } catch (err) {
      if (!isIgnoredError(err)) {
        console.error(err)
      }
      logout(AuthError.SessionExpired)
    }
  }, [auth0, logout])
}

export const useCurrentUser = (): NonNullableFields<SimpleUserFragment> => {
  const { data } = useCurrentUserQuery()

  return React.useMemo(() => {
    return {
      id: data?.currentUser?.id ?? '',
      name: data?.currentUser?.name ?? '',
      email: data?.currentUser?.email ?? '',
      avatar: data?.currentUser?.avatar ?? '',
      __typename: 'SimpleUser' as const
    }
  }, [data])
}

export const useTokenMeta = (): User | null => {
  const { tokenMeta } = useContext(AuthContext)
  return tokenMeta
}

export const useIsAuthenticated = (): boolean => {
  const { authenticated } = useContext(AuthContext)
  return authenticated
}

export const useUserId = (): string => {
  const { user } = useContext(AuthContext)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return user!.id
}

export const useUserTeams = (): TeamEdgeFragment[] => {
  const { user } = useContext(AuthContext)
  return user?.teams ?? []
}

export const useIsAdmin = (): boolean => {
  const { user } = useContext(AuthContext)
  return user?.isAdmin ?? false
}

export const useIsSupervisor = (): boolean => {
  const { user } = useContext(AuthContext)
  return user?.isSupervisor ?? false
}
