import { type BaseEntityLikeFragment } from '~/graphqlTypes'
import * as React from 'react'
import { useReactiveVar } from '@apollo/client/index.js'
import { refreshReviewCompanyMap } from '~/state'
import { useResetEntityRolesMutation } from '~/graphqlOperations'
import { Button, Typography } from '@strise/ui-components'
import { t, Trans } from '@lingui/macro'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import { TestIDs } from '~/utils/testIDs'

export const ResetRoles = ({
  entity,
  filterActive,
  ignoreCustomRoles
}: {
  entity: BaseEntityLikeFragment
  filterActive?: boolean
  ignoreCustomRoles: boolean
}): React.ReactNode => {
  const [isOpen, setIsOpen] = React.useState(false)
  const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)

  const [update, { loading }] = useResetEntityRolesMutation({
    onCompleted: () => {
      // prepare review again to reset roles, peps, sanctions, etc
      refreshReviewCompanyMap({
        ...refreshPrepareReview,
        [entity.id]: (refreshPrepareReview[entity.id] ?? 0) + 1
      })

      setIsOpen(false)
    }
  })

  const handleReset = async (): Promise<void> => {
    await update({ variables: { entity: entity.id, filterActive, ignoreCustomRoles } })
  }

  return (
    <>
      <div>
        <Button
          type='button'
          variant='ghost'
          palette='danger'
          className='rounded'
          onClick={() => setIsOpen(true)}
          data-track='Edit Roles / Open reset roles dialog'
          data-id={TestIDs.SidePanel.Roles.resetRolesButton}
        >
          <Typography variant='aLabelSmall'>
            <Trans>Reset</Trans>
          </Typography>
        </Button>
      </div>
      <ConfirmDialog
        isOpen={isOpen}
        onConfirm={handleReset}
        danger={true}
        onClose={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        title={t`Are you sure you want to reset the role table to its original form?`}
        confirmText={t`Reset`}
        contentMaxWidth={550}
        cancelButtonProps={{
          'data-track': 'Edit Roles / Reset roles / Cancel'
        }}
        confirmButtonProps={{
          'data-track': 'Edit Roles / Reset roles / Confirm',
          'data-id': TestIDs.SidePanel.Roles.resetRolesConfirmButton
        }}
        loading={loading}
        cancelText={t`Cancel`}
      >
        <Typography variant='body2'>
          <Trans>
            The custom roles between the edited entities and all edited changes will be reset. This action can not be
            reversed.
          </Trans>
        </Typography>
      </ConfirmDialog>
    </>
  )
}
