import { useLocalStorage } from 'usehooks-ts'
import { STORAGE_KEYS } from '~/constants'
import { omit, orderBy } from 'lodash-es'
import {
  RecentlyVisitedEntitiesContext,
  type RecentlyVisitedEntitiesMap
} from '~/contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'
import * as React from 'react'
import { differenceInDays } from 'date-fns'

const RECENTLY_VISITED_ENTITIES_MAX_SIZE = 100

export const RecentlyVisitedEntitiesContextProvider = ({
  children
}: {
  children: React.ReactNode
}): React.ReactNode => {
  const [recentlyVisitedEntitiesMap, setRecentlyVisitedSearchItemsMap] = useLocalStorage<RecentlyVisitedEntitiesMap>(
    STORAGE_KEYS.recentlyVisitedEntities,
    {}
  )

  const addRecentlyVisitedEntity = (id: string): void => {
    setRecentlyVisitedSearchItemsMap((prevValue) => {
      const withAddedEntity = {
        ...prevValue,
        [id]: { visitedAt: Date.now() }
      }

      // Since we add new items at the end of the object, make sure we cut off the first ones
      const entries = Object.entries(withAddedEntity)
      const sorted = orderBy(entries, ([, value]) => -value.visitedAt)
      const sliced = sorted.slice(0, RECENTLY_VISITED_ENTITIES_MAX_SIZE)
      const newValue = Object.fromEntries(sliced)
      return newValue
    })
  }

  const removeRecentlyVisitedEntity = (id: string): void => {
    setRecentlyVisitedSearchItemsMap((prevValue) => omit(prevValue, id))
  }

  const extractIsEntityRecentlyVisited = (id: string): boolean => {
    const recentlyVisited = recentlyVisitedEntitiesMap[id]

    if (!recentlyVisited) return false

    const difference = differenceInDays(new Date(), new Date(recentlyVisited.visitedAt))
    const isUnderAWeekAgo = difference < 7
    return isUnderAWeekAgo
  }

  const values = React.useMemo(() => {
    return {
      recentlyVisitedEntitiesMap,
      addRecentlyVisitedEntity,
      removeRecentlyVisitedEntity,
      extractIsEntityRecentlyVisited
    }
  }, [JSON.stringify(recentlyVisitedEntitiesMap)])

  return <RecentlyVisitedEntitiesContext.Provider value={values}>{children}</RecentlyVisitedEntitiesContext.Provider>
}
