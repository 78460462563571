import React from 'react'
import { IconButton, Tooltip, IconCheckBoxUncheckAll, IconPlusBox } from '@strise/ui-components'
import { t } from '@lingui/macro'

interface AmsEventFeedbackToggleProps {
  handleClickAmsEventFeedback: () => void
  isAmsRelevant: boolean
}

export const AmsEventFeedbackToggle: React.FC<AmsEventFeedbackToggleProps> = ({
  handleClickAmsEventFeedback,
  isAmsRelevant
}) => {
  const tooltipContent = isAmsRelevant ? t`Remove from report` : t`Add to report`
  return (
    <Tooltip content={tooltipContent} arrow sideOffset={-6}>
      <IconButton
        data-track='Event / AMS Relevance / Toggle'
        onClick={handleClickAmsEventFeedback}
        className='size-12 p-0 hover:bg-transparent focus:bg-transparent active:bg-transparent'
        aria-label={tooltipContent}
      >
        {isAmsRelevant ? (
          <IconCheckBoxUncheckAll className='size-5 text-primary-main' />
        ) : (
          <IconPlusBox className='size-5 text-text-secondary hover:text-primary-main' />
        )}
      </IconButton>
    </Tooltip>
  )
}
