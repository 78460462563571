import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconAction = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M5.443 8.615C3.794 9.605 3 10.832 3 12H1c0-2.146 1.445-3.918 3.414-5.1C6.404 5.706 9.09 5 12 5c2.912 0 5.596.706 7.586 1.9C21.556 8.082 23 9.854 23 12h-2c0-1.168-.794-2.395-2.443-3.385C16.927 7.637 14.61 7 12 7s-4.927.637-6.557 1.615ZM18.557 15.385C20.206 14.395 21 13.168 21 12h2c0 2.146-1.445 3.918-3.414 5.1C17.596 18.294 14.912 19 12 19s-5.596-.706-7.586-1.9C2.444 15.918 1 14.146 1 12h2c0 1.168.794 2.396 2.443 3.385C7.073 16.363 9.39 17 12 17s4.927-.637 6.557-1.615Z'
        clipRule='evenodd'
      />
      <path fill='currentColor' fillRule='evenodd' d='M2 11h20v2H2v-2Z' clipRule='evenodd' />
    </svg>
  )
)
IconAction.displayName = 'IconAction'
