import { t } from '@lingui/macro'
import { cn, Tooltip, type TooltipProps, Typography } from '@strise/ui-components'
import * as React from 'react'
import { type DivProps } from '@strise/react-utils'

interface TooltipContentProps extends Pick<TooltipProps, 'content'>, Omit<DivProps, 'content'> {
  dataSource?: string
}

const TooltipContent: React.FC<TooltipContentProps> = ({ className, content, dataSource, ...props }) => {
  return (
    <div className={cn('relative p-1 text-center', className)} {...props}>
      {content && (
        <Typography variant='body2' className={cn({ 'mb-2': dataSource })}>
          {content}
        </Typography>
      )}

      {dataSource && (
        <Typography className='text-secondary-shade-20' variant='body3'>
          {t`Source`}: {dataSource}
        </Typography>
      )}
    </div>
  )
}

export const DataSourceTooltip: React.FC<
  {
    dataSource?: string
  } & TooltipProps
  // eslint-disable-next-line @typescript-eslint/promise-function-async -- children is not a promise
> = ({ children, content, dataSource, open, side, ...props }) => {
  const showArrow = side !== 'left'

  if (!content && !dataSource) return children

  return (
    <Tooltip
      content={<TooltipContent content={content} dataSource={dataSource} />}
      arrow={showArrow}
      open={open}
      {...props}
    >
      {children}
    </Tooltip>
  )
}
