import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCollapse = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M24 23h-2V1h2v22ZM12.707 3.293 21.414 12l-8.707 8.707-1.414-1.414L17.586 13H2v-2h15.586l-6.293-6.293 1.414-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCollapse.displayName = 'IconCollapse'
