'use client'

import * as React from 'react'
import { cn } from '../../utils/className'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { Button, type ButtonProps } from '../Button'

export interface TabsProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>,
    Pick<ButtonProps, 'palette'> {}

const Tabs = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Root>, TabsProps>(
  ({ children, className, palette, ...props }, ref) => (
    <TabsPrimitive.Root ref={ref} className={cn('data-[orientation=vertical]:inline-flex', className)} {...props}>
      {/* eslint-disable-next-line @typescript-eslint/promise-function-async,@eslint-react/no-children-map */}
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@eslint-react/no-clone-element
        return React.cloneElement(child, {
          palette,
          ...child.props
        })
      })}
    </TabsPrimitive.Root>
  )
)
Tabs.displayName = TabsPrimitive.Tabs.displayName

interface TabsListProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>,
    Pick<ButtonProps, 'palette'> {}

const TabsList = React.forwardRef<React.ElementRef<typeof TabsPrimitive.List>, TabsListProps>(
  ({ children, className, palette, ...props }, ref) => (
    <TabsPrimitive.List
      ref={ref}
      className={cn('inline-flex data-[orientation=vertical]:flex-col', className)}
      {...props}
    >
      {/* eslint-disable-next-line @typescript-eslint/promise-function-async,@eslint-react/no-children-map */}
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) return child
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@eslint-react/no-clone-element
        return React.cloneElement(child, {
          palette,
          ...child.props
        })
      })}
    </TabsPrimitive.List>
  )
)

TabsList.displayName = TabsPrimitive.List.displayName

interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    Omit<ButtonProps, 'value'> {}

const TabsTrigger = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Trigger>, TabsTriggerProps>(
  ({ children, className, palette = 'secondary', ...props }, ref) => (
    <TabsPrimitive.Trigger
      ref={ref}
      asChild
      className={cn(
        'text-text-primary',
        'data-[orientation=horizontal]:data-[state=active]:border-b-4 data-[orientation=vertical]:data-[state=active]:border-r-4',
        'data-[orientation=horizontal]:data-[state=active]:pt-1 data-[orientation=vertical]:data-[state=active]:pr-[calc(1.25rem-4px)]',
        className
      )}
      {...props}
    >
      <Button palette={palette}>{children}</Button>
    </TabsPrimitive.Trigger>
  )
)

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

interface TabsContentProps extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> {}

const TabsContent = React.forwardRef<React.ElementRef<typeof TabsPrimitive.Content>, TabsContentProps>(
  ({ className, ...props }, ref) => <TabsPrimitive.Content ref={ref} className={className} {...props} />
)

TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
