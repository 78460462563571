import {
  type BaseEntityLikeFragment,
  type CompanyNetworkEntityPersonFragment,
  type SanctionInfoFragment
} from '@strise/app-shared/src/graphqlTypes'
import { Card, CardContent, cn } from '@strise/ui-components'
import * as React from 'react'
import { TestIDs } from '~/utils/testIDs'
import { SanctionsEntityContent } from '~/components/PepAndSanctions/SanctionsEntityContent'

interface SanctionCardProps {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies'] | null
  currentCompanyId: string | null
  entity: BaseEntityLikeFragment
  noLink?: boolean
  sanctions: SanctionInfoFragment
}

export const SanctionCard = ({
  associatedCompanies,
  currentCompanyId,
  entity,
  noLink,
  sanctions
}: SanctionCardProps): React.ReactNode => {
  const isSanctioned = sanctions.isSanctioned

  return (
    <Card
      palette={isSanctioned ? 'danger' : undefined}
      size='sm'
      className={cn('border-none p-4', { 'p-0': !isSanctioned })}
      rounded='sm'
      data-id={TestIDs.SidePanel.PepAndSanctions.sanctionCard(entity.name ?? '')}
    >
      <CardContent className='p-0'>
        <SanctionsEntityContent
          entity={entity}
          associatedCompanies={associatedCompanies}
          entityName={entity.name}
          sanctionInfo={sanctions}
          currentCompanyId={currentCompanyId}
          noLink={noLink}
        />
      </CardContent>
    </Card>
  )
}
