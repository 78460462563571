import { cn } from '@strise/ui-components'
import { type FlagSeverity } from '@strise/types'
import { flagSeverityToChipTitle, flagSeverityToClasses } from './flags'
import { i18n } from '@lingui/core'
import React from 'react'
import { AdverseFlag } from '../AdverseFlag'

export const flagChipProps = (
  severity: FlagSeverity
): { className: string; label: string; startIcon: React.ReactNode } => ({
  className: cn(flagSeverityToClasses[severity], 'mr-2'),
  label: i18n._(flagSeverityToChipTitle[severity]),
  startIcon: <AdverseFlag severity={severity} />
})
