import { PepPersonContent } from '~/components/PepAndSanctions/PepPersonContent'
import { type TableRowValueFragment } from '~/graphqlTypes'
import React from 'react'

export const PepsWithEntity2 = ({
  value
}: {
  value: TableRowValueFragment & { __typename: 'TableRowValuePepsWithEntity2' }
}): React.ReactNode => {
  return (
    <PepPersonContent
      className='mt-1'
      pepInfos={value.pepInfos}
      person={value.maybeEntity?.__typename === 'Person' ? value.maybeEntity : null}
      showSystemSuggestedFalse={false}
      associatedCompanies={null}
      currentCompanyId={null}
      enableEdit={false}
    />
  )
}
