import * as React from 'react'
import { useState } from 'react'
import { t } from '@lingui/macro'
import { DropdownMenu } from '~/components/DropdownMenu'
import { IconConnect } from '@strise/ui-components'
import { type EntityLikeMetaFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'
import { MergeEntityDialog } from '~/components/Ownerships/edit-owner/MergeEntityDialog'
import { useMergeEntityMutation } from '~/graphqlOperations'
import { useReactiveVar } from '@apollo/client/index.js'
import { refreshReviewCompanyMap } from '~/state'
import { OwnershipsContext } from '~/components/Ownerships/OwnershipsContext/OwnershipsContext'

import { useContext } from '@strise/react-utils'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'

export type ActionMode = 'MERGE'

export const MergeEntityDropdown = ({
  entity,
  ownershipValue
}: {
  entity: EntityLikeMetaFragment
  ownershipValue: number
}): React.ReactNode => {
  const [actionMode, setActionMode] = useState<ActionMode | null>(null)
  const { rootEntity, showOriginal } = useContext(OwnershipsContext)
  const { showNetworkRisk } = useContext(SidepanelContext)

  const refreshPrepareReview = useReactiveVar(refreshReviewCompanyMap)

  const [update, { loading }] = useMergeEntityMutation({
    onCompleted: () => {
      setActionMode(null)
      // prepare review again to update ownerships, peps, sanctions, etc
      refreshReviewCompanyMap({
        ...refreshPrepareReview,
        [rootEntity.id]: (refreshPrepareReview[rootEntity.id] ?? 0) + 1,
        [entity.id]: (refreshPrepareReview[entity.id] ?? 0) + 1
      })
    }
  })

  const menuOptions = [
    {
      title: t`Merge entity`,
      onClick: () => {
        setActionMode('MERGE')
      },
      'data-track': 'Edit Ownership / Merge entity / Open Dialog',
      'data-id': TestIDs.SidePanel.Ownerships.mergeEntityOption,
      hide: false
    }
  ].filter((option) => !option.hide)

  const handleMerge = async (mergeEntityId: string): Promise<void> => {
    await update({
      variables: {
        entity: entity.id,
        sidepanelEntity: rootEntity.id,
        input: {
          entity: mergeEntityId,
          ownership: ownershipValue
        },
        ignoreCustomOwnerships: showOriginal,
        includeNetworkRoles: showNetworkRisk
      }
    })
  }

  const handleDialogClose = (): void => {
    setActionMode(null)
  }

  return (
    <>
      <DropdownMenu
        menuItems={menuOptions}
        dataTrack='Merge Entity / Open dropdown'
        icon={<IconConnect />}
        buttonProps={{
          className: 'text-accent-blue-main rounded-full border-2 bg-white z-10 hover:bg-white border-primary-main '
        }}
        position='absolute'
        top={-20}
        right={-20}
      />

      {actionMode === 'MERGE' && (
        <MergeEntityDialog
          entity={entity}
          handleMerge={handleMerge}
          handleCancel={handleDialogClose}
          loading={loading}
        />
      )}
    </>
  )
}
