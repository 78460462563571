import { Editor, Element, Transforms } from 'slate'

export type Format = 'bold' | 'italic' | 'underline'

export type BlockType = 'bulleted-list'

export const isMarkActive = (editor: Editor, format: Format): boolean => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export const toggleMark = (editor: Editor, format: Format): void => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const isBlockActive = (editor: Editor, type: Element['type']): boolean => {
  const { selection } = editor
  if (!selection) return false

  const [match] = [
    ...Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === type
    })
  ]

  return !!match
}

export const toggleBlock = (editor: Editor, format: Element['type']): void => {
  const isActive = isBlockActive(editor, format)

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === format,
    split: true
  })

  Transforms.setNodes<Element>(editor, {
    type: isActive ? 'paragraph' : 'list-item'
  })

  if (!isActive) {
    Transforms.wrapNodes(editor, { type: format, children: [] })
  }
}
