import { cva, type VariantProps } from 'class-variance-authority'
import type React from 'react'

export interface SvgProps extends Omit<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'color' | 'fill'> {}

export interface IconProps extends SvgProps, VariantProps<typeof iconVariants> {}

export type IconPropsWithRef = IconProps & React.RefAttributes<SVGSVGElement>
export type IconComponent = React.ForwardRefExoticComponent<IconPropsWithRef>

export const iconVariants = cva('', {
  variants: {
    size: {
      xs: 'size-3',
      sm: 'size-3.5',
      md: 'size-4',
      lg: 'size-5',
      xl: 'size-6'
    }
  },
  defaultVariants: {
    size: 'xl'
  }
})
