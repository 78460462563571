import { Alert, Chip, cn, Divider, IconInfo, LoaderRound, Skeleton, Tooltip, Typography } from '@strise/ui-components'
import { useCompareCompaniesFinancialsQuery, useSimilarCompareCompaniesQuery } from '~/graphqlOperations'
import {
  type BaseEntityLikeFragment,
  type CompareCompanyFinancialsFragment,
  type EntityLikeMetaFragment,
  type FinancialsSectionFragment,
  type SidepanelCompanyFragment
} from '~/graphqlTypes'
import { i18n } from '@lingui/core'
import { defineMessage, t, Trans } from '@lingui/macro'
import { type DivProps, type SetStateFn, useDependencyState } from '@strise/react-utils'
import { Dropdown, Table, TableCell, type TableProps, TableRow } from '@strise/ui-components-legacy'
import { ApplicationSearchReturnType } from '@strise/types'
import { useEntityLikeName } from '~/utils/entity'
import * as React from 'react'
import { useState } from 'react'
import { EntitySearchInputMultiple } from '~/components/Entity/EntitySearchInput'
import { EntityMeta } from '~/components/EntityMeta/EntityMeta'
import { SidepanelFinancialTableRowTitle } from '~/components/Sidepanel/SidepanelCards/FinancialsCard/SidepanelFinancialTableRowTitle'
import { FinancialTableRow } from '~/components/Sidepanel/SidepanelCards/FinancialsCard/SidepanelFinancialsTable'
import { extractEntityContentLanguage } from '@strise/app-shared'

const CompareMeta = ({ company }: { company: CompareCompanyFinancialsFragment }): React.ReactNode => {
  return (
    <Tooltip content={company.corporatePurpose} className='max-w-[300px]'>
      <div className='flex h-[120px] flex-col justify-center'>
        <EntityMeta entity={company} variant='small' hideStatus link noTooltip column />
      </div>
    </Tooltip>
  )
}

const CompareSkeleton = ({ company }: { company?: boolean }): React.ReactNode => (
  <div className='pt-4'>
    {company ? (
      <div className='mb-12 space-y-1'>
        <Skeleton className='h-5 w-32' />
        <Skeleton className='h-4 w-24' />
        <Skeleton className='h-4 w-24' />
        <Skeleton className='h-4 w-24' />
        <Skeleton className='h-4 w-24' />
      </div>
    ) : (
      <div className='mb-20 space-y-1'>
        <Skeleton className='mb-2 mt-5 h-5 w-20' />
        <Skeleton className='h-3 w-24' />
        <Skeleton className='h-3 w-24' />
      </div>
    )}

    {Array.from({ length: 4 }).map((_, index) => (
      <div className='mb-8 space-y-2' key={index}>
        <Skeleton className='mb-4 h-5 w-24' />
        <Skeleton className='h-4 w-4/5' />
        <Skeleton className='h-4 w-4/5' />
        <Skeleton className='h-4 w-4/5' />
        <Skeleton className='h-4 w-4/5' />
      </div>
    ))}
  </div>
)

interface CompareTableProps extends TableProps {
  columns?: number
  headerCellContents: React.ReactNode[]
  labels?: boolean
  sections?: FinancialsSectionFragment[]
  titleElement: React.ReactNode
}

const CompareTable: React.FC<CompareTableProps> = ({
  className,
  columns,
  headerCellContents,
  labels,
  sections,
  titleElement,
  ...props
}) => {
  return (
    <div className={cn('px-2', className)} {...props}>
      {titleElement}
      <Table>
        <thead>
          <TableRow className='border-secondary-shade-70'>
            {headerCellContents.map((headerCell, index) => (
              <TableCell key={index} p={0}>
                <Typography className='text-right' variant='body2'>
                  {headerCell}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </thead>
        {sections?.map((section, sectionIndex) => {
          return (
            <React.Fragment key={sectionIndex}>
              <SidepanelFinancialTableRowTitle value={labels ? section.name : ''} />
              {section.rows.map((row, rowIndex) => {
                const filteredRow = {
                  ...row,
                  values: row.values.slice(0, columns ?? 1)
                }
                const isLast = rowIndex === section.rows.length - 1 && sectionIndex !== sections.length - 1

                return (
                  <FinancialTableRow
                    key={`${sectionIndex}-${rowIndex}`}
                    row={filteredRow}
                    rowIndex={rowIndex}
                    labels={labels}
                    cellClassName={isLast ? 'border-tertiary-main' : undefined}
                  />
                )
              })}
            </React.Fragment>
          )
        })}
      </Table>
    </div>
  )
}

const ComparedFinancials = ({
  className,
  comparedFinancials,
  loading,
  ...props
}: {
  comparedFinancials: FinancialsSectionFragment[] | null | undefined
  loading: boolean
} & DivProps): React.ReactNode => {
  if (loading) {
    return (
      <div className={className}>
        <CompareSkeleton />
      </div>
    )
  }

  if (!comparedFinancials) return <div className={className}></div>

  const header = (
    <div className='flex h-[124px] flex-col justify-center'>
      <Typography className='mb-1' variant='aLabelBold'>
        <Trans>Average</Trans>:
      </Typography>
      <Typography variant='body2'>
        <Trans>Average of all companies above</Trans>
      </Typography>
    </div>
  )

  return (
    <CompareTable
      className={className}
      sections={comparedFinancials}
      columns={2}
      {...props}
      titleElement={header}
      headerCellContents={[t`Mean`, t`Median`]}
    />
  )
}

const ComparedCompany = ({
  className,
  company,
  loading,
  periodTitle,
  primary,
  ...props
}: {
  company: CompareCompanyFinancialsFragment | null | undefined
  loading?: boolean
  periodTitle: string
  primary?: boolean
} & Omit<CompareTableProps, 'loading' | 'titleElement' | 'headerCellContents'>): React.ReactNode => {
  const wrapperClasses = cn(
    'w-2/5 border border-t-4',
    primary ? 'border-tertiary-main' : 'border-primary-main',
    className
  )

  if (loading) {
    return (
      <div className={cn('px-2', wrapperClasses)}>
        <CompareSkeleton company />
      </div>
    )
  }

  if (!company) return <div className={wrapperClasses} />

  return (
    <CompareTable
      className={wrapperClasses}
      titleElement={<CompareMeta company={company} />}
      headerCellContents={primary ? [null, periodTitle] : [periodTitle]}
      sections={company.financials?.compare}
      {...props}
    />
  )
}

const useComparedCompanies = (
  id: string
): [EntityLikeMetaFragment[], SetStateFn<EntityLikeMetaFragment[]>, boolean] => {
  const { data, loading } = useSimilarCompareCompaniesQuery({ variables: { company: id } })
  const companies = data?.company.similarCompanies.edges.map((s) => s.node) ?? []

  const [compareCompanies, setCompareCompanies] = useState<EntityLikeMetaFragment[]>(companies)

  return [compareCompanies, setCompareCompanies, loading]
}

export const SidepanelFinancialsCompare = ({
  company,
  hasConsolidated,
  periodTitle,
  year
}: {
  company: SidepanelCompanyFragment
  hasConsolidated: boolean
  periodTitle?: string | null
  year?: number | null
}): React.ReactNode => {
  const [compareCompanies, setCompareCompanies, similarCompaniesLoading] = useComparedCompanies(company.id)
  const [selectedCompanyId, setSelectedCompanyId] = useDependencyState<string | null>(
    () => compareCompanies[0]?.id ?? null,
    [JSON.stringify(compareCompanies)]
  )

  const { data, loading: financialsLoading } = useCompareCompaniesFinancialsQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      selectedCompany: selectedCompanyId!,
      ids: compareCompanies.map((c) => c.id),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      year: year!
    },
    skip: !selectedCompanyId || !year
  })
  const comparedFinancials = data?.compareCompanies.financials
  const selectedCompany = data?.selectedCompany
  const selectedCompanyPeriodTitle = selectedCompany?.financials?.meta[0]?.periodTitle

  const selectCompany = (compareCompanyId: string): void => {
    setSelectedCompanyId(compareCompanyId)
  }

  const deleteCompany = (compareCompanyId: string): void => {
    if (selectedCompanyId === compareCompanyId) {
      setSelectedCompanyId(null)
    }
    setCompareCompanies((prev) => prev.filter((c) => c.id !== compareCompanyId))
  }

  const handleChange = (values: BaseEntityLikeFragment[]): void => {
    setCompareCompanies(values as EntityLikeMetaFragment[])
  }

  const companyName = useEntityLikeName(selectedCompanyId)
  const averageInfoText = selectedCompanyId
    ? defineMessage({
        message: `Average of ${companyName ?? ''} and ${compareCompanies.length - 1} other selected companies.`
      })
    : defineMessage({
        message: `Average of ${compareCompanies.length} selected companies.`
      })

  if (!periodTitle) {
    return (
      <Typography className='pb-5 text-center text-text-secondary'>
        <Trans>No financials recorded</Trans>
      </Typography>
    )
  }

  if (financialsLoading && similarCompaniesLoading) {
    return (
      <div className='flex h-[18.75rem] items-center justify-center'>
        <LoaderRound />
      </div>
    )
  }

  const contentLanguage = extractEntityContentLanguage(company)
  const lang = contentLanguage ? [contentLanguage] : undefined

  return (
    <>
      {hasConsolidated && (
        <Alert variant='info' className='mb-2'>
          <Trans>Only comparing against companies non-consolidated financials.</Trans>
        </Alert>
      )}

      <EntitySearchInputMultiple
        className='h-[60px] border border-divider'
        variant='outlined'
        selectedEntities={compareCompanies}
        onChange={handleChange}
        entityKindFilter={ApplicationSearchReturnType.Company}
        countries={lang}
        dataTrack='Company / Financials / Compare / Search'
      />

      <div className='mb-6 mt-4 flex items-center'>
        <div className='flex'>
          <Chip className='text-text-secondary' variant='outlined' size='md' label={company.name} />
          <Divider className='mx-4' orientation='vertical' />
        </div>
        <div className='flex-1'>
          <div className='flex flex-wrap'>
            {similarCompaniesLoading && (
              <div className='flex items-center gap-1'>
                <Skeleton className='h-8 w-24 rounded-full' />
                <Skeleton className='h-8 w-24 rounded-full' />
                <Skeleton className='h-8 w-24 rounded-full' />
              </div>
            )}

            {compareCompanies.map((compareCompany) => {
              const selected = compareCompany.id === selectedCompanyId
              const companyChip = (
                <Chip
                  className='mb-1 mr-1 mt-0 max-w-[250px]'
                  size='md'
                  palette={selected ? 'primary' : 'tertiary'}
                  label={compareCompany.name}
                  onClick={() => selectCompany(compareCompany.id)}
                  onDelete={() => deleteCompany(compareCompany.id)}
                  deleteIconProps={{
                    'data-track': 'Sidepanel / Company / Compare / Delete'
                  }}
                  data-track='Sidepanel / Company / Compare / Select'
                />
              )

              return (
                <Dropdown
                  key={compareCompany.id}
                  ToggleComponent={companyChip}
                  trigger='hover'
                  paperProps={{ className: 'py-2 px-4' }}
                  popperProps={{ placement: 'bottom' }}
                >
                  <EntityMeta className='w-max' entity={compareCompany} link noTooltip />
                </Dropdown>
              )
            })}
          </div>
        </div>
        <Tooltip content={i18n._(averageInfoText)}>
          <span>
            <IconInfo className='text-text-secondary' />
          </span>
        </Tooltip>
      </div>

      <div className='flex'>
        <ComparedCompany labels company={company} periodTitle={periodTitle} primary />
        <ComparedFinancials
          className='mx-2 w-1/5 px-2 pt-0'
          comparedFinancials={comparedFinancials}
          loading={financialsLoading}
        />

        <ComparedCompany
          company={selectedCompany}
          loading={similarCompaniesLoading}
          periodTitle={selectedCompanyPeriodTitle ?? periodTitle}
        />
      </div>
    </>
  )
}
