import { t } from '@lingui/macro'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import * as React from 'react'
import { type BaseEntityLikeFragment, type EntityLikeMetaFragment } from '~/graphqlTypes'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EntityKeyMetaItems } from '~/components/Ownerships/EntityKeyMetaItems'
import { Skeleton, Typography } from '@strise/ui-components'
import { TestIDs } from '~/utils/testIDs'
import { EntitySearchInput } from '~/components/Entity/EntitySearchInput'
import { extractEntityContentLanguage, extractIsPerson } from '@strise/app-shared'
import { ApplicationSearchReturnType } from '@strise/types'
import { EntityLocationFilterKind } from '~/components/Search/searchUtils'
import { EntityIcon } from '~/components/EntityIcon'
import { useSuggestedMergeEntitiesQuery } from '~/graphqlOperations'
import { TruncatedList } from '~/components/TruncatedList'
import SuggestedMergeEntity from '~/components/Ownerships/edit-owner/SuggestedMergeEntity'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { hasDataSources } from '~/utils/entity'

export const MergeEntityDialog = ({
  entity,
  handleCancel,
  handleMerge,
  loading
}: {
  entity: EntityLikeMetaFragment
  handleCancel: () => void
  handleMerge: (mergeEntityId: string) => void
  loading: boolean
}): React.ReactNode => {
  const [selectedEntity, setSelectedEntity] = React.useState<BaseEntityLikeFragment | null>(null)

  const isPerson = extractIsPerson(entity)
  const contentLanguage = extractEntityContentLanguage(entity)

  const { data, loading: suggestedMergeEntitiesLoading } = useSuggestedMergeEntitiesQuery({
    variables: {
      q: entity.name ?? '',
      input: {
        returnType: isPerson ? ApplicationSearchReturnType.Person : ApplicationSearchReturnType.Company,
        countries: contentLanguage ? [contentLanguage] : undefined,
        withGlobal: true,
        first: 6
      },
      showExtraMeta: true,
      exclude: [entity.id]
    },
    skip: !entity.name
  })

  return (
    <ConfirmDialog
      isOpen={true}
      onClose={handleCancel}
      onConfirm={() => handleMerge(selectedEntity?.id ?? '')}
      confirmText={t`Merge`}
      cancelText={t`Cancel`}
      onCancel={handleCancel}
      confirmButtonProps={{
        palette: 'primary',
        'data-track': 'Edit ownership / Merge Entity / Confirm',
        'data-id': TestIDs.SidePanel.Ownerships.mergeEntityConfirmButton
      }}
      cancelButtonProps={{ 'data-track': 'Edit ownership / Merge Entity / Cancel' }}
      loading={loading}
      disabled={!selectedEntity}
      contentMaxWidth={800}
      containerProps={{ className: 'p-0' }}
      footerProps={{ className: 'sticky bottom-0' }}
    >
      <div className='sticky top-0 z-10 flex flex-col gap-3 border-b border-gray-15 bg-white p-5'>
        <Typography variant='aLabel'>{t`Merging:`}</Typography>
        <div className='flex flex-col gap-2 border border-gray-15 p-3'>
          <div className='flex justify-between gap-2'>
            <EntityLink entity={entity} withIcon noTooltip noLink />
          </div>
          <EntityKeyMetaItems className='flex gap-2' entity={entity} expanded size='sm' />
        </div>
        <Typography variant='aLabel'>{t`With:`}</Typography>
        <div className='flex flex-col gap-2 border border-gray-15 p-3'>
          {selectedEntity ? (
            <>
              <div className='flex justify-between gap-2'>
                <EntityLink entity={selectedEntity} withIcon noTooltip openInNewTab />
                {hasDataSources(selectedEntity) && <DataSources dataSources={selectedEntity.dataSources.baseInfo} />}
              </div>
              <EntityKeyMetaItems
                className='flex flex-wrap items-end gap-2'
                entity={selectedEntity}
                expanded
                size='sm'
              />
            </>
          ) : (
            <div className='flex items-center gap-2'>
              <EntityIcon entity={entity} size='sm' className='text-text-secondary' />
              <Typography className='text-text-secondary' variant='body2'>{t`Select an entity to merge`}</Typography>
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col gap-4 p-5'>
        <EntitySearchInput
          initialInput={entity.name ?? ''}
          variant='contained'
          dataTrack='Merge Entity / Search Entity'
          entityKindFilter={isPerson ? ApplicationSearchReturnType.Person : ApplicationSearchReturnType.Company}
          entityLocationFilters={[EntityLocationFilterKind.ALL]}
          selectedEntity={null}
          setSelectedEntity={setSelectedEntity}
          singleSelect={true}
          data-id={TestIDs.SidePanel.Ownerships.searchEntityTrigger}
          itemsWrapperProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityResults }}
          inputProps={{ 'data-id': TestIDs.SidePanel.Ownerships.searchEntityInput }}
          showExtraMeta={true}
          withDataSources={true}
          clearSearchOnSelect={false}
        />
        {suggestedMergeEntitiesLoading ? (
          <div className='flex flex-col gap-2'>
            <Skeleton className='h-10 w-full' />
            <Skeleton className='h-10 w-full' />
            <Skeleton className='h-10 w-full' />
          </div>
        ) : (
          !!data?.suggestedMergeEntities.edges.length && (
            <>
              <Typography variant='subtitle2'>{t`Suggested entities:`}</Typography>
              <div className='rounded-[2px] border border-solid border-tertiary-main px-4'>
                <TruncatedList
                  items={data.suggestedMergeEntities.edges}
                  truncateAfter={3}
                  context='Suggested Merge Entities'
                  paginationProps={{ className: 'pl-1' }}
                >
                  {({ node: suggestedEntity }, i, isLast) => {
                    return (
                      <SuggestedMergeEntity
                        key={`${suggestedEntity.id} ${i}`}
                        entity={suggestedEntity}
                        isLast={isLast}
                        onClick={() => setSelectedEntity(suggestedEntity)}
                        dataTrackId={i.toString()}
                      />
                    )
                  }}
                </TruncatedList>
              </div>
            </>
          )
        )}
      </div>
    </ConfirmDialog>
  )
}
