import { type ReviewPepValueFragment } from '~/graphqlTypes'
import { ReviewPep } from '~/components/Review/items/ReviewPep'
import * as React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { ReviewValueStatusKind } from '@strise/types'
import { t } from '@lingui/macro'

export const ReviewPeps = ({ items }: { items: ReviewPepValueFragment[] }): React.ReactNode => {
  const filtered = items.filter((item) => item.value?.isPep || item.value?.isRca)

  if (!filtered.length) {
    return (
      <ReviewItem
        reviewMeta={{
          status: ReviewValueStatusKind.Ok,
          reason: t`No PEPs`,
          __typename: 'ReviewValueMeta' as const
        }}
      />
    )
  }

  return items.map((item, index) => <ReviewPep key={index} item={item} />)
}
