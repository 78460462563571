import { Trans } from '@lingui/macro'
import { Typography, type TypographyProps } from '@strise/ui-components'
import * as React from 'react'

export const ShareClasses = ({
  className,
  shareClasses,
  ...props
}: { shareClasses: string[] } & TypographyProps): React.ReactNode => {
  if (!shareClasses.length) return null

  const shareClassesString = shareClasses.join(', ')

  return (
    <Typography className={className} {...props}>
      <Trans>Share classes</Trans>: {shareClassesString}
    </Typography>
  )
}
