import { style } from '@mui/system'

const customValues = {
  hover: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}

type CustomValuesKey = keyof typeof customValues

export interface TextDecoration {
  textDecoration?: string
}

export const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: false,
  transform: (value) => {
    if ((value as CustomValuesKey) in customValues) {
      return customValues[value as CustomValuesKey]
    }
    return { textDecoration: value as string }
  }
})
