import { type ReviewPepValueFragment } from '~/graphqlTypes'
import * as React from 'react'
import { ReviewPepWithDisposition } from '~/components/Review/items/ReviewPepWithDisposition'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { ReviewValueStatusKind } from '@strise/types'
import { t } from '@lingui/macro'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { ReviewPeps } from '~/components/Review/items/ReviewPeps'

export const ReviewPepsWithDisposition = ({ items }: { items: ReviewPepValueFragment[] }) => {
  const features = useCurrentUserFeatures()

  if (!features.PEP_DISPOSITIONING) {
    return <ReviewPeps items={items} />
  }

  if (!items.length) {
    return (
      <ReviewItem
        reviewMeta={{
          status: ReviewValueStatusKind.Danger,
          reason: t`No PEPs checked`,
          __typename: 'ReviewValueMeta' as const
        }}
      />
    )
  }

  return items.map((item, index) => <ReviewPepWithDisposition key={index} item={item} />)
}
