import { Button, Switch, Typography } from '@strise/ui-components'
import { FromToFilter } from '~/components/FromToFilter'
import { SparkLineHighlightsEdit } from '~/components/SparkLine/SparkLineHighlightsEdit'
import { t, Trans } from '@lingui/macro'
import { setChildState, useContext, type SetStateFn } from '@strise/react-utils'
import { replaceItemFromArray } from '@strise/ts-utils'
import { OpportunityFinancialHighlightCount } from '@strise/types'
import { type FinancialId } from '~/utils/financialTypes'
import {
  financialHighlightCountToNumber,
  isDayField,
  isPercentageField,
  useDefaultHighlightedFinancials,
  useHighlighted
} from '~/utils/financials'
import * as React from 'react'
import { SettingsChangesSection } from '~/views/Settings/components/SettingsChangesSection'
import { type UserSettingsFragment, type UserGrowSettingsFragment } from '~/graphqlTypes'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

const extractFilterFormat = (id: FinancialId): string => {
  const isPercentage = isPercentageField(id)
  const isDay = isDayField(id)
  if (isPercentage) return '%'
  if (isDay) return t`day(s)`
  return '‘000'
}

const FinancialFromToFilter = ({
  id,
  saveFilters,
  settingsState
}: {
  id: FinancialId
  saveFilters: SetStateFn<UserGrowSettingsFragment['financials']>
  settingsState: UserSettingsFragment
}): React.ReactNode => {
  const percentage = isPercentageField(id)
  const filterFormat = extractFilterFormat(id)

  const handleChange =
    (fromOrTo: 'from' | 'to'): ((value: number | null) => void) =>
    (value: number | null) => {
      saveFilters((prevFilters) => {
        const currentIndex = prevFilters.findIndex((filter) => filter.id === id)
        const opposite = fromOrTo === 'from' ? 'to' : 'from'

        if (currentIndex === -1) {
          return [
            ...prevFilters,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            {
              id,
              [opposite]: null,
              [fromOrTo]: value,
              __typename: 'FinancialValueFilter'
              // TS is not able to infer the opposite type based on the fromOrTo value
            } as UserGrowSettingsFragment['financials'][number]
          ]
        }

        const currentItem = prevFilters[currentIndex]
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return replaceItemFromArray(prevFilters, currentIndex, {
          id,
          [opposite]: currentItem?.[opposite] ?? null,
          [fromOrTo]: value,
          __typename: 'FinancialValueFilter'
          // TS is not able to infer the opposite type based on the fromOrTo value
        } as UserGrowSettingsFragment['financials'][number])
      })
    }

  const currFilter = settingsState.grow.financials.find((v) => v.id === id)

  return (
    <FromToFilter
      fromValue={currFilter?.from ?? null}
      setFromValue={handleChange('from')}
      toValue={currFilter?.to ?? null}
      setToValue={handleChange('to')}
      filterFormat={filterFormat}
      percentage={percentage}
      allowNegativeNumbers
    />
  )
}

export const GrowSettingsFinancials = (): React.ReactNode => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)
  const growSettings = settings.grow
  const saveGrowSettings = setChildState(saveSettings, 'grow')
  const [resetKey, setResetKey] = React.useState(0)

  const highlightedState = useHighlighted(
    growSettings.financialFieldsHighlighted,
    financialHighlightCountToNumber(growSettings.financialHighlightCount)
  )

  const highlightedStateIds = highlightedState.map(({ id }) => id)

  const { financialHighlightCount: highlightedCountState } = growSettings

  const handleHighlightChange = (index: number, value: FinancialId): void => {
    const newHighlighted = replaceItemFromArray(highlightedStateIds, index, value)
    const newFinancialFilters = growSettings.financials.filter((v) => newHighlighted.includes(v.id))

    saveGrowSettings({
      ...growSettings,
      financialFieldsHighlighted: newHighlighted,
      financials: newFinancialFilters
    })
  }

  const defaultHighlightedFinancials = useDefaultHighlightedFinancials()

  const handleReset = (): void => {
    const defaultHighlighted = defaultHighlightedFinancials.slice(
      0,
      financialHighlightCountToNumber(OpportunityFinancialHighlightCount.Three)
    )

    saveGrowSettings({
      ...growSettings,
      financialHighlightCount: OpportunityFinancialHighlightCount.Three,
      financialFieldsHighlighted: defaultHighlighted,
      financials: []
    })

    setResetKey((prevFilter) => prevFilter + 1)
  }

  const handleNumChange = (six: boolean): void => {
    // If we change to six, what we have + the default ones, avoid duplicates and only 6
    // otherwise we take only the three first from highlighted
    const newHighlighted = [...highlightedStateIds, ...defaultHighlightedFinancials]
      .reduce<string[]>((acc, cur) => {
        if (acc.includes(cur)) return acc

        return [...acc, cur]
      }, [])
      .slice(0, six ? 6 : 3)

    saveGrowSettings({
      ...growSettings,
      financialHighlightCount: six ? OpportunityFinancialHighlightCount.Six : OpportunityFinancialHighlightCount.Three,
      financialFieldsHighlighted: newHighlighted
    })
  }

  const SIX_FINANCIALS = highlightedCountState === OpportunityFinancialHighlightCount.Six

  return (
    <SettingsChangesSection
      className='max-w-[600px]'
      title={t`Financial favourites and restraints`}
      description={t`Use the dropdown to choose which financials you want as your favourites and set restraints for opportunities.`}
    >
      <div>
        <Typography className='mb-2' component='div' variant='aLabelSmall'>
          <Trans>Six financial favourites</Trans>
        </Typography>
        <div className='flex'>
          <Switch checked={SIX_FINANCIALS} onCheckedChange={handleNumChange} className='mr-1' />
          <Typography variant='aLabel'>{SIX_FINANCIALS ? t`On` : t`Off`}</Typography>
        </div>
      </div>

      <SparkLineHighlightsEdit
        className='mb-2 flex-col'
        key={resetKey}
        ids={highlightedStateIds}
        onChange={handleHighlightChange}
        selectWrapperProps={{ className: 'w-full' }}
      >
        {(id) => (
          <FinancialFromToFilter
            id={id}
            settingsState={settings}
            saveFilters={setChildState(saveGrowSettings, 'financials')}
          />
        )}
      </SparkLineHighlightsEdit>
      <div>
        <Button
          variant='outlined'
          palette='danger'
          onClick={handleReset}
          data-track='Opportunity Settings / Financial favorites / Reset to default'
        >
          <Trans>Reset to default</Trans>
        </Button>
      </div>
    </SettingsChangesSection>
  )
}
