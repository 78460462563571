import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSubsidiary = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M7 1h10v10H7V1Zm2 2v6h6V3H9Z' clipRule='evenodd' />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M11 18v-8h2v8h-2ZM1 17h6v6H1v-6Zm2 2v2h2v-2H3Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M9 17h6v6H9v-6Zm2 2v2h2v-2h-2ZM17 17h6v6h-6v-6Zm2 2v2h2v-2h-2Z'
        clipRule='evenodd'
      />
      <path fill='currentColor' fillRule='evenodd' d='M3 13h18v5h-2v-3H5v3H3v-5Z' clipRule='evenodd' />
    </svg>
  )
)
IconSubsidiary.displayName = 'IconSubsidiary'
