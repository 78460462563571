import React from 'react'
import { Dialog, type DialogProps } from '@strise/ui-components-legacy'
import {
  Button,
  Chip,
  cn,
  Divider,
  IconButton,
  IconChevronLeft,
  IconCross,
  IconWarning,
  TextArea,
  Tooltip,
  Typography
} from '@strise/ui-components'
import {
  type CreateEntityDispositionInput,
  EntityDispositionKind,
  type EntityDispositionStatusKind
} from '@strise/types'
import { t } from '@lingui/macro'
import { dialogBgColor } from '~/components/PepAndSanctions/dispositionDialogUtils'
import { type PepInfoFragment, type PersonBaseFragment } from '~/graphqlTypes'
import { extractAddress } from '~/utils/address'
import { PepPersonContent } from '~/components/PepAndSanctions/PepPersonContent'
import { Countries } from '~/components/Countries'
import { PepDispositionDialogActions } from '~/components/PepAndSanctions/PepDispositionDialogActions'
import { MissingData } from '~/components/MissingData'
import { MatchInfoAnalysisTooltipContent } from '~/components/MatchInfoAnalysis/MatchInfoAnalysisTooltipContent'
import { extractGender } from '~/utils/gender'
import { intersectionBy } from 'lodash-es'

interface DispositionComparisonDialogProps extends Omit<DialogProps, 'isOpen'> {
  onClose: () => void
  pepInfo: PepInfoFragment | null
  person: PersonBaseFragment
  setComparePepInfo: ((pepInfo: PepInfoFragment | null) => void) | null
}

const DispositionDetailsRow = ({
  columnClassName,
  currentValue,
  isMatch,
  matchValue,
  title
}: {
  columnClassName?: string
  currentValue: React.ReactNode
  isMatch: boolean
  matchValue: React.ReactNode
  title: string
}): React.ReactNode => {
  return (
    <div className='flex w-full border-b border-background-default bg-background-paper'>
      <div className={cn('flex w-full gap-2 px-4 py-3', columnClassName)}>
        <Typography className='w-1/3 shrink-0 font-regular text-text-secondary'>{title}</Typography>
        <Typography className='w-full'>{currentValue ?? <MissingData />}</Typography>
      </div>
      <Divider orientation='vertical' className={cn('w-2 shrink-0', dialogBgColor)} />
      <div className={cn('flex w-full gap-2 px-4 py-3', columnClassName)}>
        <Typography className='w-1/3 shrink-0 font-regular text-text-secondary'>{title}</Typography>
        <div className='flex w-full items-center justify-between'>
          <Typography className=''>{matchValue ?? <MissingData />}</Typography>
          {!isMatch && <IconWarning size='md' className='text-semantic-notice-main' />}
        </div>
      </div>
    </div>
  )
}

export const PepDispositionComparisonDialog = ({
  onClose,
  pepInfo,
  person,
  setComparePepInfo
}: DispositionComparisonDialogProps): React.ReactNode => {
  const [comment, setComment] = React.useState('')

  if (!pepInfo) return null

  const extractInputs = (status: EntityDispositionStatusKind): CreateEntityDispositionInput[] => {
    return [
      {
        externalId: pepInfo.id,
        kind: EntityDispositionKind.Pep,
        status,
        comment
      }
    ]
  }

  const handleCompleted = (): void => {
    setComment('')

    if (setComparePepInfo) {
      setComparePepInfo(null)
    } else {
      onClose()
    }
  }

  const body = (
    <div className={cn('flex flex-col', dialogBgColor)}>
      <div className='flex'>
        {setComparePepInfo && (
          <Button
            variant='ghost'
            startIcon={<IconChevronLeft size='sm' className='mr-1' />}
            onClick={() => setComparePepInfo(null)}
            className='pl-0 pr-2'
            data-track='Pep disposition dialog / Back to list'
          >
            {t`Back to list`}
          </Button>
        )}

        <IconButton className='ml-auto' data-track='Pep disposition dialog / Close' onClick={onClose}>
          <IconCross />
        </IconButton>
      </div>

      <div className='my-8'>
        <div className='flex flex-row items-center justify-between rounded-lg bg-semantic-notice-shade-5 p-4'>
          <PepPersonContent
            className='w-3/5'
            pepInfos={[pepInfo]}
            person={null}
            expanded
            noLink
            showSystemSuggestedFalse={true}
            enableEdit={false}
            associatedCompanies={null}
            currentCompanyId={null}
          />
          {pepInfo.matchInfoAnalysis && (
            <Tooltip arrow content={<MatchInfoAnalysisTooltipContent matchInfoAnalysis={pepInfo.matchInfoAnalysis} />}>
              <Chip
                variant='outlined'
                className='rounded-md hover:border-inherit hover:bg-inherit'
                palette='secondary'
                label={t`${pepInfo.matchInfoAnalysis.result} match`}
              />
            </Tooltip>
          )}
        </div>
        {pepInfo.source && (
          <Typography variant='body2' className='mt-1 text-right text-text-secondary'>
            Source: {pepInfo.source}
          </Typography>
        )}
      </div>

      <div className='mb-2 flex'>
        <Typography className='w-1/2' variant='body1'>{t`Current case details`}</Typography>
        <Divider orientation='vertical' className='w-2 shrink-0 bg-transparent' />
        <Typography className='w-1/2' variant='body1'>{t`Possible match details`}</Typography>
      </div>

      <div className='mb-1 flex w-full flex-col'>
        <DispositionDetailsRow
          title={t`Name`}
          currentValue={person.name}
          matchValue={pepInfo.name}
          isMatch={person.name === pepInfo.name}
        />
        <DispositionDetailsRow
          title={t`DOB`}
          currentValue={person.birthDateV2}
          matchValue={pepInfo.birthDateV2}
          isMatch={!!(person.birthDateV2 && pepInfo.birthDateV2 && person.birthDateV2 === pepInfo.birthDateV2)}
        />
        <DispositionDetailsRow
          title={t`Gender`}
          currentValue={extractGender(person.gender)}
          matchValue={extractGender(pepInfo.gender)}
          isMatch={!!(person.gender && pepInfo.gender && person.gender === pepInfo.gender)}
        />
        <DispositionDetailsRow
          title={t`Address`}
          currentValue={person.address ? extractAddress(person.address) : null}
          matchValue={extractAddress(pepInfo.address)}
          isMatch={!!(person.address && extractAddress(person.address) === extractAddress(pepInfo.address))}
        />
        <DispositionDetailsRow
          title={t`Countries`}
          currentValue={person.countries.length ? <Countries countries={person.countries} /> : null}
          matchValue={pepInfo.countries.length ? <Countries countries={pepInfo.countries} /> : null}
          isMatch={!!intersectionBy(person.countries, pepInfo.countries, (country) => country.kind).length}
          columnClassName='pb-4'
        />
      </div>
      <div className='flex justify-between'>
        <Typography variant='body2' className='self-end text-text-secondary'>
          {t`Source: Strise`}
        </Typography>

        {pepInfo.source && (
          <Typography variant='body2' className='self-end text-text-secondary'>
            Source: {pepInfo.source}
          </Typography>
        )}
      </div>

      <TextArea
        className='mt-8'
        placeholder={t`Comment (optional)`}
        value={comment}
        autoResize
        onChange={(event) => setComment(event.target.value)}
      />
    </div>
  )

  return (
    <Dialog
      onClose={onClose}
      body={body}
      isOpen
      contentMaxWidth={856}
      containerProps={{ bgcolor: dialogBgColor }}
      contentProps={{ className: 'min-w-[640px]' }}
    >
      <PepDispositionDialogActions
        pepInfos={[pepInfo]}
        extractInputs={extractInputs}
        onCompleted={handleCompleted}
        person={person}
      />
    </Dialog>
  )
}
