import * as React from 'react'
import { Helmet } from 'react-helmet'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { SidepanelPersonHeaderCard } from '~/components/Sidepanel/SidepanelCards/SidepanelPersonHeaderCard'
import { SidepanelTableCard } from '~/components/Sidepanel/SidepanelTableCard'
import { SidepanelSanctionsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelSanctionsCard'
import { SidepanelSimilarEntitiesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelSimilarEntitiesCard'
import { type SidepanelPrivatePersonFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'
import { SidepanelAmsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelAmsCard'

export const SidepanelPrivatePersonLayout: React.FC<{
  privatePerson: SidepanelPrivatePersonFragment
  tabRefs: TabRefs
}> = ({ privatePerson, tabRefs }) => {
  return (
    <>
      <Helmet>
        <title>{privatePerson.name}</title>
      </Helmet>
      <SidepanelPersonHeaderCard ref={tabRefs[SidepanelTab.Person]} person={privatePerson} />
      <SidepanelSimilarEntitiesCard entity={privatePerson} entityEdges={privatePerson.matchingBusinessPersons.edges} />
      <SidepanelTableCard
        entity={privatePerson}
        trackId='Private person / Details'
        table={privatePerson.personInformation}
      />
      <SidepanelSanctionsCard entity={privatePerson} />
      <SidepanelAmsCard entity={privatePerson} />
    </>
  )
}
