import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconGrow = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M4.188 11.28C1.766 9.344 0 6.308 0 2V1h1c3.131 0 6.678.764 9.207 3.293 1.186 1.186 1.869 2.386 2.261 3.602.37-.93.856-1.84 1.48-2.679C15.762 2.772 18.687 1 23 1h1v1c0 2.984-.208 6.622-2.793 9.207-2.326 2.326-4.706 2.715-7.149 2.78.873 2.539.247 5.912-1.71 9.043l-1.696-1.06c2.044-3.27 2.165-6.231 1.225-7.97H11.5v-.011c-2.344-.103-5.085-.926-7.312-2.708Zm6.738-.88c-.155-1.738-.637-3.197-2.133-4.693C7.035 3.95 4.563 3.19 2.04 3.032c.25 3.127 1.632 5.275 3.397 6.687 1.153.923 2.486 1.543 3.803 1.9a14.682 14.682 0 0 1-3.098-3.605l1.716-1.029a12.684 12.684 0 0 0 2.484 2.947c.204.176.4.332.584.468Zm3.51-.148a8.55 8.55 0 0 1-.702.402c.293-1.468.872-2.97 1.819-4.245 1.298-1.748 3.327-3.125 6.434-3.37-.07 2.621-.476 5.036-2.194 6.754-1.344 1.344-2.659 1.87-4.173 2.075 1.088-.706 2.432-1.813 3.844-3.448l-1.513-1.307c-1.341 1.552-2.582 2.548-3.515 3.14Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconGrow.displayName = 'IconGrow'
