import { Button, cn, Divider, Input, type InputProps, Typography, IconCheck, IconGarbage } from '@strise/ui-components'
import { useDeleteTagMutation, useUpdateTagNameMutation } from '~/graphqlOperations'
import { type CompanyTagFragment } from '~/graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { toast } from '@strise/app-shared'
import { ConfirmDialog, MenuItem } from '@strise/ui-components-legacy'
import { type SetStateFn } from '@strise/react-utils'
import * as React from 'react'
import { useToggle } from 'usehooks-ts'
import { TestIDs } from '~/utils/testIDs'

const UpdateTagName: React.FC<{ name: string; setName: SetStateFn<string> } & InputProps> = ({
  className,
  name,
  setName,
  ...props
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setName(e.target.value)
  }

  return (
    <div className={cn('p-2', className)}>
      <Input
        variant='outlined'
        palette='primary'
        className='flex-1 border-2'
        autoFocus
        value={name}
        onChange={handleInputChange}
        {...props}
      />
    </div>
  )
}

const DeleteTag: React.FC<{ tag: CompanyTagFragment }> = ({ tag }) => {
  const [open, toggle] = useToggle(false)

  const [deleteTag] = useDeleteTagMutation({
    variables: { tagId: tag.id },
    onCompleted: () => {
      toast.success(t`${tag.name} has been deleted!`)
    },
    update: (cache) => {
      cache.evict({ id: `Sticker:${tag.id}` })
      cache.gc()
    }
  })

  return (
    <>
      <MenuItem startIcon={<IconGarbage />} onClick={toggle} data-id={TestIDs.Common.Tags.deleteTagButton}>
        <Trans>Delete</Trans>
      </MenuItem>
      <ConfirmDialog
        confirmButtonProps={{ 'data-id': TestIDs.Common.Tags.confirmDeleteTagButton }}
        onConfirm={() => {
          deleteTag()
          toggle()
        }}
        onCancel={() => {
          toggle()
        }}
        confirmText={t`Delete`}
        cancelText={t`Cancel`}
        title={t`Delete tag`}
        isOpen={open}
        danger
      >
        <Trans>
          Are you sure you want to delete the <b>{tag.name}</b> tag?
        </Trans>
      </ConfirmDialog>
    </>
  )
}

export const TagActions: React.FC<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  tag: CompanyTagFragment
}> = ({ setOpen, tag }) => {
  const [name, setName] = React.useState(tag.name)

  const [updateTag] = useUpdateTagNameMutation({
    onCompleted: () => {
      toast.success(t`Successfully renamed tag!`)
    }
  })

  const handleSave = async (): Promise<void> => {
    setOpen(false)
    if (name !== tag.name) {
      await updateTag({ variables: { tagId: tag.id, name } })
    }
  }

  return (
    <div className='bg-secondary-shade-90'>
      <UpdateTagName
        name={name}
        setName={setName}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') handleSave()
        }}
      />

      <Divider className='h-0.5 bg-secondary-shade-80' />
      <DeleteTag tag={tag} />
      <Button
        variant='contained'
        palette='primary'
        className='h-12 w-full text-left'
        onClick={handleSave}
        endIcon={<IconCheck className='ml-auto' />}
        data-track='Tags / Save'
      >
        <Typography variant='aLabel'>
          <Trans>Save</Trans>
        </Typography>
      </Button>
    </div>
  )
}
