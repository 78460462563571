import { type BaseReviewCompanyFragment, type ReviewCompanyDataFragment } from '~/graphqlTypes'
import { defaultOpenedReviewState, ReviewCardContentView, type ReviewState } from '~/utils/reviewUtils2'
import type { SetStateFn } from '@strise/react-utils'
import { ReviewSectionKind } from '@strise/types'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useReactiveVar } from '@apollo/client/index.js'
import { useReviewCompanyDataLazyQuery } from '~/graphqlOperations'
import { refreshReviewState } from '~/state'
import { useEffect } from 'react'
import { deepMergeObjects, filterNullishValues } from '@strise/ts-utils'
import { noop } from 'lodash-es'

export const useReviewCompany = (
  baseCompany: BaseReviewCompanyFragment,
  cardView: ReviewCardContentView,
  setCardView: SetStateFn<ReviewCardContentView>,
  reviewState: ReviewState | undefined,
  setReviewState: SetStateFn<ReviewState>
): {
  errorSections: Set<ReviewSectionKind>
  fetchReviewCompany: () => void
  loadingSections: Set<ReviewSectionKind>
  reviewCompany: ReviewCompanyDataFragment | null
} => {
  const features = useCurrentUserFeatures()

  // TODO: update cache
  const handleFetchCompleted = noop

  const [
    fetchCompanyInformation,
    { data: companyInformationData, error: companyInformationError, loading: companyInformationLoading }
  ] = useReviewCompanyDataLazyQuery({
    variables: {
      entity: baseCompany.id,
      includeSections: [ReviewSectionKind.CompanyInformation],
      excludeSections: []
    },
    onCompleted: handleFetchCompleted,
    fetchPolicy: 'no-cache'
  })

  const [
    fetchPepsAndSanctions,
    { data: pepsAndSanctionsData, error: pepsAndSanctionsError, loading: pepsAndSanctionsLoading }
  ] = useReviewCompanyDataLazyQuery({
    variables: {
      entity: baseCompany.id,
      includeSections: [ReviewSectionKind.PepsAndSanctions],
      excludeSections: []
    },
    onCompleted: handleFetchCompleted,
    fetchPolicy: 'no-cache'
  })

  const [fetchManagement, { data: managementData, error: managementError, loading: managementLoading }] =
    useReviewCompanyDataLazyQuery({
      variables: {
        entity: baseCompany.id,
        includeSections: [ReviewSectionKind.ManagementAndRightsHolders],
        excludeSections: []
      },
      onCompleted: handleFetchCompleted,
      fetchPolicy: 'no-cache'
    })

  const [fetchAms, { data: amsData, error: amsError, loading: amsLoading }] = useReviewCompanyDataLazyQuery({
    variables: { entity: baseCompany.id, includeSections: [ReviewSectionKind.Ams], excludeSections: [] },
    onCompleted: handleFetchCompleted,
    fetchPolicy: 'no-cache'
  })

  const [fetchEsg, { data: esgData, error: esgError, loading: esgLoading }] = useReviewCompanyDataLazyQuery({
    variables: { entity: baseCompany.id, includeSections: [ReviewSectionKind.Esg], excludeSections: [] },
    onCompleted: handleFetchCompleted,
    fetchPolicy: 'no-cache'
  })

  const [
    fetchCustomCheckboxes,
    { data: customCheckboxesData, error: customCheckboxesError, loading: customCheckboxesLoading }
  ] = useReviewCompanyDataLazyQuery({
    variables: {
      entity: baseCompany.id,
      includeSections: [ReviewSectionKind.CustomCheckboxes],
      excludeSections: []
    },
    onCompleted: handleFetchCompleted
  })

  const fetchReviewCompany = (): void => {
    fetchCompanyInformation()
    fetchManagement()
    if (features.PEP_AND_SANCTIONS_V2) fetchPepsAndSanctions()
    if (features.ADVERSE_MEDIA_SCREENING) fetchAms()
    if (features.ESG) fetchEsg()
    if (features.REVIEW_CUSTOM_CHECKBOXES) fetchCustomCheckboxes()
    if (!reviewState?.opened) {
      setReviewState((prevState) => {
        return {
          ...prevState,
          ...defaultOpenedReviewState
        }
      })
    }
    setCardView(ReviewCardContentView.IN_REVIEW)
  }

  const refreshedReview = useReactiveVar(refreshReviewState)

  useEffect(() => {
    // refetch review company if review is previously opened
    if (!refreshedReview) return
    if (cardView !== ReviewCardContentView.IN_REVIEW) return
    fetchReviewCompany()
  }, [refreshedReview])

  useEffect(() => {
    if (reviewState?.opened && cardView === ReviewCardContentView.LOADING_REVIEW) {
      fetchReviewCompany()
    }
  }, [reviewState?.opened])

  const mergedResponses = deepMergeObjects(
    companyInformationData,
    pepsAndSanctionsData,
    managementData,
    amsData,
    esgData,
    customCheckboxesData
  )
  const reviewCompany = mergedResponses.reviewCompanyData

  const loadingSectionsArray = filterNullishValues([
    companyInformationLoading ? ReviewSectionKind.CompanyInformation : null,
    pepsAndSanctionsLoading ? ReviewSectionKind.PepsAndSanctions : null,
    managementLoading ? ReviewSectionKind.ManagementAndRightsHolders : null,
    amsLoading ? ReviewSectionKind.Ams : null,
    esgLoading ? ReviewSectionKind.Esg : null,
    customCheckboxesLoading ? ReviewSectionKind.CustomCheckboxes : null
  ])

  const errorSectionsArray = filterNullishValues([
    companyInformationError ? ReviewSectionKind.CompanyInformation : null,
    pepsAndSanctionsError ? ReviewSectionKind.PepsAndSanctions : null,
    managementError ? ReviewSectionKind.ManagementAndRightsHolders : null,
    amsError ? ReviewSectionKind.Ams : null,
    esgError ? ReviewSectionKind.Esg : null,
    customCheckboxesError ? ReviewSectionKind.CustomCheckboxes : null
  ])

  const loadingSections = new Set(loadingSectionsArray)
  const errorSections = new Set(errorSectionsArray)

  return { reviewCompany, fetchReviewCompany, loadingSections, errorSections }
}
