import { cn, IconChevronRightSuperSmall, Typography } from '@strise/ui-components'
import { Box, type BoxProps } from './Box'
import * as React from 'react'
import { type AccordionProps } from './Accordion'

interface AccordionV2Props extends AccordionProps {
  toggleLabel: React.ReactNode
  toggleLabelProps?: BoxProps
}

export const AccordionV2 = ({
  children,
  className,
  disabled,
  label,
  labelProps,
  open,
  toggle,
  toggleLabel,
  toggleLabelProps,
  ...props
}: AccordionV2Props): React.ReactNode => {
  return (
    <Box
      className={className}
      bgcolor={disabled ? 'background.default' : 'transparent'}
      onClick={!open && !disabled && toggle ? toggle : undefined}
      style={{ cursor: !open && !disabled && toggle ? 'pointer' : 'auto' }}
      {...props}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        onClick={open && !disabled && toggle ? toggle : undefined}
        style={{ cursor: open && !disabled && toggle ? 'pointer' : 'inherit' }}
        {...labelProps}
      >
        {label}
        {toggle && (
          <Box {...toggleLabelProps}>
            <Typography className='text-text-secondary' variant='aLabelSmall'>
              {toggleLabel}
            </Typography>
            <IconChevronRightSuperSmall
              className={cn('text-text-secondary transition-transform duration-200 ease-linear', { open: 'rotate-90' })}
            />
          </Box>
        )}
      </Box>
      {open && (
        <Box borderLeft='1px solid' borderColor='text.secondary' pl={2}>
          {children}
        </Box>
      )}
    </Box>
  )
}
