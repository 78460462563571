import { useNavigate } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client/index.js'
import { spoofUser } from '~/state'
import * as React from 'react'
import { Views } from '~/utils/viewsHooks'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useContext } from '@strise/react-utils'

export enum SetupViewStep {
  LANGUAGE = 'language',
  TERMS = 'terms',
  QUESTIONS = 'questions'
}

export const useCurrentSetupStep = (): SetupViewStep | undefined => {
  const { settings } = useContext(CurrentUserSettingsContext)

  if (!settings.displayLanguage) {
    return SetupViewStep.LANGUAGE
  }

  if (!settings.generalTermsAccepted || !settings.privacyTermsAccepted) {
    return SetupViewStep.TERMS
  }

  if (!settings.useCaseKinds) {
    return SetupViewStep.QUESTIONS
  }
}

export const useNavigateToSetup = (): void => {
  const navigate = useNavigate()
  const spoofing = useReactiveVar(spoofUser)
  const setupStep = useCurrentSetupStep()

  React.useEffect(() => {
    if (spoofing || location.pathname === (Views.SETUP as string) || !setupStep) return

    navigate(Views.SETUP)
  }, [spoofing, location.pathname, setupStep])
}
