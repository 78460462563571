import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconGarbage = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M3 7h18v16H3V7Zm2 2v12h14V9H5Z' clipRule='evenodd' />
      <path fill='currentColor' fillRule='evenodd' d='M23 9H1V7h22v2Z' clipRule='evenodd' />
      <path fill='currentColor' fillRule='evenodd' d='M7 1h10v19h-2V3H9v17H7V1Z' clipRule='evenodd' />
      <path fill='currentColor' fillRule='evenodd' d='M11 20V8h2v12h-2Z' clipRule='evenodd' />
    </svg>
  )
)
IconGarbage.displayName = 'IconGarbage'
