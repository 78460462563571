import { style } from '@mui/system'

export interface WrapText {
  wrapText?: boolean
}

export const wrapText = style({
  prop: 'wrapText',
  cssProperty: false,
  transform: () => ({
    overflowWrap: 'anywhere',
    hyphens: 'auto'
  })
})
