import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCopyright = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M7.888 6.65C8.703 5.562 10.06 5 12 5c1.942 0 3.25.56 4.056 1.456.785.873.944 1.9.944 2.544v1h-2V9c0-.356-.091-.829-.43-1.206C14.25 7.439 13.557 7 12 7c-1.56 0-2.203.438-2.512.85C9.13 8.325 9 9.04 9 10v4c0 .96.131 1.675.488 2.15.309.412.951.85 2.512.85 1.56 0 2.203-.438 2.512-.85.357-.475.488-1.19.488-2.15v-1h2v1c0 1.04-.119 2.325-.887 3.35C15.296 18.438 13.939 19 12 19c-1.94 0-3.297-.562-4.112-1.65C7.117 16.325 7 15.04 7 14v-4c0-1.04.119-2.325.888-3.35Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCopyright.displayName = 'IconCopyright'
