import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconLinkedIn = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M20.995 21H21v-6.602c0-3.23-.695-5.717-4.47-5.717-1.816 0-3.034.996-3.531 1.94h-.053V8.982h-3.58V21h3.728v-5.95c0-1.567.297-3.082 2.238-3.082 1.911 0 1.94 1.788 1.94 3.182V21h3.724ZM3.297 8.982h3.732V21H3.297V8.982ZM5.162 3A2.162 2.162 0 0 0 3 5.161c0 1.194.968 2.182 2.162 2.182 1.193 0 2.161-.988 2.161-2.182A2.163 2.163 0 0 0 5.161 3Z'
      />
    </svg>
  )
)
IconLinkedIn.displayName = 'IconLinkedIn'
