import { Divider, Typography } from '@strise/ui-components'
import { type SetStateFn } from '@strise/react-utils'
import { ReviewSettingKind, type AuditRisk } from '@strise/types'
import React from 'react'
import { Trans } from '@lingui/macro'
import { AuditRiskSelect } from '~/components/AuditRiskSelect'
import { type TeamReviewSettingsV2Fragment } from '@strise/app-shared/src/graphqlTypes'

export const ReviewAuditRiskSection = ({
  auditRisk,
  disabled,
  setAuditRisk,
  teamReviewSettings
}: {
  auditRisk: AuditRisk
  disabled: boolean
  setAuditRisk: SetStateFn<AuditRisk>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
}): React.ReactNode => {
  if (
    !teamReviewSettings.settings.find((setting) => setting.kind === ReviewSettingKind.EnableRiskAssessmentValue)
      ?.enabled
  )
    return null

  const handleAuditRiskChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newAuditRisk = event.target.value as AuditRisk

    setAuditRisk(newAuditRisk)
  }

  return (
    <>
      <Typography className='mb-2 mt-4' variant='subtitle1'>
        <Trans>Risk assessment</Trans>
      </Typography>
      <div className='py-4'>
        <AuditRiskSelect risk={auditRisk} updateRisk={handleAuditRiskChange} disabled={disabled} />
      </div>
      <Divider />
    </>
  )
}
