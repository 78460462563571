import { useEntitySanctionsQuery } from '~/graphqlOperations'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useTeamId } from '~/contexts/TeamContext/TeamContext'
import { type SanctionInfoFragment } from '~/graphqlTypes'

export const useEntitySanctionInfo = (id: string): { loading: boolean; sanctionInfo: SanctionInfoFragment | null } => {
  const teamId = useTeamId()
  const features = useCurrentUserFeatures()
  const { data, loading } = useEntitySanctionsQuery({
    variables: { id, teamId },
    skip: !features.PEP_AND_SANCTIONS_V2
  })

  const entity = data?.entity

  const sanctionInfo = entity && 'sanctionInfo' in entity ? entity.sanctionInfo : null

  return { sanctionInfo, loading }
}
