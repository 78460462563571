import { type ReviewAmsSectionFragment } from '~/graphqlTypes'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import React from 'react'
import { type ReviewState } from '~/utils/reviewUtils2'
import { type SetStateFn } from '@strise/react-utils'
import { ReviewEvents } from '~/components/Review/items/ReviewEvents'
import { reviewEntityToEntity } from '~/components/Review/reviewValueTransformers'

export const ReviewEventSection = ({
  reviewState,
  section,
  setReviewState
}: {
  reviewState: ReviewState | undefined
  section: ReviewAmsSectionFragment
  setReviewState: SetStateFn<ReviewState>
}): React.ReactNode => {
  const filtered = section.events.filter((event) => event.value)

  if (!filtered.length) return null

  const entity = reviewEntityToEntity(section.entity)

  return (
    <div>
      <EntityLink entity={entity} />
      <ReviewEvents
        items={section.events}
        entityId={section.entity.id}
        reviewState={reviewState}
        setReviewState={setReviewState}
      />
    </div>
  )
}
