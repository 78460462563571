import * as React from 'react'
import { RenderContext, type RenderContextType } from '~/contexts/RenderContext/RenderContext'

export const RenderContextProvider = ({
  children,
  type
}: {
  children: React.ReactNode
  type: RenderContextType
}): React.ReactNode => <RenderContext.Provider value={type}>{children}</RenderContext.Provider>
