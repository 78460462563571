import { style } from '@mui/system'

export interface NoWrap {
  noWrap?: boolean
}

export const noWrap = style({
  prop: 'noWrap',
  cssProperty: false,
  transform: () => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  })
})
