import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconReminderDefault = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M2.434 6.567 6.599 2.4 5.185.987 1.019 5.153l1.415 1.414ZM18.835 1 23 5.166 21.586 6.58l-4.165-4.166L18.835 1ZM11 13V7h2v5.585l3.244 3.243-1.415 1.415-3.535-3.536A.998.998 0 0 1 11 13Z'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M22 13c0 5.523-4.477 10-10 10S2 18.523 2 13 6.477 3 12 3s10 4.477 10 10Zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconReminderDefault.displayName = 'IconReminderDefault'
