import { Chip, type ChipProps, cn } from '@strise/ui-components'
import * as React from 'react'
import { auditChipColorClasses, auditRiskTitles } from '~/utils/audit'
import { AuditRisk } from '@strise/types'
import { t } from '@lingui/macro'
import { getTitle } from '~/utils/enum'

const auditChipClasses = (auditRisk: AuditRisk): string => {
  return auditChipColorClasses[auditRisk] ?? 'secondary'
}

export const AuditRiskChip = ({
  auditRisk,
  className,
  ...props
}: { auditRisk?: AuditRisk | null } & ChipProps): React.ReactNode => {
  const nonNullableAuditRisk = auditRisk || AuditRisk.None
  const chipClasses = auditChipClasses(nonNullableAuditRisk)
  const risk = t`risk`

  return (
    <Chip
      className={cn('text-gray-100', chipClasses, className)}
      variant='contained'
      label={`${getTitle(auditRiskTitles[nonNullableAuditRisk])} ${risk}`}
      {...props}
    />
  )
}
