import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconTriggers = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M16.68 17.68a8.012 8.012 0 1 1 0-11.33l1.417-1.417A10.014 10.014 0 0 0 1 12.015a10.015 10.015 0 0 0 17.097 7.082l-1.416-1.416Zm-2.582-4.575a3.27 3.27 0 0 1-6.351-1.09 3.269 3.269 0 0 1 6.35-1.09h5.635L17.77 8.312l1.744-1.307 3.268 4.358c.29.387.29.92 0 1.307l-3.268 4.358-1.744-1.307 1.962-2.615h-5.634Zm-3.083 0a1.09 1.09 0 1 0 0-2.18 1.09 1.09 0 0 0 0 2.18Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconTriggers.displayName = 'IconTriggers'
