import { Typography } from '@strise/ui-components'
import { type CreditReportFragment, type PaymentRemarkFragment } from '~/graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate, Empty } from '@strise/app-shared'
import { formatNumber } from '@strise/ts-utils'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import * as React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { type DivProps } from '@strise/react-utils'

const CreditScorePaymentRemarksRow = ({
  className,
  currency,
  paymentRemark,
  ...props
}: { currency?: string | null; paymentRemark: PaymentRemarkFragment } & DivProps): React.ReactNode => {
  const amountString = `${formatNumber(paymentRemark.amount)} ${currency ?? ''}`

  return (
    <TableRow className={className} sx={{ verticalAlign: 'top' }} {...props}>
      <TableCell size='large' pl={0}>
        <Typography className='text-text-secondary' variant='aLabelSmall'>
          {paymentRemark.kind}
        </Typography>
        <Typography>{paymentRemark.referenceNumber}</Typography>
      </TableCell>
      <TableCell size='large'>
        <Typography className='text-text-secondary' variant='aLabelSmall'>{t`Source`}</Typography>
        <Typography>{paymentRemark.source}</Typography>
      </TableCell>
      <TableCell size='large' textAlign='right' width={200}>
        {paymentRemark.creditor ? (
          <EntityLink variant='aLabelSmall' entity={paymentRemark.creditor} />
        ) : (
          <Typography variant='aLabelSmall'>{paymentRemark.creditorName}</Typography>
        )}

        <Typography className='tabular-nums'>{amountString}</Typography>
      </TableCell>
      <TableCell size='large' textAlign='right' width={140} pr={0}>
        <Typography className='text-text-secondary' variant='aLabelSmall'>{t`Date`}</Typography>
        <Typography>{formatDate(paymentRemark.date, { relative: false })}</Typography>
      </TableCell>
    </TableRow>
  )
}

export const SidepanelCreditReportPaymentRemarks = ({
  creditReport
}: {
  creditReport?: CreditReportFragment | null
}): React.ReactNode => {
  const paymentRemarkEdges = creditReport?.paymentRemarks.edges

  if (!paymentRemarkEdges?.length) {
    return (
      <Empty title={t`No payment remarks found`}>
        <Trans>We couldn't find any payment remarks on this company</Trans>
      </Empty>
    )
  }

  return (
    <div>
      <Table>
        <tbody>
          {paymentRemarkEdges.map(({ node: paymentRemark }, index) => {
            return (
              <CreditScorePaymentRemarksRow
                className={index === paymentRemarkEdges.length - 1 ? 'border-b-0' : 'border-b'}
                key={index}
                paymentRemark={paymentRemark}
                currency={creditReport?.currencyCode}
              />
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
