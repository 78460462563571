import { Dialog, type DialogProps } from '@strise/ui-components-legacy'
import {
  Button,
  cn,
  createColumnHelper,
  DataTable,
  IconButton,
  IconCross,
  Tabs,
  TabsList,
  TabsTrigger,
  TextArea,
  Tooltip,
  Typography
} from '@strise/ui-components'
import React from 'react'
import { defineMessage, t, Trans } from '@lingui/macro'
import { type PepInfoFragment, type PersonBaseFragment } from '~/graphqlTypes'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { EntityKeyMetaItems } from '~/components/Ownerships/EntityKeyMetaItems'
import { i18n, type MessageDescriptor } from '@lingui/core'
import {
  type CreateEntityDispositionInput,
  EntityDispositionKind,
  type EntityDispositionStatusKind
} from '@strise/types'
import { useDependencyState } from '@strise/react-utils'
import { filterNullishValues, objectEntries } from '@strise/ts-utils'
import { dialogBgColor, extractPepType } from '~/components/PepAndSanctions/dispositionDialogUtils'
import { Countries } from '~/components/Countries'
import { PepDispositionDialogActions } from '~/components/PepAndSanctions/PepDispositionDialogActions'
import { PepInfoStatusIcon } from '~/components/PepAndSanctions/PepInfoStatusIcon'
import {
  systemSuggestedFalsePepPredicate,
  systemSuggestedTruePepPredicate,
  userConfirmedFalsePepPredicate,
  userConfirmedTruePepPredicate
} from '~/components/PepAndSanctions/pepDispositionUtils'
import { MatchInfoAnalysisTooltipContent } from '~/components/MatchInfoAnalysis/MatchInfoAnalysisTooltipContent'
import { uniq } from 'lodash-es'
import { MissingData } from '~/components/MissingData'
import { extractGender } from '~/utils/gender'

type Tab =
  | 'SEE_ALL'
  | 'SYSTEM_SUGGESTED_TRUE'
  | 'SYSTEM_SUGGESTED_FALSE'
  | 'USER_CONFIRMED_FALSE'
  | 'USER_CONFIRMED_TRUE'

const tabsMap: Record<Tab, { label: MessageDescriptor; predicate: (pepInfo: PepInfoFragment) => boolean }> = {
  SEE_ALL: {
    label: defineMessage({ message: 'See all' }),
    predicate: (_: PepInfoFragment) => true
  },
  SYSTEM_SUGGESTED_TRUE: {
    label: defineMessage({ message: 'Suggested true' }),
    predicate: systemSuggestedTruePepPredicate
  },
  USER_CONFIRMED_TRUE: {
    label: defineMessage({ message: 'Confirmed true' }),
    predicate: userConfirmedTruePepPredicate
  },
  SYSTEM_SUGGESTED_FALSE: {
    label: defineMessage({ message: 'Suggested false' }),
    predicate: systemSuggestedFalsePepPredicate
  },
  USER_CONFIRMED_FALSE: {
    label: defineMessage({ message: 'Confirmed false' }),
    predicate: userConfirmedFalsePepPredicate
  }
}

interface DispositionListDialogProps extends Omit<DialogProps, 'isOpen'> {
  comparePepInfo: PepInfoFragment | null,
  onClose: () => void,
  pepInfos: PepInfoFragment[],
  person: PersonBaseFragment,
  setComparePepInfo: (pepInfo: PepInfoFragment | null) => void
}

const columnHelper = createColumnHelper<PepInfoFragment>()

const extractColumns = (setComparePepInfo: (pepInfo: PepInfoFragment | null) => void) => {
  return [
    columnHelper.accessor('name', {
      cell: ({ getValue, row }) => (
        <Tooltip content={t`Open comparison view`}>
          <Button
            variant='ghost'
            className='h-auto p-0 hover:bg-inherit hover:underline'
            onClick={() => setComparePepInfo(row.original)}
            data-track='Pep disposition dialog / Open comparison'
          >
            {getValue()}
          </Button>
        </Tooltip>
      ),
      header: t`Name`,
      meta: { cellClassName: 'pl-0', headerClassName: 'pl-0' }
    }),
    columnHelper.accessor((row) => row, {
      cell: ({ getValue }) => <PepInfoStatusIcon pepInfo={getValue()} />,
      header: t`Status`,
      enableHiding: false
    }),
    columnHelper.accessor('matchInfoAnalysis', {
      cell: ({ getValue }) => {
        const matchInfoAnalysis = getValue()

        if (!matchInfoAnalysis) return <MissingData />

        return (
          <Tooltip arrow content={<MatchInfoAnalysisTooltipContent matchInfoAnalysis={matchInfoAnalysis} />}>
            <span>{matchInfoAnalysis.result}</span>
          </Tooltip>
        )
      },
      header: t`Match %`
    }),
    columnHelper.accessor('birthDateV2', { header: t`DOB`, enableHiding: false }),
    columnHelper.accessor('gender', {
      cell: ({ getValue }) => extractGender(getValue()) ?? <MissingData />,
      header: t`Gender`
    }),
    columnHelper.accessor((row) => extractPepType(row), { header: t`Type`, enableHiding: false }),
    columnHelper.accessor('countries', {
      cell: ({ getValue }) => (getValue().length ? <Countries countries={getValue()} /> : <MissingData />),
      header: t`Countries`
    })
  ]
}

export const PepDispositionListDialog = ({
  comparePepInfo,
  onClose,
  pepInfos,
  person,
  setComparePepInfo
}: DispositionListDialogProps): React.ReactNode => {
  const [comment, setComment] = React.useState('')
  const [activeTab, setActiveTab] = React.useState<Tab>(() => {
    if (pepInfos.some(systemSuggestedTruePepPredicate)) {
      return 'SYSTEM_SUGGESTED_TRUE'
    }

    if (pepInfos.some(userConfirmedTruePepPredicate)) {
      return 'USER_CONFIRMED_TRUE'
    }

    if (pepInfos.some(systemSuggestedFalsePepPredicate)) {
      return 'SYSTEM_SUGGESTED_FALSE'
    }

    if (pepInfos.some(userConfirmedFalsePepPredicate)) {
      return 'USER_CONFIRMED_FALSE'
    }

    return 'SYSTEM_SUGGESTED_TRUE'
  })

  const activePredicate = tabsMap[activeTab].predicate
  const filteredRows = pepInfos.filter(activePredicate)

  const sources = uniq(filterNullishValues(pepInfos.map((info) => info.source)))

  // Reset state on tab change
  const [selectedRowsMap, setSelectedRowsMap] = useDependencyState<Record<string, boolean>>({}, [activeTab])

  // Avoid re-rendering of columns
  const columns = React.useMemo(() => extractColumns(setComparePepInfo), [])

  // If the comparison dialog is open, don't render the list dialog
  if (comparePepInfo) return null

  const handleTabChange = (value: string): void => {
    setActiveTab(value as Tab)
  }

  const extractInputs = (status: EntityDispositionStatusKind): CreateEntityDispositionInput[] => {
    const selectedIds = Object.entries(selectedRowsMap)
      .filter(([_, checked]) => checked)
      .map(([id, _]) => {
        return id
      })

    return selectedIds.map((id) => {
      return {
        externalId: id,
        kind: EntityDispositionKind.Pep,
        status,
        comment
      }
    })
  }

  const handleCompleted = (): void => {
    // Switch to show all if the last result(s) are dispositioned
    setActiveTab(filteredRows.length ? activeTab : 'SEE_ALL')

    setSelectedRowsMap({})
    setComment('')
  }

  const body = (
    <div className={cn('flex flex-col gap-4', dialogBgColor)}>
      <div className='self-end'>
        <IconButton data-track='Pep disposition dialog / Close' onClick={onClose}>
          <IconCross />
        </IconButton>
      </div>
      <div className='flex flex-col gap-3 bg-background-paper p-4'>
        <EntityLink entity={person} withIcon noTooltip noLink />
        <EntityKeyMetaItems className='flex gap-2' entity={person} expanded itemProps={{ className: 'w-full' }} />
      </div>
      <div>
        <Typography variant='subtitle2' className='mb-2'>{t`Possible matches`}</Typography>
        <Typography
          variant='body2'
          className='text-text-secondary'
        >{t`Disposition the results in the list below or click name for more information`}</Typography>
      </div>
      <div>
        <Tabs palette='primary' value={activeTab} onValueChange={handleTabChange}>
          <TabsList className='w-full'>
            {objectEntries(tabsMap).map(([tab, tabData]) => {
              return (
                <TabsTrigger className='w-full text-sm' key={tab} value={tab}>
                  {i18n._(tabData.label)} ({pepInfos.filter(tabData.predicate).length})
                </TabsTrigger>
              )
            })}
          </TabsList>
        </Tabs>
        <div className='min-h-[274px]'>
          <DataTable
            className='bg-background-paper'
            headerRowClassName='hover:bg-inherit'
            getRowClassName={() => 'hover:bg-inherit'}
            headerCellClassName='py-2 text-text-secondary sticky top-0 bg-background-paper z-[1]'
            bodyCellClassName='py-2'
            wrapperClassName='overflow-auto max-h-[250px]'
            emptyStateText={t`No results`}
            columns={columns}
            data={filteredRows}
            options={{
              onRowSelectionChange: setSelectedRowsMap,
              state: {
                rowSelection: selectedRowsMap
              },
              getRowId: (row: PepInfoFragment) => row.id
            }}
          />
          {!!sources.length && (
            <Typography variant='body2' className='mt-2 text-text-secondary'>
              {sources.length === 1 ? <Trans>Source</Trans> : <Trans>Sources</Trans>}: {sources.join(', ')}
            </Typography>
          )}
        </div>
      </div>
      <TextArea
        className='mt-8'
        placeholder={t`Comment (optional)`}
        value={comment}
        autoResize
        onChange={(event) => setComment(event.target.value)}
      />
    </div>
  )

  return (
    <Dialog
      onClose={onClose}
      body={body}
      isOpen
      contentMaxWidth={856}
      containerProps={{ bgcolor: dialogBgColor }}
      contentProps={{ className: 'min-w-[640px]' }}
    >
      <PepDispositionDialogActions
        pepInfos={pepInfos}
        extractInputs={extractInputs}
        person={person}
        onCompleted={handleCompleted}
      />
    </Dialog>
  )
}
