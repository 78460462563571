import * as React from 'react'
import { languageToCountryCode, toast } from '@strise/app-shared'
import { Navigate, Route, Routes as RDRoutes, useLocation, useNavigate } from 'react-router-dom'
import { track } from '~/utils/tracking'
import { Helmet } from 'react-helmet'
import { ContentView } from '~/views/ContentView'
import { useActiveContentView, useContentViews, Views } from '~/utils/viewsHooks'
import { useReactiveVar } from '@apollo/client/index.js'
import { spoofUser } from '~/state'
import { SpoofWarning } from '~/components/SpoofWarning'
import { SuspenseLoadingView } from '~/views/LoadingView'
import { ConnectedAppKind, TrackedActivityKind } from '@strise/types'
import { ContentViews } from '~/utils/urls'
import { hardRefresh } from '@strise/react-utils'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { contentViewToFeatureMap } from '~/utils/contentViewUtils'
import { t } from '@lingui/macro'
import { useAppVersion } from '~/utils/versionUtils'
import { Layout } from '~/components/Layout/Layout'
import { useRepairedPathFromLegacyLocation } from '~/utils/legacyRouteUtils'
import { QUERY_PARAMS } from '~/constants'

const ConflictsModal = React.lazy(async () => ({
  default: (await import('~/components/Conflicts/ConflictsModal')).ConflictsModal
}))
const EventDialog = React.lazy(async () => ({
  default: (await import('~/components/Events/EventDialog')).EventDialog
}))
const ConnectCrmView = React.lazy(async () => ({
  default: (await import('~/views/Settings/TeamFeatures/ConnectCrmView')).ConnectCrmView
}))
const CompanyByOrganizationNumberView = React.lazy(async () => ({
  default: (await import('~/views/CompanyByOrganizationNumberView')).CompanyByOrganizationNumberView
}))
const SetupView = React.lazy(async () => ({ default: (await import('~/views/Setup/SetupView')).SetupView }))
const ErrorView = React.lazy(async () => ({ default: (await import('~/views/ErrorView')).ErrorView }))
const NotFoundView = React.lazy(async () => ({ default: (await import('~/views/NotFoundView')).NotFoundView }))
const SentryTestView = React.lazy(async () => ({ default: (await import('~/views/SentryTestView')).SentryTestView }))
const LogOutView = React.lazy(async () => ({ default: (await import('@strise/app-shared')).LogOutView }))
const SettingsModal = React.lazy(async () => ({
  default: (await import('~/views/Settings/SettingsModal')).SettingsModal
}))

// Custom hook to track navigation
const useTrackNavigation = (): void => {
  const location = useLocation()
  // Store the previous path to track navigation
  const prevPath = React.useRef<string | null>(null)

  React.useEffect(() => {
    // Construct the full path including search params
    const nextPath = location.pathname + location.search

    // Track navigation events
    track(TrackedActivityKind.StriseNavigation, {
      from: prevPath.current,
      to: nextPath
    })

    // Update the previous path for the next navigation event
    prevPath.current = nextPath
  }, [location.pathname])
}

/**
 * Custom hook to handle version updates and trigger a hard refresh when necessary.
 * This hook checks for version changes on every route change and updates local storage.
 */
const useNewVersionCheck = (): void => {
  const location = useLocation()
  const deployedVersion = useAppVersion()
  const currentVersion = localStorage.getItem('STRISE_APP_VERSION') || ''

  React.useEffect(() => {
    if (deployedVersion) {
      // Update the stored version if it's different from the deployed version
      if (currentVersion !== deployedVersion) {
        localStorage.setItem('STRISE_APP_VERSION', deployedVersion)
      }

      // Trigger a hard refresh if there's a version mismatch
      if (currentVersion && currentVersion !== deployedVersion) {
        hardRefresh()
      }
    }
  }, [location.pathname, deployedVersion])
}

const useRouteFeatureGuard = (): void => {
  const homeView = ContentViews.Home
  const activeView = useActiveContentView()
  const features = useCurrentUserFeatures()
  const navigate = useNavigate()

  React.useEffect(() => {
    const currentFeatureView = activeView && contentViewToFeatureMap[activeView]

    if (currentFeatureView && !features[currentFeatureView]) {
      // If the current page is disabled, go to homeView
      navigate(`/${homeView}`, { replace: true })
      toast.error(t`You do not have access to this page`)
    }
  }, [activeView, features])
}

export const Routes = (): React.ReactNode => {
  const spoofing = useReactiveVar(spoofUser)
  const contentViews = useContentViews()
  const location = useLocation()

  useTrackNavigation()
  // check if a new version has come in, and if so, hard refresh
  useNewVersionCheck()
  // Order matters - keep this last
  useRouteFeatureGuard()

  const matchingContentView = contentViews.some((view) => location.pathname.startsWith(`/${view}`))

  const showSettingsModal = location.search.includes(QUERY_PARAMS.settingsTab)
  const showEventModal = location.search.includes(QUERY_PARAMS.eventId)
  const showConflictsModal = location.search.includes(QUERY_PARAMS.conflictId)

  return (
    <>
      <Helmet titleTemplate='%s — Strise&reg;' defaultTitle='Strise&reg;' />

      {showSettingsModal && (
        <React.Suspense fallback={null}>
          <SettingsModal />
        </React.Suspense>
      )}
      {showEventModal && (
        <React.Suspense fallback={null}>
          <EventDialog />
        </React.Suspense>
      )}
      {showConflictsModal && (
        <React.Suspense fallback={null}>
          <ConflictsModal />
        </React.Suspense>
      )}

      {spoofing && <SpoofWarning />}
      {matchingContentView ? (
        <Layout>
          <ViewRoutes contentViews={contentViews} />
        </Layout>
      ) : (
        <ViewRoutes contentViews={contentViews} />
      )}
    </>
  )
}

const ViewRoutes = ({ contentViews }: { contentViews: ContentViews[] }): React.ReactNode => {
  const repairedLegacyRoute = useRepairedPathFromLegacyLocation()
  const homePath = ContentViews.Home

  // Note: this caused an endless navigation loop when we didn't handle encoding of spaces properly.
  // Now using RFC1738 encoding to handle spaces:
  // https://github.com/ljharb/qs?tab=readme-ov-file#rfc-3986-and-rfc-1738-space-encoding
  if (repairedLegacyRoute) {
    return <Navigate to={repairedLegacyRoute} replace />
  }

  return (
    <RDRoutes>
      {contentViews.map((contentView) => {
        return <Route key={contentView} path={`/${contentView}/*`} element={<ContentView />} />
      })}
      {Object.values(languageToCountryCode).map((countryCode) => {
        if (!countryCode) return null
        return (
          <Route
            key={countryCode}
            path={`/company/${countryCode}/:organizationNumber`}
            element={
              <SuspenseLoadingView>
                <CompanyByOrganizationNumberView countryCode={countryCode} />
              </SuspenseLoadingView>
            }
          />
        )
      })}
      <Route
        path={`${Views.SETUP}/*`}
        element={
          <SuspenseLoadingView>
            <SetupView />
          </SuspenseLoadingView>
        }
      />
      <Route
        path={`${Views.HUBSPOT}/*`}
        element={
          <SuspenseLoadingView>
            <ConnectCrmView kind={ConnectedAppKind.Hubspot} />
          </SuspenseLoadingView>
        }
      />
      <Route
        path={`${Views.PIPEDRIVE}/*`}
        element={
          <SuspenseLoadingView>
            <ConnectCrmView kind={ConnectedAppKind.Pipedrive} />
          </SuspenseLoadingView>
        }
      />
      <Route
        path={Views.SENTRY_TEST}
        element={
          <SuspenseLoadingView>
            <SentryTestView />
          </SuspenseLoadingView>
        }
      />
      <Route
        path={Views.ERROR}
        element={
          <SuspenseLoadingView>
            <ErrorView />
          </SuspenseLoadingView>
        }
      />
      <Route
        path={Views.LOGOUT}
        element={
          <SuspenseLoadingView>
            <LogOutView />
          </SuspenseLoadingView>
        }
      />
      <Route path='/' element={<Navigate to={{ pathname: `/${homePath}`, search: location.search }} replace />} />
      <Route
        path='*'
        element={
          <SuspenseLoadingView>
            <NotFoundView />
          </SuspenseLoadingView>
        }
      />
    </RDRoutes>
  )
}
