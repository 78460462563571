import { t } from '@lingui/macro'
import {
  type BaseEntityLikeFragment,
  type CompanyNetworkEntityPersonFragment,
  type CompanyNetworkFragment,
  type SanctionInfoFragment
} from '~/graphqlTypes'

export const extractSanctionInfoTitle = (sanctionInfo: SanctionInfoFragment | null): string => {
  if (!sanctionInfo) return t`has unknown sanction status`

  if (!sanctionInfo.isSanctioned) return t`is not sanctioned`

  return t`is sanctioned`
}

type SanctionedNetworkEntity = {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies']
  entity: BaseEntityLikeFragment
  sanctions: SanctionInfoFragment
}

export const extractPepAndSanctionsFromCompanyNetwork = (
  companyNetwork: CompanyNetworkFragment
): {
  pepEntities: CompanyNetworkEntityPersonFragment[]
  sanctionedEntities: Array<SanctionedNetworkEntity>
} => {
  const pepEntities = companyNetwork.allPersons.filter(
    (person) => person.pep?.pep || person.pep?.rca || !!person.peps?.length
  )

  const sanctionedPersons: Array<SanctionedNetworkEntity> = companyNetwork.allPersons
    .filter(({ sanctions }) => sanctions.sanctionedBy.length > 0)
    .map(({ associatedCompanies, person, sanctions }) => ({
      entity: person,
      sanctions,
      associatedCompanies
    }))

  // companyNetwork.company is the company we are currently viewing
  // Destructure to handle the different structure of companyNetwork.company
  const { sanctionInfo, ...sidepanelCompany } = companyNetwork.company
  const companySanctions = { company: sidepanelCompany, sanctions: sanctionInfo }

  const sanctionedCompanies: Array<SanctionedNetworkEntity> = [companySanctions, ...companyNetwork.otherCompanies]
    .filter(({ sanctions }) => sanctions.sanctionedBy.length > 0)
    .map(({ company, sanctions }) => ({ entity: company, sanctions, associatedCompanies: [] }))

  return {
    pepEntities,
    sanctionedEntities: [...sanctionedCompanies, ...sanctionedPersons]
  }
}
