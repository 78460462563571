import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCheck = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m22.328 3.56-12.2 18.035-8.857-9.41 1.457-1.37 7.144 7.59 10.8-15.965 1.656 1.12Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCheck.displayName = 'IconCheck'
