import * as React from 'react'

import { type CompanyNetworkEntityPersonFragment } from '~/graphqlTypes'
import { EntityIcon } from '~/components/EntityIcon'
import { cn, Typography } from '@strise/ui-components'
import { useTryScrollToOwnershipChartNode } from '~/components/Sidepanel/SidepanelContext/useScrollToOwnershipChartNode'

interface AssociatedCompanyLinkProps {
  associatedCompany: CompanyNetworkEntityPersonFragment['associatedCompanies'][0]
  currentCompanyId: string
}

export const AssociatedCompanyLink = ({
  associatedCompany,
  currentCompanyId
}: AssociatedCompanyLinkProps): React.ReactNode => {
  const scrollToOwnershipChartNode = useTryScrollToOwnershipChartNode()

  const isCurrentCompany = associatedCompany.id === currentCompanyId

  return (
    <div className='flex items-center'>
      <EntityIcon entity={associatedCompany} className='mr-1 block pl-0' size='sm' />
      <Typography
        className={cn(
          { 'text-text-link': !isCurrentCompany },
          { 'cursor-pointer': !isCurrentCompany },
          { 'hover:underline': !isCurrentCompany },
          'text-xs'
        )}
        onClick={
          !isCurrentCompany && scrollToOwnershipChartNode
            ? () => scrollToOwnershipChartNode(associatedCompany.id)
            : undefined
        }
      >
        {associatedCompany.name}
      </Typography>
    </div>
  )
}
