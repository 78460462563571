import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconOnboarding = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M2 1h16.914l5 5-5 5H2V1Zm2 2v6h14.086l3-3-3-3H4Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M22 16a3 3 0 0 1-3 3H5.586l-5-5 5-5H22v7Zm-3 1a1 1 0 0 0 1-1v-5H6.414l-3 3 3 3H19Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M11 23v-5h2v5h-2ZM17 7H7V5h10v2ZM17 15h-7v-2h7v2Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconOnboarding.displayName = 'IconOnboarding'
