import { cva } from 'class-variance-authority'

export const skeletonVariants = cva(
  // Using String.raw to be able to use the \00a0 character without escaping it
  String.raw`relative overflow-hidden bg-tertiary-shade-5 [&>*]:invisible [&>*]:before:content-["\00a0"]`,
  {
    variants: {
      /** Styling variants */
      variant: {
        text: 'h-auto w-full rounded-sm',
        circle: 'rounded-full',
        rect: undefined
      },
      /** Animation variants */
      animation: {
        pulse: 'animate-pulse',
        wave: 'after:absolute after:inset-0 after:animate-wave after:bg-gradient-to-r after:from-transparent after:via-tertiary-main after:to-transparent'
      }
    },
    defaultVariants: {
      variant: 'text',
      animation: 'wave'
    }
  }
)
