import type { SetStateFn } from '../types/types'
import { useState, useRef, useEffect } from 'react'

export const useDependencyState = <T>(initValue: T | (() => T), dependencies: unknown[]): [T, SetStateFn<T>] => {
  const [value, setValue] = useState<T>(initValue)
  const firstRun = useRef(true)

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    setValue(initValue)
    // TODO: ignoring this rule for now as it's used many places and difficult to handle properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  return [value, setValue]
}
