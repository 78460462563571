import * as React from 'react'
import * as SwitchPrimitive from '@radix-ui/react-switch'
import type { DataProps, StyleProps } from '@strise/react-utils'
import { cn } from '../../utils/className'

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitive.Root>, DataProps, StyleProps {}

// TODO - create palette variants with cva
export const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitive.Root>, SwitchProps>(
  ({ className, ...props }, ref) => {
    return (
      <SwitchPrimitive.Root
        className={cn(
          'h-5 w-9 cursor-pointer rounded-full bg-secondary-shade-30 p-0.5 data-[state=checked]:bg-semantic-success-main',
          className
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitive.Thumb className='block size-4 rounded-full bg-white transition-transform duration-200 data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0' />
      </SwitchPrimitive.Root>
    )
  }
)

Switch.displayName = 'Switch'
