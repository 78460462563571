import { CompanyEventKind, Duration, type Event, FlagSeverity } from '@strise/types'
import { t } from '@lingui/macro'

export const prefixPaywallTitle = (event: Pick<Event, 'title' | 'behindPaywall'>): string => {
  return event.behindPaywall ? `(+) ${event.title}` : event.title
}

export const getTimeFilterText = (duration: Duration | null | undefined): string => {
  switch (duration) {
    case Duration.Day: {
      return t`past day`
    }
    case Duration.Week: {
      return t`past week`
    }
    case Duration.Month: {
      return t`past month`
    }
    case Duration.HalfYear: {
      return t`past 6 months`
    }
    case Duration.Year: {
      return t`past year`
    }
    case Duration.ThreeYear: {
      return t`past 3 years`
    }
    default: {
      return t`selected time frame`
    }
  }
}

export const getTimeFilterEndText = (duration: Duration | null | undefined): string => {
  switch (duration) {
    case Duration.Day: {
      return t`for the past day`
    }
    case Duration.Week: {
      return t`for the past week`
    }
    case Duration.Month: {
      return t`for the past month`
    }
    case Duration.HalfYear: {
      return t`for the past 6 months`
    }
    case Duration.Year: {
      return t`for the past year`
    }
    case Duration.ThreeYear: {
      return t`for the past 3 years`
    }
    default: {
      return t`for the selected time frame`
    }
  }
}

export type FlagEventColors = Record<
  FlagSeverity | CompanyEventKind.FlagInactive,
  {
    background: string
    backgroundHover: string
    borderColor: string
    flagInnerBackground: string
    flagOuterBackground: string
    interactionBackground: string
    interactionBackgroundHover: string
  }
>

export const flagEventColors: FlagEventColors = {
  [FlagSeverity.Positive]: {
    background: 'bg-semantic-success-shade-5',
    backgroundHover: 'hover:bg-semantic-success-shade-10',
    borderColor: 'border-semantic-success-main',
    flagOuterBackground: 'bg-semantic-success-shade-10/50',
    flagInnerBackground: 'bg-semantic-success-shade-10',
    interactionBackground: 'bg-semantic-success-shade-100/[0.6]',
    interactionBackgroundHover: 'group-hover/event-container:bg-semantic-success-shade-100'
  },
  [FlagSeverity.Low]: {
    background: 'bg-semantic-info-shade-5',
    backgroundHover: 'hover:bg-semantic-info-shade-10',
    borderColor: 'border-semantic-info-main',
    flagOuterBackground: 'bg-semantic-info-shade-10/50',
    flagInnerBackground: 'bg-semantic-info-shade-10',
    interactionBackground: 'bg-semantic-info-shade-100/[0.6]',
    interactionBackgroundHover: 'group-hover/event-container:bg-semantic-info-shade-100'
  },
  [FlagSeverity.Medium]: {
    background: 'bg-semantic-notice-shade-5',
    backgroundHover: 'hover:bg-semantic-notice-shade-10',
    borderColor: 'border-semantic-notice-main',
    flagOuterBackground: 'bg-semantic-notice-shade-10/50',
    flagInnerBackground: 'bg-semantic-notice-shade-10',
    interactionBackground: 'bg-semantic-notice-shade-100/[0.6]',
    interactionBackgroundHover: 'group-hover/event-container:bg-semantic-notice-shade-100'
  },
  [FlagSeverity.High]: {
    background: 'bg-semantic-danger-shade-5',
    backgroundHover: 'hover:bg-semantic-danger-shade-10',
    borderColor: 'border-semantic-danger-main',
    flagOuterBackground: 'bg-semantic-danger-shade-10/50',
    flagInnerBackground: 'bg-semantic-danger-shade-10',
    interactionBackground: 'bg-semantic-danger-shade-100/[0.6]',
    interactionBackgroundHover: 'group-hover/event-container:bg-semantic-danger-shade-100'
  },
  [CompanyEventKind.FlagInactive]: {
    background: 'bg-semantic-disabled-shade-5',
    backgroundHover: 'hover:bg-semantic-disabled-shade-10',
    borderColor: 'border-semantic-disabled-main',
    flagOuterBackground: 'bg-semantic-disabled-shade-10/50',
    flagInnerBackground: 'bg-semantic-disabled-shade-10',
    interactionBackground: 'bg-semantic-disabled-shade-100/[0.6]',
    interactionBackgroundHover: 'group-hover/event-container:bg-semantic-disabled-shade-100'
  }
}
