import { Chip, type ChipProps, cn, IconConnect, Tooltip } from '@strise/ui-components'
import * as React from 'react'
import { t } from '@lingui/macro'

export const OwnershipMergeableChip = ({ className, ...props }: ChipProps): React.ReactNode => {
  return (
    <Tooltip content={t`Unresolved missing entity connections. Enter edit mode to review.`}>
      <Chip className={cn('ml-1', className)} startIcon={<IconConnect size='md' />} {...props} />
    </Tooltip>
  )
}
