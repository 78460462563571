import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconFilter = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='#000'
        fillRule='evenodd'
        d='M1 1h22v4.5l-8 6v5.914l-6 6V11.5l-8-6V1Zm2 2v1.5l8 6v8.086l2-2V10.5l8-6V3H3Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconFilter.displayName = 'IconFilter'
