import { useEffect, useState, useCallback } from 'react'
import { type RenderNodeContent, type Subsidiary } from './types'
import { type RootNodeType } from './RootNode'
import { orderBy } from 'lodash-es'
import { type SubsidiaryNodeType } from './SubsidiaryNode'
import { type SubsidiaryEdgeType } from './SubsidiaryEdge'
import { type ExpandSubsidiariesNodeType } from './ExpandSubsidiariesNode'
import { BigNumber, formatShare } from '@strise/ts-utils'
import {
  ROOT_NODE_HEIGHT,
  NODE_SEPARATION,
  NODE_WIDTH,
  NODE_HEIGHT,
  EXPAND_SUBSIDIARIES_NODE_SIZE,
  SUBSIDIARY_LEVEL_HORIZONTAL_OFFSET,
  SUBSIDIARY_HANDLE_OFFSET
} from './sizes'

const createUniqueNodeId = (subsidiaryId: string, siblingIndex: number, parentPath: string[] = []): string => {
  return [...parentPath, `${subsidiaryId}-${siblingIndex}`].join('/')
}

export const useSubsidiaries = <T extends Subsidiary<T>>(
  rootNode: RootNodeType,
  onExpandSubsidiaries?: (id: string) => Promise<T[]>,
  renderNodeContent?: RenderNodeContent<T>,
  formatOwnership?: (percentage: number) => React.ReactNode
): {
  subsidiaryEdges: SubsidiaryEdgeType[]
  subsidiaryNodes: Array<ExpandSubsidiariesNodeType | SubsidiaryNodeType<T>>
} => {
  const [subsidiaries, setSubsidiaries] = useState<T[]>([])

  const handleExpandSubsidiaries = useCallback(
    async (nodeId: string, isExpanded: boolean) => {
      if (!onExpandSubsidiaries) return

      const expandedSubsidiaries = isExpanded ? await onExpandSubsidiaries(nodeId) : []
      const filteredSubsidiaries = expandedSubsidiaries.filter((sub) => sub.id !== rootNode.id)
      const sortedSubsidiaries = orderBy(filteredSubsidiaries, (sub) => -sub.sharePercentage)

      // If the expanded subsidiaries are empty, we don't need to update the tree, just store the first level
      if (subsidiaries.length === 0) {
        setSubsidiaries(sortedSubsidiaries)
        return
      }

      // Recursively update the subsidiaries tree
      setSubsidiaries((prevSubsidiaries) => {
        return updateSubsidiaryTree(prevSubsidiaries, nodeId, sortedSubsidiaries, true)
      })
    },
    [onExpandSubsidiaries, rootNode.id, subsidiaries.length]
  )

  useEffect(() => {
    handleExpandSubsidiaries(rootNode.id, true)
  }, [onExpandSubsidiaries, rootNode.id, handleExpandSubsidiaries])

  const subsidiaryNodes = createSubsidiaryNodes(
    subsidiaries,
    rootNode.position.x,
    rootNode.position.y,
    1,
    renderNodeContent,
    handleExpandSubsidiaries,
    [],
    new Set()
  )

  const subsidiaryEdges = createSubsidiaryEdges(subsidiaries, rootNode.id, 1, formatOwnership, [], new Set())

  return {
    subsidiaryEdges,
    subsidiaryNodes
  }
}

const updateSubsidiaryTree = <T extends Subsidiary<T>>(
  subsidiaries: T[],
  parentId: string,
  newSubsidiaries: T[],
  isExpanded: boolean
): T[] => {
  return subsidiaries.map((sub) => {
    const parentOwnership = BigNumber(sub.sharePercentage)
    const subsidiariesWithIndirectOwnership = newSubsidiaries.map((newSub) => {
      const indirectOwnership = new BigNumber(newSub.sharePercentage).times(parentOwnership).dividedBy(100).toNumber()
      return {
        ...newSub,
        sharePercentage: indirectOwnership
      }
    })

    if (sub.id === parentId) {
      return {
        ...sub,
        subsidiaries: isExpanded ? subsidiariesWithIndirectOwnership : [] // Update with the newly fetched subsidiaries
      }
    }

    // Recursively update child nodes if they exist
    if (sub.subsidiaries) {
      return {
        ...sub,
        subsidiaries: updateSubsidiaryTree(sub.subsidiaries, parentId, newSubsidiaries, isExpanded)
      }
    }

    return sub
  })
}

export const createSubsidiaryNodes = <T extends Subsidiary<T>>(
  subsidiaries: T[],
  parentX: number,
  parentY: number,
  level: number,
  renderNodeContent?: RenderNodeContent<T>,
  onExpandSubsidiaries?: (id: string, isExpanded: boolean) => Promise<void>,
  parentPath: string[] = [],
  visitedIds: Set<string> = new Set()
): Array<SubsidiaryNodeType<T> | ExpandSubsidiariesNodeType> => {
  // Keep track of where to position the next subsidiary node on the y axis
  // eslint-disable-next-line functional/no-let
  let mutableNextNodeY = parentY + NODE_SEPARATION + (level === 1 ? ROOT_NODE_HEIGHT : EXPAND_SUBSIDIARIES_NODE_SIZE)
  const idCountMap = new Map<string, number>()

  return subsidiaries.flatMap((subsidiary) => {
    // Prevent infinite loop if a company is already in the tree
    if (visitedIds.has(subsidiary.id)) {
      return []
    }

    const newVisitedIds = new Set(visitedIds).add(subsidiary.id)

    const siblingIndex = idCountMap.get(subsidiary.id) || 0
    idCountMap.set(subsidiary.id, siblingIndex + 1)

    const x = parentX + SUBSIDIARY_LEVEL_HORIZONTAL_OFFSET // Adjust X position based on level
    const y = mutableNextNodeY

    const uniqueNodeId = createUniqueNodeId(subsidiary.id, siblingIndex, parentPath)

    const subsidiaryNode: SubsidiaryNodeType<T> = {
      id: uniqueNodeId,
      data: { ...subsidiary, renderNodeContent },
      position: { x, y },
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
      type: 'subsidiary',
      draggable: false,
      connectable: false
    }

    const expandNodeY = y + NODE_HEIGHT + NODE_SEPARATION
    // Directly under the subsidiary nodes "subsidiaryParentHandle"
    const expandNodeX =
      x + SUBSIDIARY_LEVEL_HORIZONTAL_OFFSET - EXPAND_SUBSIDIARIES_NODE_SIZE / 2 - SUBSIDIARY_HANDLE_OFFSET

    const expandSubsidiariesNode = createExpandSubsidiariesNode(
      subsidiary,
      expandNodeX,
      expandNodeY,
      uniqueNodeId,
      onExpandSubsidiaries
    )

    // Recursively process children if they exist
    const childNodes = subsidiary.subsidiaries
      ? createSubsidiaryNodes(
          subsidiary.subsidiaries,
          x,
          expandNodeY,
          level + 1,
          renderNodeContent,
          onExpandSubsidiaries,
          [...parentPath, `${subsidiary.id}-${siblingIndex}`],
          newVisitedIds
        )
      : []

    const lastChildY = childNodes[childNodes.length - 1]?.position.y

    mutableNextNodeY = calculateNextNodeY(y, expandSubsidiariesNode?.position.y, lastChildY)

    // Return the current node along with all of its children nodes
    return expandSubsidiariesNode
      ? [subsidiaryNode, expandSubsidiariesNode, ...childNodes]
      : [subsidiaryNode, ...childNodes]
  })
}

const calculateNextNodeY = (currentNodeY: number, expandSubsidiariesNodeY?: number, lastChildY?: number): number => {
  if (lastChildY) {
    return lastChildY + NODE_HEIGHT + NODE_SEPARATION
  }
  if (expandSubsidiariesNodeY) {
    return expandSubsidiariesNodeY + EXPAND_SUBSIDIARIES_NODE_SIZE + NODE_SEPARATION
  }
  return currentNodeY + NODE_HEIGHT + NODE_SEPARATION
}

const createExpandSubsidiariesNode = <T extends Subsidiary<T>>(
  subsidiary: T,
  x: number,
  y: number,
  uniqueNodeId: string,
  onExpandSubsidiaries?: (id: string, isExpanded: boolean) => Promise<void>
): ExpandSubsidiariesNodeType | null => {
  if (subsidiary.numOwnerships === 0 || !onExpandSubsidiaries) return null

  return {
    id: `expand-${uniqueNodeId}`,
    data: { subsidiaryId: subsidiary.id, numOwnerships: subsidiary.numOwnerships, onExpandSubsidiaries },
    position: { x, y },
    style: { width: EXPAND_SUBSIDIARIES_NODE_SIZE, height: EXPAND_SUBSIDIARIES_NODE_SIZE },
    type: 'expandSubsidiaries',
    connectable: false
  }
}

const getSubsidiaryNodeSource = (
  parentId: string,
  parentPath: string[],
  levelIndex: number,
  level: number,
  previousNodeId: string
): { source: string; sourceHandle: string | undefined } => {
  if (level === 1 && levelIndex === 0) {
    return { source: parentId, sourceHandle: 'rootNodeBottomHandle' }
  }

  if (level > 1 && levelIndex === 0) {
    const parentNodeId = createUniqueNodeId(parentId, 0, parentPath.slice(0, -1))
    return {
      source: `expand-${parentNodeId}`,
      sourceHandle: 'expandNodeBottomHandle'
    }
  }

  return {
    source: previousNodeId,
    sourceHandle: undefined
  }
}

export const createSubsidiaryEdges = <T extends Subsidiary<T>>(
  subsidiaries: T[],
  // Represents either the root node, or the parent subsidiary
  parentId: string,
  level = 1,
  formatOwnership?: (percentage: number) => React.ReactNode,
  parentPath: string[] = [],
  visitedIds: Set<string> = new Set()
): SubsidiaryEdgeType[] => {
  const idCountMap = new Map<string, number>()

  // eslint-disable-next-line functional/no-let
  let effectiveLevelIndex = 0 // Track the actual index after skips

  return subsidiaries.flatMap((subsidiary, levelIndex) => {
    if (visitedIds.has(subsidiary.id)) {
      return []
    }

    const newVisitedIds = new Set(visitedIds).add(subsidiary.id)
    const siblingIndex = idCountMap.get(subsidiary.id) || 0

    const previousNode = subsidiaries[levelIndex - 1]
    const previousNodeId = previousNode
      ? createUniqueNodeId(previousNode.id, (idCountMap.get(previousNode.id) || 1) - 1, parentPath)
      : ''

    idCountMap.set(subsidiary.id, siblingIndex + 1)

    const { source, sourceHandle } = getSubsidiaryNodeSource(
      parentId,
      parentPath,
      effectiveLevelIndex,
      level,
      previousNodeId
    )

    effectiveLevelIndex++

    const currentNodeId = createUniqueNodeId(subsidiary.id, siblingIndex, parentPath)

    const label = formatOwnership
      ? formatOwnership(subsidiary.sharePercentage)
      : formatShare(subsidiary.sharePercentage).short

    // Create the edge between the parent (which is either the root node, an expand node or the previous subsidiary) and subsidiary
    const mainEdge: SubsidiaryEdgeType = {
      id: `${source}-${currentNodeId}`,
      source,
      sourceHandle,
      target: currentNodeId,
      label,
      type: 'subsidiary',
      focusable: false,
      selectable: false
    }

    // Create the edge to the expand node, if there is one
    const expandEdge: SubsidiaryEdgeType | null =
      subsidiary.numOwnerships > 0
        ? {
            id: `${currentNodeId}-expand`,
            source: currentNodeId,
            sourceHandle: 'subsidiaryParentHandle',
            target: `expand-${currentNodeId}`,
            type: 'subsidiary',
            focusable: false,
            selectable: false
          }
        : null

    // Recursively process children if they exist
    const childEdges = subsidiary.subsidiaries
      ? createSubsidiaryEdges(
          subsidiary.subsidiaries,
          subsidiary.id,
          level + 1,
          formatOwnership,
          [...parentPath, `${subsidiary.id}-${siblingIndex}`],
          newVisitedIds
        )
      : []

    return expandEdge ? [mainEdge, expandEdge, ...childEdges] : [mainEdge, ...childEdges]
  })
}
