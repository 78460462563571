import { useIsMobile } from '~/utils/hooks'
import { useEffect } from 'react'
import {
  setChildState,
  type SetStateFn,
  useContext,
  useRecursiveQueryParamsReactRouter,
  validateBoolean,
  validateEnum,
  validateString
} from '@strise/react-utils'
import { type SidepanelEntityFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'
import { RecentlyVisitedEntitiesContext } from '~/contexts/RecentlyVisitedEntitiesContext/RecentlyVisitedEntitiesContext'
import { QUERY_PARAMS } from '~/constants'

type SupportedEntityTypenames = 'Company' | 'Person' | 'GlobalCompany' | 'GlobalPerson' | 'PrivatePerson'

export type SupportedSidepanelEntityFragment = SidepanelEntityFragment & {
  __typename: SupportedEntityTypenames
}

export const extractIsSupportedSidepanelEntity = (
  entity: SidepanelEntityFragment | null | undefined
): entity is SupportedSidepanelEntityFragment | null | undefined => {
  if (!entity) return true

  return ['Company', 'Person', 'GlobalCompany', 'GlobalPerson', 'PrivatePerson'].includes(entity.__typename)
}

interface SidepanelQueryParamsState {
  [QUERY_PARAMS.sidepanelEntityId]: string | null
  [QUERY_PARAMS.sidepanelExpanded]: boolean | null
  [QUERY_PARAMS.sidepanelTab]: SidepanelTab | null
  [QUERY_PARAMS.sidepanelOwnerNodeId]: string | null
}

const defaultState = {
  [QUERY_PARAMS.sidepanelEntityId]: null,
  [QUERY_PARAMS.sidepanelExpanded]: false,
  [QUERY_PARAMS.sidepanelTab]: null,
  [QUERY_PARAMS.sidepanelOwnerNodeId]: null
}

const validations = {
  [QUERY_PARAMS.sidepanelEntityId]: validateString,
  [QUERY_PARAMS.sidepanelExpanded]: validateBoolean,
  [QUERY_PARAMS.sidepanelTab]: validateEnum(SidepanelTab),
  [QUERY_PARAMS.sidepanelOwnerNodeId]: validateString
}

const serializations = {
  [QUERY_PARAMS.sidepanelEntityId]: (value: SidepanelQueryParamsState[typeof QUERY_PARAMS.sidepanelEntityId]) =>
    value ?? undefined,
  [QUERY_PARAMS.sidepanelExpanded]: (value: SidepanelQueryParamsState[typeof QUERY_PARAMS.sidepanelExpanded]) =>
    value ? 'true' : undefined,
  [QUERY_PARAMS.sidepanelTab]: (value: SidepanelQueryParamsState[typeof QUERY_PARAMS.sidepanelTab]) =>
    value ?? undefined,
  [QUERY_PARAMS.sidepanelOwnerNodeId]: (value: SidepanelQueryParamsState[typeof QUERY_PARAMS.sidepanelOwnerNodeId]) =>
    value ?? undefined
}

type UseSidepanel = {
  close: () => void
  id: string | null
  isExpanded: boolean
  setId: SetStateFn<string | null>
  setIsExpanded: SetStateFn<boolean | null>
  setSidepanelTab: SetStateFn<SidepanelTab | null>
  sidepanelOwnerNodeId: string | null
  sidepanelTab: SidepanelTab | null
}

export const useSidepanel = (): UseSidepanel => {
  const { addRecentlyVisitedEntity } = useContext(RecentlyVisitedEntitiesContext)

  const [sidepanelState, setSidepanelState] = useRecursiveQueryParamsReactRouter<SidepanelQueryParamsState>(
    defaultState,
    validations,
    serializations
  )

  const isMobile = useIsMobile()

  const id = sidepanelState[QUERY_PARAMS.sidepanelEntityId]
  const isExpanded = Boolean(sidepanelState[QUERY_PARAMS.sidepanelExpanded])
  const sidepanelTab = sidepanelState[QUERY_PARAMS.sidepanelTab]
  const sidepanelOwnerNodeId = sidepanelState[QUERY_PARAMS.sidepanelOwnerNodeId]

  const setId = setChildState(setSidepanelState, QUERY_PARAMS.sidepanelEntityId)
  const setIsExpanded = setChildState(setSidepanelState, QUERY_PARAMS.sidepanelExpanded)
  const setSidepanelTab = setChildState(setSidepanelState, QUERY_PARAMS.sidepanelTab)
  const setSidepanelOwnerNodeId = setChildState(setSidepanelState, QUERY_PARAMS.sidepanelOwnerNodeId)

  useEffect(() => {
    if (!isMobile) return
    setIsExpanded(false)
  }, [isMobile])

  useEffect(() => {
    if (!id) return

    addRecentlyVisitedEntity(id)
  }, [id])

  const reset = (): void => {
    setId(null)
    setIsExpanded(false)
    setSidepanelTab(null)
    setSidepanelOwnerNodeId(null)
  }

  return {
    id,
    isExpanded,
    sidepanelTab,
    sidepanelOwnerNodeId,
    setId,
    setIsExpanded,
    setSidepanelTab,
    close: reset
  }
}
