import { type FieldMergeFunction, type FieldPolicy, type FieldReadFunction } from '@apollo/client/index.js'
import { type EventConnection, type EventConnectionEdge, EventRelevance, type EventSettingsInput } from '@strise/types'
import { isString, omit } from 'lodash-es'
import { type ReadFieldFunction } from '@apollo/client/cache/core/types/common'
import { getBrowserGlobals } from '@strise/react-utils'

const eventsFeedCache: { merge: FieldMergeFunction; read: FieldReadFunction } = {
  read(existing: EventConnection | undefined, { readField }): EventConnection | undefined {
    if (!existing) return

    const edges = [...existing.edges].sort((a, b) => {
      const aPublished = String(readField('published', readField('node', a)))
      const bPublished = String(readField('published', readField('node', b)))
      return new Date(bPublished).getTime() - new Date(aPublished).getTime()
    })

    return {
      ...existing,
      edges,
      __typename: 'EventConnection'
    }
  },
  // @ts-expect-error
  merge(
    existing: EventConnection | undefined,
    incoming: EventConnection | undefined,
    {
      readField,
      variables
    }: { readField: ReadFieldFunction; variables: { settings: EventSettingsInput | undefined } | undefined }
  ): (EventConnection & { hasMoreIrrelevant?: boolean; hasMoreRelevant?: boolean }) | undefined {
    if (!incoming) return existing

    const existingIds =
      existing?.edges.map((edge: EventConnectionEdge) => {
        return readField('id', readField('node', edge))
      }) ?? []

    const incomingEdges = incoming.edges.filter((edge: EventConnectionEdge) => {
      return !existingIds.includes(readField('id', readField('node', edge)))
    })

    const edges = [...(existing?.edges ?? []), ...incomingEdges]

    return {
      ...incoming,
      edges,
      hasMoreRelevant:
        // @ts-expect-error See types/clientSchema.graphql
        variables?.settings.events.relevance === EventRelevance.Relevant
          ? Boolean(incoming.pageInfo.hasNextPage)
          : // @ts-expect-error See types/clientSchema.graphql
            Boolean(existing?.hasMoreRelevant),
      hasMoreIrrelevant:
        // @ts-expect-error See types/clientSchema.graphql
        variables?.settings.events.relevance === EventRelevance.NotRelevant
          ? Boolean(incoming.pageInfo.hasNextPage)
          : // @ts-expect-error See types/clientSchema.graphql
            Boolean(existing?.hasMoreIrrelevant)
    }
  }
}

export const eventsCache: FieldPolicy = {
  // @ts-expect-error
  keyArgs: (args: { settings: EventSettingsInput | undefined } | undefined, context) => {
    const key: unknown = context.variables?.eventsCacheKey
    const alias = context.field?.alias?.value

    if (!key && getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.NODE_ENV !== 'production') {
      throw new Error('`eventsCacheKey` variable must be set for events queries.')
    }

    const fieldsToOmit = alias === 'eventsFeed' ? ['relevance'] : []

    if (isString(key)) {
      return `${key}${JSON.stringify(omit(args?.settings?.events ?? {}, fieldsToOmit))}`
    }

    return ['settings']
  },
  read(existing: EventConnection | undefined, opts): EventConnection | undefined {
    switch (opts.field?.alias?.value) {
      case 'eventsFeed': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return eventsFeedCache.read(existing, opts)
      }
      default: {
        return existing
      }
    }
  },
  merge(
    existing: EventConnection | undefined,
    incoming: EventConnection | undefined,
    opts
  ): EventConnection | undefined {
    switch (opts.field?.alias?.value) {
      case 'eventsFeed': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return eventsFeedCache.merge(existing, incoming, opts)
      }
      default: {
        return incoming
      }
    }
  }
}
