import * as React from 'react'
import { cn } from '../../utils/className'
import { IconRadio, IconRadioSelected } from '../../icons/general'

export interface RadioProps extends Omit<React.HTMLAttributes<HTMLLabelElement>, 'onChange'> {
  checked?: boolean
  disabled?: boolean
  id?: string
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
}

// TODO - create palette variants with cva and support uncontrolled checked state
export const Radio = React.forwardRef(
  (
    { checked, className, disabled, id, name, onChange, value, ...props }: RadioProps,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <label className={cn('inline-block', className)} {...props}>
      <input
        className='absolute -z-10 m-0 size-px overflow-hidden opacity-0'
        ref={ref}
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={Boolean(checked)}
        aria-checked={Boolean(checked)}
        onChange={onChange}
        disabled={disabled}
      />
      {checked ? <IconRadioSelected /> : <IconRadio />}
    </label>
  )
)

Radio.displayName = 'Radio'
