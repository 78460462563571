import * as React from 'react'
import { type DataProps } from '@strise/react-utils'
import { cn, twObject } from '../../utils/className'
import { IconCross, IconDangerFillRound, IconSuccessFill } from '../../icons/general'
import { Typography } from '../Typography'
import { IconButton } from '../Button'

type ToastVariant = 'success' | 'error'

// TODO - refactor variants to use `cva` like `Button` etc (see `components/variants` directory).
const variantToColor = twObject({
  success: 'text-semantic-success-shade-10',
  error: 'text-semantic-danger-shade-10'
})

const variantToCloseButtonClass = twObject({
  success: 'bg-semantic-success-shade-10 hover:bg-semantic-success-shade-20 active:bg-semantic-success-shade-50',
  error: 'bg-semantic-danger-shade-10 hover:bg-semantic-danger-shade-20 active:bg-semantic-danger-shade-50'
})

const variantToBorderColor = twObject({
  success: 'border-semantic-success-main',
  error: 'border-semantic-danger-main'
})

export interface ToastProps extends DataProps {
  actions?: React.ReactNode
  children?: React.ReactNode
  className?: string
  label: React.ReactNode
  onClose?: () => void
  variant: ToastVariant
}
// TODO - refactor to React.forwardRef
export const Toast: React.FC<ToastProps> = ({ actions, children, className, label, onClose, variant, ...props }) => {
  return (
    <div
      className={cn(
        'flex w-fit min-w-[200px] max-w-[400px] flex-row justify-between border-b-4 border-solid bg-secondary-shade-90 text-white',
        variantToBorderColor[variant],
        className
      )}
      {...props}
    >
      <div className='flex flex-col items-start gap-4 p-4'>
        <div className={cn('inline-flex items-center', variantToColor[variant])}>
          {variant === 'success' && <IconSuccessFill className='mr-2 shrink-0' />}
          {variant === 'error' && <IconDangerFillRound className='mr-2 shrink-0' />}
          <Typography variant='body1'>{label}</Typography>
        </div>
        <Typography variant='body2'>{children}</Typography>
      </div>
      {(actions || onClose) && (
        <div className='flex shrink-0 items-start p-2'>
          {actions}
          {onClose && (
            <IconButton className={`p-1 ${variantToCloseButtonClass[variant]}`} variant='contained' onClick={onClose}>
              <IconCross size='md' />
            </IconButton>
          )}
        </div>
      )}
    </div>
  )
}
