import * as Sentry from '@sentry/react'
import { type SeverityLevel } from '@sentry/react'

type SentryEventType = 'AuthError' | 'GraphQLError' | 'NetworkError' | 'UnhandledError'

const captureMessage = (
  icon: string,
  eventType: SentryEventType,
  title: string | number,
  message: string | number,
  severity: SeverityLevel = 'error',
  requestId: string | undefined = undefined
): void => {
  Sentry.captureMessage([icon, `[${title}]`, message].join(' '), (scope) => {
    scope.setLevel(severity)

    scope.setTag('eventType', eventType)
    if (requestId) {
      scope.setTag('requestId', requestId)
    }

    return scope
  })
}

export const trackError = {
  exception: (error: Error) => Sentry.captureException(error),
  network: captureMessage.bind(null, '⚡', 'NetworkError'),
  graphQL: captureMessage.bind(null, '🚨', 'GraphQLError'),
  auth: captureMessage.bind(null, '🔒', 'AuthError'),
  unhandled: captureMessage.bind(null, '💥', 'UnhandledError')
}
