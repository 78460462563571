import { colors } from '@strise/ui-components-legacy'
import { isNil } from 'lodash-es'

export const creditScoreColorIntervals = [
  { from: 0, to: 20, color: colors.semanticRed },
  { from: 21, to: 29, color: colors.semanticOrange },
  { from: 30, to: 50, color: colors.semanticYellow },
  { from: 51, to: 70, color: colors.semanticGreen },
  { from: 71, to: 100, color: colors.semanticGreen }
]

export const extractCreditNotRated = (
  score: number | null | undefined,
  scoreCharacter: string | null | undefined
): boolean => {
  return isNil(score) && (!scoreCharacter || scoreCharacter === 'E')
}
