export const white = '#ffffff'

// Accents
export const gray = {
  100: '#161717',
  90: '#2E2E2E',
  80: '#454545',
  70: '#5D5D5D',
  60: '#747474',
  50: '#8B8B8B',
  40: '#A2A2A2',
  30: '#B9B9B9',
  20: '#D1D1D1',
  15: '#DDDDDD',
  10: '#E8E8E8',
  5: '#F3F3F3',
  0: '#FFFFFF'
}

export const blue = {
  100: '#0B1633',
  90: '#172B66',
  80: '#224199',
  70: '#2D56CC',
  60: '#3361E5',
  50: '#2D62FA',
  40: '#4C7BFF',
  30: '#6089FF',
  20: '#88A7FF',
  10: '#B0C4FF',
  5: '#D7E2FF',
  0: '#FFFFFF'
}

export const green = {
  100: '#0F1827',
  90: '#0C4527',
  80: '#097227',
  70: '#03CD26',
  60: '#01E429',
  50: '#00D100',
  40: '#40FB5C',
  30: '#66FC7D',
  20: '#99FDA8',
  10: '#DBF7D6',
  5: '#F2FDF2',
  0: '#FFFFFF'
}

export const yellow = {
  100: '#5D3805',
  90: '#78510B',
  80: '#9A7B16',
  70: '#BBA421',
  60: '#DDCD2C',
  50: '#FFD12E',
  40: '#FFF969',
  30: '#FFFA87',
  20: '#FFFB9B',
  10: '#FFF2C7',
  5: '#FFF8E1',
  0: '#FFFFFF'
}

export const orange = {
  100: '#4F1C20',
  90: '#7E2517',
  80: '#B54510',
  70: '#DA5A0C',
  60: '#ED650A',
  50: '#FF6F07',
  40: '#FF7E20',
  30: '#FF8C39',
  20: '#FFA96B',
  10: '#FFC69C',
  5: '#FFE2CD'
}

export const pink = {
  100: '#33131F',
  90: '#830A36',
  80: '#C22760',
  70: '#EB4E87',
  60: '#F55791',
  50: '#FF619B',
  40: '#FF81AF',
  30: '#FFA0C3',
  20: '#FFC0D7',
  10: '#FFD0E1',
  5: '#FFDFEB'
}

export const purple = {
  100: '#1B0C33',
  90: '#361866',
  80: '#502399',
  70: '#6B2FCC',
  60: '#7935E5',
  50: '#863CFF',
  40: '#9E62FF',
  30: '#B689FF',
  20: '#CFB1FF',
  10: '#DBC4FF',
  5: '#E7D8FF'
}

// Semantics
export const semanticBlue = {
  100: '#205480',
  50: '#408CFF',
  10: '#DFF1FF',
  5: '#F3FAFF'
}

export const semanticGreen = {
  100: '#004C00',
  50: '#00D100',
  10: '#DBF7D6',
  5: '#F2FDF2'
}

export const semanticYellow = {
  100: '#7F6B28',
  50: '#FFD12E',
  10: '#FFF2C7',
  5: '#FFF8E1'
}

export const semanticOrange = {
  100: '#803900',
  50: '#FF7300',
  10: '#FFC799',
  5: '#FFEFE2'
}

export const semanticRed = {
  100: '#6B000F',
  75: '#A90008',
  50: '#E60000',
  10: '#FFD8DD',
  5: '#FFF1F1'
}

export const semanticGray = {
  100: '#161717',
  50: '#8B8B8B',
  10: '#D1D1D1',
  5: '#E8E8E8'
}

const colors = [blue, orange, pink, purple, semanticBlue, semanticGreen, semanticOrange, semanticRed, semanticGray]
const bgColors1 = colors.map((color) => color[5])
const bgColors2 = colors.map((color) => color[10])
export const bgColors = [white, ...bgColors1, ...bgColors2]
