import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconLink = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M20.517 3.43a1.546 1.546 0 0 0-2.138-.046l-4.203 3.853-1.352-1.474 4.203-3.853a3.546 3.546 0 0 1 4.938 5.088l-6.983 7.174a3.645 3.645 0 0 1-5.19.035l1.415-1.414a1.645 1.645 0 0 0 2.342-.016l6.982-7.174a1.546 1.546 0 0 0-.014-2.172Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3.457 20.439c.581.58 1.521.585 2.107.01l4.235-4.162 1.402 1.426-4.235 4.162a3.496 3.496 0 0 1-4.943-4.945L9.51 9.315a3.659 3.659 0 0 1 5.196-.022l-1.414 1.414a1.659 1.659 0 0 0-2.356.01L3.45 18.332a1.496 1.496 0 0 0 .008 2.107Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconLink.displayName = 'IconLink'
