import * as React from 'react'
import { AuthError, useLogout, useUserId } from '@strise/app-shared'
import {
  useCurrentUserSettingsQuery,
  useResetUserSettingsMutation,
  useUpdateUserSettingsMutation
} from '~/graphqlOperations'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { reduceFlatten } from '@strise/ts-utils'
import type { SetStateFn } from '@strise/react-utils'
import type { UserSettingsFragment } from '~/graphqlTypes'
import { isFunction } from 'lodash-es'
import { settingsToInput } from '~/utils/settingsUtils'

export const CurrentUserSettingsContextProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const currentUserId = useUserId()
  const logout = useLogout()
  const [resetFilterSettings] = useResetUserSettingsMutation({
    variables: {
      user: currentUserId,
      events: true,
      opportunity: true,
      listView: true,
      review: true,
      application: false,
      privacy: false
    }
  })

  const { data, refetch } = useCurrentUserSettingsQuery({
    variables: {},
    onError: (error) => {
      const statusCode = error.networkError && 'statusCode' in error.networkError && error.networkError.statusCode

      if (statusCode === 403) {
        resetFilterSettings()
          .then(async () => await refetch())
          .catch(() => {
            logout(AuthError.AuthenticationFailed)
          })
      }
    }
  })

  const settings = data?.currentUser?.userSettings
  const features = data?.currentUser?.features

  const [update] = useUpdateUserSettingsMutation()

  const saveSettings: SetStateFn<UserSettingsFragment> = async (valueOrUpdater): Promise<void> => {
    if (!settings) return
    // If valueOrUpdater is a function, call it with the current state, otherwise just use the value directly
    const value = isFunction(valueOrUpdater) ? valueOrUpdater(settings) : valueOrUpdater

    const input = settingsToInput(value)

    await update({
      variables: {
        user: currentUserId,
        input
      },
      optimisticResponse: {
        updateUserSettings: {
          currentUser: {
            id: currentUserId,
            userSettings: {
              ...value,
              __typename: 'UserSettings'
            },
            __typename: 'User'
          },
          __typename: 'MutationQuery'
        },
        __typename: 'Mutation'
      }
    })
  }

  const userSettingsContextValue = React.useMemo(() => {
    if (!settings) return null
    if (!features) return null

    const featuresMap = features.features
      .map((feature) => ({ [feature.kind]: feature.isEnabled }))
      .reduce(reduceFlatten, {})
    return {
      features: featuresMap,
      enabledContentLanguages: features.enabledContentLanguages,
      settings,
      saveSettings
    }
  }, [data])

  if (!userSettingsContextValue) return null

  return (
    <CurrentUserSettingsContext.Provider value={userSettingsContextValue}>
      {children}
    </CurrentUserSettingsContext.Provider>
  )
}
