import * as React from 'react'
import { ListViewField, SortOrdering } from '@strise/types'
import { type PortfolioField } from '~/views/Portfolio/utils/portfolioTableColumns'
import { type SortField } from '@strise/app-shared'

const columnFieldToListViewField: { [key in PortfolioField]: ListViewField } = {
  name: ListViewField.Name,
  assignees: ListViewField.Assignee,
  employees: ListViewField.Employees,
  revenue: ListViewField.Revenue,
  status: ListViewField.Status,
  statusModified: ListViewField.StatusModified,
  tags: ListViewField.Tags,
  flags: ListViewField.Flag,
  hq: ListViewField.Location
}

export const useApiSort = (
  sort: Array<SortField<PortfolioField>>
): Array<{ field: ListViewField; ordering: SortOrdering }> => {
  return React.useMemo(() => {
    return sort
      .map(({ direction, field }) => {
        return {
          field: columnFieldToListViewField[field],
          ordering: direction === 'asc' ? SortOrdering.Ascending : SortOrdering.Descending
        }
      })
      .toReversed()
  }, [sort])
}
