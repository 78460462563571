import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconLocation = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 3C8.148 3 5 6.124 5 9.964c0 1.09.371 2.15 1.053 3.001L12 20.4l5.947-7.434A4.804 4.804 0 0 0 19 9.964C19 6.124 15.852 3 12 3ZM3 9.964C3 5.007 7.055 1 12 1s9 4.007 9 8.964a6.804 6.804 0 0 1-1.491 4.25L12 23.602l-7.509-9.386A6.804 6.804 0 0 1 3 9.964Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconLocation.displayName = 'IconLocation'
