import { EntityMeta } from '~/components/EntityMeta/EntityMeta'
import * as React from 'react'
import { type BaseReviewCompanyFragment } from '~/graphqlTypes'
import { TestIDs } from '~/utils/testIDs'

export const ReviewCardHeader = ({
  company,
  filterHeight
}: {
  company: BaseReviewCompanyFragment
  filterHeight: number | undefined
}): React.ReactNode => {
  return (
    <div className='sticky z-[4] overflow-hidden' style={{ top: filterHeight }} data-id={TestIDs.Review.Card.Header}>
      <EntityMeta className='bg-background-paper px-4 py-2' variant='large' entity={company} link />
    </div>
  )
}
