import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconUnknown = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M3 3h18v18H3V3ZM1 3a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3Zm9.677 11.571h1.642v-1.577c0-.428.172-.6.604-.6A3.112 3.112 0 0 0 16 9.24C16 7.269 14.67 6 12.681 6H9v1.543h3.422c1.21 0 1.884.668 1.884 1.697s-.674 1.697-1.71 1.697h-.191c-1.21 0-1.728.429-1.728 1.629v2.005ZM10.59 18h1.815v-1.886H10.59V18Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconUnknown.displayName = 'IconUnknown'
