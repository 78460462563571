import * as React from 'react'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/system'
import { createGlobalStyle } from 'styled-components'
import { darkTheme, theme } from './theme'
import { useDarkMode } from './contexts/DarkModeContext/DarkModeContext'
import { DarkModeContextProvider } from './contexts/DarkModeContext/DarkModeContextProvider'

const BaseThemeStyle = createGlobalStyle`
  body {
    ${theme.typography.body1}
    color: ${theme.palette.text.primary};
    background-color: ${theme.palette.background.default};
  }

  body::backdrop {
    background-color: ${theme.palette.background.default};
  }

  strong, b {
    font-weight: ${theme.typography.fontWeightBold};
  }
`

const ThemeProvider: React.FC<{
  children: React.ReactNode
  overrides?: object
}> = ({ children, overrides = {} }) => {
  const { darkMode } = useDarkMode()
  const muiTheme = createTheme(darkMode ? darkTheme : theme, overrides)

  return (
    <MuiThemeProvider key={String(darkMode)} theme={muiTheme}>
      <BaseThemeStyle />
      {children}
    </MuiThemeProvider>
  )
}

export const UiComponentsLegacyProvider: React.FC<{
  children: React.ReactNode
  themeOverrides?: object
}> = ({ children, themeOverrides = {} }) => (
  <DarkModeContextProvider>
    <ThemeProvider overrides={themeOverrides}>{children}</ThemeProvider>
  </DarkModeContextProvider>
)
