import { cn, IconButton, IconInfo, IconPenBox, Tooltip, Typography } from '@strise/ui-components'
import {
  type AddressFragment,
  type CompanyNetworkEntityPersonFragment,
  type PepInfoFragment,
  type PepRoleFragment,
  type PersonBaseFragment
} from '~/graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate, NationFlag } from '@strise/app-shared'
import { AccordionV2 } from '@strise/ui-components-legacy'
import { type DivProps, type SetStateFn } from '@strise/react-utils'
import { EntityDispositionStatusKind } from '@strise/types'
import { extractAddress, hasAddressValues } from '~/utils/address'
import { intervalToDuration, parseISO } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { PepDispositionDialog } from '~/components/PepAndSanctions/PepDispositionDialog'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { PepDispositionBadge } from '~/components/PepAndSanctions/PepDispositionBadge'
import {
  systemSuggestedFalsePepPredicate,
  userConfirmedFalsePepPredicate
} from '~/components/PepAndSanctions/pepDispositionUtils'
import { TruncatedList } from '~/components/TruncatedList'
import { AssociatedCompanyLink } from '~/components/PepAndSanctions/AssociatedCompanyLink'
import { SidepanelTab } from '~/utils/urls'

const RoleDurationText: React.FC<{
  role: PepRoleFragment
}> = ({ role }) => {
  const isPresent = !role.to

  const from = role.from ?? ''
  const to = role.to ?? ''

  if (!from && !to) return null

  const startDate = from ? formatDate(from, { day: false }) : t`Unknown`
  const endDate = isPresent ? t`Present` : formatDate(to, { day: false })
  const rolePeriod = `${startDate} ${endDate && `- ${endDate}`}`

  const parsedStartDate = from ? parseISO(from) : null
  const parsedEndDate = to ? parseISO(to) : new Date()

  const roleDuration = parsedStartDate ? intervalToDuration({ start: parsedStartDate, end: parsedEndDate }) : null

  const years = roleDuration?.years ? `${roleDuration.years} ${t`yr`}` : null
  const months = roleDuration?.months ? `${roleDuration.months} ${t`m`}` : null
  const days = roleDuration?.days ? `${roleDuration.days} ${t`d`}` : null

  const duration = [years, months, days].filter(Boolean).join(' ')

  return (
    <div className='flex flex-col items-end text-right'>
      <Typography>{rolePeriod}</Typography>
      <Typography className='text-text-secondary' variant='aLabelSmall'>
        {duration}
      </Typography>
    </div>
  )
}

export const PepPersonAddressInfo = ({ address }: { address: AddressFragment }): React.ReactNode => {
  if (!hasAddressValues(address)) return null

  return (
    <div className='flex items-center'>
      <IconInfo className='mr-1 text-text-secondary' size='md' />
      <Typography className='text-text-secondary' variant='body2'>
        {extractAddress(address)}
      </Typography>
    </div>
  )
}

const PepRole = ({ role }: { role: PepRoleFragment }): React.ReactNode => {
  return (
    <div className='flex w-full items-center justify-between gap-1 py-2'>
      <div>
        <Typography variant='body1'>{role.roleCategory}</Typography>
        <div className='flex items-center gap-1'>
          {role.country && <NationFlag countryIsoCode={role.country.isoAlpha2Code} className='size-4' />}
          <Typography className='text-text-secondary' variant='aLabelSmall'>
            {role.localizedRoleDescription}
          </Typography>
        </div>
      </div>
      <RoleDurationText role={role} />
    </div>
  )
}

const PepRelation = ({
  expanded,
  relation
}: {
  expanded?: boolean
  relation: PepInfoFragment['relations'][number]
}): React.ReactNode => {
  const [openSection, setOpenSection] = useState<boolean>(!!expanded)

  const handleToggle = (): void => setOpenSection(!openSection)

  return (
    <div>
      <div className='flex w-full items-center justify-between pt-2'>
        <Typography variant='body1'>
          {relation.relationDescription} <strong>{relation.relatedPep.name}</strong>
        </Typography>
      </div>
      <PepPersonAddressInfo address={relation.relatedPep.address} />
      <AccordionV2
        open={openSection}
        toggleLabel={expanded ? null : t`More info`}
        label={
          <Typography variant='aLabelSmallBold'>
            <Trans>{relation.relatedPep.name} is a PEP due to roles:</Trans>
          </Typography>
        }
        toggle={expanded ? null : handleToggle}
      >
        <>
          {!relation.relatedPep.roles.length && <Typography>{t`Unknown roles`}</Typography>}

          {relation.relatedPep.roles.map((role, index) => (
            <PepRole key={index} role={role} />
          ))}
        </>
      </AccordionV2>
    </div>
  )
}

const OpenDispositionButton = ({
  enableEdit,
  filteredPepInfos,
  person,
  setIsDispositionDialogOpen
}: {
  enableEdit: boolean
  filteredPepInfos: PepInfoFragment[]
  person: PersonBaseFragment | null
  setIsDispositionDialogOpen: SetStateFn<boolean>
}): React.ReactNode => {
  if (!person) return null
  if (!filteredPepInfos.length) return null

  if (!enableEdit) {
    return (
      <Typography className='mt-2' variant='body2'>
        <Trans>Open person to edit</Trans>
      </Typography>
    )
  }

  return (
    <Tooltip content={t`Open PEP dispositioning`}>
      <IconButton
        variant='ghost'
        className='text-gray-60'
        data-track='Open PEP disposition dialog'
        onClick={() => setIsDispositionDialogOpen(true)}
      >
        <IconPenBox />
      </IconButton>
    </Tooltip>
  )
}

const PepInfoContentOld = ({
  className,
  expanded,
  noLink,
  pepInfos,
  person,
  ...props
}: {
  expanded?: boolean
  noLink?: boolean
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | null
} & DivProps): React.ReactNode => {
  // We return an empty pep if no results to "prove" that we've checked the person
  // Therefore, we need to filter it out here
  const filteredPepInfos = pepInfos.filter((info) => {
    return info.rca || info.pep
  })

  const pepInfosWithPep = filteredPepInfos.filter((info) => info.pep)
  const pepInfosWithRca = filteredPepInfos.filter((info) => info.rca)

  return (
    <div className={cn('w-full', className)} {...props}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          {person && !noLink && <EntityLink entity={person} />}
          {person && noLink && <Typography>{person.name}</Typography>}
          {!filteredPepInfos.length && (
            <Typography className='ml-1'>
              <Trans>is not a PEP</Trans>
            </Typography>
          )}
        </div>
      </div>

      {!!filteredPepInfos.length && (
        <div className='flex flex-col gap-4'>
          {!!pepInfosWithPep.length && (
            <div>
              <Typography className='text-text-secondary'>
                <Trans>Is PEP due to roles:</Trans>
              </Typography>
              <div className='flex flex-col gap-2'>
                {pepInfosWithPep.map((pepInfo) => {
                  return (
                    <div key={pepInfo.id}>
                      {!pepInfo.roles.length && <Typography key={pepInfo.id}>{t`Unknown roles`}</Typography>}

                      {!!pepInfo.roles.length &&
                        pepInfo.roles.map((role, index) => <PepRole key={index} role={role} />)}
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          {!!pepInfosWithRca.length && (
            <div>
              <Typography className='text-text-secondary'>
                <Trans>Is RCA due to relationships:</Trans>
              </Typography>
              <div className='flex flex-col gap-4'>
                {pepInfosWithRca.map((pepInfo) => {
                  return (
                    <div key={pepInfo.id}>
                      {!pepInfo.relations.length && (
                        <Typography key={pepInfo.id}>{t`Unknown relationships`}</Typography>
                      )}

                      {pepInfo.relations.map((relation, index) => (
                        <PepRelation key={index} relation={relation} expanded={expanded} />
                      ))}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export const PepPersonContent = ({
  associatedCompanies,
  className,
  currentCompanyId,
  enableEdit,
  expanded,
  noLink,
  pepInfos,
  person,
  showSystemSuggestedFalse,
  ...props
}: {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies'] | null | undefined
  currentCompanyId: string | null | undefined
  enableEdit: boolean
  expanded?: boolean
  noLink?: boolean
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment | null
  showSystemSuggestedFalse: boolean
} & DivProps): React.ReactNode => {
  const [isDispositionDialogOpen, setIsDispositionDialogOpen] = React.useState(false)
  const features = useCurrentUserFeatures()

  if (!features.PEP_DISPOSITIONING) {
    return (
      <PepInfoContentOld
        className={className}
        expanded={expanded}
        pepInfos={pepInfos}
        person={person}
        noLink={noLink}
        {...props}
      />
    )
  }

  // Filter and process PEP information
  const filteredPepInfos = pepInfos.filter((info) => info.rca || info.pep)

  const pepInfosToShow = filteredPepInfos.filter((info) => {
    if (info.disposition?.status === EntityDispositionStatusKind.ConfirmedFalse) return false
    if (showSystemSuggestedFalse) return true
    if (!info.matchInfoAnalysis?.suggestedTruePositive) return false

    return true
  })

  const pepInfosWithPep = pepInfosToShow.filter((info) => info.pep)
  const pepInfosWithRca = pepInfosToShow.filter((info) => info.rca)

  const firstSuggestedFalseInfo = filteredPepInfos.find(systemSuggestedFalsePepPredicate)
  const firstConfirmedFalseInfo = filteredPepInfos.find(userConfirmedFalsePepPredicate)

  const emptyPepInfo = firstSuggestedFalseInfo ?? firstConfirmedFalseInfo ?? null

  return (
    <>
      <div className={cn('w-full', className)} {...props}>
        <div className='flex items-start justify-between'>
          <div className='flex items-center'>
            {person && !noLink && (
              <EntityLink entity={person} noFlags className='self-end' sidepanelTab={SidepanelTab.PepsAndSanctions} />
            )}
            {person && noLink && <Typography>{person.name}</Typography>}
            {!pepInfosToShow.length && (
              <Typography className='ml-1'>
                <Trans>is not a PEP</Trans>
              </Typography>
            )}
          </div>
          <div className='space-y-2 text-right'>
            <OpenDispositionButton
              filteredPepInfos={filteredPepInfos}
              person={person}
              enableEdit={enableEdit}
              setIsDispositionDialogOpen={setIsDispositionDialogOpen}
            />
            {associatedCompanies && currentCompanyId && (
              <div className='flex flex-col'>
                {associatedCompanies.map((c) => (
                  <AssociatedCompanyLink key={c.id} currentCompanyId={currentCompanyId} associatedCompany={c} />
                ))}
              </div>
            )}
          </div>
        </div>
        {!pepInfosToShow.length && <PepDispositionBadge pepInfo={emptyPepInfo} person={person} />}

        {!!pepInfosToShow.length && (
          <div className='flex flex-col gap-4'>
            {!!pepInfosWithPep.length && (
              <div>
                <Typography className='text-text-secondary'>
                  <Trans>Is PEP due to roles:</Trans>
                </Typography>
                <div className='flex flex-col gap-2'>
                  {pepInfosWithPep.map((pepInfo) => (
                    <div key={pepInfo.id}>
                      {!pepInfo.roles.length && <Typography>{t`Unknown roles`}</Typography>}

                      {!!pepInfo.roles.length && (
                        <TruncatedList
                          items={pepInfo.roles}
                          truncateAfter={3}
                          context='Pep roles'
                          className='flex flex-col gap-2 p-0'
                        >
                          {(role, index) => <PepRole key={index} role={role} />}
                        </TruncatedList>
                      )}

                      <PepDispositionBadge pepInfo={pepInfo} person={person} />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {!!pepInfosWithRca.length && (
              <div>
                <Typography className='text-text-secondary'>
                  <Trans>Is RCA due to relationships:</Trans>
                </Typography>
                <div className='flex flex-col gap-4'>
                  {pepInfosWithRca.map((pepInfo) => {
                    return (
                      <div key={pepInfo.id}>
                        {!pepInfo.relations.length && <Typography>{t`Unknown relationships`}</Typography>}

                        {pepInfo.relations.map((relation, index) => (
                          <PepRelation key={index} relation={relation} expanded={expanded} />
                        ))}

                        <PepDispositionBadge pepInfo={pepInfo} person={person} />
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {isDispositionDialogOpen && person && (
        <PepDispositionDialog onClose={() => setIsDispositionDialogOpen(false)} pepInfos={pepInfos} person={person} />
      )}
    </>
  )
}
