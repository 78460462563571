import * as React from 'react'
import { type DataProps } from '@strise/react-utils'
import { Button, type ButtonProps, IconButton } from '../Button'
import { Typography, type TypographyProps } from '../Typography'
import { type IconComponent, type IconPropsWithRef } from '../../utils/iconUtils'
import { IconOpenInTab } from '../../icons/general'
import { cn } from '../../utils/className'

export type LinkProps = TypographyProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & DataProps

export const Link = React.forwardRef<HTMLElement, LinkProps>(({ className, ...props }, ref) => (
  <Typography className={cn('text-inherit hover:underline', className)} ref={ref} component='a' {...props} />
))

Link.displayName = 'Link'

export type LinkButtonProps = ButtonProps & Omit<LinkProps, 'variant'>

export const LinkButton = React.forwardRef<HTMLElement, LinkButtonProps>(({ children, className, ...props }, ref) => (
  <Button className={className} asChild variant='ghost' palette='secondary' {...props}>
    <Link ref={ref} className='no-underline'>
      {children}
    </Link>
  </Button>
))

LinkButton.displayName = 'LinkButton'

export type ExternalLinkProps = {
  Icon?: IconComponent | null
  href: string
  iconProps?: IconPropsWithRef
} & LinkProps

export const ExternalLink = ({
  Icon = IconOpenInTab,
  children,
  className,
  href,
  iconProps,
  ...props
}: ExternalLinkProps): React.ReactNode => {
  const transformedHref = href.startsWith('http') ? href : `https://${href}`
  return (
    <Link
      className={cn('underline', className)}
      target='_blank'
      rel='noopener noreferrer'
      href={transformedHref}
      {...props}
    >
      {children}
      {Icon && <Icon size='sm' {...iconProps} className={cn('ml-1', iconProps?.className)} />}
    </Link>
  )
}

type ExternalLinkIconButtonProps = Pick<ExternalLinkProps, 'href'> & ButtonProps

export const ExternalLinkIconButton: React.FC<ExternalLinkIconButtonProps> = ({
  children,
  className,
  href,
  ...props
}) => (
  <IconButton asChild className={cn('no-underline', className)} {...props}>
    <ExternalLink Icon={null} href={href}>
      {children}
    </ExternalLink>
  </IconButton>
)
