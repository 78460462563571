import * as React from 'react'
import { cn } from '../../../utils/className'
import { type IconProps, iconVariants } from '../../../utils/iconUtils'

export const IconCollapseAnimatedA = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref) => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <rect x='8' y='2' width='8' height='20' stroke='currentColor' strokeWidth='2' />
      <path d='M0.999999 8L5 12L1 16' stroke='currentColor' strokeWidth='2' />
      <path d='M23 16L19 12L23 8' stroke='currentColor' strokeWidth='2' />
    </svg>
  )
)
IconCollapseAnimatedA.displayName = 'IconCollapseAnimatedA'
