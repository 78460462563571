import { Trans } from '@lingui/macro'
import { cn, Typography } from '@strise/ui-components'
import { GrowAssigneesFilter } from '~/views/Grow/components/GrowAssigneesFilter'
import { GrowTagsFilter } from '~/views/Grow/components/GrowTagsFilter'
import * as React from 'react'
import { GrowLocationRestriction } from '~/views/Grow/components/Location/GrowLocationRestriction'
import { GrowAccountantFilter } from '~/views/Grow/components/GrowAccountantFilter'
import { GrowCollateralCreditorFilter } from '~/views/Grow/components/GrowCollateralCreditorFilter'
import { GrowEmployeeFilter } from '~/views/Grow/components/GrowEmployeeFilter'
import { GrowFlagFilter } from '~/views/Grow/components/GrowFlagFilter'
import { GrowInceptionFilter } from '~/views/Grow/components/GrowInceptionFilter'
import { GrowIndustryFilter } from '~/views/Grow/components/Industry/GrowIndustryFilter'
import { GrowLegalFormFilter } from '~/views/Grow/components/GrowLegalFormFilter'
import { GrowSettingsFinancials } from '~/views/Grow/components/GrowSettingsFinancials'
import { GrowRealEstateCollateralCreditorFilter } from '~/views/Grow/components/GrowRealEstateCollateralCreditorFilter'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const GrowSettingsPanel = React.forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
  const features = useCurrentUserFeatures()

  return (
    <div className='flex flex-col gap-4' ref={ref}>
      <div className='flex flex-col gap-4 rounded bg-background-paper p-8'>
        <div className='flex flex-col gap-2'>
          <Typography variant='subtitle1'>
            <Trans>Portfolio</Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans>
              Results are based on Team Portfolio. Use assignees and/or tags to filter the portfolio and narrow the
              results.
            </Trans>
          </Typography>
        </div>
        <GrowAssigneesFilter />
        <GrowTagsFilter />
      </div>

      <div className='grid gap-8 rounded bg-background-paper p-8'>
        <div className='flex flex-col gap-2'>
          <Typography variant='subtitle1'>
            <Trans>Filters</Trans>
          </Typography>
          <Typography variant='body2'>
            <Trans>
              Apply filters to tweak the results for Grow opportunities. Adjustments are also reflected in the side
              panel.
            </Trans>
          </Typography>
        </div>
        <GrowLocationRestriction />
        <GrowSettingsFinancials />
        <GrowIndustryFilter />
        <GrowEmployeeFilter />
        <GrowInceptionFilter />
        <GrowAccountantFilter />
        {(features.COLLATERAL_DATA_LOSOREREGISTERET || features.CREDIT_DATA) && <GrowCollateralCreditorFilter />}
        {features.REAL_ESTATE_DATA_KARTVERKET && <GrowRealEstateCollateralCreditorFilter />}
        <GrowLegalFormFilter />
        <GrowFlagFilter />
      </div>
    </div>
  )
})

interface SettingProps {
  children: React.ReactNode
  className?: string
  description?: string
  title: string
}

export const GrowSettingsSections = ({
  children,
  className,
  description,
  title,
  ...props
}: SettingProps): React.ReactNode => {
  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      <div>
        <Typography variant='subtitle2'>{title}</Typography>
        {description && (
          <Typography className='mb-4 block text-text-secondary' variant='aLabelSmall'>
            {description}
          </Typography>
        )}
      </div>
      {children}
    </div>
  )
}
