import {
  type BaseEntityLikeFragment,
  type CountryFragment,
  type EntityLikeMetaFragment,
  type ShareholderFragment
} from '../graphqlTypes'
import { filterNullishValues } from '@strise/ts-utils'
import { type ContentLanguage } from '@strise/types'
import { uniqBy } from 'lodash-es'
import { countryKindToContentLanguage } from './country'

export const extractIsGlobalEntity = (
  entity: Pick<EntityLikeMetaFragment, '__typename'> | Pick<ShareholderFragment, '__typename'>
): entity is BaseEntityLikeFragment & { __typename: 'GlobalCompany' | 'GlobalPerson' } => {
  return entity.__typename.startsWith('Global')
}

export const extractIsCompany = (
  entity: BaseEntityLikeFragment
): entity is BaseEntityLikeFragment & { __typename: 'Company' | 'GlobalCompany' } => {
  return entity.__typename === 'Company' || entity.__typename === 'GlobalCompany'
}

export const extractIsGlobalV2 = (
  entity: BaseEntityLikeFragment
): entity is BaseEntityLikeFragment & { __typename: 'Person' | 'Company' } => {
  return (
    (entity.__typename === 'Person' && entity.isGlobalPerson) ||
    (entity.__typename === 'Company' && entity.isGlobalCompany)
  )
}

export const extractIsPerson = (
  entity: BaseEntityLikeFragment
): entity is BaseEntityLikeFragment & { __typename: 'Person' | 'GlobalPerson' } => {
  return entity.__typename === 'Person' || entity.__typename === 'GlobalPerson'
}

export const extractIsActive = (entity: BaseEntityLikeFragment): boolean => {
  return 'lifeStatus' in entity ? entity.lifeStatus.isActive : true
}

export const extractEntityCountries = (entity: EntityLikeMetaFragment): CountryFragment[] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const countries: CountryFragment[] = [
    // Make sure we don't return an array with a nullish value
    'primaryCountry' in entity && entity.primaryCountry ? [entity.primaryCountry] : [],
    'countries' in entity ? entity.countries : [],
    // @ts-expect-error
    'headquartersLocation' in entity ? (entity.headquartersLocation?.countries ?? []) : []
  ].flat()

  // Casting based on filter
  return filterNullishValues(uniqBy(countries, (country) => country.id))
}

export const extractEntityContentLanguage = (entity: EntityLikeMetaFragment): ContentLanguage | null | undefined => {
  if (!('primaryCountry' in entity) || !entity.primaryCountry) {
    return null
  }

  return countryKindToContentLanguage[entity.primaryCountry.kind]
}
