import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconInfoBlue = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 22 22'
      {...props}
    >
      <path
        fill='#408CFF'
        fillRule='evenodd'
        d='M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11Zm1-14V6h-2v2h2Zm0 8V9h-2v7h2Z'
        clipRule='evenodd'
      />
      <path fill='#fff' d='M12 16h-2V9h2v7ZM12 8h-2V6h2v2Z' />
    </svg>
  )
)
IconInfoBlue.displayName = 'IconInfoBlue'
