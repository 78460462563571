import { ContentLanguage, DisplayLanguage } from '@strise/types'

export const displayLanguageToTitle: { [key in DisplayLanguage]?: string } = {
  [DisplayLanguage.English]: 'English',
  [DisplayLanguage.Norwegian]: 'Norsk',
  [DisplayLanguage.Swedish]: 'Svenska',
  [DisplayLanguage.Danish]: 'Dansk'
}

export const languageToCountryCode: { [key in ContentLanguage]?: string } = {
  [ContentLanguage.Danish]: 'dk',
  [ContentLanguage.English]: 'gb',
  [ContentLanguage.Swedish]: 'se',
  [ContentLanguage.Norwegian]: 'no',
  [ContentLanguage.German]: 'de',
  [ContentLanguage.Spanish]: 'es',
  [ContentLanguage.French]: 'fr',
  [ContentLanguage.Finnish]: 'fi'
}
