import { type ReviewSanctionValueFragment, type SanctionInfoFragment } from '~/graphqlTypes'
import { SanctionsEntityContent } from '~/components/PepAndSanctions/SanctionsEntityContent'
import React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { reviewEntityToEntity } from '~/components/Review/reviewValueTransformers'

export const ReviewSanction = ({ item }: { item: ReviewSanctionValueFragment }): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const entity = item.value.entity ? reviewEntityToEntity(item.value.entity) : null

  const sanctionInfo: SanctionInfoFragment = {
    ...item.value,
    __typename: 'SanctionInfoV2'
  }

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <SanctionsEntityContent
        entity={entity}
        entityName={item.value.entityName}
        sanctionInfo={sanctionInfo}
        associatedCompanies={null}
        currentCompanyId={null}
      />
    </ReviewItem>
  )
}
