import type * as React from 'react'
import {
  type CustomMetaFragment,
  type DataSourceFragment,
  type TableFragment,
  type TableLabelFragment,
  type TableRowFragment,
  type TableRowValueFragment,
  type TableRowValueReviewMetaFragment,
  type BaseEntityLikeFragment
} from '~/graphqlTypes'
import { type TableRowKind } from '@strise/types'
import { type ExternalLinkProps, type IconComponent } from '@strise/ui-components'
import type { ReviewState } from '~/utils/reviewUtils'
import type { SetStateFn } from '@strise/react-utils'

export interface TransformedApiTableItem {
  Icon?: IconComponent | null
  containerClassName?: string
  content?: React.ReactNode
  customMeta?: CustomMetaFragment | null
  dataTrack?: string | null
  handleCopy?: () => void
  hideBorder?: boolean
  href?: string | null
  label?: React.ReactNode
  linkProps?: Omit<ExternalLinkProps, 'href'>
  reviewMeta?: TableRowValueReviewMetaFragment | null
  tooltipContent?: React.ReactNode
}

export interface TransformedApiTableRow {
  customCheckboxName?: string
  disabled?: boolean
  hide?: boolean
  key: string
  kind: TableRowKind
  label: React.ReactNode
  paginationThreshold: number | null | undefined
  sections: TransformedApiTableSection[]
  showEmpty?: boolean
}

export interface TransformedApiTableSection {
  inlineLabel?: boolean
  items: TransformedApiTableItem[]
  label: React.ReactNode
  paginationThreshold: number | null | undefined
}

export interface TransformedApiTable {
  dataSources: DataSourceFragment[]
  description: React.ReactNode
  lastModifiedAt?: Date | null
  rows: TransformedApiTableRow[]
  title: React.ReactNode
}

interface EditRoleContext {
  comment?: string | null
  existingRole?: boolean
  periodFrom?: string | null
  periodTo?: string | null
  roleTitleId?: string
  type: 'EditRole'
}

export type EditContext = EditRoleContext

export type handleEditRowFn = (entity: BaseEntityLikeFragment | null, context?: EditContext) => void

export interface TableValueContentProps {
  editMode?: boolean
  entity: BaseEntityLikeFragment
  handleEdit?: handleEditRowFn
  reviewState?: ReviewState
  sectionEntity?: BaseEntityLikeFragment
  setReviewState?: SetStateFn<ReviewState>
  value: TableRowValueFragment
}

export interface TableLabelContentProps {
  editMode?: boolean
  handleEdit?: handleEditRowFn
  label: TableLabelFragment
}

export const extractFilteredTableRows = (table: TableFragment): TableRowFragment[] => {
  const filteredRows = table.rows.filter((row) => {
    const values = row.sections.flatMap((section) => section.values)
    return !!values.length
  })

  return filteredRows
}

export const extractFilteredTransformedApiTableRows = (table: TransformedApiTable): TransformedApiTableRow[] => {
  const filteredRows = table.rows.filter((row) => {
    const items = row.sections.flatMap((section) => section.items)
    return !!items.length
  })

  return filteredRows
}

export const mkTableValueLabel = (value: TableRowValueFragment): string | null => {
  switch (value.__typename) {
    case 'TableRowValueLabeledString': {
      return value.label
    }
  }
  return null
}
