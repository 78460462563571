import { userRole, UserRoleEnum } from '@strise/app-shared'
import { createContext, useContext } from '@strise/react-utils'
import { type TeamUserStatus } from '@strise/types'
import { type TeamEdgeFragment } from '~/graphqlTypes'

export interface TeamContextProps {
  customerId: string
  id: string
  name: string
  portfolioId: string
  role: TeamEdgeFragment['role']
  setTeam: (_: string) => void
  status: TeamUserStatus
}

export const TeamContext = createContext<TeamContextProps>()

export const useTeam = (): TeamContextProps => {
  const currentTeam = useContext(TeamContext)
  return currentTeam
}

export const useTeamId = (): string => {
  const { id } = useTeam()
  return id
}

export const useCustomerId = (): string => {
  const { customerId } = useTeam()
  return customerId
}

export const useHasRoleOrHigher = (minimumUserRole: string): boolean => {
  const currentTeam = useTeam()
  const isAuthorized = userRole(currentTeam.role.id).higherThanOrEquals(minimumUserRole)

  return isAuthorized
}

export const useHasHigherRoleOrAdmin = (minimumUserRole: string): boolean => {
  const currentTeam = useTeam()
  const isAuthorized = userRole(currentTeam.role.id).higherThanOrAdmin(minimumUserRole)

  return isAuthorized
}

export const useIsTeamManager = (): boolean => {
  const isTeamManager = useHasRoleOrHigher(UserRoleEnum.TeamManager)
  return isTeamManager
}
