import * as React from 'react'
import { useReactiveVar } from '@apollo/client/index.js'
import { financialFieldsMap } from '~/state'
import { CountryKind, Currency } from '@strise/types'
import { filterNullishValues } from '@strise/ts-utils'

export const useFinancialFieldMatching = (
  financialFieldId: string
): { matchingCountries: CountryKind[]; name: string } => {
  const financialsFieldMapState = useReactiveVar(financialFieldsMap)
  const selectedFinancialField = financialsFieldMapState[financialFieldId]

  return React.useMemo(() => {
    if (!selectedFinancialField) {
      return { matchingCountries: [], name: '' }
    }

    const matchingFinacialFieldCountries = selectedFinancialField.matchingInternationalFields.map(
      (f) => f.countryV2?.kind
    )

    const countries = filterNullishValues([selectedFinancialField.countryV2?.kind, ...matchingFinacialFieldCountries])

    return { matchingCountries: countries, name: selectedFinancialField.name }
  }, [financialFieldId])
}

// TODO: Move expected currency to backend
export const languageToCurrencyMap: Partial<Record<CountryKind, Currency>> = {
  [CountryKind.No]: Currency.Nok,
  [CountryKind.Se]: Currency.Sek,
  [CountryKind.Dk]: Currency.Dkk,
  [CountryKind.Gb]: Currency.Gbp,
  [CountryKind.Fr]: Currency.Eur,
  [CountryKind.De]: Currency.Eur,
  [CountryKind.Fi]: Currency.Eur
}

export const useFinancialFieldCurrencyType = (financialFieldId: string): string => {
  const { matchingCountries } = useFinancialFieldMatching(financialFieldId)

  return React.useMemo(() => {
    const currencies = matchingCountries.map((country) => languageToCurrencyMap[country])
    return currencies.filter(Boolean).join('/')
  }, [matchingCountries])
}
