import { Trans, t } from '@lingui/macro'
import { Typography } from '@strise/ui-components'
import { ConfirmDialog } from '@strise/ui-components-legacy'
import * as React from 'react'

export const CreditScoreConfirmDialog = ({
  context,
  disabled,
  loading,
  onCancel,
  onConfirm
}: {
  context: string
  disabled: boolean
  loading: boolean
  onCancel: () => void
  onConfirm: () => void
}): React.ReactNode => {
  return (
    <ConfirmDialog
      isOpen
      confirmText={t`Fetch score`}
      cancelText={t`Cancel`}
      title={t`Fetch credit score for Sole Proprietorship`}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonProps={{
        loading,
        disabled,
        'data-track': `${context} / Credit report / Confirmation dialog / Fetch score`
      }}
      cancelButtonProps={{ 'data-track': `${context} / Credit report / Confirmation dialog / Close` }}
      contentMaxWidth={600}
    >
      <Typography>
        <Trans>
          You are about to perform a credit check on a Sole Proprietorship. The credit check can only be carried out if
          there is a reason behind it.
        </Trans>
      </Typography>
      <Typography>
        <Trans>Note that the owner of the Sole Proprietorship will receive a letter about this credit check.</Trans>
      </Typography>
    </ConfirmDialog>
  )
}
