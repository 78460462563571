import { cn, IconButton, IconOverflowHorizontal, type IconPropsWithRef } from '@strise/ui-components'
import * as React from 'react'
import { Dropdown, type DropdownProps } from './Dropdown'

type OverflowMenuType = Omit<DropdownProps, 'buttonProps'> & {
  buttonProps: { iconProps?: IconPropsWithRef } & DropdownProps['buttonProps']
}

const ToggleButton = React.forwardRef<HTMLButtonElement, OverflowMenuType['buttonProps']>(
  ({ className, iconProps, ...props }, ref) => (
    <IconButton className={cn('shrink-0 rounded', className)} ref={ref} variant='ghost' palette='tertiary' {...props}>
      <IconOverflowHorizontal {...iconProps} />
    </IconButton>
  )
)

export const OverflowMenu: React.FC<OverflowMenuType> = ({ buttonProps, children, className, ...props }) => (
  <Dropdown className={className} menu ToggleComponent={<ToggleButton {...buttonProps} />} {...props}>
    {children}
  </Dropdown>
)
