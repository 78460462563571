import MarkdownToJsx from 'markdown-to-jsx'
import React, { type FC, type PropsWithChildren } from 'react'
import { mapValues } from 'lodash-es'
import { type StyleProps } from '@strise/react-utils'
import { Typography, type TypographyProps } from '../Typography'
import { cn } from '../../utils/className'
import { typographyVariants } from '../variants/typographyVariants'

interface MarkdownProps extends TypographyProps {
  /** The Markdown string */
  children: string
  /** Default Typography variant that gets applied to all children if no specific tags like h1 etc. are used. */
  defaultVariant?: TypographyProps['variant']
}

const UlBox = ({ children, className, ...props }: PropsWithChildren & StyleProps): React.ReactNode => (
  <ul className={cn('m-0 pl-3', className)} {...props}>
    {children}
  </ul>
)

const LiBox = ({ children, className, ...props }: PropsWithChildren & StyleProps): React.ReactNode => (
  <li className={cn('list-disc pb-1.5', className)} {...props}>
    {children}
  </li>
)

const typographyOverrides = mapValues(typographyVariants, (_, key) => {
  return {
    component: ({ children }: PropsWithChildren): React.ReactNode => (
      <Typography variant={key as TypographyProps['variant']}>{children}</Typography>
    )
  }
})

export const Markdown: FC<MarkdownProps> = ({ children, className, defaultVariant = 'body2', ...props }) => (
  <Typography className={className} component='div' variant={defaultVariant} {...props}>
    <MarkdownToJsx
      options={{
        ...typographyOverrides,
        overrides: {
          ul: {
            component: UlBox
          },
          li: {
            component: LiBox
          }
        }
      }}
    >
      {children}
    </MarkdownToJsx>
  </Typography>
)
