import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconMinusBox = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M1 1h22v22H1V1Zm2 2v18h18V3H3Z' clipRule='evenodd' />
      <path fill='currentColor' fillRule='evenodd' d='M6 11h12v2H6v-2Z' clipRule='evenodd' />
    </svg>
  )
)
IconMinusBox.displayName = 'IconMinusBox'
