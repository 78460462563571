import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSend = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M12.5 11.5 16.7 22 23 1 2 7.3l10.5 4.2ZM8.06 7.57l5.979 2.391 2.391 5.979 3.587-11.957L8.06 7.57Z'
        clipRule='evenodd'
      />
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='m12.293 10.293 8-8 1.414 1.414-8 8-1.414-1.414ZM1.293 21.293l8-8 1.414 1.414-8 8-1.414-1.414ZM6.293 21.293l6-6 1.414 1.414-6 6-1.414-1.414ZM1.293 16.293l6-6 1.414 1.414-6 6-1.414-1.414Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconSend.displayName = 'IconSend'
