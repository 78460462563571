import { type ReviewEventValueFragment } from '~/graphqlTypes'
import React from 'react'
import { type ReviewState } from '~/utils/reviewUtils2'
import { type SetStateFn } from '@strise/react-utils'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { reviewEventToEvent } from '~/components/Review/reviewValueTransformers'
import { Event2 } from '~/components/Events/Event2'

export const ReviewEvent = ({
  entityId,
  item,
  reviewState,
  setReviewState
}: {
  entityId: string
  item: ReviewEventValueFragment
  reviewState: ReviewState | undefined
  setReviewState: SetStateFn<ReviewState>
}): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const event = reviewEventToEvent(item.value)

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <Event2
        event={event}
        entityId={entityId}
        reviewState={reviewState}
        setReviewState={setReviewState}
        isReview
        hideImage
        hidePublisherImage
        topics={item.value.amsTopics}
        hideSummary
      />
    </ReviewItem>
  )
}
