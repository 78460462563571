import type { Primitive } from '@strise/types'
import * as React from 'react'
import { useState } from 'react'
import { isFunction, minBy, omit, isNil } from 'lodash-es'
import { getBrowserGlobals } from './browserGlobals'
import { type SetStateFn } from '../types/types'
import { objectEntries } from '@strise/ts-utils'
import { differenceInDays } from 'date-fns'

interface TimestampedItem<T> {
  timestamp: number
  value: T
}

/**
 * Hook to manage an object state in local storage with a max key size (FIFO) and optional expiration.
 */
export const usePersistentObjectState = <T extends object>(
  key: string,
  options: {
    daysToLive?: number
    maxSize: number
  }
): {
  removeItem: (key: string) => void
  resetState: () => void
  setItem: (key: string, item: T) => void
  state: Record<string, T>
} => {
  const [state, setState, resetState] = usePersistentState<Record<string, TimestampedItem<T>>>(key, {})

  // Memoize the current value (removing the timestamp and filtering expired items)
  const currentValue = React.useMemo(() => {
    const now = Date.now()
    const entries = objectEntries(state)

    const validEntries: Array<[string, T]> = entries
      // filter out expired items based on daysToLive
      .filter(([_, item]) => {
        if (!options.daysToLive) return true

        return differenceInDays(now, item.timestamp) < options.daysToLive
      })
      .map(([entryKey, item]) => [entryKey, item.value])

    return Object.fromEntries(validEntries)
    // Ignoring rule as we use JSON.stringify: https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state), options.daysToLive])

  const setItem = (subKey: string, item: T): void => {
    const timestamp = state[subKey] ? state[subKey].timestamp : Date.now()

    const newState = {
      ...state,
      [subKey]: {
        timestamp,
        value: item
      }
    }

    const entries = objectEntries(newState)

    if (entries.length <= options.maxSize) {
      setState(newState)
      return
    }

    // Find the oldest entry among valid items
    const oldestEntry = minBy(entries, ([_, entryItem]) => entryItem.timestamp)
    const oldestKey = oldestEntry ? oldestEntry[0] : null

    if (isNil(oldestKey)) {
      return
    }

    const newStateWithFilteredOldest = omit(newState, oldestKey)

    setState(newStateWithFilteredOldest)
  }

  const removeItem = (subKey: string): void => {
    const newState = omit(state, subKey)
    setState(newState)
  }

  return {
    state: currentValue,
    setItem,
    removeItem,
    resetState
  }
}

/**
 * Hook to manage an array state in local storage with a max size (FIFO)
 */
export const usePersistentArrayState = <T>(
  key: string,
  maxSize: number
): { addItem: (item: T) => void; resetState: () => void; state: T[] } => {
  // Use the provided usePersistentState hook with an empty array as the default value
  const [state, setState, resetState] = usePersistentState<Array<TimestampedItem<T>>>(key, [])

  // Convert the timestamped items to a set of values (without timestamps)
  const currentValue = React.useMemo(() => {
    return state.map((item) => item.value)
  }, [state])

  // Function to add a new item to the set
  const addItem = (item: T): void => {
    setState((prevState) => {
      // Remove the item if it already exists to avoid duplicates
      const filteredState = prevState.filter((i) => i.value !== item)

      // If the new size would exceed maxSize, remove the oldest item
      if (filteredState.length >= maxSize) {
        // eslint-disable-next-line functional/immutable-data
        filteredState.shift()
      }

      // Add the new item with the current timestamp
      return [...filteredState, { value: item, timestamp: Date.now() }]
    })
  }

  // Return the set and the functions to add an item or reset the set
  return {
    state: currentValue,
    addItem,
    resetState
  }
}

export const useDisableTemporarilyState = (
  localStorageKey: string,
  hoursDuration: number,
  now: Date = new Date()
): [boolean, () => void] => {
  const disableString = getBrowserGlobals()?.window.localStorage.getItem(localStorageKey)
  const disableDate = disableString && new Date(Number.parseInt(disableString))
  const [isEnabled, setIsEnabled] = useState(disableDate ? disableDate.valueOf() < now.valueOf() : true)

  const toggleIsEnabled = (): void => {
    setIsEnabled((prevIsEnabled) => {
      const nextIsEnabled = !prevIsEnabled

      if (nextIsEnabled) {
        getBrowserGlobals()?.window.localStorage.removeItem(localStorageKey)
      } else {
        const disableTimestamp = new Date().setHours(now.getHours() + hoursDuration)
        getBrowserGlobals()?.window.localStorage.setItem(localStorageKey, disableTimestamp.toString())
      }

      return nextIsEnabled
    })
  }

  return [isEnabled, toggleIsEnabled]
}

/**
 * Hook to manage a state in local storage
 */
export const usePersistentState = <T extends Primitive | object>(
  key: string,
  defaultValue: T
): [T, SetStateFn<T>, () => void] => {
  const [state, _setState] = React.useState<T>(() => {
    try {
      const currentValue = getBrowserGlobals()?.window.localStorage.getItem(key)
      if (!currentValue) return defaultValue
      if (currentValue === 'undefined') return defaultValue

      return JSON.parse(currentValue) as T
    } catch (error) {
      console.error('Error parsing persistent state', error)
      return defaultValue
    }
  })

  const setState: SetStateFn<T> = (newState: T | ((preState: T) => T)) => {
    const stringifiedState = isFunction(newState)
      ? JSON.stringify((newState as (prevState: T) => T)(state))
      : JSON.stringify(newState)

    getBrowserGlobals()?.window.localStorage.setItem(key, stringifiedState)
    _setState(newState)
  }

  const reset = (): void => {
    getBrowserGlobals()?.window.localStorage.removeItem(key)
    _setState(defaultValue)
  }

  return [state, setState, reset]
}
