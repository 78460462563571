import { SIDEPANEL_WIDTH } from '~/constants'
import { menuState } from '~/state'
import { cn, IconButton, Typography, IconBurgermenu, IconCross, IconSearch } from '@strise/ui-components'
import * as React from 'react'
import { createGlobalStyle } from 'styled-components'
import { MenuState } from '~/components/Menu/menuUtils'
import { Notifications } from '~/components/Notifications/Notifications'
import { SearchBox } from '~/components/Search/Search'
import { type DivProps } from '@strise/react-utils'

const SearchWrapperStyles = createGlobalStyle`
  #header-search-wrapper {
    width: ${SIDEPANEL_WIDTH.open};
    max-width: ${SIDEPANEL_WIDTH.max};
  }


  [data-sidepanel="expanded"] #header-search-wrapper {
    width: ${SIDEPANEL_WIDTH.openExpanded};
  }
`
type SearchDisplayClass = 'legacy-xs:hidden' | 'legacy-xs:flex'

const Search = (): React.ReactNode => {
  const [searchDisplayClass, setSearchDisplayClass] = React.useState<SearchDisplayClass>('legacy-xs:hidden')

  return (
    <>
      <div
        className={cn(
          'inset-y-0 left-0 right-[64px] h-full flex-1 legacy-xs:absolute legacy-lg:static legacy-lg:block',
          searchDisplayClass
        )}
      >
        <IconButton
          className={cn(
            'h-[calc(theme(height.header)-1px)] w-[theme(height.header)] bg-secondary-shade-5 legacy-lg:hidden',
            searchDisplayClass
          )}
          variant='contained'
          palette='tertiary'
          onClick={() => setSearchDisplayClass('legacy-xs:hidden')}
          data-track='Button / Top bar / Mobile / Close search input'
        >
          <IconCross />
        </IconButton>
        <SearchBox
          className={cn('legacy-lg:flex', searchDisplayClass)}
          focusInputRef={searchDisplayClass === 'legacy-xs:flex'}
        />
      </div>
      <IconButton
        className={cn(
          'h-[calc(theme(height.header)-1px)] w-[theme(height.header)] bg-secondary-shade-5 lg:hidden',
          searchDisplayClass === 'legacy-xs:hidden' ? 'legacy-xs:flex' : 'legacy-xs:hidden'
        )}
        variant='contained'
        palette='tertiary'
        onClick={() => setSearchDisplayClass('legacy-xs:flex')}
        data-track='Button / Top bar / Mobile / Show search input'
      >
        <IconSearch />
      </IconButton>
    </>
  )
}

export interface HeaderProps extends DivProps {
  actions?: React.ReactNode[]
  children?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ children, className, ...props }: HeaderProps) => {
  const openMenu = (event: React.MouseEvent): void => {
    event.stopPropagation()
    menuState(MenuState.MOBILE_OPEN)
  }

  return (
    <header
      className={cn(
        'sticky top-0 flex h-header items-center border-b border-b-divider bg-background-default pl-4 text-text-primary legacy-xs:z-[12] legacy-lg:z-[13]',
        className
      )}
      {...props}
    >
      <IconButton
        className='mr-4 legacy-xs:block legacy-lg:hidden'
        onClick={openMenu}
        data-track='Button / Top bar / Mobile / Show menu'
      >
        <IconBurgermenu display='block' />
      </IconButton>
      <Typography variant='h3' component='h1'>
        {children}
      </Typography>
      <SearchWrapperStyles />
      <div className='ml-auto flex h-full items-center justify-end' id='header-search-wrapper'>
        <Search key='search' />
        <Notifications key='notifications' />
      </div>
    </header>
  )
}
