import { type SxProps } from './styleFunctions/StyleFunction'

type PaletteStateStyles = Record<string, Record<string, Record<string, SxProps>>>

const styles: PaletteStateStyles = {
  contained: {
    'secondary.0': {
      hover: {
        bgcolor: 'secondary.10'
      },
      active: {
        bgcolor: 'secondary.20'
      },
      focus: {
        bgcolor: 'secondary.20'
      },
      disabled: {
        color: 'text.disabled'
      }
    },
    'secondary.5': {
      hover: {
        bgcolor: 'secondary.10'
      },
      active: {
        bgcolor: 'secondary.20'
      },
      focus: {
        bgcolor: 'secondary.20'
      },
      disabled: {
        color: 'text.disabled'
      }
    },
    'secondary.10': {
      hover: {
        bgcolor: 'secondary.20'
      },
      active: {
        bgcolor: 'secondary.30'
      },
      focus: {
        bgcolor: 'secondary.30'
      },
      disabled: {
        color: 'text.disabled'
      }
    },
    'secondary.20': {
      hover: {
        bgcolor: 'secondary.30'
      },
      active: {
        bgcolor: 'secondary.40'
      },
      focus: {
        bgcolor: 'secondary.40'
      },
      disabled: {
        color: 'text.disabled'
      }
    },
    'secondary.80': {
      hover: {
        bgcolor: 'secondary.70'
      },
      active: {
        bgcolor: 'secondary.80'
      },
      focus: {
        bgcolor: 'secondary.80'
      },
      disabled: {
        color: 'text.disabled',
        bgcolor: 'secondary.20'
      }
    },
    'secondary.90': {
      hover: {
        bgcolor: 'secondary.80'
      },
      active: {
        bgcolor: 'secondary.90'
      },
      focus: {
        bgcolor: 'secondary.90'
      },
      disabled: {
        color: 'text.disabled',
        bgcolor: 'secondary.20'
      }
    },
    primary: {
      hover: {
        bgcolor: 'primary.70'
      },
      active: {
        bgcolor: 'primary.60'
      },
      focus: {
        bgcolor: 'primary.90'
      },
      disabled: {
        color: 'text.disabled',
        bgcolor: 'primary.20'
      }
    },
    secondary: {
      hover: {
        bgcolor: 'secondary.70'
      },
      active: {
        bgcolor: 'secondary.60'
      },
      focus: {
        bgcolor: 'secondary.90'
      },
      disabled: {
        color: 'text.disabled',
        bgcolor: 'secondary.20'
      }
    },
    'background.paper': {
      hover: {
        bgcolor: 'secondary.10'
      },
      active: {
        bgcolor: 'secondary.20'
      },
      focus: {
        bgcolor: 'secondary.20'
      },
      disabled: {
        color: 'text.disabled'
      }
    }
  },
  outlined: {
    secondary: {
      hover: {
        bgcolor: 'secondary.20',
        borderColor: 'secondary.20'
      },
      active: {
        bgcolor: 'secondary.30',
        borderColor: 'secondary.30'
      },
      focus: {
        bgcolor: 'secondary.30',
        borderColor: 'secondary.30'
      },
      disabled: {
        color: 'text.disabled',
        borderColor: 'secondary.20'
      }
    },
    'secondary.10': {
      hover: {
        bgcolor: 'secondary.10',
        borderColor: 'secondary.10'
      },
      active: {
        bgcolor: 'secondary.20',
        borderColor: 'secondary.20'
      },
      focus: {
        bgcolor: 'secondary.20',
        borderColor: 'secondary.20'
      },
      disabled: {
        color: 'text.disabled',
        borderColor: 'secondary.5'
      }
    }
  },
  text: {
    'secondary.5': {
      hover: {
        bgcolor: 'secondary.5'
      },
      active: {
        bgcolor: 'secondary.10'
      },
      focus: {
        bgcolor: 'secondary.10'
      }
    },
    'secondary.10': {
      hover: {
        bgcolor: 'secondary.10'
      },
      active: {
        bgcolor: 'secondary.20'
      },
      focus: {
        bgcolor: 'secondary.20'
      }
    },
    'secondary.15': {
      hover: {
        bgcolor: 'secondary.15'
      },
      active: {
        bgcolor: 'secondary.30'
      },
      focus: {
        bgcolor: 'secondary.30'
      }
    },
    'secondary.70': {
      hover: {
        bgcolor: 'secondary.70'
      },
      active: {
        bgcolor: 'secondary.60'
      },
      focus: {
        bgcolor: 'secondary.60'
      }
    }
  }
}

export const stateStyles = (variant: string, palette: string): Record<string, SxProps> => ({
  '&:hover': styles[variant]?.[palette]?.hover ?? {},
  '&:active': styles[variant]?.[palette]?.active ?? {},
  '&:focus': styles[variant]?.[palette]?.focus ?? {},
  '&:disabled': styles[variant]?.[palette]?.disabled ?? {}
})
