import { t } from '@lingui/macro'
import { type PepInfoFragment } from '~/graphqlTypes'

export const extractPepType = (pepInfo: PepInfoFragment) => {
  if (pepInfo.pep) return t`PEP`
  if (pepInfo.rca) return t`RCA`
  return t`Unknown`
}

export const dialogBgColor = 'bg-secondary-shade-5'
