import { cva } from 'class-variance-authority'

export const tagVariants = cva('flex h-6 w-fit items-center justify-center text-nowrap rounded-[4px] px-2 py-0.5', {
  variants: {
    palette: {
      gray: 'bg-tertiary-main',
      pink: 'bg-accent-pink-shade-5 text-accent-pink-shade-90',
      orange: 'bg-accent-orange-shade-5 text-accent-orange-shade-90',
      green: 'bg-accent-green-shade-5 text-accent-green-shade-90',
      blue: 'bg-accent-blue-shade-5 text-accent-blue-shade-30'
    }
  },
  defaultVariants: {
    palette: 'gray'
  }
})
