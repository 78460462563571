export const typography = {
  fontFamily: 'Favorit Std, sans-serif',
  fontWeightBold: 500,

  // Body
  body1: { fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 300 },
  body2: { fontSize: '0.75rem', lineHeight: '1rem', fontWeight: 300 },
  body3: { fontSize: '0.625rem', lineHeight: '1rem', fontWeight: 400 },
  emphasized1: { fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 200 },
  emphasized2: { fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 200 },

  // Headings
  h1: { fontSize: '3rem', lineHeight: '3rem', fontWeight: 500 },
  h2: { fontSize: '2rem', lineHeight: '2rem', fontWeight: 500 },
  h3: { fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 500 },
  h4: { fontSize: '1.5rem', lineHeight: '2rem', fontWeight: 500 },
  h5: { fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: 'bold' }, // Outdated
  h6: { fontSize: '1.25rem', lineHeight: '1.75rem', fontWeight: 'normal' }, // Outdated
  subtitle1: { fontSize: '1.125rem', lineHeight: '1.5rem', fontWeight: 400 },
  subtitle2: { fontSize: '0.75rem', lineHeight: '1rem', fontWeight: 500 },
  subtitle3: { fontSize: '0.75rem', lineHeight: '1rem', fontWeight: 500 },

  // Labels & Links
  aLabel: { fontSize: '1rem', lineHeight: '1.25rem', fontWeight: 400 },
  aLabelBold: { fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 500 },
  aLabelLarge: { fontSize: '1.5rem', lineHeight: '1.5rem', fontWeight: 400 },
  aLabelSmall: { fontSize: '0.75rem', lineHeight: '1rem', fontWeight: 400 },
  aLabelSmallBold: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 500
  },
  aLink: { fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 400 },

  // Misc
  button: { fontSize: '1rem' },
  tiny: { fontSize: '0.5625rem', fontWeight: 'bold' }
}
