import { filterNullishValues } from './array'
import { isObject, isArray, isNil } from 'lodash-es'

export const isInObject = <Key extends PropertyKey>(
  object: Record<Key, unknown>,
  testKey: PropertyKey
): testKey is Key => {
  return testKey in object
}

export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as Array<keyof T>
}

export const objectEntries = <T extends object, K extends keyof T>(obj: T): Array<[keyof T, T[K]]> => {
  return Object.entries(obj) as Array<[keyof T, T[K]]>
}

export const deepMergeObjects = <T extends object>(...objects: Array<T | undefined>): T => {
  return filterNullishValues(objects).reduce<T>((objAcc, obj) => {
    return objectKeys(obj).reduce<T>((keyAcc, key) => {
      const accValue = objAcc[key]
      const objValue = obj[key]

      if (isNil(objValue)) return keyAcc

      if (isObject(accValue) && isObject(objValue) && !isArray(accValue) && !isArray(objValue)) {
        // Recursively merge nested objects, return a new object
        return {
          ...keyAcc,
          [key]: deepMergeObjects(accValue, objValue)
        }
      }

      // Assign the value from obj, ensuring a new object is returned
      return {
        ...keyAcc,
        [key]: objValue
      }
    }, objAcc) // Start with the current accumulator as the base
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter,@typescript-eslint/consistent-type-assertions
  }, {} as T)
}

export const reduceFlatten = <O extends object>(acc: O, cur: O): O => ({
  ...acc,
  ...cur
})
