import * as React from 'react'
import { ApolloClientProvider as AppSharedClientProvider } from '@strise/app-shared'
import { ApolloClientProvider } from '~/apolloClient/ApolloClientProvider'
import { ApolloClientContext } from '~/apolloClient/ApolloClientContext'
import { useContext } from '@strise/react-utils'

const ApolloClientWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { client, link } = useContext(ApolloClientContext)

  if (!client) return null

  return (
    <AppSharedClientProvider client={client} link={link}>
      {children}
    </AppSharedClientProvider>
  )
}

export const ApolloClientContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ApolloClientProvider>
      <ApolloClientWrapper>{children}</ApolloClientWrapper>
    </ApolloClientProvider>
  )
}
