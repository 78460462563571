import { Chip, type ChipProps } from '@strise/ui-components'
import * as React from 'react'
import { UserAvatar } from '@strise/app-shared'
import { type SimpleUserFragment } from '~/graphqlTypes'

interface UserChipProps extends ChipProps {
  user: SimpleUserFragment
}

export const UserChip = ({ className, user, ...props }: UserChipProps): React.ReactNode => {
  return (
    <Chip
      className={className}
      label={user.name}
      startIcon={<UserAvatar user={user} className='mr-1 size-5' />}
      {...props}
    />
  )
}
