import { cn, Tooltip, IconSuccessFill, IconWarningFill } from '@strise/ui-components'
import { ReviewValueStatusKind } from '@strise/types'
import { TestIDs } from '~/utils/testIDs'
import * as React from 'react'
import { type ReviewValueMetaFragment } from '~/graphqlTypes'

const statusIconMap: Record<ReviewValueStatusKind, { Icon: React.ElementType; className: string; testId: string }> = {
  [ReviewValueStatusKind.Ok]: {
    Icon: IconSuccessFill,
    className: 'text-semantic-success-main',
    testId: TestIDs.Review.Card.successIcon
  },
  [ReviewValueStatusKind.Warning]: {
    Icon: IconWarningFill,
    className: 'text-semantic-notice-main',
    testId: TestIDs.Review.Card.warningIcon
  },
  [ReviewValueStatusKind.Danger]: {
    Icon: IconWarningFill,
    className: 'text-semantic-danger-main',
    testId: TestIDs.Review.Card.dangerIcon
  }
}

export const ReviewItem = ({
  children,
  className,
  reviewMeta
}: {
  children?: React.ReactNode,
  className?: string,
  reviewMeta: ReviewValueMetaFragment | null | undefined
}): React.ReactNode => {
  const statusIcon = reviewMeta ? statusIconMap[reviewMeta.status] : null

  return (
    <div
      className={cn(
        'my-2 grid auto-cols-min grid-flow-col grid-cols-1 items-center justify-between rounded-xl bg-background-paper px-2 py-1',
        {
          'bg-semantic-notice-shade-5': reviewMeta?.status === ReviewValueStatusKind.Warning,
          'bg-semantic-danger-shade-5': reviewMeta?.status === ReviewValueStatusKind.Danger,
          'bg-background-paper': reviewMeta?.status === ReviewValueStatusKind.Ok
        },
        className
      )}
    >
      {children ?? reviewMeta?.reason}
      {reviewMeta && (
        <Tooltip content={reviewMeta.reason}>
          <div className='ml-4 flex items-center py-1'>
            {statusIcon && <statusIcon.Icon className={statusIcon.className} data-testid={statusIcon.testId} />}
          </div>
        </Tooltip>
      )}
    </div>
  )
}
