import { type UserSettingsInput } from '@strise/types'
import { type UserSettingsFragment } from '~/graphqlTypes'

export const userReviewSettingsToInput = (settings: UserSettingsFragment): UserSettingsInput => {
  const { review } = settings
  return {
    review: {
      companies: {
        assignees: review.assignees.map((assignee) => assignee?.id ?? null),
        statuses: review.statuses,
        stickers: review.tags.map((tag) => tag.id)
      }
    }
  }
}

export const userPortfolioSettingsToInput = (settings: UserSettingsFragment): UserSettingsInput => {
  const { portfolio } = settings
  return {
    listView: {
      companies: {
        countries: portfolio.countries,
        severity: portfolio.flagSeverities,
        statuses: portfolio.statuses,
        assignees: portfolio.assignees.map((assignee) => assignee?.id ?? null),
        stickers: portfolio.tags.map((tag) => tag.id),
        employees: portfolio.employees,
        revenue: portfolio.revenue,
        locations: portfolio.locations.map((location) => location.id)
      }
    }
  }
}

export const userSidepanelSettingsToInput = (settings: UserSettingsFragment): UserSettingsInput => {
  const { sidepanel } = settings

  return {
    application: {
      sidePanelFinancialHighlightsSelector: sidepanel.financialFieldsHighlighted
    },
    events: {
      events: {
        topics: sidepanel.topics.map((topic) => topic.id),
        sources: {
          eventTypes: sidepanel.sources
        }
      }
    }
  }
}

export const userGrowSettingsToInput = (settings: UserSettingsFragment): UserSettingsInput => {
  const { grow } = settings

  return {
    application: {
      opportunityFinancialHighlightCount: grow.financialHighlightCount,
      opportunityFinancialHighlightsSelector: grow.financialFieldsHighlighted
    },
    opportunities: {
      assignees: grow.assignees.map((assignee) => assignee.id),
      stickers: grow.tags.map((tag) => tag.id),
      locations: {
        locations: grow.locations.length ? grow.locations.map((location) => location.id) : null,
        polygon: grow.coordinates.length ? grow.coordinates : null
      },
      industries: grow.industries.map((industry) => industry.id),
      employees: grow.employees,
      inception: grow.inception,
      accountants: grow.accountants.map((accountant) => accountant.id),
      requireNoAccountant: grow.requireNoAccountant,
      collateralCreditors: grow.collateralCreditors.map((collateralCreditor) => collateralCreditor.id),
      realEstateCollateralCreditors: grow.realEstateCollateralCreditors.map(
        (realEstateCollateralCreditor) => realEstateCollateralCreditor.id
      ),
      legalForms: grow.legalForms.map((legalForm) => legalForm.kind),
      flagKinds: grow.flagKinds.map((flag) => flag.id),
      financials: {
        valueFilters: grow.financials.map((financial) => ({
          id: financial.id,
          from: financial.from,
          to: financial.to
        }))
      }
    }
  }
}

export const settingsToInput = (settings: UserSettingsFragment): UserSettingsInput => {
  const reviewInput = userReviewSettingsToInput(settings)
  const listViewInput = userPortfolioSettingsToInput(settings)
  const eventsInput = userSidepanelSettingsToInput(settings)
  const opportunitiesInput = userGrowSettingsToInput(settings)

  return {
    application: {
      ...eventsInput.application,
      ...opportunitiesInput.application,
      timeZone: settings.timeZone,
      useCaseKinds: settings.useCaseKinds,
      displayLanguage: settings.displayLanguage
    },
    terms: {
      generalTerms: settings.generalTermsAccepted,
      privacyTerms: settings.privacyTermsAccepted
    },
    review: reviewInput.review,
    listView: listViewInput.listView,
    events: eventsInput.events,
    opportunities: opportunitiesInput.opportunities
  }
}
