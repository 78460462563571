import * as React from 'react'
import { useState } from 'react'
import { EventsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelEventsCard'
import { SidepanelReviewCard } from '~/components/Sidepanel/SidepanelCards/SidepanelReviewCard'
import { SidepanelGrow } from '~/components/Sidepanel/SidepanelGrow'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { SidepanelCompanyMetaCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyMetaCard'
import { SidepanelCompanyDetailsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyDetailsCard'
import { Helmet } from 'react-helmet'
import { SidepanelCompanyRegistersCard } from '~/components/Sidepanel/SidepanelCards/SidepanelRegistersCard'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { SidepanelCompanyCreditReportCard } from '~/components/Sidepanel/SidepanelCards/CreditReport/SidepanelCompanyCreditReportCard'
import { SidepanelRiskSummaryCard } from '~/components/Sidepanel/SidepanelCards/SidepanelRiskSummaryCard'
import { SidepanelOwnershipsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelOwnershipsCard'
import { SidepanelFinancialsCard } from '~/components/Sidepanel/SidepanelCards/FinancialsCard/SidepanelFinancialsCard'
import { SidepanelBranchesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyBranchesCard'
import { SidepanelCompanyOwnersCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyOwnersCard'
import { SidepanelCompanyHeaderCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyHeaderCard'
import { SidepanelRolesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelRolesCard'
import { SidepanelSimilarEntities } from '~/components/Sidepanel/SidepanelCards/SidepanelSimilarEntities'
import { SidepanelCompanyCollateralCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyCollateralCard'
import { SidepanelRealEstateCard } from '~/components/Sidepanel/SidepanelCards/CreditReport/SidepanelRealEstateCard'
import { SidepanelCCJCard } from '~/components/Sidepanel/SidepanelCards/CCJReport/SidepanelCCJCard'
import { type SidepanelNetworkRiskQuery, type SidepanelCompanyFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'
import { hasEntityAccess, isEntityActive, isGraniteEntity } from '~/utils/entity'
import { SidepanelCounterpartiesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCounterpartiesCard'
import { SidepanelCompanyPepsAndSanctionsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelCompanyPepsAndSanctionsCard'
import { UserFeedbackCard } from '~/components/UserFeedback/UserFeedbackCard'
import { useSidepanelNetworkRiskQuery } from '~/graphqlOperations'
import { SidepanelSanctionsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelSanctionsCard'
import { useContext } from '@strise/react-utils'

export const SidepanelCompanyLayout: React.FC<{
  company: SidepanelCompanyFragment
  tabRefs: TabRefs
}> = ({ company, tabRefs }) => {
  const features = useCurrentUserFeatures()
  const isActive = isEntityActive(company)
  const [showOriginal, setShowOriginal] = useState(false)
  const ownershipsRef = React.useRef<HTMLDivElement | null>(null)
  const { setShowNetworkRisk, showNetworkRisk } = useContext(SidepanelContext)
  const [networkData, setNetworkData] = useState<SidepanelNetworkRiskQuery>()

  const hasCreditReport = !!company.legalForm?.hasCreditReport
  const hasCCJReport = hasCreditReport && company.primaryCountry?.isoAlpha2Code === 'GB' && features.CREDIT_DATA

  const { data, loading: networkRiskLoading } = useSidepanelNetworkRiskQuery({
    variables: {
      entity: company.id,
      includeNetworkRoles: showNetworkRisk,
      includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
      includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
      includeRiskSummary: !!features.RISK_SUMMARIES && !!features.SIDEPANEL_COMPANY_PEPS,
      includeCompanyPeps: !!features.SIDEPANEL_COMPANY_PEPS
    },
    skip: !features.RISK_SUMMARIES && !features.SIDEPANEL_COMPANY_PEPS
  })

  React.useEffect(() => {
    if (data) {
      setNetworkData(data)
    }
  }, [data])

  return (
    <>
      <Helmet>
        <title>{company.name}</title>
      </Helmet>
      <div className={isActive ? undefined : 'text-text-secondary'}>
        <SidepanelCompanyHeaderCard company={company} ref={tabRefs[SidepanelTab.Company]} />
        {!company.isGlobalCompany && !isGraniteEntity(company) && <SidepanelSimilarEntities />}
        {!company.isGlobalCompany && <SidepanelCompanyMetaCard company={company} ref={tabRefs[SidepanelTab.Company]} />}
      </div>

      {hasEntityAccess(company) && (
        <>
          {features.RISK_SUMMARIES && features.SIDEPANEL_COMPANY_PEPS && (
            <SidepanelRiskSummaryCard
              ref={tabRefs[SidepanelTab.RiskSummary]}
              riskSummary={networkData?.company.riskSummary}
              showNetwork={showNetworkRisk}
              changeNetworkState={setShowNetworkRisk}
              loading={networkRiskLoading}
            />
          )}
          <SidepanelCompanyDetailsCard company={company} />
          {!!company.branches.edges.length && <SidepanelBranchesCard company={company} />}

          {features.SIDEPANEL_COMPANY_PEPS ? (
            <SidepanelCompanyPepsAndSanctionsCard
              company={company}
              companyNetwork={networkData?.company.companyNetwork}
              loading={networkRiskLoading}
            />
          ) : (
            <SidepanelSanctionsCard entity={company} />
          )}
          {!company.isGlobalCompany && <SidepanelCompanyRegistersCard company={company} />}
          {features.REVIEW && !company.isGlobalCompany && <SidepanelReviewCard ownershipsElement={ownershipsRef} />}
          <SidepanelRolesCard entity={company} ref={tabRefs[SidepanelTab.People]} sidepanelTab={SidepanelTab.People} />
          {features.COUNTERPARTIES && (
            <SidepanelCounterpartiesCard
              entity={company}
              ref={tabRefs[SidepanelTab.Counterparties]}
              sidepanelTab={SidepanelTab.Counterparties}
            />
          )}

          {company.showOwnerChart && <SidepanelCompanyOwnersCard company={company} showOriginal={showOriginal} />}

          <SidepanelOwnershipsCard
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            chartRef={ownershipsRef}
            entity={company}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />

          {features.COLLATERAL_DATA_LOSOREREGISTERET && !features.CREDIT_DATA && (
            <SidepanelCompanyCollateralCard company={company} />
          )}

          {hasCreditReport && <SidepanelCompanyCreditReportCard company={company} ref={tabRefs[SidepanelTab.Credit]} />}
          {hasCCJReport && <SidepanelCCJCard company={company} ref={tabRefs[SidepanelTab.CCJs]} />}
          {features.REAL_ESTATE_DATA_KARTVERKET && <SidepanelRealEstateCard company={company} />}
          {!company.isGlobalCompany && (
            <SidepanelFinancialsCard company={company} ref={tabRefs[SidepanelTab.Financials]} />
          )}

          {!company.isGlobalCompany && <EventsCard ref={tabRefs[SidepanelTab.Events]} />}
          {!company.isGlobalCompany && <SidepanelGrow ref={tabRefs[SidepanelTab.Grow]} />}
          {company.isGlobalCompany && <UserFeedbackCard origin='Sidepanel / Global Company' />}
        </>
      )}
    </>
  )
}
