import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { contentViewToFeatureMap } from '~/utils/contentViewUtils'
import { ContentViews } from '~/utils/urls'

export enum Views {
  SETUP = '/setup',
  HUBSPOT = '/oauth/hubspot',
  PIPEDRIVE = 'oauth/pipedrive',
  SENTRY_TEST = 'sentry-test',
  ERROR = '/500',
  LOGOUT = '/logout'
}

export const useContentViews = (): ContentViews[] => {
  const features = useCurrentUserFeatures()

  return useMemo(() => {
    return Object.values(ContentViews).filter((view) => {
      const feature = contentViewToFeatureMap[view]
      if (!feature) return true

      return features[feature]
    })
  }, [features])
}

export const useActiveContentView = (): ContentViews | undefined => {
  const location = useLocation()
  const contentViews = Object.values(ContentViews)
  const split = location.pathname.split('/')
  if (!split.length) return undefined

  const currentContentView = split[1] as ContentViews

  return contentViews.find((contentView) => contentView === currentContentView)
}
