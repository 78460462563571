import { style } from '@mui/system'

export interface Numeric {
  numeric?: boolean
}

export const numeric = style({
  prop: 'numeric',
  cssProperty: false,
  transform: () => ({
    fontVariantNumeric: 'tabular-nums'
  })
})
