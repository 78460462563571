import { useTheme } from '@mui/system'
import { useMemo } from 'react'
import { getColor } from '../styleFunctions/paletteStyle'

export const usePalette = (path: string): string => {
  const { palette } = useTheme()
  // Ignoring rule as we use JSON.stringify: https://github.com/facebook/react/issues/14476#issuecomment-471199055
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => getColor(palette, path), [path, JSON.stringify(palette)])
}
