import {
  type AddressFragment,
  type BaseEntityLikeFragment,
  type BaseEventFragment,
  type BasePepInfoFragment,
  type CountryFragment,
  type CustomMetaFragment,
  type PepInfoFragment,
  type PepRoleFragment,
  type PersonBaseFragment,
  type ReviewAddressFragment,
  type ReviewCountryFragment,
  type ReviewCustomMetaFragment,
  type ReviewEntityFragment,
  type ReviewEventFragment,
  type ReviewPepBaseFragment,
  type ReviewPepFragment,
  type ReviewRoleMetaFragment,
  type ReviewUserFragment,
  type RoleMetaFragment,
  type SimpleUserFragment
} from '~/graphqlTypes'
import {
  type CountryKind,
  EntityDispositionStatusKind,
  Gender,
  MatchInfoKind,
  ReviewEntityDispositionStatusKind,
  ReviewGender,
  ReviewMatchInfoKind
} from '@strise/types'

export const reviewEntityToEntity = (entity: ReviewEntityFragment): BaseEntityLikeFragment => {
  return {
    ...entity,
    access: {
      ...entity.access,
      __typename: 'EntityAccess' as const
    },

    __typename: 'Entity' as const
  }
}

export const reviewCountryToCountry = (country: ReviewCountryFragment): CountryFragment => {
  return {
    ...country,
    kind: country.isoAlpha2Code as CountryKind,
    __typename: 'Country' as const
  }
}

export const reviewGenderToGender: Record<ReviewGender, Gender> = {
  [ReviewGender.Male]: Gender.Male,
  [ReviewGender.Female]: Gender.Female,
  [ReviewGender.Other]: Gender.Other
}

export const reviewEntityToPerson = (entity: ReviewEntityFragment): PersonBaseFragment => {
  // @ts-expect-error TODO:  missing fields
  return {
    ...entity,
    ...reviewEntityToEntity(entity),
    gender: entity.gender ? reviewGenderToGender[entity.gender] : null,
    isGlobalPerson: entity.isGlobalPerson ?? false,
    countries: entity.countries.map((country) => reviewCountryToCountry(country)),
    primaryCountry: entity.primaryCountry ? reviewCountryToCountry(entity.primaryCountry) : null,
    __typename: 'Person' as const
  }
}

export const reviewEntityToCompany = (entity: ReviewEntityFragment): BaseEntityLikeFragment => {
  // @ts-expect-error TODO:  missing fields
  return {
    ...entity,
    ...reviewEntityToEntity(entity),
    primaryCountry: entity.primaryCountry ? reviewCountryToCountry(entity.primaryCountry) : null,
    __typename: 'Company' as const
  }
}

export const reviewUserToUser = (user: ReviewUserFragment): SimpleUserFragment => {
  return {
    ...user,
    __typename: 'SimpleUser' as const
  }
}

export const reviewCustomMetaToCustomMeta = (customMeta: ReviewCustomMetaFragment): CustomMetaFragment => {
  return {
    ...customMeta,
    createdBy: customMeta.createdBy ? reviewUserToUser(customMeta.createdBy) : null,
    lastModifiedBy: customMeta.lastModifiedBy ? reviewUserToUser(customMeta.lastModifiedBy) : null,
    __typename: 'CustomMeta' as const
  }
}

export const reviewRoleMetaToRoleMeta = (roleMeta: ReviewRoleMetaFragment): RoleMetaFragment => {
  return {
    ...roleMeta,
    period: {
      from: roleMeta.from,
      to: roleMeta.to,
      __typename: 'Timespan' as const
    },
    prettyTimespan: roleMeta.timespan,
    prettyDuration: roleMeta.duration,
    roleTitleDescription: '', // TODO
    customMeta: roleMeta.customMeta ? reviewCustomMetaToCustomMeta(roleMeta.customMeta) : null,
    __typename: 'RoleMeta' as const
  }
}

export const reviewEventToEvent = (event: ReviewEventFragment): BaseEventFragment => {
  return {
    ...event,
    behindPaywall: false,
    published: event.publishedDate,
    companyEventKinds: [],
    flagSeverity: null,
    images: [],
    __typename: 'Event' as const
  }
}

const reviewMatchInfoKindToKind: Record<ReviewMatchInfoKind, MatchInfoKind> = {
  [ReviewMatchInfoKind.FullMatch]: MatchInfoKind.FullMatch,
  [ReviewMatchInfoKind.PartialMatch]: MatchInfoKind.PartialMatch,
  [ReviewMatchInfoKind.NoMatch]: MatchInfoKind.NoMatch,
  [ReviewMatchInfoKind.MissingData]: MatchInfoKind.MissingData
}

const reviewAddressToAddress = (address: ReviewAddressFragment | null): AddressFragment => {
  return {
    ...address,
    addressLine: address?.addressLine ?? null,
    city: address?.city ?? null,
    zipCode: address?.zipCode ?? null,
    country: address?.country ?? null,
    __typename: 'Address' as const
  }
}

const reviewBasePepToBasePep = (pep: ReviewPepBaseFragment): BasePepInfoFragment => {
  return {
    ...pep,
    // TODO:  Fix id
    id: pep.id ?? crypto.randomUUID(),
    name: pep.entityName,
    pep: pep.isPep,
    rca: pep.isRca,
    address: reviewAddressToAddress(pep.address),
    roles: pep.roles.map(
      (role): PepRoleFragment => ({
        ...role,
        country: role.country ? reviewCountryToCountry(role.country) : null,
        active: role.isActive,
        roleCategory: role.category,
        localizedRoleDescription: role.title,
        __typename: 'PepRole' as const
      })
    ),
    __typename: 'PepInfo' as const
  }
}

const reviewEntityDispositionStatusToEntityDispositionStatus: Record<
  ReviewEntityDispositionStatusKind,
  EntityDispositionStatusKind
> = {
  [ReviewEntityDispositionStatusKind.ConfirmedFalse]: EntityDispositionStatusKind.ConfirmedFalse,
  [ReviewEntityDispositionStatusKind.ConfirmedTrue]: EntityDispositionStatusKind.ConfirmedTrue,
  [ReviewEntityDispositionStatusKind.SuggestedFalse]: EntityDispositionStatusKind.SuggestedFalse,
  [ReviewEntityDispositionStatusKind.SuggestedTrue]: EntityDispositionStatusKind.SuggestedTrue
}

export const reviewPepToPep = (pep: ReviewPepFragment): PepInfoFragment => {
  return {
    ...pep,
    ...reviewBasePepToBasePep(pep),
    gender: pep.gender ? reviewGenderToGender[pep.gender] : null,
    countries: pep.countries.map((country) => reviewCountryToCountry(country)),
    relations: pep.relatedPersons.map((relatedPerson) => ({
      ...relatedPerson,
      relatedPep: reviewBasePepToBasePep(relatedPerson.pep),
      relationDescription: relatedPerson.relationType,
      __typename: 'PepRelation' as const
    })),
    roles: pep.roles.map((role) => ({
      ...role,
      country: role.country ? reviewCountryToCountry(role.country) : null,
      active: role.isActive,
      roleCategory: role.category,
      localizedRoleDescription: role.title,
      __typename: 'PepRole' as const
    })),
    disposition: pep.disposition
      ? {
          ...pep.disposition,
          status: reviewEntityDispositionStatusToEntityDispositionStatus[pep.disposition.status],
          createdBy: pep.disposition.createdBy ? reviewUserToUser(pep.disposition.createdBy) : null,
          __typename: 'EntityDisposition' as const
        }
      : null,
    matchInfoAnalysis: pep.matchInfoAnalysis
      ? {
          ...pep.matchInfoAnalysis,
          matchInfos: pep.matchInfoAnalysis.matchInfos.map((matchInfo) => ({
            ...matchInfo,
            kind: reviewMatchInfoKindToKind[matchInfo.kind],
            __typename: 'MatchInfo' as const
          })),
          __typename: 'MatchInfoAnalysis' as const
        }
      : null
  }
}
