import * as React from 'react'
import { type DivProps } from '@strise/react-utils'
import { FlagSeverity } from '@strise/types'
import { orderBy, uniq } from 'lodash-es'
import { cn, type IconPropsWithRef } from '@strise/ui-components'
import { AdverseFlag } from '../AdverseFlag'

const flagSeverityWeight = {
  [FlagSeverity.Positive]: 0,
  [FlagSeverity.Low]: 1,
  [FlagSeverity.Medium]: 2,
  [FlagSeverity.High]: 3
} as const

export const AdverseFlags = ({
  className,
  flagProps,
  severities,
  ...props
}: { flagProps?: IconPropsWithRef; severities: FlagSeverity[] } & DivProps): React.ReactNode => {
  const uniqueSeverities = uniq(severities)
  const orderedSeverities = orderBy(uniqueSeverities, (severity) => flagSeverityWeight[severity])

  return (
    <div className={className} {...props}>
      {orderedSeverities.map((severity, index) => {
        return (
          <AdverseFlag
            severity={severity}
            key={String(severity)}
            {...flagProps}
            className={cn(flagProps?.className, { '-ml-3': index > 0 })}
            style={{ zIndex: index }}
          />
        )
      })}
    </div>
  )
}
