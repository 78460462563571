import * as React from 'react'
import { cn } from '../../utils/className'
import { type VariantProps } from 'class-variance-authority'
import { type DivProps } from '@strise/react-utils'
import { Slot } from '@radix-ui/react-slot'
import { skeletonVariants } from '../variants/skeletonVariants'

export interface SkeletonProps extends DivProps, VariantProps<typeof skeletonVariants> {
  /** The child component(s) */
  children?: React.ReactNode
  /**
   * Used when wrapped around child components. Renders the skeleton when `false` and the children without the
   * `skeletonVariants` classes when `true`. If wrapping a single child component, the child component will be returned
   * with `className` applied and no wrapper (using `Slot`). If wrapping multiple child components, a div with
   * `className` applied will be wrapped around the children.
   */
  isLoaded?: boolean
  /** Class name that is only applied when `isLoaded` is falsy */
  loadingOnlyClassName?: string
}

const Skeleton = React.forwardRef<HTMLDivElement, SkeletonProps>(
  ({ animation, children, className, isLoaded, loadingOnlyClassName, variant, ...props }, ref) =>
    isLoaded ? (
      <LoadedSkeletonWrapper className={className} {...props} ref={ref}>
        {children}
      </LoadedSkeletonWrapper>
    ) : (
      <div
        className={cn(skeletonVariants({ animation, variant }), className, loadingOnlyClassName)}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    )
)
Skeleton.displayName = 'Skeleton'

const LoadedSkeletonWrapper = React.forwardRef<HTMLDivElement, SkeletonProps>(
  ({ children, className, ...props }, ref) => {
    // eslint-disable-next-line @eslint-react/no-children-count
    const Comp = React.Children.count(children) === 1 ? Slot : 'div'
    return (
      <Comp className={className} {...props} ref={ref}>
        {children}
      </Comp>
    )
  }
)
LoadedSkeletonWrapper.displayName = 'LoadedSkeletonWrapper'

export { Skeleton }
