import * as React from 'react'
import { type StyleProps } from '@strise/react-utils'
import { Button, type ButtonProps } from '../Button'
import { cn } from '../../utils/className'

export interface ButtonGroupOption<T> {
  buttonProps?: ButtonProps
  text: string
  value: T
}

export interface ButtonGroupProps<T extends string | boolean> extends StyleProps {
  buttonProps?: ButtonProps
  disabled?: boolean
  onClick: (value: T) => void
  options: ButtonGroupOption<T>[]
  palette?: ButtonProps['palette']
  size?: ButtonProps['size']
  value: T
}

const extractButtonGroupBorderRadius = (
  index: number,
  optionsLength: number
): 'rounded-l' | 'rounded-r' | undefined => {
  if (index === 0) return 'rounded-l'
  if (index === optionsLength - 1) return 'rounded-r'
}

export const ButtonGroup = <T extends string | boolean>({
  buttonProps,
  className,
  onClick,
  options,
  palette = 'secondary',
  size,
  value,
  ...props
}: ButtonGroupProps<T>): React.ReactNode => {
  return (
    <div className={cn('inline-flex flex-row', className)} {...props}>
      {options.map((option, index) => (
        <React.Fragment key={option.value.toString()}>
          <Button
            size={size}
            variant='contained'
            palette={value === option.value ? palette : 'tertiary'}
            onClick={() => onClick(option.value)}
            {...buttonProps}
            {...option.buttonProps}
            className={cn(
              'shrink-0 grow basis-0 justify-start truncate pl-3 text-sm',
              extractButtonGroupBorderRadius(index, options.length),
              buttonProps?.className,
              option.buttonProps?.className
            )}
          >
            {option.text}
          </Button>
          {index < options.length - 1 && <div className='flex-[0_0_1px]' />}
        </React.Fragment>
      ))}
    </div>
  )
}
