import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconChevronLeftSuperSmall = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' d='m6 12 9-5.196v10.392L6 12Z' />
    </svg>
  )
)
IconChevronLeftSuperSmall.displayName = 'IconChevronLeftSuperSmall'
