import React from 'react'
import {
  IconSanction,
  Card,
  CardContent,
  CardTitle,
  CardDescription,
  CardHeader,
  type CardProps
} from '@strise/ui-components'
import { type RiskSummaryRisk, RiskSummaryRiskLevel, RiskSummaryRiskKind } from '@strise/types'
import { t } from '@lingui/macro'

const kindLink: Record<RiskSummaryRiskKind, string> = {
  [RiskSummaryRiskKind.Pep]: '#pep-and-sanctions',
  [RiskSummaryRiskKind.Ubo]: '#ownership',
  [RiskSummaryRiskKind.Industry]: '#ownership',
  [RiskSummaryRiskKind.Sanction]: '#pep-and-sanctions',
  [RiskSummaryRiskKind.Jurisdiction]: '#ownership',
  [RiskSummaryRiskKind.Psc]: '#roles'
}

export const RiskCard: React.FC<{ risk: RiskSummaryRisk }> = ({ risk }) => {
  const { found, kind, level } = risk
  const linkHref = found && found > 0 ? kindLink[kind] : undefined
  const action: 'anchor' | 'none' = linkHref ? 'anchor' : 'none'
  const isPlural = (found ?? 0) > 1
  const textDescription = level === RiskSummaryRiskLevel.NotApplicable ? t`Not Applicable` : `${found} ${t`found`}`
  const textDescriptionColor = level === RiskSummaryRiskLevel.NotApplicable ? 'text-gray-40' : 'text-text-primary'
  const icon = level === RiskSummaryRiskLevel.High && (
    <IconSanction className='shrink-0 self-center text-semantic-danger-main' size='xl' />
  )

  const kindTitle: Record<RiskSummaryRiskKind, string> = {
    [RiskSummaryRiskKind.Psc]: isPlural ? t`PSCs` : t`PSC`,
    [RiskSummaryRiskKind.Pep]: isPlural ? t`PEPs` : t`PEP`,
    [RiskSummaryRiskKind.Ubo]: isPlural ? t`UBOs` : t`UBO`,
    [RiskSummaryRiskKind.Industry]: isPlural ? t`Industries` : t`Industry`,
    [RiskSummaryRiskKind.Sanction]: isPlural ? t`Sanctions` : t`Sanction`,
    [RiskSummaryRiskKind.Jurisdiction]: isPlural ? t`Jurisdictions` : t`Jurisdiction`
  }

  const riskLevelCardProps: Partial<Record<RiskSummaryRiskLevel, Partial<CardProps>>> = {
    [RiskSummaryRiskLevel.NotApplicable]: {
      palette: 'white'
    },
    [RiskSummaryRiskLevel.Low]: {
      palette: 'warning',
      className: 'border-none'
    },
    [RiskSummaryRiskLevel.High]: {
      palette: 'danger',
      className: 'border-none'
    },
    [RiskSummaryRiskLevel.None]: {
      palette: 'neutral',
      className: `border-none ${linkHref && 'hover:bg-gray-10'}`
    }
  }

  const cardProps: Partial<CardProps> = {
    variant: 'contained',
    size: 'lg',
    rounded: 'sm',
    action,
    to: linkHref,
    ...riskLevelCardProps[level]
  }

  return (
    <Card {...cardProps}>
      <CardContent className='flex-row justify-between space-y-2 p-0'>
        <CardHeader className='flex flex-col items-start p-0'>
          <CardTitle className='py-0 text-sm text-gray-40'>{kindTitle[kind]}</CardTitle>
          <CardDescription className={textDescriptionColor}>{textDescription}</CardDescription>
        </CardHeader>

        {icon}
      </CardContent>
    </Card>
  )
}
