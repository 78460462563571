import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconWarningYellow = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 21'
      {...props}
    >
      <path
        fill='#FFD12E'
        d='M9.864 1.019c.768-1.359 2.725-1.359 3.493 0l9.602 16.988c.756 1.337-.21 2.993-1.746 2.993H2.009C.473 21-.493 19.344.263 18.007L9.864 1.019Z'
      />
      <path fill='currentColor' d='M10.608 6.957h2.006v7.021h-2.006V6.957ZM10.608 14.982h2.006v2.006h-2.006v-2.006Z' />
    </svg>
  )
)
IconWarningYellow.displayName = 'IconWarningYellow'
