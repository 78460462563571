import { EntityDispositionStatusKind, type CreateEntityDispositionInput, EntityDispositionKind } from '@strise/types'
import { useCreateEntityDispositionsMutation, useDeleteEntityDispositionsMutation } from '~/graphqlOperations'
import { Button, cn } from '@strise/ui-components'
import { dialogBgColor } from '~/components/PepAndSanctions/dispositionDialogUtils'
import { type PepInfoFragment, type PersonBaseFragment } from '~/graphqlTypes'
import React from 'react'
import { t, Trans } from '@lingui/macro'
import { uuid } from '@jsplumb/browser-ui'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { toast, useCurrentUser, useIsAdmin } from '@strise/app-shared'

export const PepDispositionDialogActions = ({
  extractInputs,
  onCompleted,
  pepInfos,
  person
}: {
  extractInputs: (status: EntityDispositionStatusKind) => CreateEntityDispositionInput[]
  onCompleted: () => void
  pepInfos: PepInfoFragment[]
  person: PersonBaseFragment
}): React.ReactNode => {
  const currentUser = useCurrentUser()
  const isAdmin = useIsAdmin()
  const [statusLoading, setStatusLoading] = React.useState<EntityDispositionStatusKind | null>(null)
  const features = useCurrentUserFeatures()

  const handleCompleted = (): void => {
    onCompleted()
    setStatusLoading(null)
    toast.success(t`PEP dispositioning saved`)
  }

  const [create] = useCreateEntityDispositionsMutation({ onCompleted: handleCompleted })
  const [remove, { loading: deleteLoading }] = useDeleteEntityDispositionsMutation()

  const handleDelete = async (): Promise<void> => {
    await remove({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
        kind: EntityDispositionKind.Pep
      }
    })
  }

  const handleSave = (status: EntityDispositionStatusKind) => async (): Promise<void> => {
    setStatusLoading(status)
    const inputs = extractInputs(status)

    const nextPepInfos: PepInfoFragment[] = pepInfos.map((pepInfo) => {
      const matchingInput = inputs.find((input) => input.externalId === pepInfo.id)

      return {
        ...pepInfo,
        disposition: matchingInput
          ? {
              id: uuid(),
              status: matchingInput.status,
              createdBy: currentUser,
              created: new Date().toISOString(),
              comment: matchingInput.comment ?? null,
              __typename: 'EntityDisposition'
            }
          : null
      }
    })

    await create({
      variables: {
        entity: person.id,
        includePepV1: !!features.PEP_AND_SANCTIONS_V2 && !features.PEP_DISPOSITIONING,
        includePepV2: !!features.PEP_AND_SANCTIONS_V2 && !!features.PEP_DISPOSITIONING,
        inputs
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createEntityDispositions: {
          __typename: 'MutationQuery',
          person: {
            ...person,
            pepInfos: nextPepInfos
          }
        }
      }
    })

    // TODO: Review cache update
    // TODO: Company network cache update
    // TODO: Risk summary cache update
  }

  const disabled = !!statusLoading || deleteLoading

  return (
    <div className={cn('flex w-full gap-4 px-8 pb-8', dialogBgColor)}>
      {isAdmin && (
        <Button
          variant='ghost'
          palette='danger'
          onClick={handleDelete}
          loading={deleteLoading}
          disabled={disabled}
          data-track='Pep disposition dialog / Reset'
        >
          <Trans>Reset all (admin)</Trans>
        </Button>
      )}
      <Button
        className='ml-auto'
        variant='contained'
        palette='primary'
        onClick={handleSave(EntityDispositionStatusKind.ConfirmedTrue)}
        loading={statusLoading === EntityDispositionStatusKind.ConfirmedTrue}
        disabled={disabled}
        data-track='Pep disposition dialog / Confirm PEP status'
      >
        {t`Confirm PEP status`}
      </Button>
      <Button
        variant='contained'
        palette='secondary'
        onClick={handleSave(EntityDispositionStatusKind.ConfirmedFalse)}
        loading={statusLoading === EntityDispositionStatusKind.ConfirmedFalse}
        disabled={disabled}
        data-track='Pep disposition dialog / Mark as false positive(s)'
      >
        {t`Mark as false positive(s)`}
      </Button>
    </div>
  )
}
