import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconPlus = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' fillRule='evenodd' d='M11 22V2h2v20h-2Z' clipRule='evenodd' />
      <path fill='currentColor' fillRule='evenodd' d='M2 11h20v2H2v-2Z' clipRule='evenodd' />
    </svg>
  )
)
IconPlus.displayName = 'IconPlus'
