import { type BaseEntityLikeFragment, type KeyEntityMetaFragment } from '~/graphqlTypes'
import React from 'react'
import { EntityMetaItem } from '~/components/EntityMeta/EntityMetaItem'
import { cn } from '@strise/ui-components'
import { hasKeyMetaExtra, hasKeyMetaV2 } from '~/components/EntityMeta/entityMetaUtils'
import { useEntityQuery } from '~/graphqlOperations'

const useEntityKeyMetaItems = (entity: BaseEntityLikeFragment): KeyEntityMetaFragment[] | null => {
  const { data } = useEntityQuery({ variables: { id: entity.id }, skip: hasKeyMetaV2(entity) })

  if (hasKeyMetaV2(entity)) return entity.keyMetaV2

  if (!data?.entity) return null

  if (!hasKeyMetaV2(data.entity)) return null

  return data.entity.keyMetaV2
}

export const EntityMetaItems = ({
  column,
  entity,
  maxItems,
  noTooltip
}: {
  column?: boolean
  entity: BaseEntityLikeFragment
  maxItems?: number
  noTooltip?: boolean
}): React.ReactNode => {
  const entityKeyMetaItems = useEntityKeyMetaItems(entity)

  if (!entityKeyMetaItems) return null

  const items = (hasKeyMetaExtra(entity) ? entity.keyMetaExtra : entityKeyMetaItems).slice(0, maxItems)

  if (!items.length) return null

  return (
    <div className={cn('items-center', column ? 'block' : 'flex')}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1
        return (
          <EntityMetaItem
            key={index}
            item={item}
            noTooltip={noTooltip}
            className={cn(column && isLast ? 'mb-0' : 'mb-1', !column && isLast ? 'mr-0' : 'mr-4')}
          />
        )
      })}
    </div>
  )
}
