import type { BaseEntityLikeFragment, KeyEntityMetaFragment } from '~/graphqlTypes'

export const hasKeyMetaV2 = (
  entity: BaseEntityLikeFragment
): entity is BaseEntityLikeFragment & { keyMetaV2: KeyEntityMetaFragment[] } => {
  return 'keyMetaV2' in entity && Array.isArray(entity.keyMetaV2)
}

export const hasKeyMetaExtra = (
  entity: BaseEntityLikeFragment
): entity is BaseEntityLikeFragment & { keyMetaExtra: KeyEntityMetaFragment[] } => {
  return 'keyMetaExtra' in entity && Array.isArray(entity.keyMetaExtra)
}
