import { getBrowserGlobals } from './browserGlobals'

export const keepScrollPosition = (ref: React.RefObject<HTMLElement>): void => {
  // Position before the list rerenders
  const originalButtonPosition = ref.current?.getBoundingClientRect().top ?? 0
  setTimeout(() => {
    if (ref.current) {
      // First scroll so the element is at the top of the screen
      ref.current.scrollIntoView({ behavior: 'instant', block: 'start' })

      const newButtonPosition = ref.current.getBoundingClientRect().top
      const scrollableParent = getScrollableParent(ref.current)

      // Then scroll the parent so the element is at the same position as before
      const scrollDistance = newButtonPosition - originalButtonPosition
      scrollableParent?.scrollBy({ top: scrollDistance, behavior: 'instant' })
    }
  }, 0)
}

export const isScrollable = (element: Element): boolean => {
  const window = getBrowserGlobals()?.window

  const hasScrollableContent = element.scrollHeight > element.clientHeight

  const overflowYStyle = window?.getComputedStyle(element).overflowY
  const isOverflowHidden = overflowYStyle?.includes('hidden')

  return hasScrollableContent && !isOverflowHidden
}

export const getScrollableParent = (element: Element | undefined): Element | undefined => {
  const document = getBrowserGlobals()?.document
  if (!element || element === document?.body) {
    return document?.body
  }
  return isScrollable(element) ? element : getScrollableParent(element.parentNode as Element)
}
