import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getBrowserGlobals } from '@strise/react-utils'
import { type CoordinateInput, type Coordinate } from '@strise/types'
import { type SetStateFn } from '@strise/react-utils'
import { gMapButton, useGoogleMaps } from '~/utils/googleMapsUtils'

const oslo = { lat: 59.911_491, lng: 10.757_933 }

const pathListeners = (path: google.maps.MVCArray<google.maps.LatLng>, action: () => void): void => {
  google.maps.event.addListener(path, 'insert_at', action)
  google.maps.event.addListener(path, 'remove_at', action)
  google.maps.event.addListener(path, 'set_at', action)
}

export const GrowLocationAreaSelection = ({
  polygon,
  setPolygon
}: {
  polygon: Coordinate[]
  setPolygon: SetStateFn<Coordinate[]>
}): React.ReactNode => {
  const [mapPolygon, setMapPolygon] = useState<google.maps.Polygon | null>(null)

  const change = useCallback(() => {
    if (!mapPolygon || mapPolygon.getPath().getArray().length < 3) return
    const coords = mapPolygon
      .getPath()
      .getArray()
      .map((point) => ({ latitude: point.lat(), longitude: point.lng() }))
    if (coords.length < 3) return
    const sanitizedCoords = coords.map(
      (coord) =>
        ({
          __typename: 'Coordinate',
          latitude: Number.parseFloat(coord.latitude.toFixed(6)),
          longitude: Number.parseFloat(coord.longitude.toFixed(6))
        }) as const
    )
    setPolygon(sanitizedCoords)
  }, [mapPolygon])

  const reset = useCallback(() => {
    if (!mapPolygon) return
    mapPolygon.setPath([])
    pathListeners(mapPolygon.getPath(), change)
    setPolygon([])
  }, [mapPolygon])

  const mapOptions = useMemo(() => ({ zoom: 12, center: oslo, fullscreenControl: false }), [])
  const { map, mapRef } = useGoogleMaps(mapOptions)

  React.useEffect(() => {
    if (!map) return

    setMapPolygon(
      new google.maps.Polygon({
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: 'rgba(0, 0, 0, 0.5)',
        editable: true,
        draggable: true
      })
    )
  }, [map])

  useEffect(() => {
    if (!map || !mapPolygon) return

    mapPolygon.setMap(map)

    try {
      // To avoid uncaught error if no google maps
      mapPolygon.getPath()
    } catch {
      return
    }

    if (polygon.length) {
      const bounds = new google.maps.LatLngBounds()

      const selectionPath = polygon.map((coord: CoordinateInput) => {
        const latLng = new google.maps.LatLng(coord.latitude, coord.longitude)
        bounds.extend(latLng)
        return latLng
      })

      mapPolygon.setPath(selectionPath)
      map.setCenter(bounds.getCenter())
    } else {
      getBrowserGlobals()?.window.navigator.geolocation.getCurrentPosition((position) => {
        map.setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      })
    }

    pathListeners(mapPolygon.getPath(), change)
    google.maps.event.addListener(mapPolygon, 'dragend', change)
    google.maps.event.addListener(map, 'click', ({ latLng }: google.maps.MapMouseEvent) =>
      latLng ? mapPolygon.getPath().push(latLng) : null
    )

    const topRightControlsElement = getBrowserGlobals()?.document.createElement('div')
    if (topRightControlsElement) {
      topRightControlsElement.style.padding = '10px'
      const gMapButtonElement = gMapButton('Reset', reset)
      if (gMapButtonElement) {
        topRightControlsElement.append(gMapButtonElement)
      }
      map.controls[google.maps.ControlPosition.TOP_RIGHT]?.push(topRightControlsElement)
    }
  }, [map, mapPolygon])

  return (
    <div>
      <div className='h-[800px] w-full' ref={mapRef} />
    </div>
  )
}
