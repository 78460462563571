import { useDarkMode, usePalette } from '@strise/ui-components-legacy'
import React from 'react'
import * as gmapsLoader from '@googlemaps/js-api-loader'
import { getBrowserGlobals } from '@strise/react-utils'
const { Loader } = gmapsLoader

export const useGoogleMaps = (
  options: google.maps.MapOptions
): { map: google.maps.Map | null; mapRef: React.MutableRefObject<HTMLDivElement | null> } => {
  const mapRef = React.useRef<HTMLDivElement | null>(null)
  const [map, setMap] = React.useState<google.maps.Map | null>(null)

  const optionsWithDefaults = {
    mapTypeControl: false,
    streetViewControl: false,
    ...options
  }

  React.useEffect(() => {
    ;(async () => {
      if (!mapRef.current || !getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.GOOGLE_MAPS_KEY) return

      const loader: { load: () => Promise<typeof google> } = new Loader({
        apiKey: getBrowserGlobals()?.window.__PUBLIC_ENV_VARS__?.GOOGLE_MAPS_KEY || ''
      })
      await loader.load()
      setMap(new google.maps.Map(mapRef.current, optionsWithDefaults))
    })()
  }, [mapRef])

  return { map, mapRef }
}

export const gMapButton = (text: string, action: () => void): HTMLDivElement | undefined => {
  const buttonElement = getBrowserGlobals()?.document.createElement('div')
  if (buttonElement) {
    buttonElement.style.backgroundColor = '#fff'
    buttonElement.style.borderRadius = '3px'
    buttonElement.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
    buttonElement.style.cursor = 'pointer'
    buttonElement.style.padding = '10px'
    buttonElement.style.textAlign = 'center'
    buttonElement.style.color = 'rgb(25,25,25)'
    buttonElement.style.fontFamily = 'Favorit Medium, sans-serif'
    buttonElement.style.fontSize = '14px'

    buttonElement.innerHTML = text
    buttonElement.addEventListener('click', action)
  }

  return buttonElement
}

export const useMapsPalette = (): google.maps.MapTypeStyle[] => {
  const { darkMode } = useDarkMode()
  const landPalette = darkMode ? 'secondary.40' : 'secondary.20'
  const landColor = usePalette(landPalette)
  const waterColor = usePalette('background.paper')

  return [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: landColor
        }
      ]
    },
    {
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: waterColor
        }
      ]
    }
  ]
}
