import { Skeleton } from '@strise/ui-components'
import React from 'react'

export const SidepanelTabsLoader = (): React.ReactNode => {
  return (
    <div className='flex h-sub-header bg-tertiary-main pt-1'>
      <Skeleton className='size-full px-8' />
    </div>
  )
}
