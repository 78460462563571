import { type CompanyNetworkEntityPersonFragment, type PepInfoFragment } from '@strise/app-shared/src/graphqlTypes'
import { Card, CardContent, cn } from '@strise/ui-components'
import * as React from 'react'
import { TestIDs } from '~/utils/testIDs'
import { PepPersonContent } from '~/components/PepAndSanctions/PepPersonContent'
import {
  systemSuggestedTruePepPredicate,
  userConfirmedTruePepPredicate
} from '~/components/PepAndSanctions/pepDispositionUtils'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

interface CompanyPepCardProps {
  associatedCompanies: CompanyNetworkEntityPersonFragment['associatedCompanies'] | null
  currentCompanyId: string | null
  noLink?: boolean
  pepInfos: PepInfoFragment[] | null | undefined
  person: CompanyNetworkEntityPersonFragment['person']
}

export const PersonPepCard = ({
  associatedCompanies,
  currentCompanyId,
  noLink,
  pepInfos,
  person
}: CompanyPepCardProps): React.ReactNode => {
  const features = useCurrentUserFeatures()
  const filteredPepInfos = pepInfos?.filter((pep) => pep.pep || pep.rca) ?? []

  const isPepOrRca = features.PEP_DISPOSITIONING
    ? filteredPepInfos.some(userConfirmedTruePepPredicate) || filteredPepInfos.some(systemSuggestedTruePepPredicate)
    : !!filteredPepInfos.length

  return (
    <Card
      palette={isPepOrRca ? 'warning' : undefined}
      size='sm'
      className={cn('border-none p-4', { 'p-0': !isPepOrRca })}
      rounded='sm'
      data-id={TestIDs.SidePanel.PepAndSanctions.pepCard(person.name ?? '')}
    >
      <CardContent className='gap-2 p-0'>
        <PepPersonContent
          associatedCompanies={associatedCompanies}
          currentCompanyId={currentCompanyId}
          pepInfos={pepInfos ?? []}
          person={person}
          noLink={noLink}
          showSystemSuggestedFalse={false}
          enableEdit={true}
        />
      </CardContent>
    </Card>
  )
}
