import { Button, Typography } from '@strise/ui-components'
import { SelectCompanyAssignee } from '~/components/Assignee/SelectCompanyAssignee'
import { SelectCompaniesStatus } from '~/components/CompanyStatus/SelectCompaniesStatus'
import { InputCompanyTags } from '~/components/InputCompanyTags'
import { SUB_HEADER_HEIGHT } from '~/constants'
import {
  type PortfolioCompaniesQuery,
  type CompanyTagConnectionEdgeFragment,
  type SimpleUserConnectionEdgeFragment
} from '~/graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { intersectByKey } from '@strise/ts-utils'
import { TableRow } from '@strise/ui-components-legacy'
import * as React from 'react'
import { useMemo } from 'react'
import { SelectAllCheckbox } from '@strise/app-shared'

interface CompaniesBulkActionsHeaderProps {
  companies: PortfolioCompaniesQuery['portfolioCompanies']['edges']
  count: number
  filterOpen: boolean
  onUnselectAll: () => void
  selected: Record<string, boolean>
}

const findIntersectedAssignees = (
  companies: PortfolioCompaniesQuery['portfolioCompanies']['edges'],
  selectedCompanyIds: string[]
): SimpleUserConnectionEdgeFragment[] => {
  const filteredCompanies = companies.filter((c) => selectedCompanyIds.includes(c.node.id))
  const assignees = filteredCompanies.map((c) => c.node.assignee?.edges.map((edge) => edge.node) ?? [])
  return intersectByKey(assignees, 'id').map((node) => ({
    node,
    __typename: 'SimpleUserConnectionEdge' as const
  }))
}

export const PortfolioBulkActionsHeader: React.FC<CompaniesBulkActionsHeaderProps> = ({
  companies,
  count,
  filterOpen,
  onUnselectAll,
  selected
}) => {
  const selectedCompanyIds = Object.keys(selected)
  const selectedCount = selectedCompanyIds.length

  const [intersectedTagEdges, setInteresectedTagEdges] = React.useState<CompanyTagConnectionEdgeFragment[]>([])

  const selectedCompanies = selectedCompanyIds.map((id) => ({ id }))

  const intersectedAssigneesEdges = useMemo(() => {
    // re-calculate intersected assignees whenever selected companies change
    return findIntersectedAssignees(companies, selectedCompanyIds)
  }, [companies, selected])

  const computeIntersectedTags = (): void => {
    const filteredCompanies = companies.filter((c) => selectedCompanyIds.includes(c.node.id))
    const companiesTagsNodes = filteredCompanies.map((c) => c.node.tags?.edges.map((edge) => edge.node) ?? [])

    const intersected = intersectByKey(companiesTagsNodes, 'id').map(
      (node) =>
        ({
          node,
          __typename: 'CompanyStickerConnectionEdge'
        }) as const
    )
    setInteresectedTagEdges(intersected)
  }

  return (
    <TableRow position='absolute' top={filterOpen ? SUB_HEADER_HEIGHT : 0} left={0} width={1}>
      <th className='block w-full p-0 text-left font-regular'>
        <div className='flex h-[calc(theme(height.sub-header)+1px)] w-full justify-between bg-secondary-shade-20'>
          <div className='flex items-center'>
            <div className='px-4 py-5'>
              <SelectAllCheckbox
                id='portfolio-unselect-all'
                onCheckedChange={onUnselectAll}
                selectedCount={selectedCount}
                totalCount={count}
                data-track='Portfolio / Unselect all checkbox'
              />
            </div>
            <div>
              <Typography>
                {selectedCount} <Trans>companies selected</Trans>
              </Typography>
            </div>
          </div>
          <div className='flex'>
            <SelectCompaniesStatus
              className='h-full'
              variant='ghost'
              companies={selectedCompanies}
              triggerText={<Typography className='pr-2' variant='aLabel'>{t`Status`}</Typography>}
              removable
              data-track-context='portfolio-multi'
            />

            <SelectCompanyAssignee
              startIcon={null}
              palette='tertiary'
              className='h-full'
              hideSelected
              companyIds={selectedCompanyIds}
              assignees={intersectedAssigneesEdges}
              inlineSearch={false}
              indicatorVariant='show'
              data-track-context='portfolio-multi'
            >
              <Trans>Assign</Trans>
            </SelectCompanyAssignee>

            <InputCompanyTags
              closeOnSelect
              startIcon={null}
              className='h-full'
              companyIds={selectedCompanyIds}
              tags={intersectedTagEdges}
              onAction={computeIntersectedTags}
              hideSelected
              inlineSearch={false}
              indicatorVariant='show'
              data-track-context='portfolio-multi'
            >
              <Trans>Tags</Trans>
            </InputCompanyTags>

            <Button
              variant='contained'
              palette='tertiary'
              className='h-full'
              onClick={onUnselectAll}
              data-track='Portfolio / Multi select / Cancel'
            >
              <Trans>Cancel</Trans>
            </Button>
          </div>
        </div>
      </th>
    </TableRow>
  )
}
