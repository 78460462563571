import * as React from 'react'
import { cn } from '../../../utils/className'
import { type IconProps, iconVariants } from '../../../utils/iconUtils'

export const IconExpandAnimatedB = React.forwardRef<SVGSVGElement, IconProps>(({ className, size, ...props }, ref) => (
  <svg
    ref={ref}
    className={cn(
      iconVariants({
        className,
        size
      })
    )}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    {...props}
  >
    <rect x='2' y='2' width='20' height='20' stroke='currentColor' strokeWidth='2' />
    <path d='M9 16L5 12L9 8' stroke='currentColor' strokeWidth='2' />
    <path d='M15 8L19 12L15 16' stroke='currentColor' strokeWidth='2' />
    <path d='M18 12L6 12' stroke='currentColor' strokeWidth='2' />
  </svg>
))
IconExpandAnimatedB.displayName = 'IconExpandAnimatedB'
