import {
  Button,
  cn,
  IconButton,
  IconChat,
  IconCross,
  IconPenBox,
  IconSend,
  Input,
  Typography
} from '@strise/ui-components'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { Trans } from '@lingui/macro'
import { formatDate, UserAvatar, useCurrentUser } from '@strise/app-shared'
import { replaceItemFromArray } from '@strise/ts-utils'
import { type SetStateFn, useContext } from '@strise/react-utils'
import { type InlineCommentState } from '~/utils/reviewUtils2'
import { BASE_Z_INDEX } from '~/utils/zIndexes'
import * as React from 'react'
import { useHover, useOnClickOutside, useToggle } from 'usehooks-ts'

const INLINE_COMMENTS_WIDTH = 300

const InlineComment = ({
  inlineComment,
  isEditing,
  isLast,
  onRemove,
  toggleEdit
}: {
  inlineComment: InlineCommentState
  isEditing: boolean
  isLast: boolean
  onRemove: () => void
  toggleEdit: () => void
}): React.ReactNode => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const isHovered = useHover(containerRef)
  const currentUser = useCurrentUser()

  return (
    <div
      className={cn(
        'flex items-center justify-between border-divider p-4',
        isLast ? 'border-b-0' : 'border-b',
        isHovered ? 'bg-background-default' : 'bg-background-paper'
      )}
      ref={containerRef}
    >
      <div className='flex'>
        <UserAvatar user={inlineComment.createdBy} className='mr-2 size-5' />

        <div>
          <div className='flex items-center'>
            <Typography className='mr-2' variant='aLabel'>
              {inlineComment.createdBy.name}
            </Typography>
            <Typography className='text-primary-shade-20' variant='aLabelSmall'>
              {formatDate(inlineComment.createdAt, { relative: true })}
            </Typography>
          </div>
          <Typography className='hyphens-auto [overflow-wrap:anywhere]'>{inlineComment.message}</Typography>
        </div>
      </div>
      <div className='ml-1 flex w-[3.75rem] shrink-0'>
        {isEditing && (
          <div className='text-center'>
            <Typography className='block text-text-secondary' variant='aLabelSmall'>
              <Trans>Editing</Trans>
            </Typography>
            <Button
              variant='ghost'
              palette='primary'
              className='block h-auto p-1 text-sm'
              onClick={toggleEdit}
              data-track='Review / Inline comment / Cancel edit'
            >
              <Trans>Cancel</Trans>
            </Button>
          </div>
        )}

        {!isEditing && isHovered && inlineComment.createdBy.id === currentUser.id && (
          <>
            <IconButton
              className='mr-2 rounded-full'
              variant='contained'
              palette='tertiary'
              onClick={toggleEdit}
              data-track='Review / Inline comment / Edit'
            >
              <IconPenBox size='md' />
            </IconButton>
            <IconButton
              className='rounded-full'
              variant='contained'
              palette='tertiary'
              onClick={onRemove}
              data-track='Review / Inline comment / Delete'
            >
              <IconCross size='md' />
            </IconButton>
          </>
        )}
      </div>
    </div>
  )
}

export const ReviewCardInlineComments2 = ({
  customCheckboxName,
  inlineComments,
  isHovered,
  rowKey,
  setInlineComments,
  title
}: {
  customCheckboxName?: string
  inlineComments: InlineCommentState[] | undefined
  isHovered: boolean
  rowKey: string
  setInlineComments: SetStateFn<InlineCommentState[] | undefined>
  title: React.ReactNode
}): React.ReactNode => {
  const [inputValue, setInputValue] = React.useState('')
  const [editId, setEditId] = React.useState<string | null>(null)
  const [showInlineComment, toggleShowInlineComment] = useToggle(false)
  const { isLargeScreen } = useContext(ContentViewContext)

  const ref = React.useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, toggleShowInlineComment)

  const currentUser = useCurrentUser()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(event.target.value)
  }

  const handleSubmitInlineComment = (event: React.FormEvent): void => {
    event.preventDefault()

    if (!inputValue.length) return

    setInlineComments((prevInlineComments) => {
      const newComment = {
        id: crypto.randomUUID(),
        createdBy: currentUser,
        message: inputValue,
        // TODO - we need to implement IDs for custom checkboxes, lose comments when updating names when work in progress
        customCheckboxName,
        createdAt: formatDate(new Date(), { format: "yyyy-MM-dd'T'HH:mm:ss" })
      }

      if (!prevInlineComments) return [newComment]

      if (editId !== null) {
        const index = prevInlineComments.findIndex((item) => item.id === editId)
        return replaceItemFromArray(prevInlineComments, index, newComment)
      }

      return [...prevInlineComments, newComment]
    })

    setInputValue('')
    setEditId(null)
  }

  const handleToggleEdit = (id: string): void => {
    if (!inlineComments) return

    if (editId === id) {
      setEditId(null)
      setInputValue('')
      return
    }

    setEditId(id)
    setInputValue(inlineComments.find((ic) => ic.id === id)?.message ?? '')
  }

  const handleRemove = (id: string): void => {
    if (editId !== null) setInputValue('')
    setEditId(null)
    setInlineComments((prevInlineComments) => {
      if (!prevInlineComments) return prevInlineComments
      return prevInlineComments.filter((ic) => ic.id !== id)
    })
  }

  const submitButtonElement = (
    <IconButton
      className='h-7 rounded-[4px] p-1'
      type='submit'
      variant='contained'
      palette='primary'
      data-track='Review / Inline comment / Submit'
    >
      <IconSend size='lg' />
    </IconButton>
  )

  return (
    <div
      className='absolute h-full min-w-[40px] rounded-[12px]'
      style={{
        right: showInlineComment && !isLargeScreen ? -(INLINE_COMMENTS_WIDTH + 4) : -40,
        top: showInlineComment ? 'calc(50% - 30px)' : 0,
        zIndex: showInlineComment ? BASE_Z_INDEX + 1 : BASE_Z_INDEX
      }}
      data-id={`Review / Inline Comment / ${rowKey || ''}`}
    >
      {!showInlineComment && (isHovered || !!inlineComments?.length) && (
        <IconButton
          className='h-full rounded-[12px] rounded-l-none'
          variant='contained'
          palette='tertiary'
          onClick={toggleShowInlineComment}
          data-track='Review / Inline comment / Open'
        >
          <div className='relative'>
            <IconChat className='text-text-link' />
            {!!inlineComments?.length && (
              <div className='absolute bottom-[-2px] left-[-2px] flex size-4 items-center justify-center rounded-full bg-primary-main text-background-paper'>
                <Typography variant='body2'>{inlineComments.length}</Typography>
              </div>
            )}
          </div>
        </IconButton>
      )}

      {showInlineComment && (
        <div
          className='rounded-[12px] border-2 border-solid border-divider bg-background-paper'
          ref={ref}
          style={{ width: INLINE_COMMENTS_WIDTH }}
        >
          <div className='flex items-center justify-between border-b border-solid border-divider p-1 pl-4'>
            <Typography className='text-text-secondary' variant='aLabelSmall'>
              {title}
            </Typography>
            <IconButton
              className='rounded-full bg-white'
              variant='contained'
              palette='tertiary'
              onClick={toggleShowInlineComment}
              data-track='Review / Inline Comment / Close'
            >
              <IconCross size='sm' />
            </IconButton>
          </div>
          <div className='pt-0'>
            {inlineComments?.map((inlineComment, index) => {
              const isLast = inlineComments.length - 1 === index
              const isEditing = editId === inlineComment.id
              return (
                <InlineComment
                  key={index}
                  inlineComment={inlineComment}
                  toggleEdit={() => handleToggleEdit(inlineComment.id)}
                  onRemove={() => handleRemove(inlineComment.id)}
                  isLast={isLast}
                  isEditing={isEditing}
                />
              )
            })}
            <form onSubmit={handleSubmitInlineComment}>
              <div className='p-4'>
                <Input
                  variant='outlined'
                  palette='tertiary'
                  className='mr-2 w-full rounded-lg'
                  inputClassName='w-[200px]'
                  value={inputValue}
                  onChange={handleInputChange}
                  autoFocus
                  endIcon={submitButtonElement}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}
