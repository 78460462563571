import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconItalic = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 16 16'
      {...props}
    >
      <path
        fill='#454545'
        d='M6.806.679h6.805v1.36h-2.859L7.996 14.293h2.893v1.36H4.083v-1.36h2.518L9.357 2.04H6.806V.679Z'
      />
    </svg>
  )
)
IconItalic.displayName = 'IconItalic'
