import * as React from 'react'
import { useState } from 'react'
import { EventsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelEventsCard'
import { Helmet } from 'react-helmet'
import { SidepanelPersonHeaderCard } from '~/components/Sidepanel/SidepanelCards/SidepanelPersonHeaderCard'
import { PersonDetailsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelPersonDetailsCard'
import { SidepanelOwnershipsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelOwnershipsCard'
import { type TabRefs } from '~/components/Sidepanel/SidepanelTabs/sidepanelTabsUtils'
import { SidepanelRolesCard } from '~/components/Sidepanel/SidepanelCards/SidepanelRolesCard'
import { SidepanelSimilarEntities } from '~/components/Sidepanel/SidepanelCards/SidepanelSimilarEntities'
import { type SidepanelPersonFragment } from '~/graphqlTypes'
import { SidepanelTab } from '~/utils/urls'
import { hasEntityAccess, isGraniteEntity } from '~/utils/entity'
import { SidepanelPersonPepsAndSanctionsCard } from '~/components/Sidepanel/SidepanelCards/SidepanelPersonPepsAndSanctionsCard'

export const SidepanelPersonLayout: React.FC<{
  person: SidepanelPersonFragment
  tabRefs: TabRefs
}> = ({ person, tabRefs }) => {
  const [showOriginal, setShowOriginal] = useState(false)

  return (
    <>
      <Helmet>
        <title>{person.name}</title>
      </Helmet>
      <SidepanelPersonHeaderCard ref={tabRefs[SidepanelTab.Person]} person={person} />
      {hasEntityAccess(person) && (
        <>
          {!person.isGlobalPerson && !isGraniteEntity(person) && <SidepanelSimilarEntities />}
          <PersonDetailsCard person={person} />
          <SidepanelPersonPepsAndSanctionsCard person={person} ref={tabRefs[SidepanelTab.PepsAndSanctions]} />
          <SidepanelRolesCard entity={person} ref={tabRefs[SidepanelTab.Roles]} sidepanelTab={SidepanelTab.Roles} />
          <SidepanelOwnershipsCard
            ref={tabRefs[SidepanelTab.Ownership]}
            sidepanelTab={SidepanelTab.Ownership}
            entity={person}
            showOriginal={showOriginal}
            setShowOriginal={setShowOriginal}
          />
          {!person.isGlobalPerson && <EventsCard ref={tabRefs[SidepanelTab.Events]} />}
        </>
      )}
    </>
  )
}
