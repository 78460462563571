import { type CountryFragment } from '~/graphqlTypes'
import React from 'react'
import { Tooltip } from '@strise/ui-components'
import { NationFlag } from '@strise/app-shared'

export const Countries = ({ countries }: { countries: CountryFragment[] }): React.ReactNode[] => {
  return countries.map((country, index) => {
    const isLast = index === countries.length - 1

    const content = (
      <div className='flex gap-1'>
        <NationFlag countryIsoCode={country.isoAlpha2Code} className='size-4' />
        <span>{country.englishName}</span>
      </div>
    )

    return (
      <React.Fragment key={country.isoAlpha2Code}>
        <Tooltip content={content}>
          <span>{country.isoAlpha2Code}</span>
        </Tooltip>
        {!isLast && <span>, </span>}
      </React.Fragment>
    )
  })
}
