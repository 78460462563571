import * as React from 'react'
import { type PortfolioTableColumn } from '~/views/Portfolio/utils/portfolioTableColumns'
import { TableHeadCell, TableRow } from '@strise/ui-components-legacy'
import { SUB_HEADER_HEIGHT } from '~/constants'
import { IconButton, IconCross } from '@strise/ui-components'
import { portfolioFilters } from '~/views/Portfolio/utils/portfolioFilters'
import { useContext } from '@strise/react-utils'
import { CurrentUserSettingsContext } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'

export const PortfolioFilterEdit: React.FC<{
  columns: PortfolioTableColumn[]
  toggleFilters: () => void
}> = ({ columns, toggleFilters }) => {
  const { saveSettings, settings } = useContext(CurrentUserSettingsContext)

  return (
    <TableRow borderBottom='0'>
      <TableHeadCell width={SUB_HEADER_HEIGHT} height={SUB_HEADER_HEIGHT} palette='secondary.90' position='relative'>
        <IconButton
          className='absolute inset-y-0 left-0 w-[theme(height.sub-header)] bg-secondary-shade-90'
          variant='contained'
          palette='secondary'
          onClick={toggleFilters}
          data-track='Portfolio / Filter / Close'
        >
          <IconCross />
        </IconButton>
      </TableHeadCell>

      {columns.map((v) => {
        const C = portfolioFilters[v.field]?.EditComponent
        return (
          <TableHeadCell key={v.field} height={SUB_HEADER_HEIGHT} p={0} palette='secondary.90' {...v.headCellProps}>
            {C && <C value={settings} setValue={saveSettings} />}
          </TableHeadCell>
        )
      })}
    </TableRow>
  )
}
