import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconCheckMedium = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M19.053 6.127 11.278 18.88 4.85 12.73l1.382-1.445 4.632 4.431 6.481-10.63 1.708 1.041Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconCheckMedium.displayName = 'IconCheckMedium'
