import * as React from 'react'
import { useState } from 'react'

/**
 * A custom hook to manage hover state with a delay.
 *
 * This hook provides `handleMouseEnter` and `handleMouseLeave` functions to manage hover states
 * with a specified delay. It is specifically designed to also handle elements rendered in a React Portal,
 * where traditional ref-based event listening does not work as expected.
 *
 * @param {number} [timeoutDelay=200] - The delay (in milliseconds) before setting the hover state.
 * @returns An object containing `handleMouseEnter`, `handleMouseLeave` event handlers, and the `isHovered` state.
 */
export function useHoverDelay(timeoutDelay: number = 200): {
  handleMouseEnter: () => void
  handleMouseLeave: () => void
  isHovered: boolean
} {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleMouseEnter = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setIsHovered(true)
    }, timeoutDelay)
  }

  const handleMouseLeave = (): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setIsHovered(false)
    }, timeoutDelay)
  }

  return { handleMouseEnter, handleMouseLeave, isHovered }
}
