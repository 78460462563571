import { cva } from 'class-variance-authority'

export const checkboxVariants = cva(
  'peer size-[22px] shrink-0 rounded-sm border-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      palette: {
        primary: 'border-primary-main data-[state=checked]:bg-primary-main data-[state=checked]:text-primary-main',
        secondary:
          'border-secondary-main data-[state=checked]:bg-secondary-main data-[state=indeterminate]:bg-secondary-main data-[state=checked]:text-secondary-main data-[state=indeterminate]:text-secondary-main',
        tertiary: 'border-tertiary-main data-[state=checked]:bg-tertiary-main data-[state=checked]:text-tertiary-main'
      }
    }
  }
)
