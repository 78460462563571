import {
  border,
  borderBottom,
  borderLeft,
  borderRight,
  type BordersProps,
  borderTop,
  compose,
  style,
  type Theme
} from '@mui/system'
import { paletteStyle } from './paletteStyle'
import { isNumber, isString } from 'lodash-es'

export type Borders = BordersProps & {
  borderBottomColor?: string
  borderLeftColor?: string
  borderRightColor?: string
  borderTopColor?: string
}

const getRadiusPx = (shapes: Theme['shape'], value: unknown): string | null => {
  if (isString(value)) {
    if (value in shapes) {
      return shapes[value] ?? null
    }
    return value
  }
  if (isNumber(value)) {
    return `${value}px`
  }
  return null
}

const borderRadius = (props: { theme: Theme }): unknown => {
  return style({
    prop: 'borderRadius',
    cssProperty: false,
    transform: (value) => {
      const radius = getRadiusPx(props.theme.shape, value)
      if (radius) return { borderRadius: radius }
      return {}
    }
  })(props)
}
// eslint-disable-next-line functional/immutable-data
borderRadius.filterProps = ['borderRadius']

const borderColor = paletteStyle({ prop: 'borderColor' })
const borderLeftColor = paletteStyle({ prop: 'borderLeftColor' })
const borderRightColor = paletteStyle({ prop: 'borderRightColor' })
const borderTopColor = paletteStyle({ prop: 'borderTopColor' })
const borderBottomColor = paletteStyle({ prop: 'borderBottomColor' })

export const borders = compose(
  border,
  borderTop,
  borderLeft,
  borderRight,
  borderBottom,
  borderRadius,
  borderColor,
  borderLeftColor,
  borderRightColor,
  borderTopColor,
  borderBottomColor
)
