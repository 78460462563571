import { Typography } from '@strise/ui-components'
import React from 'react'

export const Detail2 = ({
  children,
  dataId,
  description,
  title
}: {
  children: React.ReactNode
  dataId: string
  description: string | null
  title: string
}): React.ReactNode => {
  return (
    <div className='grid grid-cols-3 py-4'>
      <div className='flex flex-col gap-2'>
        <Typography className='text-text-secondary' variant='aLabel'>
          {title}
        </Typography>
        <Typography variant='aLabelSmall' className='text-text-secondary'>
          {description}
        </Typography>
      </div>
      <div className='col-span-2 flex flex-col gap-4' data-id={dataId}>
        {children}
      </div>
    </div>
  )
}
