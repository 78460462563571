import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconSanction = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <g clipPath='url(#IconSanction_svg__a)'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M17.173 1.497A2 2 0 0 0 15.76.912L8.23.915a2 2 0 0 0-1.414.586l-5.32 5.325A2 2 0 0 0 .91 8.241l.003 7.527a2 2 0 0 0 .587 1.414l5.325 5.32a2 2 0 0 0 1.414.586l7.528-.003a2 2 0 0 0 1.414-.586l5.32-5.325a2 2 0 0 0 .586-1.415l-.003-7.527a2 2 0 0 0-.587-1.414l-5.325-5.32ZM11 6v9h2V6h-2Zm0 10v2h2v-2h-2Z'
          clipRule='evenodd'
        />
      </g>
      <defs>
        <clipPath id='IconSanction_svg__a'>
          <path fill='#fff' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  )
)
IconSanction.displayName = 'IconSanction'
