import { type MatchInfoAnalysisFragment } from '@strise/app-shared/src/graphqlTypes'
import { MatchInfoKind } from '@strise/types/src/graphqlTypes'
import { cn, Divider, IconCheck, IconCross, IconHelp, IconWarning, Typography } from '@strise/ui-components'
import React from 'react'
import { Trans } from '@lingui/macro'

const kindToIcon: Partial<Record<MatchInfoKind, React.ReactElement>> = {
  [MatchInfoKind.FullMatch]: <IconCheck className='size-3 text-semantic-success-main' />,
  [MatchInfoKind.PartialMatch]: <IconWarning className='size-3 text-semantic-notice-main' />,
  [MatchInfoKind.NoMatch]: <IconCross className='size-3 text-semantic-danger-main' />,
  [MatchInfoKind.MissingData]: <IconHelp className='size-3 text-text-secondary' />
}

export const MatchInfoAnalysisTooltipContent = ({
  matchInfoAnalysis
}: {
  matchInfoAnalysis: MatchInfoAnalysisFragment
}): React.ReactNode => {
  return (
    <div className='min-w-[250px] space-y-4 p-4'>
      <div className='space-y-2'>
        <Typography variant='aLabelBold'>Match Details</Typography>
        {matchInfoAnalysis.matchInfos.map((matchInfo, index) => (
          <div key={index} className='flex items-center justify-between'>
            <span className='flex items-center gap-2'>
              {kindToIcon[matchInfo.kind]}
              <Typography variant='body2'>{matchInfo.kindName}</Typography>
            </span>
            <Typography variant='body2'>
              {matchInfo.name}
              {matchInfo.description && ` (${matchInfo.description})`}
            </Typography>
          </div>
        ))}
      </div>

      {matchInfoAnalysis.algorithm.length > 0 && (
        <>
          <Divider />

          <div className='space-y-2'>
            <Typography variant='aLabelBold'>
              <Trans>Calculation</Trans>
            </Typography>
            {matchInfoAnalysis.algorithm.map((algo, index) => (
              <Typography key={index} variant='body2'>
                {algo}
              </Typography>
            ))}
          </div>
        </>
      )}

      <Divider />

      <div className='space-y-2'>
        <div className='flex items-center justify-between'>
          <Typography variant='body2' className='font-bold'>
            <Trans>Result</Trans>
          </Typography>
          <Typography
            variant='body2'
            className={cn(
              'font-bold',
              matchInfoAnalysis.suggestedTruePositive ? 'text-semantic-success-main' : 'text-semantic-warning-main'
            )}
          >
            {matchInfoAnalysis.result}
          </Typography>
        </div>
        <div className='flex items-center justify-between'>
          <Typography variant='body2'>
            <Trans>Threshold</Trans>
          </Typography>
          <Typography variant='body2'>{matchInfoAnalysis.threshold}</Typography>
        </div>
        <Typography
          className={`text-center ${
            matchInfoAnalysis.suggestedTruePositive ? 'text-semantic-success-main' : 'text-semantic-warning-main'
          }`}
        >
          {matchInfoAnalysis.suggestedTruePositive ? <Trans>Suggested true</Trans> : <Trans>Suggested false</Trans>}
        </Typography>
      </div>
    </div>
  )
}
