import { t } from '@lingui/macro'
import { useToggle } from 'usehooks-ts'

export const useTruncatedList = <T>(
  items: T[],
  truncateAfter: number | null | undefined
): {
  buttonText: string
  hasReachedTreshold: boolean
  itemsLeftText: string
  showAll: boolean
  toggleShowAll: () => void
  truncatedItems: T[]
} => {
  const [showAll, toggleShowAll] = useToggle(false)

  const truncatedItems = showAll || !truncateAfter ? items : items.slice(0, truncateAfter)

  const hasReachedTreshold = !!(truncateAfter && items.length > truncateAfter)

  return {
    truncatedItems,
    showAll,
    toggleShowAll,
    buttonText: showAll ? t`Show less` : t`Show all`,
    itemsLeftText: `${truncatedItems.length} / ${items.length}${showAll ? '' : ' - '}`,
    hasReachedTreshold
  }
}
