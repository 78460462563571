import { ContentLanguage, CountryKind } from '@strise/types'

// Convert all content languages to country kinds
export const contentLanguageToCountryKind: Record<ContentLanguage, CountryKind> = {
  [ContentLanguage.Norwegian]: CountryKind.No,
  [ContentLanguage.Swedish]: CountryKind.Se,
  [ContentLanguage.Danish]: CountryKind.Dk,
  [ContentLanguage.Finnish]: CountryKind.Fi,
  [ContentLanguage.English]: CountryKind.Gb,
  [ContentLanguage.French]: CountryKind.Fr,
  [ContentLanguage.German]: CountryKind.De,
  [ContentLanguage.Spanish]: CountryKind.Es,
  [ContentLanguage.Belgian]: CountryKind.Be,
  [ContentLanguage.Dutch]: CountryKind.Nl,
  [ContentLanguage.Luxembourgish]: CountryKind.Lu
}

// Convert all country kinds to content languages
export const countryKindToContentLanguage: Partial<Record<CountryKind, ContentLanguage>> = {
  [CountryKind.No]: ContentLanguage.Norwegian,
  [CountryKind.Se]: ContentLanguage.Swedish,
  [CountryKind.Dk]: ContentLanguage.Danish,
  [CountryKind.Fi]: ContentLanguage.Finnish,
  [CountryKind.Gb]: ContentLanguage.English,
  [CountryKind.Fr]: ContentLanguage.French,
  [CountryKind.De]: ContentLanguage.German,
  [CountryKind.Es]: ContentLanguage.Spanish,
  [CountryKind.Be]: ContentLanguage.Belgian,
  [CountryKind.Nl]: ContentLanguage.Dutch,
  [CountryKind.Lu]: ContentLanguage.Luxembourgish
}
