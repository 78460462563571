import { useCustomerId } from '~/contexts/TeamContext/TeamContext'
import { hasOneMonthPassed } from '@strise/app-shared'
import { isRateLimitQuotaExceeded } from '~/utils/rateLimits'
import { extractRequestButtonProps } from '~/utils/button'
import { CreditReportMonitorButton } from '~/components/Sidepanel/SidepanelCards/CreditReport/CreditReportMonitorButton'
import { ContactStrise } from '~/components/ContactStrise'
import { t, Trans } from '@lingui/macro'
import { CreditScoreConfirmDialog } from '~/components/CreditScore/CreditScoreConfirmDialog'
import * as React from 'react'
import { type LatestRateLimitQuery, type SidepanelCompanyFragment } from '~/graphqlTypes'
import { useOrderCreditReportMutation } from '~/graphqlOperations'
import { useToggle } from 'usehooks-ts'
import { track } from '~/utils/tracking'
import { TrackedActivityKind } from '@strise/types'
import { Button, type ButtonProps } from '@strise/ui-components'
import { TestIDs } from '~/utils/testIDs'

export interface OrderCreditReportProps {
  company: SidepanelCompanyFragment
  context: 'Sidepanel'
  fetchCreditScoreButtonProps?: ButtonProps
  isConfirmationNeeded: boolean
  rateLimitData: LatestRateLimitQuery | null | undefined
}

export const OrderCreditReport = ({
  company,
  context,
  fetchCreditScoreButtonProps,
  isConfirmationNeeded,
  rateLimitData
}: OrderCreditReportProps): React.ReactNode => {
  const customerId = useCustomerId()
  const [confirmOpen, toggleConfirmOpen, setConfirmOpen] = useToggle(false)

  const handleCompleted = (): void => {
    track(TrackedActivityKind.StriseCreditScoreFetched, {
      companyId: company.id,
      origin: 'SIDEPANEL'
    })
  }

  const [orderCreditReport, { loading: orderCreditReportLoading }] = useOrderCreditReportMutation({
    onCompleted: handleCompleted
  })

  if (!rateLimitData) return null

  const { creditReport } = company
  const rateLimitQuotaExceeded = isRateLimitQuotaExceeded(rateLimitData)

  const handleOrderCreditReport = async (): Promise<void> => {
    if (orderCreditReportLoading) return

    setConfirmOpen(false)
    await orderCreditReport({
      variables: {
        company: company.id,
        customer: customerId
      }
    })
  }

  const requestButtonProps = extractRequestButtonProps(!!creditReport && !hasOneMonthPassed(creditReport.date))

  return (
    <>
      <div className='flex items-center gap-2'>
        <CreditReportMonitorButton companyId={company.id} context={context} />
        {rateLimitQuotaExceeded && (
          <ContactStrise
            data-track={`Button / ${context} / Credit report / Contact Strise to extend quota`}
            additionalText={t`to extend quota`}
            intention={t`extend credit score quota`}
          />
        )}
        {!rateLimitQuotaExceeded && (
          <Button
            {...requestButtonProps}
            onClick={isConfirmationNeeded ? toggleConfirmOpen : handleOrderCreditReport}
            loading={orderCreditReportLoading}
            disabled={!rateLimitData}
            data-track={
              isConfirmationNeeded
                ? `Button / ${context} / Credit report / Open Confirmation dialog`
                : `Button / ${context} / Credit report / Fetch score`
            }
            data-id={TestIDs.Common.CreditScore.fetchButton}
            {...fetchCreditScoreButtonProps}
          >
            {fetchCreditScoreButtonProps?.children ?? <Trans>Fetch score</Trans>}
          </Button>
        )}
      </div>
      {confirmOpen && (
        <CreditScoreConfirmDialog
          onConfirm={handleOrderCreditReport}
          onCancel={toggleConfirmOpen}
          loading={orderCreditReportLoading}
          disabled={!rateLimitData}
          context={context}
        />
      )}
    </>
  )
}
