import { cva } from 'class-variance-authority'

export const typographyVariants = cva('font-sans', {
  variants: {
    variant: {
      h1: 'text-4xl font-medium leading-[3rem]',
      h2: 'text-3xl font-medium leading-8',
      h3: 'text-2xl font-medium leading-8',
      h4: 'text-2xl font-medium leading-8',
      h5: 'text-xl font-medium leading-7',
      h6: 'text-xl font-medium leading-7',
      subtitle1: 'text-lg font-medium leading-6',
      subtitle2: 'text-md font-medium leading-4',
      subtitle3: 'text-sm font-medium leading-4',
      body1: 'text-md font-book leading-6',
      body2: 'text-sm font-book leading-4',
      body3: 'text-xs font-regular leading-4',
      emphasized1: 'text-2xl font-light leading-8',
      emphasized2: 'text-2xl font-light leading-8',
      aLabel: 'text-md font-regular leading-5',
      aLabelBold: 'text-md font-medium leading-6',
      aLabelLarge: 'text-2xl font-regular leading-6',
      aLabelSmall: 'text-sm font-regular leading-4',
      aLabelSmallBold: 'text-sm font-medium leading-4',
      aLabelExtraSmall: 'text-xs font-regular leading-4',
      aLabelExtraSmallBold: 'text-xs font-medium leading-4',
      aLink: 'text-md font-regular leading-6'
    }
  }
})
