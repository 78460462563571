type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export const breakpoints = {
  xs: 0,
  sm: 320,
  md: 672,
  lg: 1056,
  xl: 1568
}

export const themeBreakpoints = {
  breakpoints: {
    values: breakpoints,
    up: (key: Breakpoint) => `@media (min-width:${breakpoints[key]}px)`
  }
}
