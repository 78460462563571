import * as React from 'react'
import { SidepanelCollateral } from '~/components/Sidepanel/SidepanelCards/CreditReport/SidepanelCollateral'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { t } from '@lingui/macro'
import { type SidepanelCompanyFragment } from '~/graphqlTypes'
import { DataSources } from '~/components/Sidepanel/DataSources'

export const SidepanelCompanyCollateralCard = ({ company }: { company: SidepanelCompanyFragment }): React.ReactNode => {
  if (!company.legalForm?.hasCollateral) {
    return null
  }

  return (
    <SidepanelCard title={t`Collateral`}>
      <div className='flex flex-col px-4'>
        <SidepanelCollateral creditReport={null} />
      </div>
      <DataSources dataSources={company.dataSources.collateral} />
    </SidepanelCard>
  )
}
