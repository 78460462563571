import {
  Button,
  Input,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormField,
  FormMessage,
  Radio,
  Typography,
  Label
} from '@strise/ui-components'
import { parseISO } from 'date-fns'
import * as React from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { DatePicker } from '~/components/DatePicker'
import { isDate } from 'lodash-es'
import { zodResolver } from '@hookform/resolvers/zod'
import { type CreatePrivatePersonInput, CreatePrivatePersonInputSchema, Gender } from '@strise/types'
import { t, Trans } from '@lingui/macro'
import { extractGender } from '~/utils/gender'
import { YearSelect } from '~/components/PrivatePersons/CreatePrivatePersonForm/YearSelect'
import { CountryCombobox } from '~/components/PrivatePersons/CreatePrivatePersonForm/CountryCombobox'
import { useCreatePrivatePersonMutation } from '~/graphqlOperations'
import { toast } from '@strise/app-shared'

const inputSchema = CreatePrivatePersonInputSchema()

interface CreatePrivatePersonFormProps {
  handleClose: () => void
}

export const CreatePrivatePersonForm = ({ handleClose }: CreatePrivatePersonFormProps): React.ReactNode => {
  const [createPrivatePerson, { loading }] = useCreatePrivatePersonMutation({
    onCompleted: () => {
      toast.success(t`Private person created`)
      handleClose()
    }
  })

  const form = useForm<CreatePrivatePersonInput>({
    resolver: zodResolver(inputSchema),
    defaultValues: {
      address: [{ addressLine: null, zipCode: null, city: null, country: null }],
      birthDate: null,
      birthYear: null,
      citizenship: null,
      country: '',
      gender: null,
      name: '',
      nin: ''
    }
  })

  const onSubmit: SubmitHandler<CreatePrivatePersonInput> = async (data) => {
    await createPrivatePerson({ variables: { input: data } })
    handleClose()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='bg-white'>
        <div className='space-y-4 p-4'>
          <FormField
            control={form.control}
            name='name'
            render={({ field }) => (
              <FormItem required>
                <FormLabel>
                  <Trans>Name</Trans>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='country'
            render={({ field }) => (
              <FormItem required>
                <FormLabel>
                  <Trans>Country</Trans>
                </FormLabel>
                <FormControl>
                  <CountryCombobox
                    value={field.value}
                    onChange={(items) => field.onChange(items[0]?.value ?? '')}
                    variant='outlined'
                    data-track='Create Private Person / Country'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='nin'
            render={({ field }) => (
              <FormItem required>
                <FormLabel>
                  <Trans>National identification number</Trans>
                </FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='birthDate'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Birth date</Trans>
                </FormLabel>
                <FormControl>
                  {/* TODO: Implement a new DatePicker */}
                  <DatePicker
                    selected={field.value ? parseISO(field.value) : null}
                    onChange={(date: Date) => {
                      if (isDate(date)) {
                        const year = date.getFullYear()
                        form.setValue('birthYear', year)
                        field.onChange(date.toISOString())
                      }
                    }}
                    dateFormat='yyyy-MM-dd'
                    popperPlacement='bottom-start'
                    className='h-10 w-full border border-tertiary-main p-2'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='birthYear'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Birth year</Trans>
                </FormLabel>
                <FormControl>
                  <YearSelect
                    variant='outlined'
                    palette='tertiary'
                    className='border'
                    onValueChange={(value) => field.onChange(Number.parseInt(value))}
                    value={field.value?.toString()}
                    contentProps={{ className: 'max-h-96' }}
                    data-track='Create Private Person / Birth year'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='gender'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Gender</Trans>
                </FormLabel>
                <div className='flex gap-2'>
                  {Object.values(Gender).map((gender) => (
                    <Label key={gender} className='flex cursor-pointer items-center gap-1'>
                      <Radio value={gender} checked={field.value === gender} onChange={() => field.onChange(gender)} />
                      <Typography variant='body2'>{extractGender(gender)}</Typography>
                    </Label>
                  ))}
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='address.0.addressLine'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Address</Trans>
                </FormLabel>
                <FormControl>
                  <Input {...field} value={field.value ?? ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='address.0.zipCode'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Zip code</Trans>
                </FormLabel>
                <FormControl>
                  <Input {...field} value={field.value ?? ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='address.0.city'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>City</Trans>
                </FormLabel>
                <FormControl>
                  <Input {...field} value={field.value ?? ''} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='address.0.country'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Country</Trans>
                </FormLabel>
                <FormControl>
                  <CountryCombobox
                    value={field.value}
                    onChange={(items) => field.onChange(items[0]?.value ?? '')}
                    data-track='Create Private Person / AddressCountry'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='citizenship'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans>Citizenship</Trans>
                </FormLabel>
                <FormControl>
                  <CountryCombobox
                    value={field.value}
                    onChange={(items) => field.onChange(items[0]?.value ?? '')}
                    data-track='Create Private Person / Citizenship'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className='grid w-full grid-cols-2'>
          <Button
            variant='contained'
            onClick={() => {
              form.reset()
              handleClose()
            }}
            data-track='Create Private Person / Cancel'
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            type='submit'
            variant='contained'
            palette='primary'
            disabled={loading}
            loading={loading}
            data-track='Create Private Person / Create'
          >
            <Trans>Create</Trans>
          </Button>
        </div>
      </form>
    </Form>
  )
}
