import * as React from 'react'
import type { BaseEntityLikeFragment, OwnerChartFragment } from '~/graphqlTypes'
import { OwnershipChart, type OwnershipChartProps } from '@strise/app-shared'
import { OwnershipEntity } from '~/components/Ownerships/OwnershipEntity'
import { OwnershipTargetEntity } from '~/components/Ownerships/OwnershipTargetEntity'
import { useEntityOwnershipsLazyQuery } from '~/graphqlOperations'
import { getDanishOwnershipRange } from '~/components/Ownerships/ownershipUtils'
import { formatShare } from '@strise/ts-utils'
import { TestIDs } from '~/utils/testIDs'
import { t } from '@lingui/macro'
import { LoaderRound } from '@strise/ui-components'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'

export interface ChartProps
  extends Omit<
    // We exclude the props that need type arguments, so using any here is fine
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OwnershipChartProps<any, any>,
    | 'chart'
    | 'onExpandSubsidiaries'
    | 'rootEntity'
    | 'renderNodeContent'
    | 'renderRootNodeContent'
    | 'renderSubsidiaryNodeContent'
  > {}

interface EditableOwnershipChartProps extends ChartProps {
  chart: OwnerChartFragment | null
  className?: string
  danish?: boolean
  disableControls?: boolean
  disableMinimap?: boolean
  displaySubsidiaries?: boolean
  rootEntity: BaseEntityLikeFragment
  shouldHaveChart: boolean
}

export const EditableOwnershipChart: React.FC<EditableOwnershipChartProps> = ({
  chart,
  danish,
  disableControls,
  disableMinimap,
  displaySubsidiaries,
  rootEntity,
  shouldHaveChart,
  ...chartProps
}) => {
  const sidepanelContext = React.useContext(SidepanelContext)
  const { chartFocusNodeId, setChartFocusNodeId } = sidepanelContext ?? {}

  const [fetchExpandedOwnerships] = useEntityOwnershipsLazyQuery()

  const handleFetchExpandedOwnerships = React.useCallback(
    async (id: string) => {
      const { data } = await fetchExpandedOwnerships({ variables: { id } })
      const subsidiaries = data?.entity && 'ownerships' in data.entity ? data.entity.ownerships.edges : []
      return subsidiaries.map(({ isUncertain, node, sharePercentage }) => ({
        sharePercentage,
        isUncertain,
        ...node
      }))
    },
    [fetchExpandedOwnerships]
  )

  const formatOwnership = React.useCallback(
    (ownership: number) => {
      if (danish) {
        return getDanishOwnershipRange(ownership)
      }
      return formatShare(ownership).short
    },
    [danish]
  )

  // For entities that are not companies, the chart is empty, but they can still have subsidiaries
  const fallbackChart = { nodes: [], edges: [] }

  // If the entity should have a chart, we need to return null if the chart is not ready
  // If we use the fallback chart in this case, we will mess up the initial layout as the fallback chart has no nodes
  if (shouldHaveChart && !chart) {
    return (
      <div className='mb-4'>
        <LoaderRound size='sm' data-id={TestIDs.SidePanel.Ownerships.loader} />
      </div>
    )
  }

  return (
    <OwnershipChart
      buttonTitles={{
        'zoom-in': t`Zoom in`,
        'zoom-out': t`Zoom out`,
        'focus-root': t`Scroll to ${rootEntity.name}`,
        'scroll-to-last-node': t`Scroll to last subsidiary`,
        fullscreen: t`Enter full screen`
      }}
      chart={chart ?? fallbackChart}
      rootEntity={rootEntity}
      focusNodeId={chartFocusNodeId ?? undefined}
      setFocusNodeId={setChartFocusNodeId}
      disableMinimap={disableMinimap}
      disableControls={disableControls}
      onExpandSubsidiaries={displaySubsidiaries ? handleFetchExpandedOwnerships : undefined}
      renderNodeContent={({ className: nodeClassName, data, height, ownershipEdge, width }) => {
        const ownerNode = data
        const ownerEdge = chart?.edges.find(
          (edge) => edge.parent === ownershipEdge?.source && edge.child === ownershipEdge.target
        )
        return (
          <OwnershipEntity
            className={nodeClassName}
            style={{ width, height }}
            entity={ownerNode.entity}
            ownership={ownerNode.indirectShare}
            ownershipValue={ownerNode.indirectShareValue}
            fallbackName={ownerNode.name}
            uncertain={false}
            meta={ownerNode.beneficialOwnerMeta?.meta}
            isCustom={!!ownerEdge?.customMeta}
            customMeta={ownerEdge?.customMeta}
            isMerged={ownerNode.isMerged}
            data-id={TestIDs.SidePanel.Ownerships.node(ownerNode.name)}
            subsidiariesCount
          />
        )
      }}
      renderRootNodeContent={({ className: nodeClassName, height }) => {
        return <OwnershipTargetEntity style={{ height }} className={nodeClassName} entity={rootEntity} />
      }}
      renderSubsidiaryNodeContent={({ className: nodeClassName, data, height, width }) => {
        const subsidiary = data
        return (
          <OwnershipEntity
            entity={subsidiary}
            className={nodeClassName}
            style={{ width, height }}
            ownership={formatOwnership(subsidiary.sharePercentage)}
            ownershipValue={subsidiary.sharePercentage}
            subsidiary
            isCustom={false}
            isMerged={false}
            uncertain={subsidiary.isUncertain}
          />
        )
      }}
      formatOwnership={formatOwnership}
      {...chartProps}
      reactFlowProps={{ ...chartProps.reactFlowProps, panOnScroll: true, fitView: true }}
    />
  )
}
