import {
  format as formatDateFn,
  formatDistanceToNowStrict,
  formatRelative,
  isAfter,
  isBefore,
  isValid,
  type Locale,
  parseISO,
  sub
} from 'date-fns'
import { DisplayLanguage } from '@strise/types'
import { displayLanguage } from '../state'
import { da, enGB, nb, sv } from 'date-fns/locale'
import { capitalize, filterNullishValues } from '@strise/ts-utils'
import { isDate } from 'lodash-es'

export const languageToLocale: {
  [key in DisplayLanguage]: Locale | undefined
} = {
  [DisplayLanguage.Norwegian]: nb,
  [DisplayLanguage.Swedish]: sv,
  [DisplayLanguage.Danish]: da,
  [DisplayLanguage.English]: enGB
}

export interface FormatDateOpts {
  day?: boolean
  format?: string
  relative?: boolean
  time?: boolean
}

export const formatDate = (
  date: Date | string,
  { day = true, format, relative = true, time = false }: FormatDateOpts = {}
): string => {
  const parsedDate = isDate(date) ? date : parseISO(date)

  if (!isValid(parsedDate)) return ''

  const locale = languageToLocale[displayLanguage() || DisplayLanguage.English]

  if (format) {
    return formatDateFn(parsedDate, format, { locale })
  }

  if (relative) {
    const oneWeekAgo = sub(new Date(), { days: 7 })
    if (isAfter(parsedDate, oneWeekAgo)) {
      return formatDistanceToNowStrict(parsedDate, { addSuffix: true, locale })
    }
  }

  const dateFormat = filterNullishValues([day ? 'dd' : null, 'MMM', 'yyyy', time ? 'HH:mm' : null])

  return formatDateFn(parsedDate, dateFormat.join(' '), { locale })
}

export const formatRelativeDate = (date: string | Date): string => {
  const parsedDate = isDate(date) ? date : parseISO(date)

  const locale = languageToLocale[displayLanguage() || DisplayLanguage.English]

  return capitalize(formatRelative(parsedDate, new Date(), { locale }))
}

export const hasOneMonthPassed = (date: string | null | undefined): boolean => {
  if (!date) return false
  const parsedDate = parseISO(date)
  if (!isValid(parsedDate)) return false
  const oneMonthAgo = sub(new Date(), { months: 1 })
  return isBefore(parsedDate, oneMonthAgo)
}
