import { defineMessage } from '@lingui/macro'
import { type MessageDescriptor } from '@lingui/core'
import { AuditRisk } from '@strise/types'

export const auditRiskTitles: { [key in AuditRisk]?: MessageDescriptor } = {
  [AuditRisk.High]: defineMessage({ message: 'High' }),
  [AuditRisk.Medium]: defineMessage({ message: 'Medium' }),
  [AuditRisk.Low]: defineMessage({ message: 'Low' }),
  [AuditRisk.None]: defineMessage({ message: 'None' })
}

export const auditColors: { [key in AuditRisk]?: { bgColorClass: string; colorClass: string } } = {
  [AuditRisk.None]: { colorClass: 'text-secondary-main', bgColorClass: 'bg-secondary-shade-10' },
  [AuditRisk.Low]: {
    colorClass: 'text-semantic-success-main',
    bgColorClass: 'bg-semantic-success-shade-10'
  },
  [AuditRisk.Medium]: {
    colorClass: 'text-semantic-notice-main',
    bgColorClass: 'bg-semantic-notice-shade-10'
  },
  [AuditRisk.High]: {
    colorClass: 'text-semantic-danger-main',
    bgColorClass: 'bg-semantic-danger-shade-10'
  }
}

export const auditChipColorClasses: { [key in AuditRisk]?: string } = {
  [AuditRisk.None]: 'bg-secondary-shade-30 hover:bg-secondary-shade-40 active:bg-secondary-shade-50',
  [AuditRisk.Low]:
    'bg-semantic-success-shade-10 hover:bg-semantic-success-shade-20 active:bg-semantic-success-shade-30',
  [AuditRisk.Medium]:
    'bg-semantic-notice-shade-10 hover:bg-semantic-notice-shade-20 active:bg-semantic-notice-shade-30',
  [AuditRisk.High]: 'bg-semantic-danger-shade-10 hover:bg-semantic-danger-shade-20 active:bg-semantic-danger-shade-30'
}
