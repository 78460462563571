import { ReviewEvent } from '~/components/Review/items/ReviewEvent'
import * as React from 'react'
import { type ReviewEventValueFragment } from '~/graphqlTypes'
import { type ReviewState } from '~/utils/reviewUtils2'
import { type SetStateFn } from '@strise/react-utils'
import { TruncatedList } from '~/components/TruncatedList'

export const ReviewEvents = ({
  entityId,
  items,
  reviewState,
  setReviewState
}: {
  entityId: string
  items: ReviewEventValueFragment[]
  reviewState: ReviewState | undefined
  setReviewState: SetStateFn<ReviewState>
}): React.ReactNode => {
  return (
    <TruncatedList context='Review' items={items} truncateAfter={3}>
      {(item) => {
        return (
          <ReviewEvent
            key={item.value?.id}
            entityId={entityId}
            item={item}
            reviewState={reviewState}
            setReviewState={setReviewState}
          />
        )
      }}
    </TruncatedList>
  )
}
