import { getBrowserGlobals } from './browserGlobals'

export const copyElToClipboard = (el: HTMLElement): void => {
  el.style.position = 'absolute'
  el.style.top = '0'
  el.style.left = '0'
  el.style.zIndex = '-99999'
  el.style.opacity = '0'

  getBrowserGlobals()?.document.body.append(el)

  const range = getBrowserGlobals()?.document.createRange()
  const selection = getBrowserGlobals()?.window.getSelection()

  if (!selection) return

  selection.removeAllRanges()
  try {
    range?.selectNodeContents(el)
  } catch (e) {
    range?.selectNode(el)
  }
  if (range) {
    selection.addRange(range)
  }

  getBrowserGlobals()?.document.execCommand('copy')

  getBrowserGlobals()?.document.body.removeChild(el)
}

export const copyTextToClipboard = (text: string): void => {
  const el = getBrowserGlobals()?.document.createElement('div')
  const content = getBrowserGlobals()?.document.createTextNode(text)
  if (el && content) {
    el.append(content)
    copyElToClipboard(el)
  }
}
