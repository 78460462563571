import { Alert, cn, IconSanction, Link, Typography } from '@strise/ui-components'
import type { EntityLikeMetaFragment } from '~/graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import React from 'react'
import { OpenChatLink } from '~/components/ContactStrise'
import { type DivProps } from '@strise/react-utils'
import { useEntitySanctionInfo } from '~/utils/sanctions'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import { hasEntityAccess, isEntityActive } from '~/utils/entity'

export const SidepanelInactiveAlert: React.FC<{
  entity: EntityLikeMetaFragment & {
    __typename: 'Company' | 'GlobalCompany'
  }
}> = ({ entity }) => {
  if (!entity.lifeStatus.lifeStatus) return null
  if (isEntityActive(entity)) return null

  return (
    <Alert variant='danger' className='w-full'>
      {entity.lifeStatus.lifeStatus} {entity.lifeStatus.date && formatDate(entity.lifeStatus.date, { relative: false })}
    </Alert>
  )
}

export const SidepanelHasAccessAlert: React.FC<{
  entity: SupportedSidepanelEntityFragment
}> = ({ entity }) => {
  if (hasEntityAccess(entity)) return null
  return (
    <div>
      <Alert variant='warning' className='w-full'>
        {entity.access.description}
        <OpenChatLink msg={t`I need more countries in my plan.`}>
          <Trans>Contact Strise</Trans>
        </OpenChatLink>
      </Alert>
    </div>
  )
}

export const SidepanelSanctionsAlert = ({
  className,
  entityId,
  ...props
}: {
  entityId: string
} & DivProps): React.ReactNode => {
  const { sanctionInfo } = useEntitySanctionInfo(entityId)

  if (!sanctionInfo?.sanctionedBy.length) return null

  return (
    <div className={cn('flex flex-col gap-2', className)} {...props}>
      {sanctionInfo.sanctionedBy.map((sanctionedBy, i) => (
        <Link href='#sanctions' key={i}>
          <Alert
            icon={IconSanction}
            className='w-full border border-semantic-danger-shade-10 px-4'
            textProps={{
              className: 'flex justify-between w-full text-text-primary',
              variant: 'aLabelBold'
            }}
            variant='danger'
          >
            <Trans>Sanctioned by {sanctionedBy}</Trans>
            <Typography className='pl-4 underline' variant='aLink'>
              <Trans>See details</Trans>
            </Typography>
          </Alert>
        </Link>
      ))}
    </div>
  )
}
