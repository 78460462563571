import qs from 'qs'

export const updateQueryString = (currentQueryString: string | null, newQueryParams: qs.ParsedQs): string => {
  const parsedParams = currentQueryString ? qs.parse(currentQueryString, { ignoreQueryPrefix: true }) : {}
  const updatedParams = { ...parsedParams, ...newQueryParams }

  // https://github.com/ljharb/qs?tab=readme-ov-file#rfc-3986-and-rfc-1738-space-encoding
  return qs.stringify(updatedParams, { addQueryPrefix: false, skipNulls: true, format: 'RFC1738' })
}

export const migrateQueryString = (currentQueryString: string, oldToNewMap: Record<string, string>): string => {
  const parsedParams = qs.parse(currentQueryString, { ignoreQueryPrefix: true })

  const updatedParams = Object.entries(parsedParams).reduce((acc, [key, value]) => {
    const newKey = oldToNewMap[key] ?? key
    return { ...acc, [newKey]: value }
  }, {})

  // https://github.com/ljharb/qs?tab=readme-ov-file#rfc-3986-and-rfc-1738-space-encoding
  return qs.stringify(updatedParams, { addQueryPrefix: false, skipNulls: true, format: 'RFC1738' })
}

export const filterQueryString = (currentQueryString: string, includeQueryParams: string[]): string => {
  const parsedParams = currentQueryString ? qs.parse(currentQueryString, { ignoreQueryPrefix: true }) : {}

  const updatedParams = Object.entries(parsedParams).reduce((acc, [key, value]) => {
    if (includeQueryParams.includes(key)) {
      return { ...acc, [key]: value }
    }
    return acc
  }, {})

  // https://github.com/ljharb/qs?tab=readme-ov-file#rfc-3986-and-rfc-1738-space-encoding
  return qs.stringify(updatedParams, { addQueryPrefix: false, skipNulls: true, format: 'RFC1738' })
}
