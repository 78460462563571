import { type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from '../../utils/className'
import { Slot } from '@radix-ui/react-slot'
import { typographyVariants } from '../variants/typographyVariants'

const tag: Record<string, React.ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p'
}

export interface TypographyProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'color'>,
    VariantProps<typeof typographyVariants> {
  asChild?: boolean
  children?: React.ReactNode
  /** This will get a default value based on the variant.
   *  Body variants will be a p by default.
   *  Heading variants will be a h1-h6 by default (remember to follow heading hierarchy).
   *  Others will be a span by default.
   *  Will always be overridden by passing a component prop
   */
  component?: React.ElementType
}

export const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  ({ asChild, className, component, variant = 'body1', ...props }, ref) => {
    const Component = asChild ? Slot : (component ?? (variant && tag[variant]) ?? 'span')
    return <Component ref={ref} className={cn(typographyVariants({ variant }), className)} {...props} />
  }
)
Typography.displayName = 'Typography'
