import {
  type ReviewCompanyDataFragment,
  type ReviewFragment,
  type SimpleUserFragment,
  type TeamReviewSettingsV2Fragment
} from '~/graphqlTypes'
import { toast } from '@strise/app-shared'
import { defineMessage, t } from '@lingui/macro'
import { type MessageDescriptor } from '@lingui/core'
import REVIEW_FILE from '~/graphql/review/queries/reviewFile.graphql'
import { ContentType, type SetStateFn, triggerBase64Download } from '@strise/react-utils'
import { useReviewFileLazyQuery, useReviewFileNameQuery } from '~/graphqlOperations'
import { differenceInHours } from 'date-fns'
import {
  type AmsEventFeedbackInput,
  type ReviewAmsHitsInput,
  ReviewRiskKind,
  type ReviewRowKind,
  ReviewSectionKind,
  type ReviewSettingInput,
  ReviewSettingKind
} from '@strise/types'

// Beware of refactoring of this, as it is used in local storage
export enum ReviewCardContentView {
  BEFORE_REVIEW = 0,
  LOADING_REVIEW = 1,
  IN_REVIEW = 2,
  AFTER_REVIEW = 3
}

export interface CheckedState {
  checkedMap: CheckedMap
  customCheckedMap: CustomCheckedMap
}

export const defaultCheckedState: CheckedState = {
  checkedMap: {},
  customCheckedMap: {}
}

export type CheckedMap = {
  [key in ReviewRowKind]?: boolean
}

export type CustomCheckedMap = Record<string, boolean>

export interface InlineCommentState {
  createdAt: string
  createdBy: SimpleUserFragment
  customCheckboxName?: string
  id: string
  message: string
}

export type InlineCommentsStateMap = {
  [key in ReviewRowKind]?: InlineCommentState[]
}

export interface ReviewState {
  amsEventFeedback: AmsEventFeedbackInput[]
  checkedV2: CheckedState
  comment: string
  customChecked: CustomCheckedMap
  inlineComments: InlineCommentsStateMap
  lastModifiedAt: number
  opened: boolean
  openedAt: number
}

export interface BaseCheckboxSectionProps {
  checkedState: CheckedState
  companyId: string
  disabled?: boolean
  errors: boolean
  inlineComments: InlineCommentsStateMap
  loading: boolean
  setInlineComments: SetStateFn<InlineCommentsStateMap>
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
  updateCheckedState: (tableRowKind: ReviewRowKind, customCheckboxName?: string) => void
}

export const reviewSectionKeyToTitle: {
  [key in ReviewSectionKind]?: MessageDescriptor
} = {
  [ReviewSectionKind.CompanyInformation]: defineMessage({
    message: 'Company information'
  }),
  [ReviewSectionKind.ManagementAndRightsHolders]: defineMessage({
    message: 'Management and rights holders'
  }),
  [ReviewSectionKind.Ams]: defineMessage({
    message: 'Adverse media screening'
  }),
  [ReviewSectionKind.Esg]: defineMessage({ message: 'ESG' })
}

export const reviewRowKindToDescription: Partial<Record<ReviewSettingKind, MessageDescriptor>> = {
  [ReviewSettingKind.EnableInlineComments]: defineMessage({
    message: 'Comments will be included in the Review PDF as nodes.'
  }),
  [ReviewSettingKind.EnableComment]: defineMessage({
    message: 'Comment will be included on the first page of the Review PDF.'
  }),
  [ReviewSettingKind.EnableRiskAssessmentValue]: defineMessage({
    message:
      'Enables the possibility to assess the risk of a reviewed entity as none, low, medium, or high. Will be included in the Review PDF.'
  }),
  [ReviewSettingKind.EnableCheckAll]: defineMessage({
    message: 'Enables a check all checkbox in Review for checking every checkbox.'
  })
}

export const reviewRowKindToTitle: Partial<Record<ReviewSettingKind, MessageDescriptor>> = {
  [ReviewSettingKind.EnableInlineComments]: defineMessage({
    message: 'Comments on checkpoints in Review'
  }),
  [ReviewSettingKind.EnableComment]: defineMessage({
    message: 'Final comment of Review'
  }),
  [ReviewSettingKind.EnableRiskAssessmentValue]: defineMessage({
    message: 'Risk assessment in Review'
  }),
  [ReviewSettingKind.EnableCheckAll]: defineMessage({
    message: 'Check all in Review'
  }),
  [ReviewSettingKind.Countries]: defineMessage({ message: 'HQ/Country' }),
  [ReviewSettingKind.LegalForms]: defineMessage({ message: 'Legal form' }),
  [ReviewSettingKind.Industries]: defineMessage({ message: 'Industries' }),
  [ReviewSettingKind.CorporatePurpose]: defineMessage({
    message: 'Corporate purpose'
  }),
  [ReviewSettingKind.FlaggedEvents]: defineMessage({ message: 'Flagged events' }),
  [ReviewSettingKind.Registers]: defineMessage({ message: 'Registers' }),
  [ReviewSettingKind.CompanySanctions]: defineMessage({
    message: 'Company sanctions'
  }),
  [ReviewSettingKind.CreditScore]: defineMessage({ message: 'Credit score' }),
  [ReviewSettingKind.Ccjs]: defineMessage({ message: "CCJ's" }),
  [ReviewSettingKind.Owners]: defineMessage({
    id: 'Owner (Innehaver)',
    message: 'Owner'
  }),
  [ReviewSettingKind.Partners]: defineMessage({ message: 'Partners' }),
  [ReviewSettingKind.Ceos]: defineMessage({ message: 'CEO' }),
  [ReviewSettingKind.Chairpersons]: defineMessage({ message: 'Chairperson' }),
  [ReviewSettingKind.OtherBoardMembers]: defineMessage({
    message: 'Other board members'
  }),
  [ReviewSettingKind.GlobalOwnerships]: defineMessage({
    message: 'Global ownerships'
  }),
  [ReviewSettingKind.BeneficialOwners]: defineMessage({
    message: 'Beneficial owners'
  }),
  [ReviewSettingKind.AlternativeBeneficialOwners]: defineMessage({
    message: 'Alternative beneficial owners'
  }),
  [ReviewSettingKind.Peps]: defineMessage({ message: 'PEPs' }),
  [ReviewSettingKind.Sanctions]: defineMessage({ message: 'Sanctions' }),
  [ReviewSettingKind.OtherOwners]: defineMessage({ message: 'Other owners' }),
  [ReviewSettingKind.OtherRoles]: defineMessage({ message: 'Other roles' }),
  [ReviewSettingKind.ShareClasses]: defineMessage({ message: 'Share classes' }),
  [ReviewSettingKind.CompanyAdverseMediaScreening]: defineMessage({
    message: 'Company AMS'
  }),
  [ReviewSettingKind.RolesAdverseMediaScreening]: defineMessage({
    message: 'Management and Rights holders AMS'
  }),
  [ReviewSettingKind.BoardGenderDiversity]: defineMessage({
    message: 'Board gender diversity'
  }),
  [ReviewSettingKind.SubjectToTransparencyAct]: defineMessage({
    message: 'Subject to the Transparency Act'
  })
}

export const reviewSectionsMapper = (
  settings: ReviewSettingInput[]
): Partial<Record<ReviewSectionKind, ReviewSettingInput[]>> => ({
  [ReviewSectionKind.CompanyInformation]: settings.filter((setting) =>
    [
      ReviewSettingKind.Countries,
      ReviewSettingKind.LegalForms,
      ReviewSettingKind.Industries,
      ReviewSettingKind.CorporatePurpose,
      ReviewSettingKind.FlaggedEvents,
      ReviewSettingKind.Registers,
      ReviewSettingKind.CompanySanctions,
      ReviewSettingKind.CreditScore,
      ReviewSettingKind.Ccjs
    ].includes(setting.kind)
  ),
  [ReviewSectionKind.ManagementAndRightsHolders]: settings.filter((setting) =>
    [
      ReviewSettingKind.Owners,
      ReviewSettingKind.Partners,
      ReviewSettingKind.Ceos,
      ReviewSettingKind.Chairpersons,
      ReviewSettingKind.OtherBoardMembers,
      ReviewSettingKind.GlobalOwnerships,
      ReviewSettingKind.BeneficialOwners,
      ReviewSettingKind.AlternativeBeneficialOwners,
      ReviewSettingKind.Peps,
      ReviewSettingKind.Sanctions,
      ReviewSettingKind.OtherOwners,
      ReviewSettingKind.OtherRoles,
      ReviewSettingKind.ShareClasses
    ].includes(setting.kind)
  ),
  [ReviewSectionKind.Ams]: settings.filter((setting) =>
    [ReviewSettingKind.CompanyAdverseMediaScreening, ReviewSettingKind.RolesAdverseMediaScreening].includes(
      setting.kind
    )
  ),
  [ReviewSectionKind.Esg]: settings.filter((setting) =>
    [ReviewSettingKind.BoardGenderDiversity, ReviewSettingKind.SubjectToTransparencyAct].includes(setting.kind)
  )
})

export const roleRiskKindToTitle: {
  [key in ReviewRiskKind]?: MessageDescriptor
} = {
  [ReviewRiskKind.HasNoValues]: defineMessage({
    message: 'No persons/companies in this role'
  }),
  [ReviewRiskKind.HasFlagsOutsideCompany]: defineMessage({
    message: 'The role has a person/company with flags outside the company'
  }),
  [ReviewRiskKind.IsCompany]: defineMessage({
    message: 'The role is assigned to a company'
  }),
  [ReviewRiskKind.HasMultipleValues]: defineMessage({
    message: 'The role has multiple persons/companies'
  })
}

export const REVIEW_CHECKBOX_COLUMN_WIDTH_CLASSES = 'legacy-xs:w-[200px] legacy-lg:w-[250px]'

export const useDownloadReview = (): {
  downloadReview: (id: string, onCompleted?: () => void) => Promise<void>
  loading: boolean
} => {
  const [fetch, { loading }] = useReviewFileLazyQuery()

  const downloadReview = async (id: string, onCompleted?: () => void): Promise<void> => {
    try {
      const { data } = await fetch({
        query: REVIEW_FILE,
        variables: { id },
        onCompleted
      })
      const { file, fileName } = data?.review || {}
      if (!file || !fileName) {
        console.error(`No file for review ${id}`)
        return
      }

      triggerBase64Download(file, fileName, ContentType.PDF)
    } catch (e) {
      console.error(e)
      toast.error(t`Error downloading file`)
    }
  }

  return { downloadReview, loading }
}

const REVIEW_FILE_POLLING_MS = 2000

export const useHasReviewFile = (
  review: ReviewFragment | null | undefined
): { hasFile: boolean; isOver1HourOld: boolean } => {
  // If there's no file after 1 hour, something went wrong, and we should not poll anymore
  const isOver1HourOld = review ? differenceInHours(new Date(), new Date(review.created)) > 1 : false

  const { data, startPolling, stopPolling } = useReviewFileNameQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion
    variables: { id: review?.id! },
    skip: !review || !!review.fileName || isOver1HourOld
  })

  if (!review) return { hasFile: false, isOver1HourOld }

  const hasFile = !!(data?.review.fileName || review.fileName)

  if (hasFile || isOver1HourOld) {
    stopPolling()
  } else {
    startPolling(REVIEW_FILE_POLLING_MS)
  }

  return { hasFile, isOver1HourOld }
}

export const extractTextColorClass = (
  hasError: boolean,
  checked: boolean
): 'text-semantic-danger-main' | 'text-text-secondary' | 'text-text-primary' => {
  if (hasError) return 'text-semantic-danger-main'
  return checked ? 'text-text-secondary' : 'text-text-primary'
}

// TODO - write test for this function
export const extractEntityAmsHits = (reviewCompanyData: ReviewCompanyDataFragment): ReviewAmsHitsInput[] => {
  const companyAms: ReviewAmsHitsInput = {
    entity: reviewCompanyData.id,
    hits: (reviewCompanyData.companyAms?.filter((ams) => ams.value) ?? []).length
  }

  const personsAms: ReviewAmsHitsInput[] =
    reviewCompanyData.personsAms?.map((section) => {
      return { entity: section.entity.id, hits: section.events.filter((event) => event.value).length }
    }) ?? []

  const entityAmsHits = [companyAms, ...personsAms]

  return entityAmsHits
}

export const defaultOpenedReviewState: ReviewState = {
  opened: true,
  lastModifiedAt: Date.now(),
  openedAt: Date.now(),
  checkedV2: defaultCheckedState,
  inlineComments: {},
  comment: '',
  amsEventFeedback: [],
  customChecked: {}
}
