import * as React from 'react'
import { cn, IconLocation } from '@strise/ui-components'
import { type StyleProps } from '@strise/react-utils'
import { Img } from '../Img'
import { t } from '@lingui/macro'

const fallbackSrc =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTIgM0M4LjE0OCAzIDUgNi4xMjQgNSA5Ljk2NGMwIDEuMDkuMzcxIDIuMTUgMS4wNTMgMy4wMDFMMTIgMjAuNGw1Ljk0Ny03LjQzNEE0LjgwNCA0LjgwNCAwIDAgMCAxOSA5Ljk2NEMxOSA2LjEyNCAxNS44NTIgMyAxMiAzWk0zIDkuOTY0QzMgNS4wMDcgNy4wNTUgMSAxMiAxczkgNC4wMDcgOSA4Ljk2NGE2LjgwNCA2LjgwNCAwIDAgMS0xLjQ5MSA0LjI1TDEyIDIzLjYwMmwtNy41MDktOS4zODZBNi44MDQgNi44MDQgMCAwIDEgMyA5Ljk2NFoiIGZpbGw9ImN1cnJlbnRDb2xvciIvPgogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxMWExIDEgMCAxIDAgMC0yIDEgMSAwIDAgMCAwIDJabTAgMmEzIDMgMCAxIDAgMC02IDMgMyAwIDAgMCAwIDZaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg=='

export type NationFlagBaseProps = { noFallback?: boolean } & StyleProps

interface NationFlagProps extends NationFlagBaseProps {
  countryIsoCode: string | null | undefined
}

export const NationFlag = React.forwardRef<HTMLImageElement, NationFlagProps>(
  ({ className, countryIsoCode, noFallback, ...props }, ref) => {
    if (!countryIsoCode && noFallback) return null
    if (!countryIsoCode) return <IconLocation {...props} />

    const url = `/images/nation-flags/${countryIsoCode.toLowerCase()}.svg`

    return (
      <Img
        ref={ref}
        className={cn('size-5', className)}
        src={url}
        noFallback={noFallback}
        fallbackSrc={fallbackSrc}
        alt={`${t`Flag of`} ${countryIsoCode}`}
        {...props}
      />
    )
  }
)
