import * as React from 'react'
import { useUserId } from '../auth/authUtils'
import { type SimpleUserFragment } from '../../graphqlTypes'

export const useSortedAssignees = (
  userEdges: Array<{ node: SimpleUserFragment }>,
  assignedIds: string[] = []
): { node: SimpleUserFragment }[] => {
  const currentUserId = useUserId()
  return React.useMemo(
    () =>
      [...userEdges].sort((userA, userB) => {
        if (userA.node.id === currentUserId) {
          // Current user at the top
          return -1
        }
        if (userB.node.id === currentUserId) {
          return 1
        }
        if (userA.node.id.endsWith('@clients')) {
          // Client at the end
          return 1
        }
        if (userB.node.id.endsWith('@clients')) {
          return -1
        }
        // Fallback to regular sorting
        return (
          Number(assignedIds.includes(userB.node.id)) - Number(assignedIds.includes(userA.node.id)) ||
          userA.node.name.localeCompare(userB.node.name)
        )
      }),
    [userEdges, assignedIds, currentUserId]
  )
}
