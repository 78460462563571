import { Button, IconReview, Typography } from '@strise/ui-components'
import { ContentViewContext } from '~/components/Layout/ContentViewContext'
import { STORAGE_KEYS } from '~/constants'
import { type BaseReviewCompanyFragment, type TeamReviewSettingsV2Fragment } from '~/graphqlTypes'
import { Trans } from '@lingui/macro'
import { useContext, usePersistentState } from '@strise/react-utils'
import { objectEntries, objectKeys } from '@strise/ts-utils'
import { type SetStateFn } from '@strise/react-utils'
import { type ReviewState } from '~/utils/reviewUtils'
import { minBy, omit, isFunction } from 'lodash-es'
import * as React from 'react'
import { type SetStateAction } from 'react'
import { ReviewCompanyCard } from '~/components/Review/ReviewCompanyCard'
import { differenceInDays } from 'date-fns'
import { TestIDs } from '~/utils/testIDs'

const maxPersistentStateSize = 5

const createReviewStateId = (companyId: string, teamId: string): string => `${companyId}-${teamId}`

const setChildStateReview = (setState: SetStateFn<Record<string, ReviewState>>, companyId: string, teamId: string) => {
  return (valueOrFn: SetStateAction<ReviewState>) => {
    setState((prevState) => {
      const id = createReviewStateId(companyId, teamId)
      // @ts-expect-error
      const newValue = isFunction(valueOrFn) ? valueOrFn(prevState[id]) : valueOrFn

      const keys = objectKeys(prevState)
      const oldestModified = minBy(objectEntries(prevState), ([, item]) => item.lastModifiedAt)

      const state =
        !(id in prevState) && keys.length >= maxPersistentStateSize && !!oldestModified
          ? omit(prevState, oldestModified[0])
          : prevState

      const newCompanyState: ReviewState = {
        ...newValue,
        lastModifiedAt: Date.now()
      }

      return {
        ...state,
        [id]: newCompanyState
      }
    })
  }
}

const extractCompanyReviewState = (
  state: Record<string, ReviewState>,
  companyId: string,
  teamId: string
): ReviewState | undefined => {
  const id = createReviewStateId(companyId, teamId)
  // fallback to the old format, so customers don't lose their state
  const companyState = state[id] ?? state[companyId]

  if (!companyState) return

  const difference = differenceInDays(new Date(), new Date(companyState.openedAt))

  // Old state - PEPs needs to be refetched to ensure that the data is up-to-date
  if (difference >= 7) return

  return companyState
}

export const ReviewContent = ({
  companies,
  filterHeight,
  infiniteScrollItemRef,
  loading,
  teamId,
  teamReviewSettings,
  toggleFilterOpen
}: {
  companies: BaseReviewCompanyFragment[]
  filterHeight: number | undefined
  infiniteScrollItemRef: React.Ref<HTMLDivElement>
  loading: boolean
  teamId: string
  teamReviewSettings: TeamReviewSettingsV2Fragment['reviewSettingsV2']
  toggleFilterOpen: () => void
}): React.ReactNode => {
  const { isMediumScreen } = useContext(ContentViewContext)
  const [state, setState] = usePersistentState<Record<string, ReviewState>>(STORAGE_KEYS.reviewState, {})

  const resetState = (companyId: string): void => {
    setState((prevState) => {
      return omit(prevState, createReviewStateId(companyId, teamId))
    })
  }

  if (!companies.length && !loading) {
    return (
      <div className='mt-16 flex flex-col items-center justify-center text-center' data-id={TestIDs.Review.emptyState}>
        <IconReview className='mb-4 size-[84px] text-secondary-shade-30' />
        <Typography className='mb-4' variant='h2'>
          <Trans>No companies to show</Trans>
        </Typography>
        <Typography className='mb-4 w-3/4' variant='body1'>
          <Trans>Explore more companies by adjusting one of the many filters</Trans>
        </Typography>
        <Button
          variant='contained'
          palette='secondary'
          onClick={toggleFilterOpen}
          data-track='Review / Empty / Open filters'
        >
          <Trans>Open filters</Trans>
        </Button>
      </div>
    )
  }

  return (
    // Adding padding for inline comments
    <div className={isMediumScreen ? 'pr-10' : undefined}>
      {companies.map((company) => {
        const setReviewState = setChildStateReview(setState, company.id, teamId)
        const reviewState = extractCompanyReviewState(state, company.id, teamId)

        return (
          <ReviewCompanyCard
            ref={infiniteScrollItemRef}
            filterHeight={filterHeight}
            key={company.id}
            baseCompany={company}
            teamReviewSettings={teamReviewSettings}
            reviewState={reviewState}
            setReviewState={setReviewState}
            resetReviewState={resetState}
          />
        )
      })}
    </div>
  )
}
