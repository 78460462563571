import { cn, ExternalLink, Tooltip, Typography } from '@strise/ui-components'
import { type CompanyBranchFragment, type SidepanelCompanyFragment } from '~/graphqlTypes'
import { t } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import { ellipsis } from '@strise/ts-utils'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import { extractAddress } from '~/utils/address'
import { extractNaceName } from '~/utils/industryUtils'
import * as React from 'react'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { useContext } from '@strise/react-utils'

const COMPANY_BRANCH_NAME_LENGTH = 20

const CompanyBranchRow = ({
  companyBranch,
  hasPeriod
}: {
  companyBranch: CompanyBranchFragment
  hasPeriod: boolean
}): React.ReactNode => {
  const { expanded } = useContext(SidepanelContext)
  const industryDescription = companyBranch.industry ? extractNaceName(companyBranch.industry) : null
  const industryContent = companyBranch.industry ? extractNaceName(companyBranch.industry, 38) : 'N/A'

  const valueWithLimit = expanded ? companyBranch.name : ellipsis(companyBranch.name, COMPANY_BRANCH_NAME_LENGTH)

  return (
    <TableRow>
      <TableCell px={1} maxWidth={120}>
        {companyBranch.url && (
          <Tooltip content={expanded ? undefined : <Typography variant='body2'>{companyBranch.name}</Typography>}>
            <div>
              <ExternalLink
                data-track='Company details/ Branches / Org number Ext Link'
                variant='body1'
                className={cn('w-1/3 text-text-link', !companyBranch.isActive && 'text-text-secondary line-through')}
                href={companyBranch.url}
              >
                {valueWithLimit}
              </ExternalLink>
            </div>
          </Tooltip>
        )}

        {!companyBranch.url && (
          <Typography
            className={cn('[overflow-wrap:anywhere]', !companyBranch.isActive && 'text-text-secondary line-through')}
          >
            {valueWithLimit}
          </Typography>
        )}
      </TableCell>

      <TableCell px={1} maxWidth={120}>
        <Tooltip content={<Typography variant='body2'>{extractAddress(companyBranch.address)}</Typography>}>
          <Typography data-track='Company details/ Branches / Address copied' className='[overflow-wrap:anywhere]'>
            {companyBranch.address.city ?? '-'}
          </Typography>
        </Tooltip>
      </TableCell>

      <TableCell px={1} maxWidth={120}>
        <Typography>{companyBranch.numberOfEmployees ?? '-'}</Typography>
      </TableCell>

      <TableCell px={1} maxWidth={120}>
        <Tooltip content={industryDescription}>
          <Typography className='inline-block [overflow-wrap:anywhere]'>{industryContent}</Typography>
        </Tooltip>
      </TableCell>

      {hasPeriod && (
        <TableCell px={1} maxWidth={120}>
          <Typography className='inline-block [overflow-wrap:anywhere]'>
            {companyBranch.activeFrom ? formatDate(companyBranch.activeFrom, { relative: false }) : ''}-
            {companyBranch.activeTo ? formatDate(companyBranch.activeTo, { relative: false }) : t`Now`}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  )
}

export const SidepanelBranchesCard: React.FC<{
  company: SidepanelCompanyFragment
}> = ({ company }) => {
  const defaultBranchesToShow = 5
  const [branchesToShow, setBranchesToShow] = React.useState(defaultBranchesToShow)
  const branches = company.branches.edges.map(({ node: branch }) => branch)

  const toggleShowMore = (): void => {
    setBranchesToShow((prevBranchesToShow) => {
      if (prevBranchesToShow === branches.length) return defaultBranchesToShow

      return branches.length
    })
  }

  const hasPeriod = branches.some((branch) => branch.activeFrom || branch.activeTo)

  const tableHeadCells = [
    {
      title: t`Name`
    },
    {
      title: t`Address`
    },
    {
      title: t`Employees`
    },
    {
      title: t`Industry`
    },
    {
      title: t`Period`,
      hide: !hasPeriod
    }
  ]

  return (
    <SidepanelCard title={t`Branches`}>
      <div className='px-4'>
        <Table>
          <thead>
            <TableRow>
              {tableHeadCells.map((tableHeadCell, index) => {
                if (tableHeadCell.hide) return null
                return (
                  <TableCell key={index} component='th' variant='aLabelBold' px={1} textAlign='left'>
                    {tableHeadCell.title}
                  </TableCell>
                )
              })}
            </TableRow>
          </thead>
          <tbody>
            {branches.slice(0, branchesToShow).map((branch, index) => {
              return <CompanyBranchRow companyBranch={branch} hasPeriod={hasPeriod} key={index} />
            })}
          </tbody>
        </Table>
        {branches.length > defaultBranchesToShow && (
          <Typography className='mt-1 cursor-pointer text-center text-text-link underline' onClick={toggleShowMore}>
            {branchesToShow === defaultBranchesToShow ? t`Show ${branches.length - branchesToShow} more` : t`Show less`}
          </Typography>
        )}
      </div>
      <DataSources dataSources={company.dataSources.baseInfo} />
    </SidepanelCard>
  )
}
