import { EntityIdSearchInput } from '~/components/Entity/EntitySearchInput'
import { RoleTitlesCombobox } from '~/components/Roles/RoleTitlesCombobox'
import { EntityLocationFilterKind } from '~/components/Search/searchUtils'
import {
  useGenerateRoleConflictMutation,
  useRoleTitlesQuery,
  useGenerateOwnershipConflictMutation
} from '~/graphqlOperations'
import { Button, Input, Select, Typography } from '@strise/ui-components'
import { type SetStateFn, setChildState } from '@strise/react-utils'
import { DatePicker, Modal, ModalContent, ModalHeader } from '@strise/ui-components-legacy'

import {
  ApplicationSearchReturnType,
  type UpdateEntityOwnerInput,
  type UpdateEntityBusinessRoleInput
} from '@strise/types'

import * as React from 'react'

interface DevUtilsModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

// Had to do some typescript gymnastics because of the typing of setSelectedEntity in EntitySearchInput needs the state handler to be
// SetStateFn<string | null>
type BusinessRoleInput = Omit<UpdateEntityBusinessRoleInput, 'entity'> & { entity: string | null }

const defaultRoleInput: BusinessRoleInput = {
  entity: 'df271882a7eb95a427ca54405e63c5ae',
  role: {
    roleTitleId: 'Accountant',
    period: {
      from: new Date('2020-01-01').toISOString(),
      // Default active role
      to: null
    }
  }
}

const defaultConflictRoleInput: BusinessRoleInput = {
  entity: 'df271882a7eb95a427ca54405e63c5ae',
  role: {
    roleTitleId: 'Accountant',
    period: {
      from: new Date('2020-01-01').toISOString(),
      // Default active role
      to: null
    }
  }
}

type CustomOwnershipInput = Omit<UpdateEntityOwnerInput, 'entity' | 'ownership'> & {
  entity: string | null
  ownership: number | null
}

const defaultCustomOwnershipInput: CustomOwnershipInput = {
  entity: 'MITO_ENHET_918125396',
  ownership: 50
}

const defaultConflictCustomOwnershipInput: CustomOwnershipInput = {
  entity: 'MITO_ENHET_918125396',
  ownership: 25
}

const ownershipConflict = 'ownershipConflict'
const roleConflict = 'roleConflict'

export const DevUtilsModal = ({ open, setOpen }: DevUtilsModalProps): React.ReactNode => {
  const [generateRoleConflict, { loading: roleConflictLoading }] = useGenerateRoleConflictMutation()
  const [generateOwnershipConflict, { loading: ownershipConflictLoading }] = useGenerateOwnershipConflictMutation()

  const [selectedEntityId, setSelectedEntityId] = React.useState<string | null>('Q28792275')

  const [selectedConflictType, setSelectedConflictType] = React.useState<string>(ownershipConflict)

  const [customOwnershipInput, setCustomOwnershipInput] =
    React.useState<CustomOwnershipInput>(defaultCustomOwnershipInput)
  const [conflictingOwnershipInput, setConflictingCustomOwnershipInput] = React.useState<CustomOwnershipInput>(
    defaultConflictCustomOwnershipInput
  )

  const [customRoleInput, setCustomRoleInput] = React.useState<BusinessRoleInput>(defaultRoleInput)
  const [conflictingRoleInput, setConflictingRoleInput] = React.useState<BusinessRoleInput>(defaultConflictRoleInput)

  const handleCreateConflict = async (): Promise<void> => {
    if (!selectedEntityId) {
      return
    }

    switch (selectedConflictType) {
      case ownershipConflict: {
        if (customOwnershipInput.entity && conflictingOwnershipInput.entity) {
          await generateOwnershipConflict({
            variables: {
              entity: selectedEntityId,
              customOwnershipInput: {
                ...customOwnershipInput,
                entity: customOwnershipInput.entity
              },
              conflictingOwnershipInput: {
                ...conflictingOwnershipInput,
                entity: conflictingOwnershipInput.entity
              }
            }
          })
        }
        break
      }
      case roleConflict: {
        if (customRoleInput.entity && conflictingRoleInput.entity) {
          await generateRoleConflict({
            variables: {
              entity: selectedEntityId,
              customRoleInput: {
                ...customRoleInput,
                entity: customRoleInput.entity
              },
              conflictingRoleInput: {
                ...conflictingRoleInput,
                entity: conflictingRoleInput.entity
              }
            }
          })
        }
        break
      }
    }
  }

  return (
    <Modal isOpen={open} shouldCloseOnEsc onRequestClose={() => setOpen(false)}>
      <ModalHeader>
        <Typography variant='h2'>Dev Utils</Typography>
      </ModalHeader>
      <ModalContent className='flex flex-col'>
        <Typography variant='h3'>Create conflict</Typography>
        <Typography variant='body1'>
          Create a conflict for a given entity. This is useful for testing the conflict logic.
        </Typography>
        <div className='flex flex-row gap-4'>
          <Select
            className='my-4 w-1/4'
            onValueChange={(value) => {
              setSelectedConflictType(value)
            }}
            defaultValue={selectedConflictType}
            options={[
              {
                children: 'Role Conflict',
                value: roleConflict
              },
              {
                children: 'Ownership Conflict',
                value: ownershipConflict
              }
            ]}
            placeholder='Select something'
          />

          <EntityIdSearchInput
            className='my-4 flex-1'
            dataTrack='Dont track pls'
            entityKindFilter={ApplicationSearchReturnType.Company}
            selectedEntity={selectedEntityId}
            setSelectedEntity={setSelectedEntityId}
            entityLocationFilters={[EntityLocationFilterKind.ALL]}
            variant='outlined'
          />
        </div>
        {selectedConflictType === ownershipConflict && (
          <div className='mb-4 grid grid-cols-2 gap-4'>
            <OwnershipCreator
              title='Custom Ownership'
              ownershipInput={customOwnershipInput}
              setOwnershipInput={setCustomOwnershipInput}
            />
            <OwnershipCreator
              title='Conflicting Ownership'
              ownershipInput={conflictingOwnershipInput}
              setOwnershipInput={setConflictingCustomOwnershipInput}
            />
          </div>
        )}

        {selectedConflictType === roleConflict && (
          <div className='mb-4 grid grid-cols-2 gap-4'>
            <RoleCreator title='Custom role' setRoleInput={setCustomRoleInput} roleInput={customRoleInput} />
            <RoleCreator
              title='Conflicting role'
              setRoleInput={setConflictingRoleInput}
              roleInput={conflictingRoleInput}
            />
          </div>
        )}
        <Button
          data-track='no-track-pls'
          palette='primary'
          variant='contained'
          onClick={handleCreateConflict}
          loading={roleConflictLoading || ownershipConflictLoading}
        >
          Create conflict
        </Button>
      </ModalContent>
    </Modal>
  )
}

interface RoleCreatorProps {
  roleInput: BusinessRoleInput
  setRoleInput: SetStateFn<BusinessRoleInput>
  title: string
}

const RoleCreator = ({ roleInput, setRoleInput, title }: RoleCreatorProps): React.ReactNode => {
  const { data, loading: loadingRoleTitles } = useRoleTitlesQuery({})

  const selectedRoleTitle = data?.roleTitles.find((roleTitle) => roleTitle.id === roleInput.role.roleTitleId)

  return (
    <div className='flex flex-col rounded border border-divider p-4'>
      <Typography variant='subtitle2'>{title}</Typography>
      <EntityIdSearchInput
        dataTrack='Dont track pls'
        entityKindFilter={ApplicationSearchReturnType.Person}
        selectedEntity={roleInput.entity}
        setSelectedEntity={setChildState(setRoleInput, 'entity')}
        entityLocationFilters={[EntityLocationFilterKind.ALL]}
      />

      <RoleTitlesCombobox
        isCompany={false}
        isLoading={loadingRoleTitles}
        onAdd={(role) => {
          setRoleInput((prev) => ({
            ...prev,
            role: {
              ...prev.role,
              roleTitleId: role.id
            }
          }))
        }}
        roleTitles={data?.roleTitles ?? []}
        selectedRoleTitle={selectedRoleTitle}
      />
      <div className='flex gap-2'>
        <DatePicker
          onChange={(date) =>
            setRoleInput((prev) => ({
              ...prev,
              role: {
                ...prev.role,
                period: {
                  ...prev.role.period,
                  from: date?.toISOString() ?? null
                }
              }
            }))
          }
          selected={roleInput.role.period.from ? new Date(roleInput.role.period.from) : null}
          showMonthYearPicker
          dateFormat='MMMM yyyy'
          placeholderText='From'
        />
        <DatePicker
          onChange={(date) =>
            setRoleInput((prev) => ({
              ...prev,
              role: {
                ...prev.role,
                period: {
                  ...prev.role.period,
                  to: date?.toISOString() ?? null
                }
              }
            }))
          }
          selected={roleInput.role.period.to ? new Date(roleInput.role.period.to) : null}
          showMonthYearPicker
          dateFormat='MMMM yyyy'
          placeholderText='To'
        />
      </div>
    </div>
  )
}

interface OwnershipCreatorProps {
  ownershipInput: CustomOwnershipInput
  setOwnershipInput: SetStateFn<CustomOwnershipInput>
  title: string
}

const OwnershipCreator = ({ ownershipInput, setOwnershipInput, title }: OwnershipCreatorProps): React.ReactNode => {
  return (
    <div className='flex flex-col rounded border border-divider p-4'>
      <Typography variant='subtitle2'>{title}</Typography>
      <EntityIdSearchInput
        dataTrack='Dont track pls'
        entityKindFilter={ApplicationSearchReturnType.Company}
        selectedEntity={ownershipInput.entity}
        setSelectedEntity={setChildState(setOwnershipInput, 'entity')}
        variant='outlined'
      />
      <Input
        type='number'
        onChange={(e) => setOwnershipInput((prev) => ({ ...prev, ownership: Number.parseInt(e.target.value, 10) }))}
        value={ownershipInput.ownership?.toString() ?? ''}
      />
    </div>
  )
}
