// Key used to store the schema version in the cache
import { RenewalPolicy } from '@nerdwallet/apollo-cache-policies'

export const SCHEMA_VERSION_KEY = 'strise-schema-version'

// Current schema version
export const SCHEMA_VERSION = '2'

const ONE_HOUR = 1000 * 60 * 60
const TEN_MINUTES = 1000 * 60 * 10

// Configuration for Apollo Client cache invalidation policies
export const invalidationPolicies = {
  // Default time-to-live for all types
  timeToLive: ONE_HOUR,

  // Only write operations will renew the TTL for cached items
  renewalPolicy: RenewalPolicy.WriteOnly,
  // Type-specific invalidation policies
  types: {
    // Types that can be mutated by other users
    Sticker: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Tags
    TeamCompanyStatus: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Company status
    Sanction: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Sanctions
    OwnershipChart: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Owners
    EntityReviewConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Reviews
    TeamSettings: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Team settings
    UserSettings: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // User settings
    TeamActivityConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // User activities
    CompanyTeamActivityConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Company activities
    Features: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // User features
    TeamSimpleUserConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Team users
    SimpleUserConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Portfolio view assignees
    CompanyStickerConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Portfolio view tags
    CompanyUserConnection: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Assignees
    Table: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Custom owners, Custom roles, CreditReport, Sanctions
    CreditReport: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly }, // Credit score
    EntityAccess: { timeToLive: TEN_MINUTES, renewalPolicy: RenewalPolicy.WriteOnly } // Dependent on user features
  }
}
