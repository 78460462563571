import { type ReviewFlaggedEventValueFragment } from '~/graphqlTypes'
import { Flag } from '~/utils/apiTable/Flag'
import React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { FlagSeverity, ReviewFlagSeverity } from '@strise/types'
import { reviewEntityToEntity, reviewEventToEvent } from '~/components/Review/reviewValueTransformers'

const reviewFlagSeverityToFlagSeverity: Record<ReviewFlagSeverity, FlagSeverity> = {
  [ReviewFlagSeverity.Low]: FlagSeverity.Low,
  [ReviewFlagSeverity.Medium]: FlagSeverity.Medium,
  [ReviewFlagSeverity.High]: FlagSeverity.High,
  [ReviewFlagSeverity.Positive]: FlagSeverity.Positive
}

export const ReviewFlag = ({
  entityId,
  item
}: {
  entityId: string
  item: ReviewFlaggedEventValueFragment
}): React.ReactNode => {
  if (!item.value) return <ReviewItem reviewMeta={item.reviewMeta} />

  const flag = {
    ...item.value,
    severity: reviewFlagSeverityToFlagSeverity[item.value.severity],
    entity: reviewEntityToEntity(item.value.entity),
    event: item.value.event ? reviewEventToEvent(item.value.event) : null,
    __typename: 'AdverseFlag' as const
  }

  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <Flag entityId={entityId} flag={flag} />
    </ReviewItem>
  )
}
