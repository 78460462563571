import { Typography } from '@strise/ui-components'
import { t, Trans } from '@lingui/macro'
import { formatDate } from '@strise/app-shared'
import React from 'react'
import { type EntityDispositionFragment } from '@strise/app-shared/src/graphqlTypes'

export const PepDispositionMeta = ({
  disposition
}: {
  disposition: EntityDispositionFragment | null
}): React.ReactNode => {
  if (!disposition) return null

  return (
    <div className='grid place-items-center gap-2 p-1'>
      {disposition.comment && <Typography variant='aLabelSmallBold'>"{disposition.comment}"</Typography>}
      <Typography className='text-gray-20' variant='aLabelSmall'>
        <Trans>Edited by</Trans> {disposition.createdBy?.name ?? t`Deleted user`},{' '}
        {formatDate(disposition.created, { relative: false })}
      </Typography>
    </div>
  )
}
