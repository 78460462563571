import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconChevronDoubleSuperSmall = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path fill='currentColor' d='m12 2 5.196 9H6.804L12 2ZM12 22l-5.196-9h10.392L12 22Z' />
    </svg>
  )
)
IconChevronDoubleSuperSmall.displayName = 'IconChevronDoubleSuperSmall'
