import type React from 'react'
import { type SetStateFn } from '@strise/react-utils'
import { createContext } from '@strise/react-utils'

interface SidepanelContextProps {
  chartFocusNodeId: string | null
  expanded: boolean
  id: string
  scrollEl: React.RefObject<HTMLElement | null>
  setChartFocusNodeId: SetStateFn<string | null>
  setExpanded: SetStateFn<boolean | null>
  setId: SetStateFn<string | null>
  setShowHeaderEntity: SetStateFn<boolean>
  setShowNetworkRisk: SetStateFn<boolean>
  showHeaderEntity: boolean
  showNetworkRisk: boolean
}

export const SidepanelContext = createContext<SidepanelContextProps>()
