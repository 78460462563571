'use client'

import * as React from 'react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { cn } from '../../utils/className'
import { basePopperClasses } from '../variants/common'
import { type DataProps } from '@strise/react-utils'

interface PopoverArrowProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow> {}

interface PopoverContentProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {}

export interface PopoverProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Root>,
    Omit<PopoverContentProps, 'content'>,
    DataProps {
  /** Displays an arrow if `true` */
  arrow?: boolean
  /** Props applied to the arrow */
  arrowProps?: PopoverArrowProps
  /** The content of the `Popover` */
  content?: React.ReactNode
}
const Popover = React.forwardRef<React.ElementRef<typeof PopoverPrimitive.Content>, PopoverProps>(
  (
    {
      align = 'center',
      arrow,
      arrowProps,
      children,
      className,
      content,
      defaultOpen,
      modal,
      onOpenChange,
      open,
      sideOffset = 0,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Root defaultOpen={defaultOpen} modal={modal} onOpenChange={onOpenChange} open={open}>
      <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={cn(basePopperClasses.content, basePopperClasses.shadow, className)}
          {...props}
        >
          {content}
          {arrow && (
            <PopoverPrimitive.Arrow width={30} height={15} className='fill-secondary-shade-90' {...arrowProps} />
          )}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  )
)
Popover.displayName = 'Popover'

export { Popover }
