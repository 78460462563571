import { twObject } from '../../utils/className'

/** Common classes used for Button and Input */
export const baseButtonAndInputClasses = twObject({
  borders: {
    primary: 'border-primary-main',
    secondary: 'border-secondary-main',
    tertiary: 'border-tertiary-main',
    danger: 'border-semantic-danger-main'
  },
  contained: {
    primary: 'bg-primary-main text-primary-contrastText hover:bg-primary-shade-70 active:bg-primary-shade-90',
    secondary: 'bg-secondary-main text-secondary-contrastText hover:bg-secondary-shade-90 active:bg-secondary-shade-80',
    tertiary: 'bg-tertiary-main text-tertiary-contrastText hover:bg-tertiary-shade-20 active:bg-tertiary-shade-30',
    danger: 'bg-semantic-danger-main text-white hover:bg-semantic-danger-shade-75 active:bg-semantic-danger-shade-100'
  },
  outlined: {
    primary: 'text-primary-main hover:bg-primary-shade-5 active:bg-primary-shade-10',
    secondary:
      'hover:border-tertiary-main hover:bg-tertiary-main active:border-tertiary-shade-20 active:bg-tertiary-shade-20',
    tertiary: 'hover:bg-tertiary-main active:border-tertiary-shade-20 active:bg-tertiary-shade-20',
    danger: 'text-semantic-danger-main hover:bg-semantic-danger-shade-5 active:bg-semantic-danger-shade-10'
  },
  ghost: {
    primary: 'text-primary-main focus-within:bg-primary-shade-5 hover:bg-primary-shade-5 active:bg-primary-shade-10',
    secondary: 'hover:bg-tertiary-main focus-within:bg-tertiary-main active:bg-tertiary-shade-20',
    tertiary: 'hover:bg-tertiary-main focus-within:bg-tertiary-main active:bg-tertiary-shade-20',
    danger:
      'text-semantic-danger-main focus-within:bg-semantic-danger-shade-5 hover:bg-semantic-danger-shade-5 active:bg-semantic-danger-shade-10'
  },
  disabled: {
    base: 'pointer-events-none cursor-default text-text-disabled',
    contained: 'bg-background-disabled text-text-disabled',
    outlined: 'border-tertiary-main text-text-disabled'
  }
})

/** Common classes used for Tooltip, Popover and Select (all using Radix UI Popper internally) */
export const basePopperClasses = twObject({
  content: 'z-max overflow-hidden bg-secondary-shade-90 text-secondary-contrastText',
  shadow: 'shadow-md shadow-white',
  item: 'flex h-auto min-h-12 justify-start w-full cursor-pointer select-none items-center bg-secondary-shade-90 px-4 hover:bg-secondary-shade-80 focus-visible:outline-none data-[selected=true]:bg-secondary-shade-80 data-[highlighted]:bg-secondary-shade-80 data-[state=selected]:bg-secondary-shade-80 data-[state=selected]:pr-2',
  disabledItem:
    'text-secondary-shade-40 bg-secondary-shade-70 hover:bg-secondary-shade-70 data-[selected=true]:bg-secondary-shade-70 data-[highlighted]:bg-secondary-shade-70 data-[state=selected]:bg-secondary-shade-70'
})

/** Common classes used for Card */
export const baseCardClasses = twObject({
  palette: {
    white: 'bg-white',
    primary: 'border-primary-main bg-primary-main',
    secondary: 'border-secondary-main bg-secondary-main',
    tertiary: 'border-tertiary-main bg-tertiary-main',
    danger: 'bg-semantic-danger-shade-5 hover:bg-semantic-danger-shade-10',
    warning: 'bg-semantic-notice-shade-5 hover:bg-semantic-notice-shade-10',
    neutral: 'bg-gray-5'
  },
  contained: {
    hover: {
      primary: 'hover:border-blue-80 active:border-blue-90'
    },
    highlight: {
      primary: 'border-transparent bg-primary-main text-primary-contrastText',
      secondary:
        'border-transparent bg-blue-5 hover:border-blue-10 hover:bg-blue-10 active:bg-blue-20 active:border-blue-20',
      tertiary: 'bg-tertiary-main text-tertiary-contrastText',
      danger: 'bg-semantic-danger-main text-white'
    },
    secondary: 'bg-secondary-main text-secondary-contrastText hover:bg-secondary-shade-90 active:bg-secondary-shade-80',
    tertiary: 'bg-tertiary-main text-tertiary-contrastText hover:bg-tertiary-shade-20 active:bg-tertiary-shade-30',
    danger: 'bg-semantic-danger-main text-white hover:bg-semantic-danger-shade-75 active:bg-semantic-danger-shade-100'
  }
})

export const baseCardHighlightClasses = twObject({
  bottom: {
    primary: ' group-hover:bg-blue-80 group-active:bg-blue-90',
    secondary: 'group-hover:bg-blue-80 group-active:bg-blue-90',
    tertiary: 'bg-tertiary-main text-tertiary-contrastText hover:bg-tertiary-shade-20 active:bg-tertiary-shade-30',
    danger: 'bg-semantic-danger-main text-white hover:bg-semantic-danger-shade-75 active:bg-semantic-danger-shade-100'
  }
})
