import { isNil } from 'lodash-es'

export type SortDirection = 'asc' | 'desc' | false

export const removeItemFromArray = <T>(arr: T[], index: number): T[] => {
  return arr.filter((_, i) => i !== index)
}

export const replaceItemFromArray = <T>(arr: T[], index: number, newItem: T): T[] => {
  if (index > arr.length - 1) return arr

  return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)]
}

export const intersectByKey = <T extends Record<string, unknown>>(arrs: T[][], key: string): T[] => {
  if (!arrs.length) return []
  if (arrs.length === 1 && arrs[0] !== undefined) return arrs[0]

  const [firstArr, ...restArrs] = arrs

  if (!firstArr) return []

  return firstArr.filter((item1) => restArrs.every((restArr) => restArr.some((item2) => item1[key] === item2[key])))
}

export const moveItemInArray = <T>(array: T[], moveIndex: number, toIndex: number): T[] => {
  const item = array[moveIndex]
  const length = array.length
  const diff = moveIndex - toIndex

  if (!diff || !item) return array

  if (diff > 0) {
    // move left
    return [...array.slice(0, toIndex), item, ...array.slice(toIndex, moveIndex), ...array.slice(moveIndex + 1, length)]
  }

  // move right
  const targetIndex = toIndex + 1
  return [
    ...array.slice(0, moveIndex),
    ...array.slice(moveIndex + 1, targetIndex),
    item,
    ...array.slice(targetIndex, length)
  ]
}

export const filterNullishValues = <T>(arr: Array<T | null | undefined>): T[] => {
  return arr.filter((value): value is T => !isNil(value))
}
