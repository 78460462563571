import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconTwitter = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        d='M23 6.894a8.653 8.653 0 0 1-2.363.638A4.026 4.026 0 0 0 22.441 5.3a8.27 8.27 0 0 1-2.6.978A4.139 4.139 0 0 0 16.846 5c-2.27 0-4.097 1.814-4.097 4.038 0 .32.027.628.095.92a11.666 11.666 0 0 1-8.451-4.222 4.017 4.017 0 0 0-.562 2.04c0 1.399.732 2.638 1.821 3.356a4.098 4.098 0 0 1-1.852-.498v.045c0 1.962 1.421 3.591 3.285 3.966a4.15 4.15 0 0 1-1.075.133c-.263 0-.527-.014-.776-.069a4.131 4.131 0 0 0 3.831 2.813 8.32 8.32 0 0 1-5.084 1.722c-.336 0-.659-.015-.981-.056A11.675 11.675 0 0 0 9.29 21c7.545 0 11.67-6.154 11.67-11.488 0-.178-.006-.35-.015-.522A8.11 8.11 0 0 0 23 6.894Z'
      />
    </svg>
  )
)
IconTwitter.displayName = 'IconTwitter'
