export enum SettingsTab {
  USER_ACCOUNT = 'account',
  USER_LANGUAGE = 'language',
  USER_FEATURES = 'features',
  USER_NOTIFICATIONS = 'notifications',
  TEAM_SETTINGS = 'team-settings',
  TEAM_MEMBERS = 'team-members',
  TEAM_CONNECTED_APPS = 'team-connected-apps',
  TEAM_SANCTIONS = 'team-sanctions',
  TEAM_PDF_SETTINGS = 'team-pdf-settings',
  TEAM_TRIGGERS = 'team-triggers',
  TEAM_REVIEW_SETTINGS = 'team-review-settings',
  LOGOUT = 'logout'
}
