import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { t } from '@lingui/macro'
import { useHelpCenterLink } from '~/utils/userHooks'
import * as React from 'react'
import { SanctionsEntityContent } from '~/components/PepAndSanctions/SanctionsEntityContent'
import { SidepanelCard } from '~/components/Sidepanel/SidepanelCard'
import { DataSources } from '~/components/Sidepanel/DataSources'
import { type SupportedSidepanelEntityFragment } from '~/components/Sidepanel/utils/sidepanelUtils'
import { useEntitySanctionInfo } from '~/utils/sanctions'

export const SidepanelSanctionsCard: React.FC<{
  entity: SupportedSidepanelEntityFragment
}> = ({ entity }) => {
  const features = useCurrentUserFeatures()
  const helpCenterLink = useHelpCenterLink()

  const { sanctionInfo } = useEntitySanctionInfo(entity.id)

  if (!features.PEP_AND_SANCTIONS_V2) return null

  if (!sanctionInfo) return null

  return (
    <SidepanelCard title={t`Sanctions`} id='sanctions'>
      <SanctionsEntityContent
        className='px-4'
        entity={entity}
        entityName={entity.name}
        sanctionInfo={sanctionInfo}
        associatedCompanies={null}
        currentCompanyId={null}
        noLink
      />

      <DataSources
        dataSources={entity.dataSources.sanctions}
        hideLinks
        learnMoreLink={`${helpCenterLink}/articles/37050-how-strise-sanctions-work`}
      />
    </SidepanelCard>
  )
}
