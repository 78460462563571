import { useActiveContentView } from '~/utils/viewsHooks'
import { matchPath, useLocation } from 'react-router-dom'
import { migrateQueryString, updateQueryString } from '@strise/ts-utils'
import { ContentViews } from '~/utils/urls'

import { QUERY_PARAMS, QUERY_PARAMS_LEGACY } from '~/constants'

// Need to create a camelCase keys as the path pattern doesn't support kebab-case
const sidepanelEntityIdPathKey = 'sidepanelEntityId'
const eventIdPathKey = 'eventId'
const eventEntityIdPathKey = 'eventEntityId'

// Need this object to map from path pattern camelCase keys to query param kebab-case keys
const pathKeyToQueryParam = {
  [sidepanelEntityIdPathKey]: QUERY_PARAMS.sidepanelEntityId,
  [eventIdPathKey]: QUERY_PARAMS.eventId,
  [eventEntityIdPathKey]: QUERY_PARAMS.eventEntityId
}

const contentViews = Object.values(ContentViews)
const entityKinds = ['person', 'company', 'globalperson', 'globalcompany']

const extractQueryParamsFromLegacyRoute = (pathname: string): Partial<Record<string, string>> | null => {
  // Order matters!
  const routes = [
    `/event/:${eventEntityIdPathKey}/:${eventIdPathKey}`,
    ...entityKinds.flatMap((sidepanelEntityKind) => {
      return [
        ...contentViews.flatMap((contentView) => {
          return [
            `/${contentView}/${sidepanelEntityKind}/:${sidepanelEntityIdPathKey}`,
            `/${contentView}/${sidepanelEntityKind}/:${sidepanelEntityIdPathKey}/event/:${eventEntityIdPathKey}/:${eventIdPathKey}`,
            `/${contentView}/event/:${eventEntityIdPathKey}/:${eventIdPathKey}`
          ]
        }),
        `/${sidepanelEntityKind}/:${sidepanelEntityIdPathKey}/event/:${eventEntityIdPathKey}/:${eventIdPathKey}`,
        `/${sidepanelEntityKind}/:${sidepanelEntityIdPathKey}`
      ]
    })
  ]

  const pathMatch = routes.map((route) => matchPath(route, pathname)).find((match) => !!match)
  if (!pathMatch) return null

  const queryParams = Object.fromEntries(
    Object.entries(pathKeyToQueryParam)
      .filter(([key]) => pathMatch.params[key])
      .map(([key, queryParam]) => [queryParam, pathMatch.params[key]])
  )

  return queryParams
}

export const extractQueryStringFromLegacyLocation = (pathname: string, queryString: string): string | null => {
  const queryStringWithoutQuestionMark = queryString.startsWith('?') ? queryString.slice(1) : queryString

  const repairedQueryParams = extractQueryParamsFromLegacyRoute(pathname)
  const migratedQueryString = migrateQueryString(queryString, QUERY_PARAMS_LEGACY)

  const updatedQueryString = repairedQueryParams
    ? updateQueryString(migratedQueryString, repairedQueryParams)
    : migratedQueryString

  if (updatedQueryString === queryStringWithoutQuestionMark) return null

  return updatedQueryString
}

export const useRepairedPathFromLegacyLocation = (): { pathname: string; search: string } | null => {
  const location = useLocation()
  const activeContentView = useActiveContentView()
  const homeView = ContentViews.Home

  const repairedQueryString = extractQueryStringFromLegacyLocation(location.pathname, location.search)

  if (!repairedQueryString) return null

  return { pathname: `/${activeContentView ?? homeView}`, search: repairedQueryString }
}
