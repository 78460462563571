import { CreditScore } from '~/utils/apiTable/CreditScore'
import {
  type BaseEntityLikeFragment,
  type CreditReportFragment,
  type ReviewCreditScoreValueFragment
} from '~/graphqlTypes'
import React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'

export const ReviewCreditScore = ({
  entity,
  item
}: {
  entity: BaseEntityLikeFragment
  item: ReviewCreditScoreValueFragment
}): React.ReactNode => {
  if (!item.value) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  // @ts-expect-error TODO: Fix
  const creditReport: CreditReportFragment = {
    ...item.value,
    __typename: 'CreditReport' as const
  }
  return (
    <ReviewItem reviewMeta={item.reviewMeta}>
      <CreditScore entity={entity} creditReport={creditReport} />
    </ReviewItem>
  )
}
