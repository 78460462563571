import { EntityLink } from '~/components/EntityLink/EntityLink'
import { TeamActivityCompleteButton } from '~/components/TeamActivity/TeamActivityCompleteButton'
import { useTeamActivityKindPrefix } from '~/components/TeamActivity/teamActivityHooks'
import { getTeamActivityKindText, getTeamActivityNoteColors } from '~/components/TeamActivity/teamActivityUtils'
import { useUpdateTeamActivityV2Mutation } from '~/graphqlOperations'
import { type TeamActivityFragment, type UpdateTeamActivityV2Mutation } from '~/graphqlTypes'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { refreshActivityViewState } from '~/state'
import { toast, UserAvatar } from '@strise/app-shared'
import { undoToast } from '@strise/app-shared/src/utils/toasts'
import { cn, Typography } from '@strise/ui-components'
import { type DivProps } from '@strise/react-utils'
import { ReminderStatus } from '@strise/types'
import * as React from 'react'
import { useActivityCompleteButtonStyle, useActivityStyle } from '~/views/Activity/utils/activityHooks'
import { TestIDs } from '~/utils/testIDs'

const ActivityCompleteButton = ({ activity }: { activity: TeamActivityFragment }): React.ReactNode => {
  const [updatedStatus, setUpdatedStatus] = React.useState<ReminderStatus | null>(null)

  const handleUpdateCompleted = (data: UpdateTeamActivityV2Mutation): void => {
    refreshActivityViewState(refreshActivityViewState() + 1)

    // To trigger the useEffect to create toast message
    setUpdatedStatus(data.updateTeamActivityV2.teamActivity.reminder?.status ?? null)
  }

  const [update, { loading: updateLoading }] = useUpdateTeamActivityV2Mutation({ onCompleted: handleUpdateCompleted })

  const {
    backgroundImage,
    buttonText,
    className: activityClassName,
    color,
    palette,
    trackId
  } = useActivityCompleteButtonStyle(activity.timestamp, activity.reminder)

  const updateReminderStatus = async (): Promise<void> => {
    const input = {
      note: activity.note,
      kind: activity.kind,
      timestamp: activity.timestamp || new Date().toISOString(),
      assignee: activity.assignee?.id ?? undefined,
      reminderStatus:
        activity.reminder?.status === ReminderStatus.Inactive ? ReminderStatus.Active : ReminderStatus.Inactive
    }
    await update({ variables: { teamActivity: activity.id, input } })
  }

  React.useEffect(() => {
    if (!updatedStatus) return

    if (updatedStatus === ReminderStatus.Active) {
      toast.success(t`Team activity updated`)
      return
    }

    undoToast({
      label: t`Team activity updated`,
      onUndo: updateReminderStatus,
      i18n
    })
  }, [updatedStatus])

  return (
    <TeamActivityCompleteButton
      disabled={updateLoading}
      className={cn('border-0 pl-0 pr-2', activityClassName)}
      loading={updateLoading}
      color={color}
      palette={palette}
      trackId={trackId}
      style={{ backgroundImage }}
      onClick={updateReminderStatus}
      data-tesid='activity-view-team-activity-complete-button'
    >
      {buttonText}
    </TeamActivityCompleteButton>
  )
}

export const ActivityDetailsCard: React.FC<
  {
    activity: TeamActivityFragment
    isCompletedActivities: boolean
    isFirst: boolean
  } & DivProps
> = ({ activity, className, isCompletedActivities, isFirst, ...props }) => {
  const { noteColor } = getTeamActivityNoteColors(activity.kind)
  const { titlePrefix } = useTeamActivityKindPrefix(activity.kind, activity.timestamp, activity.createdBy?.id)
  const noteTitle = getTeamActivityKindText(activity.kind).toLowerCase()
  const { ReminderIcon, backgroundColorClass, colorClassName, text } = useActivityStyle(activity.timestamp)

  const name = t({ id: 'You (Du)', message: 'You' })

  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  const handleMouseEnter = (): void => setIsHovered(true)
  const handleMouseLeave = (): void => setIsHovered(false)

  const user = activity.assignee ?? activity.createdBy

  return (
    <EntityLink
      className='text-text-primary hover:no-underline'
      wrapperProps={{
        className: 'block',
        'data-id': TestIDs.Activities.activityCard
      }}
      entity={activity.entity}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      noTooltip
      noFlags
      noLifeStatus
    >
      <div
        className={cn(
          'relative z-[1] mb-3 flex w-full items-center overflow-hidden rounded p-4',
          isHovered ? 'bg-tertiary-main' : 'bg-tertiary-shade-5',
          { 'mt-3': isFirst },
          className
        )}
        {...props}
      >
        <div className='px-8'>
          {!isCompletedActivities && (
            <>
              {backgroundColorClass && (
                <div className='absolute left-0 top-[20px] z-[-1] h-[200px] w-[193px] rounded'>
                  <div
                    className={cn('absolute left-[-55px] top-[-53px] size-full rounded-full', backgroundColorClass)}
                  />
                </div>
              )}

              <ReminderIcon className={colorClassName} />
            </>
          )}

          {isCompletedActivities && user && <UserAvatar user={user} className='mr-3 size-8 bg-divider' />}
        </div>
        <div>
          <Typography variant='aLabel' className={colorClassName ?? 'text-text-secondary'}>
            {text}
          </Typography>
          <Typography className='my-2 font-regular' variant='subtitle1'>
            {`${name} ${titlePrefix} `}
            <Typography className={cn('px-1 font-regular', noteColor)} component='span' variant='subtitle1'>
              {` ${noteTitle}`}
            </Typography>
            <Typography component='span' variant='subtitle1'>
              {` with ${activity.entity.name}`}
            </Typography>
          </Typography>
          <ActivityCompleteButton activity={activity} />
        </div>
      </div>
    </EntityLink>
  )
}
