import { LoaderRound, Typography } from '@strise/ui-components'
import { useCurrentUserFeatures } from '~/contexts/CurrentUserSettingsContext/CurrentUserSettingsContext'
import { useCompanyCollateralQuery } from '~/graphqlOperations'
import { type CollateralFragment, type CreditReportFragment } from '~/graphqlTypes'
import { t, Trans } from '@lingui/macro'
import { formatDate, Empty } from '@strise/app-shared'
import { formatNumber } from '@strise/ts-utils'
import { Table, TableCell, TableRow } from '@strise/ui-components-legacy'
import * as React from 'react'
import { EntityLink } from '~/components/EntityLink/EntityLink'
import { SidepanelContext } from '~/components/Sidepanel/SidepanelContext/SidepanelContext'
import { useContext } from '@strise/react-utils'

const CollateralRow: React.FC<{
  node: CollateralFragment
}> = ({ node }) => {
  const amount = [formatNumber(node.amount), node.currency].join(' ')

  return (
    <TableRow style={{ verticalAlign: 'top' }}>
      <TableCell size='large' pl={0}>
        <Typography variant='aLabel'>
          <div className='text-text-secondary'>{node.description}</div>
          {node.creditor ? (
            <EntityLink entity={node.creditor} className='font-regular' />
          ) : (
            <div>{node.creditorName || '-'}</div>
          )}
        </Typography>
      </TableCell>
      <TableCell size='large' textAlign='right' numeric>
        {amount}
      </TableCell>
      <TableCell size='large' pr={0} color='text.secondary' textAlign='right'>
        {formatDate(node.date, { relative: false })}
      </TableCell>
    </TableRow>
  )
}

export const SidepanelCollateral = ({
  creditReport
}: {
  creditReport?: CreditReportFragment | null
}): React.ReactNode => {
  const features = useCurrentUserFeatures()
  const { id } = useContext(SidepanelContext)
  const { data, loading } = useCompanyCollateralQuery({
    variables: { id },
    skip: !features.COLLATERAL_DATA_LOSOREREGISTERET
  })

  const creditReportCollaterals = creditReport?.collateral?.edges ?? []
  const collateralEdges = data?.company.collaterals.edges ?? creditReportCollaterals

  if (loading) {
    return (
      <div className='flex items-center justify-center p-8'>
        <LoaderRound />
      </div>
    )
  }

  if (!collateralEdges.length) {
    return (
      <Empty title={t`No collateral found`}>
        <Trans>We couldn't find any collateral on this company</Trans>
      </Empty>
    )
  }

  return (
    <Table>
      <tbody>
        {collateralEdges.map(({ node }, index) => {
          return <CollateralRow key={index} node={node} />
        })}
      </tbody>
    </Table>
  )
}
