import { type ReviewPepValueFragment } from '~/graphqlTypes'
import React from 'react'
import { ReviewItem } from '~/components/Review/ReviewItem'
import { reviewEntityToPerson, reviewPepToPep } from '~/components/Review/reviewValueTransformers'
import { PepPersonContent } from '~/components/PepAndSanctions/PepPersonContent'

export const ReviewPepWithDisposition = ({ item }: { item: ReviewPepValueFragment }) => {
  if (!item.values.length) {
    return <ReviewItem reviewMeta={item.reviewMeta} />
  }

  const entity = item.values[0]?.entity ? reviewEntityToPerson(item.values[0].entity) : null

  const pepInfos = item.values.map((value) => reviewPepToPep(value))

  return (
    <ReviewItem reviewMeta={item.reviewMeta} className='pb-2'>
      <PepPersonContent
        person={entity}
        pepInfos={pepInfos}
        associatedCompanies={null}
        currentCompanyId={null}
        showSystemSuggestedFalse={false}
        enableEdit={false}
      />
    </ReviewItem>
  )
}
