import * as React from 'react'
import { cn } from '../../utils/className'
import { iconVariants, type IconProps } from '../../utils/iconUtils'
export const IconChevronRight = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, size, ...props }, ref): React.ReactNode => (
    <svg
      ref={ref}
      className={cn(
        iconVariants({
          className,
          size
        })
      )}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M18.414 12 7.707 22.707l-1.414-1.414L15.586 12 6.293 2.707l1.414-1.414L18.414 12Z'
        clipRule='evenodd'
      />
    </svg>
  )
)
IconChevronRight.displayName = 'IconChevronRight'
