import { IconButton, IconLink } from '@strise/ui-components'
import * as React from 'react'
import { copyString } from '~/utils/hooks'
import { t } from '@lingui/macro'

export const SidepanelCopyEntityLink: React.FC<{ entityLink: string }> = ({ entityLink }) => {
  return (
    <IconButton
      className='p-2'
      variant='ghost'
      onClick={copyString(entityLink)}
      disabled={!entityLink}
      palette='tertiary'
      aria-label={t`Copy company link to clipboard`}
      data-track='Sidepanel / Copy company link to clipboard'
    >
      <IconLink />
    </IconButton>
  )
}
